<template>
    <v-container class="mt-12 mb-12">
        <v-row>
            <v-col  cols="12" md="12">
                <v-card elevation="5">
                    <v-card-title class="d-flex justify-center">
                        Descargar Estudio
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center">
                        
                    </v-card-subtitle>
                    <v-card-text>
                        
                        <v-row>
                            <v-col  v-if="1==2"
                                    cols="12" md="6">
                                <v-text-field
                                    label="RFC o Número de Contrato"
                                    dense
                                    v-model="filtro.Contrato"
                                ></v-text-field><!--@keyup.enter="getCreditos()"-->
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Folio de estudio"
                                    dense
                                    v-model="filtro.ClaveUnica"
                                ></v-text-field><!--@keyup.enter="getCreditos()"-->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-btn
                                    color="blue"
                                    block
                                    class="white--text"                                    
                                    @click="recuperaEstudio()"
                                ><!--@click="getCreditos()"-->
                                Consultar Estudio
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogPagos.show" persistent max-width="900">
            <v-card>
                <v-card-title>
                    <span > {{ dialogPagos.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="cerrarListaPagos()"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>

                    <div v-if="listaCredito.length>0">
                        <!-- Muestra lista-->                        
                        <v-list two-line>
                            
                            <v-list-item v-for="(item, index) of listaCredito" :key="index"
                                        @click="()=>{}">
                                <v-list-item-content>
                                    <v-row>
                                        <v-col cols="8" md="8">
                                            A pagar: $ {{ sumaCreditoMoratorio(item.MontoTotal, item.CargoMoratorio) }}
                                        </v-col>
                                        <v-col cols="4" md="4">
                                            
                                            <v-btn
                                                class="white--text"
                                                color="blue"
                                                small
                                                block
                                                @click="goPago(item.IdCredito)"
                                            >
                                                Pagar
                                            </v-btn>

                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </div>

                    <div v-else class="d-flex justify-center">
                        
                        <div v-if="vProgress.show">
                            <v-progress-circular
                                :size="50"
                                color="blue"
                                indeterminate
                            ></v-progress-circular>

                        </div>

                        <div v-else>
                            {{ vProgress.text }}
                        </div>

                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>

        <!--
            <StripeCardComponent></StripeCardComponent>
        -->
        
    </v-container>
</template>

<script>
import {mapState} from "vuex";
//import ApiCreditoService from '@/services/ApiCreditoService';
import ApiEstudiosService from '@/services/ApiEstudiosService';

import StripeCardComponent from '@/components/Pagos/StripeCardComponent';
import ListaCreditoComponent from '@/components/Pagos/ListaCreditoComponent';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN', 'StripePK', 'Empresa']),
    },
    //#endregion

    //#region Components
    components:{
        StripeCardComponent,
        ListaCreditoComponent,
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiEstudios: new  ApiEstudiosService(),
            //------------------------------------------------
            dialogPagos:{
                show:false,
                text:'Saldos pendientes'
            },
            //------------------------------------------------
            filtro:{
                Contrato:null,
                ClaveUnica:null
            },
            estudio:{},
            //------------------------------------------------
            listaCredito:[],
            vProgress:{
                show:false,
                text:'No hay saldos pendientes.',
            },
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la vista
        
        //#endregion


        //#region Metodos de la api de credito
        recuperaEstudio(){

            this.apiEstudios.recuperaEstudioUnico( this.URLApi, this.Empresa, this.filtro.ClaveUnica)
            .then ( response => {
                console.log('recuperaEstudio.data', response.data);

            })
            .catch(error=>{console.log(error);});



        },
        //#endregion
    }
    //#endregion

}
</script>