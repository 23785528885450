<template>
    <div>
        <HeaderAppComponent
            tituloPagina="Usuarios"
            />

        <v-container>
            <v-row>
                <v-col cols="12" md="12">
                    <UsuariosCatalogoComponent />
                </v-col>
                <!--
                    
                <v-col cols="12" md="6">
                    <ListaUsuariosComponent @emitOUsuario="oUsuario=$event" 
                                            @emitBanderaUpdate="banderaUpdate=$event"/>
                </v-col>

                -->

            </v-row>
        </v-container>
    </div>
</template>
<script> 
import HeaderAppComponent from '@/components/HeaderAppComponent'; 

import UsuariosCatalogoComponent from '@/components/Usuarios/UsuariosCatalogoComponent'; 
import ListaUsuariosComponent from '@/components/Usuarios/ListaUsuariosComponent';

export default {
    components:{
        UsuariosCatalogoComponent,
        ListaUsuariosComponent,
        HeaderAppComponent         
    }, 
    
    data(){
        return{ 
            banderaUpdate:false,
            oUsuario:{
                Usuario:null,
                Nombre:null,
                Contraseña:null,
                Usuarios:null,

                Abonos:null,
                Configuraciones:null,
                Desempeños:null,
                Empeños:null,
                Refrendos:null,
                Reportes:null,
                Sucursal:null,
                Traspasos:null,
                Vencimientos:null,
                Ventas:null,
            }
        }
    },

    methods:{
        
    },

}
</script> 
