import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //Local
    //URLApi: 'http://localhost:8080/system/system-apisam/public/api',
    //URLSrc: 'http://localhost:8080/system/system-apisam/storage/app',

    //Production Promedica
    URLApi: 'https://sampromedica.com/apisam/api',
    URLSrc: 'https://sampromedica.com/apisam/storage/app',

    //Production EMA
    //URLApi: 'https://sampromedica.com/apiema/api',
    //URLSrc: 'https://sampromedica.com/apiema/storage/app',

    StripePK:'pk_test_51JDA5NBglZzYDBFkC4KvZAvgDcADZMclaoAmsLNLzn9d1GY9o3lpaBwS8wP68A2G2SB98NXKEkv0pbnVqmyZVyWk00Tt33irvS',
    VersionApp: '2.75.242',
    FechaPublicacion:'20 de Mayo 2023',
    Empresa:'GENERAL',//EMA //PROMEDICA
    User:{},
    TOKEN:'',
    EstudioLab:{
      Numero: null, 
      Contrato: null, 
      Socio: null, 
      TipoEstudio: null, 
      Fecha: null, 
      FechaHora: null, 
      HoraLlamada: null, 
      Usuario: null, 
      Despacho: null, 
      Salida: null, 
      Llegada: null, 
      Traslado: null, 
      Hospital: null, 
      Libre: null, 
      MS: null, 
      Solicitante: null, 
      Edad: null, 
      Sexo: null, 
      Beneficiario: null, 
      Paciente: null, 
      Telefonos: null, 
      Domicilio: null, 
      Signos: null, 
      TipoServicio: null, 
      Triage: null, 
      Equipo: null, 
      Horario: null, 
      NumeroLab: null, 
      Diagnostico: null, 
      Tratamiento: null, 
      Observaciones: null, 
      PadecimientoActual: null, 
      TratamientoActual: null, 
      InicioSintomas: null, 
      ExploracionFisica: null, 
      AntecedentesPersonalesPato: null, 
      EstadoCivil: null, 
      GlasgowMotora: null, 
      GlasgowRespuestaVerbal: null, 
      GlasgowAperturaOcular: null, 
      Estatus: null, 
      Cancelacion: null, 
      Destino: null, 
      Viaje: null, 
      Extra1: null, 
      NumLatidos: null, 
      Estatura: null, 
      Medico: null, 
      Oxigenacion: null, 
      Peso: null
    }
  },
  mutations: {
    setUser(state, user){
      //console.log('setUser', user);
      state.User = user;
    },
    setToken(state, TKN){
      //console.log('setToken', TKN)
      state.TOKEN = TKN;
    },
    setEstudioLab(state, EstudioLab){
      state.EstudioLab = EstudioLab;
    },
  },
  actions: {
  },
  modules: {
  }
})
