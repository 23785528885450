<template>
    <div>
        <v-form @submit.prevent>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="oUser.Usuario"
                        label="Usuario"
                        @keyup.enter="doLogin()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="oUser.Contrasena"
                        label="Contraseña"
                        type="password"
                        @keyup.enter="doLogin()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn  
                        block 
                        color="blue" 
                        class="white--text"
                        @click="doLogin()"

                    >
                        Entrar 
                        
                        <v-icon
                            v-if="!clickLogin"
                        > mdi-login  </v-icon>

                        <v-progress-circular
                                    v-else
                                    :size="20"
                                    color="white"
                                    indeterminate
                                ></v-progress-circular>

                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert 
                        v-model="oAlert.show" 
                        type="info"
                        color="orange" 
                        transition="scale-transition"
                        @click="oAlert.show =false " 
                    >
                        {{ oAlert.text }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import ApiUsuariosService from '@/services/ApiUsuariosService';
import {mapMutations, mapState} from "vuex";

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN']),
    },
    //#endregion

    //#region Props
    props:{
        textoRuta:String,
        Ruta:String
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiUser:new ApiUsuariosService(),
            oUser:{
                Nombre:'',
                Usuario:'',
                Contrasena:'',
                getToken:null
            },
            oAlert:{
                show:false,
                text:'',
                type:''
            },
            clickLogin:false,
            modulos:{
                Ambulancias: { clave: 'AMBULANCIAS', texto:'Ambulancias', ruta:'/ambulancias' },
                Contratos: { clave: 'CONTRATOS', texto:'Contratos', ruta:'/contratos' },
                PuntoVenta: { clave: 'PUNTOVENTA', texto:'Punto de Venta', ruta:'' },
                Inventario: { clave: 'INVENTARIO', texto:'Inventario', ruta:'/inventario' },
                Pagos: { clave: 'PAGOS', texto:'Pagos', ruta:'' },
                Configuracion: { clave: 'CONFIGURACION', texto:'Configuración', ruta:'/configuracion' },
                Reportes: { clave: 'REPORTES', texto:'Reportes', ruta:'' },
                Usuarios: { clave: 'USUARIOS', texto:'Usuarios', ruta:'' },
                Estudios: { clave: 'ESTUDIOS', texto:'Estudios', ruta:'' },
                Salir: { clave: 'SALIR', texto:'Salir', ruta:'' }
            }
        }
    },
    //#endregion

    //#region Metodos
    methods:{
        ...mapMutations(['setUser', 'setToken']),
        doLogin(){
            this.oAlert.show = false;
            this.clickLogin = true;
            if(this.oUser.Usuario == '' || this.oUser.Contrasena == ''){
                 this.clickLogin = false;
                 return; 
            }

            this.apiUser.login(this.URLApi , 
                        this.oUser.Nombre, this.oUser.Usuario, this.oUser.Contrasena )
            .then( response =>{ 
                const oLogin = response.data;
                //console.log('oLogin', oLogin);

                if(oLogin.OK){
                    this.setUser(oLogin.USER);
                    this.setToken(oLogin.TOKEN);
                    this.comprobarAcceso(oLogin.USER);
                }
                else{
                    this.showAlert('info', 'Usuario y/o Contraseña incorrectos');
                }

                this.clickLogin = false;
                
            })
            .catch( error => {
                console.log(error);
                this.clickLogin = false;

            } );
        },

        comprobarAcceso(Usuario){
            let flagPermiso = false;
            let clave = '';

            //console.log('Usuario', Usuario);
            //console.log('this.textoRuta', this.textoRuta);
            //console.log('this.modulos.Usuarios.clave', this.modulos.Usuarios.clave);

            //debugger;

            switch(this.textoRuta){
                case this.modulos.Ambulancias.clave:
                    //
                    if( Number.parseInt(Usuario.Crum) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Contratos.clave:
                    //
                        if( Number.parseInt(Usuario.Configuraciones) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.PuntoVenta.clave:
                    //
                        if( Number.parseInt(Usuario.Ventas) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Inventario.clave:
                    //
                        if( Number.parseInt(Usuario.Configuraciones) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Pagos.clave:
                    //
                        if( Number.parseInt(Usuario.Abonos) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Configuracion.clave:
                    //
                        if( Number.parseInt(Usuario.Configuraciones) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Reportes.clave:
                    //
                        if( Number.parseInt(Usuario.Reportes) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Usuarios.clave:
                    //
                        if( Number.parseInt(Usuario.Usuarios) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Estudios.clave:
                    //
                        if( Number.parseInt(Usuario.Estudios) == 1 ){ flagPermiso = true; }
                    break;
                case this.modulos.Salir.clave:
                    //
                    break;
            }
            
            if(flagPermiso){
                if(this.Ruta == ''){
                    this.showAlert('info', 'Módulo en construcción');
                    return;
                }
                //console.log('LoginForm.Ruta', this.Ruta);
                this.goRuta(this.Ruta);
            }
            else{
                this.showAlert('info', 'Acceso Denegado');
            }
        },

        goRuta(ruta){
            if( ruta =='' ){ return; }

            this.$router.push(ruta);
        },

        showAlert(type, text){
            //console.log('showAlert');
            this.oAlert.type = type;
            this.oAlert.text = text;
            this.oAlert.show = true;

        },

    },
    //#endregion
}
</script>