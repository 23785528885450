<template>
    <v-container>
        <v-row>
            <v-col>
                <h3 class="text-center">
                    <b>Listado de usuarios</b>
                </h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaUsuarios"
                    :single-select="singleSelect"
                    :footer-props="{
                    'items-per-page-text':'Usuarios por página',
                    }"
                    :loading="loadingDatatable"
                    loading-text="Cargando datos..."
                    no-data-text="Sin datos"
                    item-key="Usuario"
                    class="elevation-1"
                    @click:row="handleClick"> 
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ApiUsuarios from '@/services/ApiUsuariosService';
import {mapState} from "vuex";

export default { 
    props: {

    },

    computed:{
        ...mapState(['URLSrc', 'URLApi']),
    }, 

    data(){
        return{
            api: new ApiUsuarios(),
            listaUsuarios:[],
            loadingDatatable:true,
            singleSelect: true,
            selected: [],
            headers:[
                { text: 'Usuario', value: 'Usuario' },
                { text: 'Nombre', value: 'Nombre' },
                //{ text: 'Contraseña', value: 'Contraseña' },
            ],
            banderaUpdate:false,
            oUsuario:{
                Usuario:null,
                Nombre:null,
                Contraseña:null,
                Usuarios:null,

                Abonos:null,
                Configuraciones:null,
                Desempeños:null,
                Empeños:null,
                Refrendos:null,
                Reportes:null,
                Sucursal:null,
                Traspasos:null,
                Vencimientos:null,
                Ventas:null,
            }
        }
    },

    methods:{
        //#region Metodos de la vista
        handleClick(value) {
            //console.log('handleClick', value);
            this.fillOUsuario( value );
        },

        emitToFather(){
            //console.log('emitToFather');
            this.$emit('emitOUsuario',this.oUsuario);
            this.$emit('emitBanderaUpdate', true);
        },

        fillOUsuario(item){
            this.oUsuario.Usuario           = item.Usuario;
            this.oUsuario.Nombre            = item.Nombre;
            this.oUsuario.Contraseña        = item.Contraseña;
            this.oUsuario.Usuarios          = item.Usuarios;

            this.oUsuario.Abonos            = item.Abonos;
            this.oUsuario.Configuraciones   = item.Configuraciones;
            this.oUsuario.Desempeños        = item.Desempeños;
            this.oUsuario.Empeños           = item.Empeños;
            this.oUsuario.Refrendos         = item.Refrendos;
            this.oUsuario.Reportes          = item.Reportes;
            this.oUsuario.Sucursal          = item.Sucursal;
            this.oUsuario.Traspasos         = item.Traspasos;
            this.oUsuario.Vencimientos      = item.Vencimientos;
            this.oUsuario.Ventas            = item.Ventas;


            this.emitToFather();
        },
        //#endregion

        //#region Metodos de Api

        getUsuarios(){
            this.api.recuperaUsuarios(this.URLApi, '')
            .then( response =>{

                //console.log("listaUsuarios.data", response.data);
                this.listaUsuarios = response.data.objeto;
                this.loadingDatatable = false;

            })
            .catch( error=>{
                console.log(error);
                this.loadingDatatable = false;
            });
        }

        //#endregion
    },

    mounted(){
        this.getUsuarios();
    },
}
</script>