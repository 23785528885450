<template>
    <div>
        <v-row>
            <v-col>
                <v-form>
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-btn 
                                @click="nuevaVenta()"
                                color="blue"
                                class="white--text"
                                block
                                :disabled="ventaEnCurso"
                                >
                                Nueva Venta
                                <v-icon> mdi-cart </v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="1">
                            <CorteOReporteComponent></CorteOReporteComponent>
                        </v-col>

                        <v-col cols="12" offset-md="2" md="3">
                            <v-text-field
                                v-if="DatosPago.FormaPago == 'EFECTIVO'"
                                label="Importe"
                                v-model="oVenta.Abono"
                                prefix="$"
                                type="number"
                                :disabled="!ventaEnCurso"
                                @keyup.enter="verificarVenta()"
                            />
                            <v-text-field
                                v-else
                                label="Folio ticket"
                                v-model="oVenta.FolioPagoTarjeta"
                                :disabled="!ventaEnCurso"
                                @keyup.enter="verificarVenta()"
                            />

                        </v-col>

                        <v-col cols="12" md="2">
                            <v-btn 
                                @click="verificarVenta()"
                                color="orange"
                                class="white--text"
                                block
                                large
                                :disabled="!ventaEnCurso"
                                >
                                Pagar $ {{ ventaDescuento }}
                                <v-icon> mdi-check-circle </v-icon>
                            </v-btn>

                        </v-col>

                        <v-col cols="12" md="2">
                            <v-btn 
                                @click="nuevaVenta()"
                                color="red"
                                class="white--text"
                                block
                                large
                                :disabled="!ventaEnCurso"
                                >
                                Cancelar
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>

                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Número Almacen"
                                ref="txtNumeroAlmacen"
                                v-model="oArticulo.NumeroAlmacen"
                                append-icon="mdi-magnify"
                                @click:append="openDialog( opcClave )"
                                @keyup.enter="buscarArticulo()"
                                @click:clear="clearBusqueda()"
                                clearable
                                :loading="loadBusqueda"
                                :disabled="!ventaEnCurso"
                            />
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Descripción"
                                ref="txtDescripcion"
                                v-model="oArticulo.Descripcion"
                                append-icon="mdi-magnify"
                                @click:append="openDialog( opcDescripcion )"
                                @keyup.enter="buscarArticulo()"
                                @click:clear="clearBusqueda()"
                                clearable
                                :loading="loadBusqueda"
                                :disabled="!ventaEnCurso"
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-text-field
                                label="Precio"
                                v-model="precioPublico"
                                prefix="$"
                                readonly
                                type="number"
                                :disabled="!ventaEnCurso"
                            />
                            <!--prepend-inner-icon="mdi-currency-usd"-->
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-text-field
                                label="Cantidad"
                                ref="txtCantidad"
                                v-model="oArticulo.cantidadCompra"
                                type="number"
                                @keyup.enter="agregarCompra(oArticulo)"
                                prepend-inner-icon="mdi-minus"
                                @click:prepend-inner="oArticulo.cantidadCompra--"
                                append-icon="mdi-plus"
                                @click:append="oArticulo.cantidadCompra++"
                                :disabled="!ventaEnCurso"
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-btn 
                                color="green"
                                class="white--text"
                                block
                                @click="agregarCompra( oArticulo )"
                                :disabled="!ventaEnCurso"
                                >
                                Agregar
                                <v-icon> mdi-plus </v-icon>
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <v-row>
            <!--INICIO Lista de articulos-->
            <v-col cols="12" md="12">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaVentaDetalle"
                    :single-select="singleSelect"
                    :items-per-page="15"
                    :loading="loadingDatatable"
                    :footer-props="{
                        'items-per-page-text':'Productos por página',
                    }"
                    item-key="Clave"
                    class="elevation-1"
                    loading-text="Cargando..."
                    no-data-text="Sin productos."
                    @click:row="handleClick"
                    @dblclick:row="handleDblClick"
                >
                </v-data-table>

            </v-col>
            <!--FIN Lista de articulos-->

            <!--INICIO Botones de Accion-->
            <v-col cols="12" md="3">

            </v-col>
            <!--FIN Botones de Accion-->

        </v-row>

        <!--Inicio Dialogo para busqueda-->
        <v-dialog v-model="dialogBusqueda.show" max-width="850">
            <v-card>
                <v-card-title>
                    <span > Busqueda </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogBusqueda.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="5">
                            <v-text-field                                
                                label="Número Almacen"
                                v-model="oArticulo.NumeroAlmacen"
                                @keyup.enter="buscarArticulo()"
                                @click:clear="clearBusqueda()"
                                clearable
                                :loading="loadBusqueda"
                            />
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-text-field
                                label="Descripción"
                                v-model="oArticulo.Descripcion"
                                @keyup.enter="buscarArticulo()"
                                @click:clear="clearBusqueda()"
                                clearable
                                :loading="loadBusqueda"
                            />
                        </v-col>

                        <v-col cols="12" md="2">                            
                            <v-btn
                                block
                                color="blue"
                                class="white--text"
                                @click="buscarArticulo()"
                                >
                                Buscar
                                <v-icon> mdi-magnify </v-icon>
                            </v-btn>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">

                            <v-list two-line>
                                <v-list-item v-for="(item, index) of listaArticulos" :key="index"
                                            @click="seleccionarArticulo( item )" >

                                    <v-list-item-content>
                                        <v-row>
                                            <v-col cols="3" md="3">
                                                {{ item.NumeroAlmacen }}
                                            </v-col>
                                            <v-col cols="7" md="5">
                                                {{ item.Descripcion }}
                                            </v-col>
                                            <v-col cols="2" md="2">
                                                {{ calculaPrecioPublico( item.CostoNeto, item.PorcentajeUtilidad) }}
                                            </v-col>

                                            <v-col cols="2" md="2" class="d-flex justify-end">
                                                <v-icon>
                                                    mdi-arrow-top-left
                                                </v-icon>
                                            </v-col>

                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                        </v-col>
                    </v-row>



                </v-card-text>
            </v-card>
        </v-dialog>
        <!--FIN Dialogo para busqueda-->

        <!--INICIO Dialogo Venta-->
        <v-dialog v-model="dialogVenta.show" max-width="600">
            <v-card>
                <v-card-title>
                    <span > Verifique venta </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogVenta.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>

                <v-card-text >
                    <h2> Importe: $ {{ oVenta.Abono }} </h2> <br>
                    <h2> Total: $ {{ totalVenta }} </h2> <br>
                    <h2> Descuento: $ {{ descuento }} </h2> <br>
                    <h2> A pagar: $ {{ ventaDescuento }} </h2> <br>
                    <h2 v-if="DatosPago.FormaPago == 'EFECTIVO'" > Cambio: $ {{ cambioVenta }}</h2> <br>
                    <!--<h2> Total de árticulos: {{ listaVentaDetalle.length }} </h2>-->

                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        color="orange"
                        class="white--text"
                        @click="realizarVenta()"
                        >
                        Finalizar Venta
                        <v-icon> mdi-check-circle </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Dialogo Venta-->

        
        <!--Inicio Mensaje de errores o advertencias -->
        <v-snackbar v-model="snackBar.show" :timeout="snackBar.timeout" >
            {{ snackBar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackBar.color"
                    text
                    v-bind="attrs"
                    @click="snackBar.show = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!--Inicio Mensaje de errores o advertencias -->

        <!--INICIO Dialog para stock faltante-->
        <v-dialog v-model="dialogStock.show" persistent max-width="900">
            <v-card>
                <v-card-title>
                    <span > {{ dialogStock.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogStock.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-list two-line>
                                <v-list-item v-for="(item, index) of dialogStock.lista" :key="index"
                                        @click="()=>{}">
                                    <v-list-item-content >
                                        <v-card elevation="0">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field
                                                            v-model="item.NumeroAlmacen "
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        {{ item.Descripcion }}
                                                    </v-col>
                                                    <v-col cols="6" md="3" class="red--text d-flex justify-end">
                                                        Existencia( {{ item.ExistenciaTotal }} )
                                                    </v-col>
                                                    <v-col cols="6" md="2" class="red--text d-flex justify-end">
                                                        Mínimo ST( {{ item.MinimoStock }} )
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>

        <VentanaTicketVentaComponent
            :showDialog="ventaDialog.show"
            :IdVenta="oVenta.IdVenta"
            @setShowDialog="ventaDialog.show = $event"
        ></VentanaTicketVentaComponent>

    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";

import ApiInventario from '@/services/ApiInventarioService';
import ApiPuntoVenta from '@/services/ApiPuntoVentaService';

import VentanaTicketVentaComponent from '@/components/PuntoVenta/VentanaTicketVentaComponent';
import CorteOReporteComponent from '@/components/PuntoVenta/CorteOReporteComponent';

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        precioPublico(){
            const costo = Number.parseFloat(this.oArticulo.CostoNeto);
            const porcentaje = Number.parseFloat(this.oArticulo.PorcentajeUtilidad);
            
            if(isNaN(costo)){
                return '';
            }

            if(isNaN(porcentaje)){
                return '';
            }

            let precio = costo * (1 + ( porcentaje  / 100 ));
            precio = precio.toFixed(2);

            this.oArticulo.PrecioPublicoNeto = precio;

            return (this.oArticulo.NumeroAlmacen=='')?null:precio;
        },
        totalVenta(){
            let suma = 0;

            if( this.listaVentaDetalle.length>0 ){
               this.listaVentaDetalle.forEach(item => {
                    suma += Number.parseFloat(item.PrecioSubtotal);
                }); 
            }
            else{
                suma = 0.00;
            }            
            //console.log('totalVenta.suma', suma);
            return suma.toFixed(2);
        },
        cambioVenta(){
            let resta = this.oVenta.Abono - this.ventaDescuento;
            this.oVenta.Cambio = resta;
            return Number.parseFloat(resta).toFixed(2);
        },
        descuento(){
            let desc = this.calculaDescuento(this.totalVenta, this.DatosPago.Descuento);
            //console.log('descuento', desc);
            return Number.parseFloat(desc).toFixed(2);
        },
        ventaDescuento(){

            let comiTarjeta = Number.parseFloat(this.DatosPago.ComisionTarjeta)/100;
            comiTarjeta = comiTarjeta>0?comiTarjeta:0;


            console.log('comiTarjeta', comiTarjeta);

            let ventaDescuento2 = Number.parseFloat( Number.parseFloat(this.totalVenta) - Number.parseFloat(this.descuento)).toFixed(2)

            let resultadoThis = ( ventaDescuento2 * (1+comiTarjeta) ).toFixed(2);


            //console.log('ventaDescuento', ventaDescuento2);
            return resultadoThis;
        }
    },
    //#region Components
    components:{
        VentanaTicketVentaComponent,
        CorteOReporteComponent
    },
    //#endregion

    props:{
        TipoPago:String,
        DatosPago:{
                Factura:'',
                Empresa:'',
                Usuario:'',
                Autoriza:'',
                Descuento:'',
                FormaPago:'',
                ComisionTarjeta:0
        }
    },

    //#region Data
    data(){
        return{
            apiInventario: new ApiInventario(),
            apiPVenta: new ApiPuntoVenta(),
            selected:[],
            singleSelect:true,
            loadingDatatable:false,
            ventaEnCurso:false,
            headers:[
                { text: 'Cantidad', align: 'start', sortable: false, value: 'cantidadCompra' },
                { text: 'Código', value: 'NumeroAlmacen' },
                { text: 'Descripcion', value: 'Descripcion' },
                { text: 'Precio Unit.', value: 'PrecioPublicoNeto' },
                { text: 'Subtotal', value: 'PrecioSubtotal' },
            ],
            listaArticulos:[],
            listaVentaDetalle:[],
            dialogBusqueda:{
                show:false,
            },
            dialogVenta:{
                show:false,
            },
            dialogStock:{
                show:false,
                text:'Los siguientes productos deben surtirse.',
                color:'',
                lista:[]
            },
            snackBar:{
                show:false,
                text:'',
                color:'blue',
                timeout:2000,
            },
            opcClave:'CLAVE',
            opcDescripcion:'DESCRIPCION',
            loadBusqueda:false,
            //Datos para la venta (Ventas)
            ventaDialog:{
                show:false,
            },
            estatusPagado:'PAGADO',
            oVenta:{
                IdVenta: null, 
                Sucursal: null, 
                Cliente: null,
                Consecutivo: null, 
                Fecha: null, 
                FechaHora: null, 
                Renglones: null, 
                TipoPago: null, 
                FolioPagoTarjeta:null,
                Subtotal: null, 
                Iva: null, 
                Autoriza: null,
                PorcentajeDescuento: null,
                DescuentoNeto:null,
                Total: null, 
                Nota: null, 
                Factura: null, 
                Vendedor: null, 
                Porcentaje: null, 
                Abono: null, 
                Cambio: null,
                Saldo: null, 
                Estatus: null, 
                //----------------------------------
                Importe:null,
            },
            oVentaDetalle:{
                IdVentaDetalle: null, 
                IdVenta: null, 
                Sucursal: null, 
                Consecutivo: null, 
                Fecha: null, 
                FechaHora: null, 
                NumeroAlmacen: null, 
                Cantidad: null, 
                Descripcion: null, 
                Precio: null, 
                TipoPago: null, 
                Subtotal: null, 
                Iva: null, 
                Total: null, 
                Nota: null, 
                Factura: null, 
                Vendedor: null, 
                Porcentaje: null, 
                Abono: null, 
                Saldo: null, 
                Estatus: null, 
            },
            //Datos para inventario (Articulos)
            oArticulo:{
                CodigoBarras:'',
                NumeroAlmacen:'',
                CostoNeto:null,
                Descripcion:'',
                Existencia:null,
                PorcentajeUtilidad:null,
                PrecioPublicoNeto:null,
                MinimoStock:null,
                Grupo:'',
                Subgrupo:'',
                Marca:'',
                Ubicacion:'',
                Tipo:'',
                Presentacion:'',
                UnidadesPorPtn:null,
                CantidadPtn:null,
                ExistenciaTotal:null,
                //------------------------
                PrecioSinIva:null,
                //------------------------
                cantidadCompra:null,
                precioPublico:null,
                PrecioSubtotal:null,
            }

        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la pantalla
        calculaPrecioPublico(CostoNeto, PorcentajeUtilidad){
            const precio =  (Number.parseFloat(CostoNeto) * (1+(Number.parseFloat(PorcentajeUtilidad)/100))).toFixed(2);
            return (isNaN(precio))?'Sin Precio': '$ '+precio;
        },

        openDialog( foco ){
            if( foco == this.opcClave){
                //Hacer foco en la busqueda de Clave
                //this.$refs.txtNumeroAlmacen.focus();
            }
            if( foco == this.opcDescripcion){
                //Hacer foco en la busqueda de descripcion
                //this.$refs.txtDescripcion.focus();
            }
            this.dialogBusqueda.show = true;
        },

        handleClick(value) {
            console.log('handleClick', value);
            //this.fillOArticulo( value );
        },

        handleDblClick( value ){
            //console.log('handleDblClick', value);
            this.remueveProducto( value );
            this.clearBusqueda();
        },

        buscarArticulo(){
            //console.log('buscarArticulo');
            this.loadBusqueda = true;

            this.getArticulos( this.oArticulo );
        },

        seleccionarArticulo( articulo ){
            //console.log('seleccionarArticulo', articulo);
            this.dialogBusqueda.show = false;

            this.fillOArticulo( articulo );
            this.$refs.txtCantidad.focus();
            this.listaArticulos = [];
        },

        clearBusqueda(){
            this.listaArticulos = [];
            this.clearOArticulo();

        },

        clearOArticulo(){

            this.oArticulo = {
                CodigoBarras:'',
                NumeroAlmacen:'',
                CostoNeto:null,
                Descripcion:'',
                Existencia:null,
                PorcentajeUtilidad:null,
                PrecioPublicoNeto:null,
                MinimoStock:null,
                Grupo:'',
                Subgrupo:'',
                Marca:'',
                Ubicacion:'',
                Tipo:'',
                Presentacion:'',
                UnidadesPorPtn:null,
                CantidadPtn:null,
                ExistenciaTotal:null,
                //------------------------
                PrecioSinIva:null,
                //------------------------
                cantidadCompra:null,
                precioPublico:null,
                PrecioSubtotal:null
            }

        },

        fillOArticulo( articulo ){            
            this.oArticulo.CantidadPtn = articulo.CantidadPtn ;
            this.oArticulo.CodigoBarras = articulo.CodigoBarras ;
            this.oArticulo.CostoNeto = articulo.CostoNeto ;
            this.oArticulo.Descripcion = articulo.Descripcion ;
            this.oArticulo.Existencia = articulo.Existencia ;
            this.oArticulo.ExistenciaTotal = articulo.ExistenciaTotal ;
            this.oArticulo.Grupo = articulo.Grupo ;
            this.oArticulo.Marca = articulo.Marca ;
            this.oArticulo.MinimoStock = articulo.MinimoStock ;
            this.oArticulo.NumeroAlmacen = articulo.NumeroAlmacen ;
            this.oArticulo.PorcentajeUtilidad = articulo.PorcentajeUtilidad ;
            this.oArticulo.PrecioPublicoNeto = articulo.PrecioPublicoNeto ;
            this.oArticulo.PrecioSinIva = articulo.PrecioSinIva ;
            this.oArticulo.PrecioSubtotal = articulo.PrecioSubtotal ;
            this.oArticulo.Presentacion = articulo.Presentacion ;
            this.oArticulo.Subgrupo = articulo.Subgrupo ;
            this.oArticulo.Tipo = articulo.Tipo ;
            this.oArticulo.Ubicacion = articulo.Ubicacion ;
            this.oArticulo.UnidadesPorPtn = articulo.UnidadesPorPtn ;
            //this.oArticulo.cantidadCompra = articulo.cantidadCompra ;
            this.oArticulo.precioPublico = articulo.precioPublico ;

            this.oArticulo.cantidadCompra = (articulo.cantidadCompra>0)?articulo.cantidadCompra:1;

            this.$refs.txtCantidad.focus();

        },

        agregarCompra( articulo ){
            const noNumero = isNaN( Number.parseFloat(articulo.PrecioPublicoNeto) );
            //console.log('noNumero', noNumero);

            //No esta seleccionado ningun articulo 
            if(articulo.NumeroAlmacen == ''){
                this.mostrarSnackbar('Seleccione algún artículo primero.');
                return;
            }
            //No tiene PrecioPublicoNeto
            if( noNumero ){
                this.mostrarSnackbar('El artículo no tiene precio. Verifique con administración.', 3500, 'red');
                return;
            }

            if( Number.parseFloat(articulo.PrecioPublicoNeto) <= 0 ){
                this.mostrarSnackbar('El artículo no tiene precio. Verifique con administración.', 3500, 'red');
                return;
            }
            
            if( articulo.cantidadCompra<=0){
                this.mostrarSnackbar('Agregue una cantidad mayor a 0.', 3500, 'red');
                this.$refs.txtCantidad.focus();
                return;
            }

            const subtotal = articulo.PrecioPublicoNeto * this.oArticulo.cantidadCompra;
            articulo.PrecioSubtotal = subtotal.toFixed(2);

            this.listaVentaDetalle.push(articulo);

            this.clearBusqueda();
            this.$refs.txtNumeroAlmacen.focus();
        },

        nuevaVenta(){
            this.ventaEnCurso = !this.ventaEnCurso;
            this.clearBusqueda()
            this.oVenta = {};
            this.listaVentaDetalle = [];
            this.dialogVenta.show = false;
            this.DatosPago.Empresa = '';
            this.DatosPago.Descuento = 0;
            this.DatosPago.Usuario = '';
            this.DatosPago.FormaPago = 'EFECTIVO';
            //this.DatosPago.Autoriza = '';
            this.$emit('setDescuento', 0);
            this.$emit('setFormaPago', 'EFECTIVO');
            this.$emit('setFactura', false);
            this.$emit('setComisionTarjeta', 0);
            this.$refs.txtNumeroAlmacen.focus();

        },

        mostrarSnackbar(texto, timeout=2000, color=''){
            this.snackBar.text = texto;
            this.snackBar.color = (color=='')?'blue':color;
            this.snackBar.timeout = timeout;
            this.snackBar.show = true;

        },

        remueveProducto( articulo ){
            //console.log( 'remueveProducto', articulo );
            this.listaVentaDetalle.pop( articulo );
            this.clearBusqueda();

        },

        verificarVenta(){
            console.log('Importe', this.oVenta.Abono);
            console.log('verificarVenta', this.totalVenta);
            console.log('this.DatosPago.FormaPago', this.DatosPago.FormaPago);

            const venta = Number.parseFloat(this.ventaDescuento);

            let importe = Number.parseFloat(this.oVenta.Abono);
            if( this.DatosPago.FormaPago == 'EFECTIVO'){
                
            }
            else{
                importe = venta;
                this.oVenta.Abono = importe;
            }
               
            if( this.DatosPago.FormaPago != 'EFECTIVO' &&
                (this.oVenta.FolioPagoTarjeta == '' || !this.oVenta.FolioPagoTarjeta ) ){
                this.mostrarSnackbar('Ingrese folio del ticket de venta por tarjeta', 3000, 'orange');
                return;
            }

            if(  importe >= venta  ){
                this.dialogVenta.show = true;
            }
            else{
                this.mostrarSnackbar('Ingrese importe recibido para pagar', 3000, 'orange');
            }

        },

        realizarVenta(){

            this.oVenta.Sucursal = 'TORREON';
            this.oVenta.Consecutivo = 1;
            this.oVenta.Renglones = this.listaVentaDetalle.length;
            this.oVenta.TipoPago = this.DatosPago.FormaPago;
            //this.oVenta.FolioPagoTarjeta = 
            
            this.oVenta.Subtotal = this.totalVenta;
            this.oVenta.Iva = 0;
            const totVenta = this.oVenta.Subtotal + this.oVenta.Iva;

            const descuento = this.calculaDescuento(totVenta, this.DatosPago.Descuento);
            this.oVenta.PorcentajeDescuento = this.DatosPago.Descuento;
            this.oVenta.DescuentoNeto = descuento;

            this.oVenta.Total = totVenta - descuento;

            this.oVenta.Nota = '';
            this.oVenta.Factura = this.DatosPago.Factura;
            this.oVenta.Vendedor = this.User.Usuario;
            this.oVenta.Porcentaje = 0;

            //this.oVenta.Abono = 0;
            //this.oVenta.Saldo = 0;
            this.oVenta.Estatus = this.estatusPagado;
            
            console.log('arrayVentasDetalles', this.listaVentaDetalle);
            //return;

            this.generaVenta(this.oVenta);
        },

        revisaStock( listaCompra ){
            let alertaStock = [];

            listaCompra.forEach( item =>{
                
                const resta = Number.parseFloat(item.Existencia) - Number.parseFloat(item.cantidadCompra);                

                if( resta < Number.parseFloat(item.MinimoStock) ){
                    item.Existencia = resta;
                    item.ExistenciaTotal = resta;
                    alertaStock.push(item);
                }

            });

            if( alertaStock.length > 0){
                this.dialogStock.show = true;
                this.dialogStock.lista = alertaStock;
            }
        },

        muestraTicket(IdVenta){
            this.ventaDialog.show = true;
        },

        calculaDescuento(totalVenta, descuento){
            let factor = 1;
            if(descuento > 0){
                factor = descuento/100;
            }
            else{
                factor = 0;
            }
            return Number.parseFloat(totalVenta).toFixed(2) * factor;
        },

        //#endregion

        //#region Metodos con Api de Articulos
        getArticulos( articulo ){


            this.apiInventario.recuperaArticulos( this.URLApi, articulo.NumeroAlmacen, articulo.Descripcion )
            .then( response => {
                //console.log('getArticulos.data', response.data);
                this.listaArticulos = response.data.objeto;

                if( this.listaArticulos.length > 1){
                    this.dialogBusqueda.show = true;
                }
                else if( this.listaArticulos.length == 1){
                    this.seleccionarArticulo( this.listaArticulos[0] );
                }
                else{
                    //Sin resultados
                    this.mostrarSnackbar('No se encontrarón artículos con estos datos.');
                }

                this.loadBusqueda = false;

            })
            .catch( error => { 
                console.log( error )
                this.loadBusqueda = false;
            } );

        },
        //#endregion

        //#region Metods con Api Punto de Venta
        generaVenta(venta){

            this.apiPVenta.guardaVenta(this.URLApi, venta.IdVenta,
                venta.Sucursal, venta.Cliente, venta.Consecutivo,
                venta.Fecha, venta.FechaHora, venta.Renglones,
                venta.TipoPago, venta.FolioPagoTarjeta, venta.Subtotal, venta.Iva,
                venta.Autoriza, venta.PorcentajeDescuento, venta.DescuentoNeto,
                venta.Total, venta.Nota, venta.Factura,
                venta.Vendedor, venta.Porcentaje, venta.Abono,
                venta.Cambio, venta.Saldo, venta.Estatus)
            .then( response => {
                console.log('guardaVenta', response.data);

                const venta = response.data.objeto;

                if( venta.IdVenta > 0 ){
                    console.log('guardaVenta','Se guardo Venta. Sigue guardar detalle');
                    this.guardaVentaDetalles(venta);
                }
                else{
                    console.log('guardaVenta', response.data.mensaje);
                }

            })
            .catch( error => console.log( error ) );

        },

        guardaVentaDetalles( Venta ){

            const objeto = {
                Venta,
                arrayVentasDetalles : this.listaVentaDetalle,
                Usuario:this.User.Usuario,
                Estatus:this.estatusPagado
            }

            //Preparacion de Body
            const objetoString = JSON.stringify(objeto);
            const json = 'json='+objetoString;


            const URL = this.apiPVenta.guardaVentaDetalle(this.URLApi);

            axios.post(URL, json)
            .then(response =>{
                console.log("guardaVentaDetalles", response.data);

                if(response.data.objeto>0){
                    console.log('Total de articulos guardados '+response.data.objeto);
                    this.revisaStock( this.listaVentaDetalle );
                    this.oVenta = Venta;
                    this.muestraTicket(Venta.IdVenta);
                    //this.nuevaVenta();
                }

            })
            .catch(error=>console.log(error));

        },
        //#endregion





    },
    //#endregion

    //#region mounted
    mounted(){

    },
    //#endregion

    //#region beforeMount
    beforeMount(){

    },
    //#endregion

}
</script>