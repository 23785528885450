<template>
    <v-card elevation="0">
        <!--<v-card-title> Numero: {{ Numero }} </v-card-title>-->
        <v-form ref="form" v-model="valid" lazy-validation >
            <v-card-subtitle>
                <v-row>
                    <v-col cols="6" md="3">
                        <v-select
                            label="Estatus"
                            v-model="selectEstatus"
                            :items="listaEstatus"
                            item-text="text"
                            item-value="value"
                            @change="selectDropDownEstatus()"
                        ></v-select>

                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                                v-model="Contrato.Contrato"
                                :counter="6"
                                :rules="ContratoRules.Contrato"
                                :loading="loadingContrato"
                                label="Contrato"
                                required
                                @keyup.enter="getContrato()"
                                ></v-text-field>
                    </v-col>
                    <v-col cols="3" md="1">
                        <!--Boton para buscar-->                        
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  v-on="on" v-bind="attrs"
                                            color="blue" outlined fab
                                            @click="getContrato()">
                                        <v-icon> mdi-magnify  </v-icon>
                                    </v-btn>

                                </template>
                                <span>Buscar Contrato</span>
                            </v-tooltip>
                    </v-col>
                    <v-col cols="3" md="1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  v-on="on" v-bind="attrs"
                                        color="error" class="mr-4" 
                                        outlined fab
                                        @click="reset">
                                    <v-icon> mdi-broom </v-icon>
                                </v-btn>
                            </template>
                            <span>Limpiar Datos</span>
                        </v-tooltip>

                    </v-col>
                    <v-col cols="12" md="4" class="d-flex justify-end"> 
                        <v-radio-group row v-model="Contrato.Tipo" required>
                            <v-radio label="Empresa" value="CORPORATIVO" color="blue" ></v-radio>
                            <v-radio label="Familiar" value="FAMILIAR" color="blue"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-card-subtitle>

            <v-card-text>
                
                    <v-row>
                        <v-col cols="12" md="9">
                            <v-text-field
                                v-model="Contrato.NombreS"
                                :counter="80"
                                :rules="ContratoRules.NombreS"
                                :loading="loadingContrato"
                                label="Nombre"
                                required
                                @keyup.enter="getContrato()"
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.RFCS"
                                :counter="15"
                                :rules="ContratoRules.RFCS"
                                label="RFC"
                                required
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="9">
                            <v-text-field
                                v-model="Contrato.DomicilioS"
                                :counter="80"
                                :rules="ContratoRules.DomicilioS"
                                label="Domicilio"
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.ColoniaS"
                                :counter="40"
                                :rules="ContratoRules.ColoniaS"
                                label="Col."
                                required
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="selectCiudadS"
                                :items="listaCiudades"
                                :loading="selectCiudadSLoading"
                                item-text="Nombre"
                                item-value="Nombre"
                                :rules="[v => !!v || 'Es necesario seleccionar una ciudad.']"
                                label="Ciudad"
                                required
                                @change="selectDropDownCiudadS()"
                                ></v-select>

                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.CPS"
                                :counter="10"
                                :rules="ContratoRules.CPS"
                                label="C.P."
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.CorreoS"
                                :rules="ContratoRules.CorreoS"
                                label="Correo"
                                required
                                ></v-text-field>
                            
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.TelefonosS"
                                :counter="50"
                                :rules="ContratoRules.TelefonosS"
                                label="Telefonos"
                                required
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.DiasServicioS"
                                :counter="50"
                                :rules="ContratoRules.DiasServicioS"
                                label="Dias Servicio"
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="Contrato.HorarioS"
                                :counter="50"
                                :rules="ContratoRules.HorarioS"
                                label="Horarios"
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="Contrato.AntecedentesS"
                                :counter="100"
                                :rules="ContratoRules.AntecedentesS"
                                label="Antecedentes"
                                required
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="2">
                            <v-select
                                v-model="selectSexoS"
                                :items="listaSexos"
                                :loading="selectSexoSLoading"
                                label="Sexo"
                                :disabled="empresaSexo"
                                @change="selectDropDownSexoS()"
                                ></v-select>
                                <!--
                                    :rules="[v => !!v || 'Es necesario seleccionar un sexo.']"
                                -->
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-menu
                                ref="menu"
                                v-model="menuFechaNacimiento"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="200px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="Contrato.FechaNacimientoS"
                                            label="Fecha Nacimiento"
                                            hint="AAAA/MM/DD Ej.: 2000/01/15"
                                            persistent-hint
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            :rules="ContratoRules.FechaNacimientoS"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="Contrato.FechaNacimientoS"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1900-01-01"
                                        locale="es-419"
                                        @change="saveFechaNacimiento()"
                                        ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-select
                                v-model="tipoEdad"
                                :items="listaTiposEdad"
                                :loading="selectTipoSLoading"
                                item-text="Nombre"
                                item-value="Nombre"
                                :rules="[v => !!v || 'Es necesario seleccionar un tipo de edad.']"
                                label="Tipo"
                                required
                                @change="selectDropDownTipoS()"
                                ></v-select>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-text-field
                                v-model="Contrato.EdadS"
                                :counter="3"
                                :rules="ContratoRules.EdadS"
                                label="Edad"
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                v-model="Contrato.Beneficiarios"
                                :counter="2"
                                :rules="ContratoRules.Beneficiarios"
                                label="Beneficiarios"
                                required
                                ></v-text-field>

                        </v-col>
                    </v-row>

                    <!-- Contacto 2da Parte -->
                    <v-card-subtitle> <h4> Contacto </h4> </v-card-subtitle>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="Contrato.NombreC"
                                :counter="80"
                                :rules="ContratoRules.NombreC"
                                label="Nombre"
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="Contrato.TelefonosC"
                                :counter="80"
                                :rules="ContratoRules.TelefonosC"
                                label="Telefonos"
                                required
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                v-model="Contrato.CorreoC"
                                :rules="ContratoRules.CorreoC"
                                label="Correo"
                                required
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                v-model="Contrato.Observaciones"
                                :rules="ContratoRules.Observaciones"
                                :counter="200"
                                :loading="loadingContrato"
                                label="Observaciones"
                                required
                                ></v-text-field>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="4">

                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn :disabled="!banderaUpdate" color="success" class="mr-4" 
                                    block
                                    @click="validate(operacionUpdate)" >
                                Modificar
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn :disabled="!valid||banderaUpdate||loadingContrato" color="success" class="mr-4" 
                                    block
                                    @click="validate(operacionInsert)" >
                                Guardar
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn  color="error" class="mr-4" 
                                    block outlined 
                                    @click="reset">
                                <v-icon> mdi-broom </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!--
                    <v-btn color="error" class="mr-4" @click="reset" >
                        Reset Form
                    </v-btn>

                    <v-btn color="warning" @click="resetValidation" >
                        Reset Validation
                    </v-btn>
                    -->
                
            </v-card-text>
        </v-form>

        <!--Mensaje para accion-->
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackbar.color"
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

    </v-card>
</template>

<script>
import {mapState} from "vuex";
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';
import BeneficiariosComponentVue from './BeneficiariosComponent.vue';

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN']),
        tipoEdad:{
            get:function(){
                let TipoEdad = '';

                if(this.Contrato.EdadS > 59){
                    TipoEdad =  this.listaTiposEdad[2].Nombre;
                }
                else if(this.Contrato.EdadS > 16){
                    TipoEdad =  this.listaTiposEdad[1].Nombre;
                }
                else if(this.Contrato.EdadS > 0){
                    TipoEdad =  this.listaTiposEdad[0].Nombre;
                }
                else{
                    TipoEdad =  '';
                }
                this.Contrato.TipoS = TipoEdad;
                return TipoEdad;
            },
            set:function(){
                return '';
            }
        },
        empresaSexo(){
            //Inhabilita si el contrato es para empresa o no
            let inhabilitar = false;
            inhabilitar = ( this.Contrato.Tipo == 'CORPORATIVO' )? true : false ;
            return inhabilitar;
        }
    },
    props:{
        Nuevo:String
    },
    data(){
        return{
            apiConfiguracion:new ApiConfiguracionService,
            api:new ApiContratosService,
            valid: true,
            operacionInsert:'INSERT',
            operacionUpdate:'UPDATE',
            banderaUpdate:false,
            menuFechaNacimiento:false,
            estatusContratoActivo:'ACTIVO',
            //estatusContratoActivo:'ACTIVO',
            estatusContratoCancelado:'CANCELADO',
            listaContratos:[],
            selectEstatus:'ACTIVO',
            listaEstatus:[
                { text:'Activo' , value:'ACTIVO'},
                { text:'Cancelado' , value:'CANCELADO'},
            ],
            snackbar:{
                show:false,
                text:'',
                timeout:3500,
                color:'blue'
            },
            loadingContrato:false,
            Contrato:{
                Numero:0,
                Contrato:null,
                Tipo:'CORPORATIVO',
                Estatus:null,
                NombreS:'',
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Observaciones:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            ContratoPrueba:{
                Numero:null,
                Contrato:92,
                Tipo:'CORPORATIVO',
                Estatus:null,
                NombreS:'Dilsa',
                RFCS:'DMCA121212122',
                DomicilioS:'Miguel Aleman 12',
                ColoniaS:'Campestre',
                CiudadS:'GOMEZ PALACIO',
                CPS:'35000',
                CorreoS:'contacto@dilsa.com',
                TelefonosS:'8711111111',
                DiasServicioS:'Lun-Dom',
                HorarioS:'00:00-23:59',
                AntecedentesS:'No',
                SexoS:'HOMBRE',
                FechaNacimientoS:'2012-12-12',
                TipoS:'PEDIATRICO',
                EdadS:8,
                Beneficiarios:'2',
                NombreC:'Juanito G.',
                TelefonosC:'8711111111',
                CorreoC:'contacto@dilsa.com',
                Usuario:'ADMINISTRADOR DEL SISTEMA',
                FechaContrato:null,
                HoraContrato:null,
                Observaciones:null,
                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            selectCiudadS:null,
            selectCiudadSLoading:true,
            selectTipoS:null,
            selectTipoSLoading:true,
            selectSexoS:null,
            selectSexoSLoading:false,
            ContratoRules:{
                Numero:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Contrato:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El contrato debe ser un número válido.',
                ],
                Tipo:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Estatus:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                NombreS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                RFCS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 15) || 'El campo debe tener menos 15 caracteres.', 
                ],
                DomicilioS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                ColoniaS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 40) || 'El campo debe tener menos 40 caracteres.', 
                ],
                CiudadS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                CPS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                CorreoS:[ 
                    v => !!v || 'Correo es Requerido.',
                    v => /.+@.+\..+/.test(v) || 'El correo debe ser válido.',
                ],
                TelefonosS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El teléfono debe ser válido.',
                ],
                DiasServicioS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 50) || 'El campo debe tener menos 50 caracteres.', 
                ],
                HorarioS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 50) || 'El campo debe tener menos 50 caracteres.', 
                ],
                AntecedentesS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 100) || 'El campo debe tener menos 100 caracteres.', 
                ],
                SexoS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                FechaNacimientoS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                TipoS:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                EdadS:[ 
                    v=> !!v || '' , 
                    v => /^([0-9])*$/.test(v) || 'La edad debe ser un número ser válido.',
                ],
                Beneficiarios:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'Debe ser un número ser válido.',
                ],
                NombreC:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                TelefonosC:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El teléfono debe ser válido.',
                ],
                CorreoC:[ 
                    v => !!v || 'Correo es Requerido.',
                    v => /.+@.+\..+/.test(v) || 'El correo debe ser válido.',
                ],
                Usuario:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                FechaContrato:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                HoraContrato:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Observaciones:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 200) || 'El campo debe tener menos 200 caracteres.', 
                ],
                Extra1:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Extra2:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Extra3:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
            },
            listaCiudades: [ 'Tórreon, Coah.', 'Gómez Palacio, Dgo.', 'Lerdo, Dgo.', ],
            listaSexos:[ 'MUJER', 'HOMBRE', ],
            listaTiposEdad:[ 'PEDIATRICO', 'ADULTO MAYOR', 'ADULTO' ],
        }
    },

    methods:{
        
        //#region Funciones Locales
        emitiToComponentFather(Contrato, NombreS, Beneficiarios){
            this.$emit('setContrato', Contrato);
            this.$emit('setNombreS', NombreS);
            this.$emit('setBeneficiarios', Beneficiarios);
        },
        clearContratoObject(){
            this.Contrato = {
                Numero:null,
                Contrato:null,
                Tipo:'CORPORATIVO',
                Estatus:null,
                NombreS:null,                
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Observaciones:null,                

                Extra1:null,
                Extra2:null,
                Extra3:null
            }
            
        },
        calcularEdad(){
            var hoy = new Date();
            var cumpleanos = new Date(this.Contrato.FechaNacimientoS);
            var edad = hoy.getFullYear() - cumpleanos.getFullYear();
            var m = hoy.getMonth() - cumpleanos.getMonth();
            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--;
            }
            this.Contrato.EdadS = edad;
        },
        fillContrato(item){
            //console.log('fillContrato', item);
            this.Numero                     = item.Numero;

            this.Contrato.Numero            = item.Numero;
            this.Contrato.Contrato          = item.Contrato;
            this.Contrato.Tipo              = item.Tipo;
            
            this.Contrato.Estatus           = item.Estatus;            
            this.selectEstatus              = item.Estatus;

            this.Contrato.NombreS           = item.NombreS;
            this.Contrato.RFCS              = item.RFCS;
            this.Contrato.DomicilioS        = item.DomicilioS;
            this.Contrato.ColoniaS          = item.ColoniaS;
            this.selectCiudadS              = item.CiudadS; //this.Contrato.CiudadS
            this.Contrato.CPS               = item.CPS;

            this.Contrato.CorreoS           = item.CorreoS;
            this.Contrato.TelefonosS        = item.TelefonosS;

            this.Contrato.DiasServicioS     = item.DiasServicioS;
            this.Contrato.HorarioS          = item.HorarioS;

            this.Contrato.AntecedentesS     = item.AntecedentesS;
            this.selectSexoS                = item.SexoS; //this.Contrato.SexoS
            this.Contrato.FechaNacimientoS  = item.FechaNacimientoS;
            this.selectTipoS                = item.TipoS; //this.Contrato.TipoS
            this.Contrato.EdadS             = item.EdadS;
            
            this.Contrato.Beneficiarios     = item.Beneficiarios;
            this.Contrato.NombreC           = item.NombreC;
            this.Contrato.TelefonosC        = item.TelefonosC;
            this.Contrato.CorreoC           = item.CorreoC;
            this.Contrato.Usuario           = item.Usuario;
                
            this.Contrato.FechaContrato     = item.FechaContrato;
            this.Contrato.HoraContrato      = item.HoraContrato;

            this.Contrato.Observaciones      = item.Observaciones;

            this.Contrato.Extra1            = item.Extra1;
            this.Contrato.Extra2            = item.Extra2;
            this.Contrato.Extra3            = item.Extra3;

            this.banderaUpdate = true;
            this.emitiToComponentFather(
                this.Contrato.Contrato, this.Contrato.NombreS, this.Contrato.Beneficiarios);
        },
        selectDropDownEstatus(){ 
            this.Contrato.Estatus = this.selectEstatus;
        },
        //#endregion
        
        //#region Formulario
        validate (operacion) {
            const formValid = this.$refs.form.validate();
            console.log('formValid', formValid);
            if(formValid){
                if(operacion == this.operacionInsert){
                    //console.log('insert');
                    this.insertContrato();
                }
                else if(operacion == this.operacionUpdate){
                    //console.log('update');
                    this.updateContrato();
                }
            }
        },
        reset () { //Limpia todo
            //console.log('Reset');
            this.$refs.form.reset();
            this.banderaUpdate = false;
            this.Contrato.Tipo = 'CORPORATIVO';
            this.emitiToComponentFather(
                this.Contrato.Contrato, this.Contrato.NombreS, this.Contrato.Beneficiarios);
        },
        resetValidation () { //Reestablece la validación sin quitar datos
            this.$refs.form.resetValidation();
        },
        saveFechaNacimiento (date) {
            this.$refs.menu.save(date);
            this.calcularEdad();
        },
        //#endregion
        
        //#region selects-Dropdown        
        selectDropDownCiudadS(){ this.Contrato.CiudadS = this.selectCiudadS; },
        selectDropDownTipoS(){ this.Contrato.TipoS = this.selectTipoS; },
        selectDropDownSexoS(){ this.Contrato.SexoS = this.selectSexoS; },
        //#endregion

        //#region Consumo Api-Configuracion
        getCiudades(){
            this.selectCiudadSLoading = true;
            this.apiConfiguracion.recuperaCiudad(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaCiudad.data", response.data);
                this.listaCiudades = response.data.objeto;

            })
            .catch(error=>{console.log(error);});
            this.selectCiudadSLoading = false;
        },
        getTiposEdad(){
            this.selectTipoSLoading = true;
            this.apiConfiguracion.recuperaTiposEdad(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaTiposEdad.data", response.data);
                this.listaTiposEdad = response.data.objeto;
            })
            .catch(error=>{console.log(error);});
            this.selectTipoSLoading = false;
        },
        //#endregion

        //#region Consumo Api-Contratos
        getContrato(){
            this.emitiToComponentFather();
            if(!this.Contrato.Contrato && !this.Contrato.NombreS ){
                return;
            }

            //Solo puede mostrar uno por seguridad
            this.api.recuperaContratos( this.URLApi, 
                    this.Contrato.Numero, this.Contrato.Contrato, this.Contrato.NombreS)
            .then( response => {
                //console.log('getContrato.data', response.data)
                this.listaContratos = response.data.objeto;
                if(this.listaContratos.length>0){
                    this.fillContrato( this.listaContratos[0] );
                }
                else{
                    this.reset();
                }
            })
            .catch(error=>{console.log(error);});

        },
        
        insertContrato(){
            this.Contrato.Estatus = this.estatusContratoActivo;
            this.loadingContrato = true;

            this.api.guardaContrato(this.URLApi, 
                this.Contrato.Numero,
                this.Contrato.Contrato, this.Contrato.Tipo, this.Contrato.Estatus, this.Contrato.NombreS, this.Contrato.RFCS,
                this.Contrato.DomicilioS, this.Contrato.ColoniaS, this.Contrato.CiudadS, this.Contrato.CPS,
                this.Contrato.CorreoS, this.Contrato.TelefonosS,
                this.Contrato.DiasServicioS, this.Contrato.HorarioS,
                this.Contrato.AntecedentesS, this.Contrato.SexoS, this.Contrato.FechaNacimientoS, this.Contrato.TipoS, this.Contrato.EdadS,
                this.Contrato.Beneficiarios, this.Contrato.NombreC, this.Contrato.TelefonosC, this.Contrato.CorreoC, this.Contrato.Usuario,
                this.Contrato.FechaContrato, this.Contrato.HoraContrato,
                this.Contrato.Observaciones,
                this.Contrato.Extra1, this.Contrato.Extra2, this.Contrato.Extra3
            )
            .then( response =>{
                //console.log("insertContrato.data", response.data);

                if(response.data.objeto>0){

                    this.snackbar.color = 'blue';
                    this.snackbar.text = 'Contrato guardado correctamente';
                    this.snackbar.show = true;

                    this.Contrato.Numero = response.data.objeto;
                    this.emitiToComponentFather(this.Contrato.Contrato, 
                        this.Contrato.NombreS, this.Contrato.Beneficiarios);
                }
                else{
                    this.snackbar.color = 'red';
                    if(response.data.mensaje[1] == 1062){
                        this.snackbar.text = 'Número de contrato duplicado, intente con otro folio.';
                    }
                    else{
                        this.snackbar.text = 'Ocurrio un problema al guardarlo';
                    }
                    this.snackbar.show = true;
                }
                this.loadingContrato = false;
            })
            .catch(error=>{
                console.log(error)
                this.loadingContrato = false;
            });
        },
        updateContrato(){
            this.api.modificaContrato(this.URLApi, 
                this.Contrato.Numero,
                this.Contrato.Contrato, this.Contrato.Tipo, this.Contrato.Estatus, this.Contrato.NombreS, this.Contrato.RFCS,
                this.Contrato.DomicilioS, this.Contrato.ColoniaS, this.Contrato.CiudadS, this.Contrato.CPS,
                this.Contrato.CorreoS, this.Contrato.TelefonosS,
                this.Contrato.DiasServicioS, this.Contrato.HorarioS,
                this.Contrato.AntecedentesS, this.Contrato.SexoS, this.Contrato.FechaNacimientoS, this.Contrato.TipoS, this.Contrato.EdadS,
                this.Contrato.Beneficiarios, this.Contrato.NombreC, this.Contrato.TelefonosC, this.Contrato.CorreoC, this.Contrato.Usuario,
                this.Contrato.FechaContrato, this.Contrato.HoraContrato,
                this.Contrato.Observaciones,
                this.Contrato.Extra1, this.Contrato.Extra2, this.Contrato.Extra3
            )
            .then( response =>{
                //console.log("updateContrato.data", response.data);
                if(response.data.objeto>0){
                    this.getContrato();

                    this.snackbar.show = true;
                    this.snackbar.color = 'blue';
                    this.snackbar.text = 'Contrato modificado correctamente';
                }
            })
            .catch(error=>{console.log(error);});

        },
        deleteContrato(){

        },
        //#endregion
        
    },

    beforeMount(){
        //console.log('beforeMount', this.User);
        this.Contrato.Usuario = this.User.Nombre;
    },

    mounted(){
        this.getCiudades();
        this.getTiposEdad();

    },

    watch: {
      menuFechaNacimiento (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
      },
      Nuevo(val){
          //console.log('watch.Nuevo', val);
      }
    },

}
</script>