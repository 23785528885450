<template>
    <v-container>
        <!--Formulario para objeto-->
        <v-row>
            <v-col cols="12"
                    md="4">
                <v-select
                    :items="listaEquipos"
                    item-text="Nombre"
                    item-value="Nombre"
                    label="Equipo"
                    v-model="EquipoSelect"
                    @change="selectDropDown()"
                ></v-select>
            </v-col>
            <v-col
                    cols="12"
                    md="4"
                >
                <v-text-field
                    v-model="Horario.NombreHorario"
                    label="Horario"
                    @click:clear="clearHorario()"
                    @keyup.enter="checkUpdate()"
                    clearable
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2" > 
                <v-btn  block color="green" class="white--text"
                        @click="checkUpdate()"> 
                    <v-icon v-if="updateHorarioFlag" > mdi-content-save-alert</v-icon>
                    <v-icon v-else> mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>

            <v-col cols="12" md="2" > 
                <v-btn  block color="red" class="white--text"
                        :disabled="!updateHorarioFlag"
                        @click="showDialog = true;"> 
                    <v-icon> mdi-delete</v-icon>
                    Eliminar
                </v-btn>
            </v-col>

        </v-row>

        <!--Info data table https://stackoverflow.com/questions/62259868/vuetify-change-text-rows-per-page-text-in-v-data-tables-footer-->
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="listaHorarios"
            :single-select="singleSelect"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-text':'Horarios por página',
            }"
            :loading="loadingDatatable"
            item-key="Numero"
            class="elevation-1"
            @click:row="handleClick"
        > <!--show-select-->
        </v-data-table>

        <!--Formulario para objeto-->
        <v-row>
            <v-col cols="12"
                    md="4">
                <v-select
                    :items="listaEquipos"
                    label="Equipo"
                    item-text="Nombre"
                    item-value="Nombre"
                    v-model="EquipoSelect"
                    @change="selectDropDown()"
                ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model="Horario.NombreHorario"
                    label="Horario"
                    @click:clear="clearHorario()"
                    @keyup.enter="checkUpdate()"
                    clearable
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2" > 
                <v-btn  block color="green" class="white--text"
                        @click="checkUpdate()"> 
                    <v-icon v-if="updateHorarioFlag" > mdi-content-save-alert</v-icon>
                    <v-icon v-else> mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>

            <v-col cols="12" md="2" > 
                <v-btn  block color="red" class="white--text"
                        :disabled="!updateHorarioFlag"
                        @click="showDialog = true;"> 
                    <v-icon> mdi-delete</v-icon>
                    Eliminar
                </v-btn>
            </v-col>

        </v-row>

        <!--Mensaje para accion-->
        <v-snackbar v-model="showSnackbar" :timeout="timeoutSnackbar" >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="showSnackbar = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <!--Alerta de Borrado-->
        <v-dialog
            v-model="showDialog" persistent max-width="290" >
            <v-card>
                <v-card-title class="headline">
                    ¿Desea continuar?
                </v-card-title>
                    <v-card-text>
                        Esta a punto de eliminar el Horario:  <strong> {{ Horario.NombreHorario }} </strong>  .
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="showDialog = false" >
                            No
                        </v-btn>
                        <v-btn color="green darken-1" text @click="deleteHorario()" >
                            Si
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
import ApiService from '@/services/ApiConfiguracionService';
import {mapState} from "vuex";

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    data(){
        return{
            api: new ApiService(),
            showDialog:false,
            showSnackbar:false,
            timeoutSnackbar:2500,
            textSnackbar:'La Horario fue guardada',
            listaHorarios:[],
            listaEquipos:[],
            selected: [],
            singleSelect: true,
            loadingDatatable:true,
            headers:[
                //{ text: 'Clave', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Equipo', value: 'Equipo' },
                { text: 'Nombre', value: 'Nombre' },
            ],
            valid: false,
            updateHorarioFlag:false,
            Horario:{
                Numero:0,
                Equipo:'',
                NombreHorario:''
            },
            EquipoSelect:'',
            Equipo:{
                Numero:0,
                NombreEquipo:''
            }
        }
    },
    methods:{
        accion(){
            console.log('selected', this.selected[0]);
            console.log('Numero', this.selected[0].Numero);
            console.log('Nombre', this.selected[0].Nombre);
        },
        handleClick(value) {
            //console.log('handleClick', value);
            this.Horario.Numero = value.Numero;
            this.Horario.NombreHorario = value.Nombre;
            this.Equipo.Nombre = value.Equipo;
            this.EquipoSelect = this.Equipo.Nombre;

            this.updateHorarioFlag = true;
        },
        selectDropDown(){
            console.log('selectDropDown', this.EquipoSelect);
            this.Equipo.NombreEquipo = this.EquipoSelect;
        }
        ,
        clearHorario(){
            this.updateHorarioFlag=false;
            this.Horario.Numero = 0;
            this.Horario.NombreHorario = '';
            this.EquipoSelect = '';

        },
        checkUpdate(){
            console.log('checkUpdate', this.updateHorarioFlag);
            this.Equipo.NombreEquipo = this.EquipoSelect;


            if(this.Horario.NombreHorario===''){
                console.log('NombreHorario vacío');
                this.textSnackbar = 'Favor de completar los campos faltantes.';
                this.showSnackbar = true;
                return;
            }
            
            if(this.updateHorarioFlag){
                //Modifica
                this.updateHorario();
            }
            else{
                //Guarda
                this.insertHorario();
            }
        },
        getHorarios(){
            this.clearHorario();
            this.loadingDatatable = true;

            this.api.recuperaHorarios(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaHorarios.data", response.data);
                this.listaHorarios = response.data.objeto;
                this.loadingDatatable = false;

            })
            .catch(error=>{console.log(error);});

        },
        insertHorario(){
            this.api.guardaHorario(this.URLApi, this.Horario.NombreHorario, this.Equipo.NombreEquipo)
            .then( response =>{
                console.log("guardaHorario.data", response.data);

                if(response.data.objeto){
                    this.getHorarios();
                    this.getEquipos();
                    this.updateHorarioFlag = false;
                    this.textSnackbar = 'El Horario fue guardado';
                    this.showSnackbar = true;
                }
            })
            .catch(error=>{console.log(error);});
        },
        updateHorario(){
            this.api.modificaHorario(this.URLApi, this.Horario.Numero, this.Horario.NombreHorario
                                    , this.Equipo.NombreEquipo)
            .then( response =>{
                console.log("modificaHorario.data", response.data);

                if(response.data.objeto){
                    this.getHorarios();
                    this.getEquipos();
                    this.updateHorarioFlag = false;
                    this.textSnackbar = 'El Horario fue guardado';
                    this.showSnackbar = true;
                }
            })
            .catch(error=>{console.log(error);});
        },
        deleteHorario(){
            this.api.eliminaHorario(this.URLApi, this.Horario.Numero)
            .then( response =>{
                console.log("eliminaHorario.data", response.data);

                if(response.data.objeto){
                    this.getHorarios();
                    this.updateHorarioFlag = false;

                    this.textSnackbar = 'El Horario fue eliminado';
                    this.showSnackbar = true;
                    this.showDialog = false;
                }
            })
            .catch(error=>{console.log(error);});
        },
        //De otro controlador
        getEquipos(){
            //this.clearEquipo();
            this.loadingDatatable = true;

            this.api.recuperaEquipos(this.URLApi, 0, 0)
            .then( response =>{
                console.log("recuperaEquipos.data", response.data);
                this.listaEquipos = response.data.objeto;
                this.loadingDatatable = false;

            })
            .catch(error=>{console.log(error);});
            this.loadingDatatable = false;

        },

    },
    beforeMount(){
        //console.log('Evento que se lanza antes de que se imprima la pantalla');
        this.getEquipos();
        this.getHorarios();
    },
    updated(){
        //this.getEquipos();
        //this.getHorarios();
    }
}
</script>