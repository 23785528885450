<template>
    <div>
         <v-row>
            <v-col cols="5" md="2">
                <v-text-field
                    v-model="Contrato.Contrato"
                    label="Contrato"
                    @keyup.enter="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                    @click:clear="clearContratoObject()"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="Contrato.NombreS"
                    label="Socio"
                    append-icon="mdi-magnify"
                    :loading="loadingContrato"
                    @keyup.enter="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                    @click:clear="clearContratoObject()"
                    @click:append="showDialogBusqueda = true "
                    clearable
                ></v-text-field>

            </v-col>

            <v-col cols="8" md="3">
                <v-text-field
                    v-model="Contrato.Tipo" 
                    label="Tipo Socio"
                    @click:clear="clearContratoObject()"
                    clearable
                    readonly
                ></v-text-field>
            </v-col>

            <v-col cols="4" md="1" class="d-flex justify-end">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on" v-bind="attrs"
                            color="blue" outlined  icon
                            @click="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                            >
                            <v-icon> mdi-magnify </v-icon>
                        </v-btn>
                    </template>
                    <span>Consultar</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <!-- Ventana de busqueda -->
        <v-dialog v-model="showDialogBusqueda" max-width="600">
            <v-card>
                <v-card-title>
                    Buscar Socio
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="10" md="10">
                            <v-text-field
                                v-model="Contrato.NombreS"
                                label="Nombre Socio"
                                @click:clear="clearContratoObject()"
                                @keyup.enter="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                                clearable
                                >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2" md="2" class="d-flex justify-center">
                            <v-btn icon
                                color="blue"
                                @click="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                                >
                                <v-icon> mdi-magnify </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <v-list two-line>
                                <v-list-item v-for="(item, index) of listaContratos" :key="index"
                                            @click="selectSocio(item)" >

                                    <v-list-item-content>
                                        <v-row>
                                            <v-col cols="10" md="10">
                                                {{ item.NombreS }}
                                            </v-col>
                                            <v-col cols="2" md="2" class="d-flex justify-end">
                                                <v-icon>
                                                    mdi-arrow-top-left
                                                </v-icon>
                                            </v-col>

                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Aviso -->
        <v-snackbar v-model="showSnackbarAviso" :timeout="timeSnackbar">
            {{ mensajeSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                v-bind="attrs"
                color="red" text
                @click="showSnackbarAviso = false"
                >
                <v-icon> mdi-close </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import {mapState} from "vuex"; 

import ApiContratos from '@/services/ApiContratosService';

export default {

    //#region Computed
    computed:{ ...mapState(['URLSrc', 'URLApi', 'User']), }, 
    //#endregion 

    //#region Data
    data(){
        return{
            api: new ApiContratos(),

            showDialogBusqueda:false,
            loadingContrato:false,

            showSnackbarAviso:false,
            timeSnackbar:2000,
            mensajeSnackbar:'No hay resultados',

            listaContratos:[],
            Contrato:{
                Numero:0,
                Contrato:null,
                Tipo:'',
                Estatus:null,
                NombreS:'',
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            },
        }
    },
    //#endregion

    //#region Metodos
    methods:{

        //#region Metodos de la vista
        emitiToComponentFather(Contrato, NombreS, TelefonosS, DomicilioS){
            this.$emit('setContrato', Contrato);
            this.$emit('setNombreS', NombreS);
            this.$emit('setTelefonosS', TelefonosS);
            this.$emit('setDomicilioS', DomicilioS);
            //this.$emit('setEdad', Edad);
            //this.$emit('setEstatus', Estatus);
            //Emite esta variable para saber si tiene que buscar en beneficiarios o solo en contrato
            //this.$emit('setEsBeneficiario', EsBeneficiario);
        },

        fillContrato(item){
            //console.log('fillContrato', item);
            this.Numero                     = item.Numero;

            this.Contrato.Numero            = item.Numero;
            this.Contrato.Contrato          = item.Contrato;
            this.Contrato.Tipo              = item.Tipo;
            this.Contrato.Estatus           = item.Estatus;
            this.Contrato.NombreS           = item.NombreS; 
            this.ContratoNombreInput        = item.NombreS;

            this.Contrato.RFCS              = item.RFCS;
            this.Contrato.DomicilioS        = item.DomicilioS;
            this.Contrato.ColoniaS          = item.ColoniaS; 
            this.selectCiudadS              = item.CiudadS; //this.Contrato.CiudadS
            this.Contrato.CPS               = item.CPS;

            this.Contrato.CorreoS           = item.CorreoS;
            this.Contrato.TelefonosS        = item.TelefonosS;

            this.Contrato.DiasServicioS     = item.DiasServicioS;
            this.Contrato.HorarioS          = item.HorarioS;

            this.Contrato.AntecedentesS     = item.AntecedentesS;
            this.selectSexoS                = item.SexoS; //this.Contrato.SexoS
            this.Contrato.FechaNacimientoS  = item.FechaNacimientoS;
            this.selectTipoS                = item.TipoS; //this.Contrato.TipoS
            this.Contrato.EdadS             = item.EdadS;
            
            this.Contrato.Beneficiarios     = item.Beneficiarios;
            this.Contrato.NombreC           = item.NombreC;
            this.Contrato.TelefonosC        = item.TelefonosC;
            this.Contrato.CorreoC           = item.CorreoC;
            this.Contrato.Usuario           = item.Usuario;
                
            this.Contrato.FechaContrato     = item.FechaContrato;
            this.Contrato.HoraContrato      = item.HoraContrato;

            this.Contrato.Extra1            = item.Extra1;
            this.Contrato.Extra2            = item.Extra2;
            this.Contrato.Extra3            = item.Extra3;

            //this.fillFormDatos(this.Contrato,null);
            this.emitiToComponentFather(    this.Contrato.Contrato, 
                                            this.Contrato.NombreS,
                                            this.Contrato.TelefonosS,
                                            this.Contrato.DomicilioS );
        },

        clearContratoObject(){
            this.listaContratos = [];
            this.ContratoNombre = '';
            //this.clearFormDatosObject();
            this.emitiToComponentFather(0,'','','');
            this.Contrato = {
                Numero:null,
                Contrato:null,
                Tipo:'',
                Estatus:null,
                NombreS:null,                
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            }
            this.getContratos(this.Contrato.Numero, this.Contrato.Contrato, this.Contrato.NombreS);
            
        },

        selectSocio(item){
            this.showDialogBusqueda = false;
            this.fillContrato(item);
            this.getContratos(this.Contrato.Numero, this.Contrato.Contrato, this.Contrato.NombreS);
        },
        //#endregion

        //#region Metodos con Api
        getContratos(ContratoNumero, ContratoContrato, ContratoNombreS){
            this.loadingContrato = true;
            this.showSnackbarAviso = false;
            this.listaBeneficiarios = [];

            //debugger;

            if(!ContratoContrato && !ContratoNombreS ){
                this.loadingContrato = false;
                this.showSnackbarAviso = false;
                return;
            }

            //console.log('getContratos', this.Contrato.NombreS);

            //Solo puede mostrar uno por seguridad
            this.api.recuperaContratos( this.URLApi, 
                    ContratoNumero, ContratoContrato, ContratoNombreS)
            .then( response => {
                //console.log('getContratos.data', response.data);
                this.loadingContrato = false;

                this.listaContratos = response.data.objeto;

                if( this.listaContratos.length == 1 ){
                    this.fillContrato( this.listaContratos[0] );
                    this.showDialogBusqueda = false;
                }
                else if(this.listaContratos.length > 1){
                    this.showDialogBusqueda = true;
                    //this.clearContratoObject();
                }
                else{
                    //console.log('else', this.listaContratos);
                    this.showSnackbarAviso = true;
                }
            })
            .catch(error=>{console.log(error);});

        },     
        //#endregion

    },
    //#endregion
    
}



</script>