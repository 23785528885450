<template>
    <div>
        <v-container class="fill-height" fluid >

            <v-row align="center" justify="center">
                
                <v-col cols="12" sm="8" md="8">

                    <v-card class="elevation-12">

                        <v-window v-model="step">

                            <v-window-item :value="1">
                                <!--Formulario Login-->
                                <v-row>
                                    <v-col cols="12" md="8">
                                        <FormLogin @stepSon="step = $event" ></FormLogin>
                                    </v-col>
                                    <v-col cols="12" md="4" class="teal accent-8">

                                        <v-card-text class="white--text mt-12">
                                            <h1 class="text-center display-1" > Hello ! </h1>
                                            <h5 class="text-center"> Enter your personal details and start journay whith us </h5>
                                        </v-card-text>
                                        <div class="text-center">
                                            <v-btn rounded outlined="" dark @click="step++"> SIGN UP  </v-btn>
                                        </div>

                                    </v-col>
                                </v-row>
                            </v-window-item>

                            <v-window-item :value="2">
                                <!--Formulario Registro-->
                                <v-row class="fill-height">
                                    <v-col cols="12" md="4" class="teal accent-8">
                                        <v-card-text class="white--text mt-12">
                                            <h1 class="text-center display-1"> Welcome Back! </h1>
                                            <h5 class="text-center"> To keep connected with us please login with your personal info </h5>
                                        </v-card-text>
                                        <div class="text-center">
                                            <v-btn rounded outlined dark @click="step--"> SIGN IN </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <FormRegister @stepSon="step = $event" ></FormRegister>
                                    </v-col>
                                </v-row>
                            </v-window-item>

                        </v-window>

                    </v-card>

                </v-col>

            </v-row>
            
        </v-container>
    </div>
</template>

<script>

import FormLogin from '@/components/Usuario/FormLogin';
import FormRegister from '@/components/Usuario/FormRegister';

export default {
    components:{
        FormLogin,
        FormRegister
    },
    data(){
        return{
            step:1
        }
    }
}
</script>