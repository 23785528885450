<template>
    <v-container>
        <!--Formulario para objeto-->
        <v-row>
            <v-col
                cols="12"
                md="6"
            >
            <v-text-field
                v-model="Proveedor.NombreProveedor"
                label="Proveedor"
                @click:clear="clearProveedor()"
                @keyup.enter="checkUpdate()"
                clearable
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="green" class="white--text"
                        @click="checkUpdate()"> 
                    <v-icon v-if="updateProveedorFlag" > mdi-content-save-alert</v-icon>
                    <v-icon v-else> mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="red" class="white--text"
                        :disabled="!updateProveedorFlag"
                        @click="showDialog = true;"> 
                    <v-icon> mdi-delete</v-icon>
                    Eliminar
                </v-btn>
            </v-col>

        </v-row>

        <!--Info data table https://stackoverflow.com/questions/62259868/vuetify-change-text-rows-per-page-text-in-v-data-tables-footer-->
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="listaProveedores"
            :single-select="singleSelect"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-text':'Proveedores por página',
            }"
            :loading="loadingDatatable"
            item-key="Numero"
            class="elevation-1"
            @click:row="handleClick"
            @dblclick:row="handleDblClick"
        > <!--show-select-->
        </v-data-table>

        <!--Formulario para objeto-->
        <v-row>
            <v-col
                cols="12"
                md="6"
            >
            <v-text-field
                v-model="Proveedor.NombreProveedor"
                label="Proveedor"
                @click:clear="clearProveedor()"
                @keyup.enter="checkUpdate()"
                clearable
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="green" class="white--text"
                        @click="checkUpdate()"> 
                    <v-icon v-if="updateProveedorFlag" > mdi-content-save-alert</v-icon>
                    <v-icon v-else> mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="red" class="white--text"
                        :disabled="!updateProveedorFlag"
                        @click="showDialog = true;"> 
                    <v-icon> mdi-delete</v-icon>
                    Eliminar
                </v-btn>
            </v-col>

        </v-row>

        <!--Mensaje para accion-->
        <v-snackbar v-model="showSnackbar" :timeout="timeoutSnackbar" >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="showSnackbar = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <!--Alerta de Borrado-->
        <v-dialog v-model="showDialog" persistent max-width="290" >
            <v-card>
                <v-card-title class="headline">
                    ¿Desea continuar?
                </v-card-title>
                <v-card-text>
                    Esta a punto de eliminar el Proveedor con el nombre:  <strong> {{ Proveedor.NombreProveedor }} </strong>  .
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="showDialog = false" >
                        No
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteProveedor()" >
                        Si
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiService from '@/services/ApiInventarioService';

export default {
    //-------------------------------------------------------------------------------
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    //#endregion

    //-------------------------------------------------------------------------------
    //#region Data
    data(){
        return{
            apiConfiguracion:new ApiConfiguracionService(),
            api:new ApiService(),
            showDialog:false,
            showSnackbar:false,
            timeoutSnackbar:2500,
            textSnackbar:'El Proveedor fue guardada',
            listaProveedores:[],
            selected: [],
            singleSelect: true,
            loadingDatatable:true,
            headers:[
                //{ text: 'Clave', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Numero', value: 'NumeroProveedor' },
                { text: 'Nombre', value: 'NombreProveedor' },
            ],
            valid: false,
            updateProveedorFlag:false,
            Proveedor:{
                NumeroProveedor: '' ,
                NombreProveedor: '' ,
                Telefono1: '' ,
                Telefono2: '' ,
                Fax: '' ,
                Direccion: '' ,
                Colonia: '' ,
                Cp: '' ,
                Ciudad: '' ,
                Estado: '' ,
                Rfc: '' ,
                FormaPago: '' ,
                Banco: '' ,
                Cuenta: '' ,
                Clabe: '' ,
                Email: '' ,
                Contacto: '' ,
                Comentarios: '' ,
                DiasCredito: '' ,
                Credito: '' ,
                PaginaWeb: '' 
            }

        }
    },
    //#endregion

    //-------------------------------------------------------------------------------
    //#region  Methods
    methods:{
        //#region Metodos Funciones Locales
        handleClick(value) {
            //console.log('handleClick', value);
            this.Proveedor.NumeroProveedor = value.NumeroProveedor;
            this.Proveedor.NombreProveedor = value.NombreProveedor;

            this.updateProveedorFlag = true;
        },

        handleDblClick(value){
            console.log('handleDblClick1', value);
            this.Proveedor.NumeroProveedor = value.NumeroProveedor;
            this.Proveedor.NombreProveedor = value.NombreProveedor;

            this.emithToFather(this.Proveedor.NombreProveedor);
        },

        clearProveedor(){
            this.updateProveedorFlag=false;
            this.Proveedor.NumeroProveedor = '';
            this.Proveedor.NombreProveedor = '';

        },

        emithToFather(NombreProveedor){
            console.log('emithToFather', NombreProveedor);
            this.$emit('setNombreProveedor', NombreProveedor);
            //this.$emit('setShowDialogProveedores', false);
            
        },

        //#endregion

        //#region Consumo Api-Inventario
        getProveedores(){
            this.clearProveedor();
            this.loadingDatatable = true;

            this.api.recuperaProveedores(this.URLApi, 
                    this.Proveedor.NumeroProveedor, this.Proveedor.NumeroProveedor)
            .then( response =>{
                //console.log("recuperaCiudad.data", response.data);
                this.listaProveedores = response.data.objeto;
                this.loadingDatatable = false;

            })
            .catch(error=>{console.log(error);});

        },
        
        insertProveedor(){

            this.api.guardaProveedor( this.URLApi,
                    this.Proveedor.NumeroProveedor, this.Proveedor.NombreProveedor, this.Proveedor.Telefono1, 
                    this.Proveedor.Telefono2, this.Proveedor.Fax, this.Proveedor.Direccion, 
                    this.Proveedor.Colonia, this.Proveedor.Cp, this.Proveedor.Ciudad, 
                    this.Proveedor.Estado, this.Proveedor.Rfc, this.Proveedor.FormaPago, 
                    this.Proveedor.Banco, this.Proveedor.Cuenta, this.Proveedor.Clabe, 
                    this.Proveedor.Email, this.Proveedor.Contacto, this.Proveedor.Comentarios, 
                    this.Proveedor.DiasCredito, this.Proveedor.Credito,  this.Proveedor.PaginaWeb 
            )
            .then( response =>{
                //console.log('insertProveedor', response.data);

                if( response.data.objeto > 0){
                    this.getProveedores();
                }
                else{
                    console.log('Ocurrio un error al guardar.', response.data.mensaje);
                }

            })
            .catch( error => console.log( error ) );

        },
        
        updateProveedor(){
            this.api.modificaProveedor( this.URLApi,
                    this.Proveedor.NumeroProveedor, this.Proveedor.NombreProveedor, this.Proveedor.Telefono1, 
                    this.Proveedor.Telefono2, this.Proveedor.Fax, this.Proveedor.Direccion, 
                    this.Proveedor.Colonia, this.Proveedor.Cp, this.Proveedor.Ciudad, 
                    this.Proveedor.Estado, this.Proveedor.Rfc, this.Proveedor.FormaPago, 
                    this.Proveedor.Banco, this.Proveedor.Cuenta, this.Proveedor.Clabe, 
                    this.Proveedor.Email, this.Proveedor.Contacto, this.Proveedor.Comentarios, 
                    this.Proveedor.DiasCredito, this.Proveedor.Credito,  this.Proveedor.PaginaWeb 
            )
            .then( response =>{
                //console.log('insertProveedor', response.data);

                if( response.data.objeto > 0){
                    this.getProveedores();
                }
                else{
                    console.log('Ocurrio un error al guardar.', response.data.mensaje);
                }

            })
            .catch( error => console.log( error ) );
        },

        deleteProveedor(){
            this.api.eliminaProveedor(this.URLApi, this.Proveedor.NumeroProveedor)
            .then( response => {
                if( response.data.objeto ){
                    this.getProveedores();
                }
                this.showDialog = false;
            })
            .catch( error => console.log( error ) )
        },

        checkUpdate(){
            if(this.updateProveedorFlag){
                //Modifica
                this.updateProveedor();
            }
            else{
                //Guarda
                this.insertProveedor();
            }

        },

        //#endregion

    },
    //#endregion

    //#region Mounted
    mounted(){
        this.getProveedores();
    }
    //#endregion
}
</script>