<template>
    <v-container class="mt-12 mb-12">
        <v-row>
            <v-col  cols="12" md="12">
                <v-card elevation="5">
                    <v-card-title class="d-flex justify-center">
                        Realizar pago.
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center">
                        
                    </v-card-subtitle>
                    <v-card-text>
                        
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="RFC o Número de Contrato"
                                    dense
                                    v-model="filtro.Contrato"
                                    @keyup.enter="getCreditos()"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-btn
                                    color="blue"
                                    block
                                    class="white--text"
                                    @click="getCreditos()"
                                >
                                Consultar adeudo
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogPagos.show" persistent max-width="900">
            <v-card>
                <v-card-title>
                    <span > {{ dialogPagos.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="cerrarListaPagos()"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>

                    <div v-if="listaCredito.length>0">
                        <!-- Muestra lista-->                        
                        <v-list two-line>
                            
                            <v-list-item v-for="(item, index) of listaCredito" :key="index"
                                        @click="()=>{}">
                                <v-list-item-content>
                                    <v-row>
                                        <v-col cols="8" md="8">
                                            A pagar: $ {{ sumaCreditoMoratorio(item.MontoTotal, item.CargoMoratorio) }}
                                        </v-col>
                                        <v-col cols="4" md="4">
                                            
                                            <v-btn
                                                class="white--text"
                                                color="blue"
                                                small
                                                block
                                                @click="goPago(item.IdCredito)"
                                            >
                                                Pagar
                                            </v-btn>

                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </div>

                    <div v-else class="d-flex justify-center">
                        
                        <div v-if="vProgress.show">
                            <v-progress-circular
                                :size="50"
                                color="blue"
                                indeterminate
                            ></v-progress-circular>

                        </div>

                        <div v-else>
                            {{ vProgress.text }}
                        </div>

                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>

        <!--
            <StripeCardComponent></StripeCardComponent>
        -->
        
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import ApiCreditoService from '@/services/ApiCreditoService';

import StripeCardComponent from '@/components/Pagos/StripeCardComponent';
import ListaCreditoComponent from '@/components/Pagos/ListaCreditoComponent';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN', 'StripePK']),
    },
    //#endregion

    //#region Components
    components:{
        StripeCardComponent,
        ListaCreditoComponent,
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiCredito: new  ApiCreditoService(),
            //------------------------------------------------
            dialogPagos:{
                show:false,
                text:'Saldos pendientes'
            },
            //------------------------------------------------
            filtro:{
                Contrato:null,
            },
            //------------------------------------------------
            listaCredito:[],
            vProgress:{
                show:false,
                text:'No hay saldos pendientes.',
            },
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la vista
        cerrarListaPagos(){
            this.dialogPagos.show = false
            this.listaCredito = [];
        },

        goPago(IdCredito){
            const ruta = `/c/${ IdCredito }`;
            this.$router.push(ruta);
        },

        sumaCreditoMoratorio(MontoTotal, CargoMoratorio){
            const monto =  Number.parseFloat(MontoTotal);
            const cargo =  Number.parseFloat(CargoMoratorio);
            return monto + cargo;
        },

        //#endregion


        //#region Metodos de la api de credito
        getCreditos(){
            this.dialogPagos.show = true;
            this.vProgress.show  = true;
            this.vProgress.text = 'No hay saldos pendientes';

            if(this.filtro.Contrato == null || this.filtro.Contrato == ''){
                this.vProgress.show = false;
                this.vProgress.text = 'No se encontró información.';
                return;
            }


            this.apiCredito.recuperaCredito(this.URLApi, this.filtro.Contrato)
            .then( response => {
                console.log('recuperaCredito.data', response.data);
                if(response.data.objeto.length>0){
                    this.listaCredito = response.data.objeto;
                }
                else{
                    this.vProgress.show = false;
                }
            })
            .catch( error => console.log( error ) );

        },
        //#endregion
    }
    //#endregion

}
</script>