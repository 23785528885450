<template>

    <v-row>
        <v-col cols="12" md="12">
            <h4>Escala Glasgow</h4>
        </v-col>
        <v-col cols="12" md="4">
            <v-select
                :items="listaGlasgowMotora"
                v-model="selectGlasgowMotora"
                item-text="descripcion"
                item-value="valor"
                label="Motora"
                @change="selectDropDownGlasgowMotora()"
            ></v-select>
        </v-col>

        <v-col cols="12" md="4">
            <v-select
                :items="listaGlasgowRespuestaVerbal"
                v-model="selectGlasgowRespuestaVerbal"
                item-text="descripcion"
                item-value="valor"
                label="Respuesta Verbal"
                @change="selectDropDownGlasgowRespuestaVerbal()"
            ></v-select>
        </v-col>

        <v-col cols="12" md="4">
            <v-select
                :items="listaGlasgowAperturaOcular"
                v-model="selectGlasgowAperturaOcular"
                item-text="descripcion"
                item-value="valor"
                label="Apertura Ocular"
                @change="selectDropDownGlasgowAperturaOcular()"
            ></v-select>
        </v-col>
    
    <div style="display:none;">        
        A: {{ propGlasgowMotora }} <br>
        B: {{ propGlasgowRespuestaVerbal }} <br>
        C: {{ propGlasgowAperturaOcular }} <br>
    </div>

    </v-row>
    
</template>

<script>
export default {
    props:{
        propGlasgowMotora:Number,
        propGlasgowRespuestaVerbal:Number,
        propGlasgowAperturaOcular:Number,
    },

    computed:{
        selectGlasgowMotora:{
            get:function(){
                return this.valorGlasgowMotora;
            },
            set:function(newValue){
                this.valorGlasgowMotora = newValue;
                return newValue;
            },
        },

        selectGlasgowRespuestaVerbal:{
            get:function(){
                return this.valorGlasgowRespuestaVerbal;
            },
            set:function(newValue){
                this.valorGlasgowRespuestaVerbal = newValue;
                return newValue;
            },
        },

        selectGlasgowAperturaOcular:{
            get:function(){
                return this.valorGlasgowAperturaOcular;
            },
            set:function(newValue){
                this.valorGlasgowAperturaOcular = newValue;
                return newValue;
            },
        }
    },

    data(){
        return{
            valorGlasgowMotora:null,
            valorGlasgowRespuestaVerbal:null,
            valorGlasgowAperturaOcular:null,
            //------------------------------------------------
            //selectGlasgowMotora:'',
            listaGlasgowMotora:[
                { valor:6, descripcion:'6- Obedece'},
                { valor:5, descripcion:'5- Localiza el dolor'},
                { valor:4, descripcion:'4- Retiro de Flexión'},
                { valor:3, descripcion:'3- Flexión Anormal'},
                { valor:2, descripcion:'2- Extensión'},
                { valor:1, descripcion:'1- Sin Respuesta'},
            ],
            //selectGlasgowRespuestaVerbal:'',
            listaGlasgowRespuestaVerbal:[
                { valor:5, descripcion:'5- Orientado y dialogo'},
                { valor:4, descripcion:'4- Confuso'},
                { valor:3, descripcion:'3- Palabras Inadecuadas'},
                { valor:2, descripcion:'2- Sonidos Incomprensibles'},
                { valor:1, descripcion:'1- Sin Respuesta'},
            ],
            //selectGlasgowAperturaOcular:'',
            listaGlasgowAperturaOcular:[
                { valor:4, descripcion:'4- Espontáneamente'},
                { valor:3, descripcion:'3- Al habla'},
                { valor:2, descripcion:'2- Al dolor'},
                { valor:1, descripcion:'1- Sin Respuesta'},
            ],
        }        
    },

    methods:{
        emitiToComponentFather(valorGlasgowMotora, valorGlasgowRespuestaVerbal, valorGlasgowAperturaOcular ){
            //console.log('setValorGlasgowMotora', valorGlasgowMotora );
            this.$emit('setValorGlasgowMotora', valorGlasgowMotora);

            //console.log('setValorGlasgowRespuestaVerbal', valorGlasgowRespuestaVerbal );
            this.$emit('setValorGlasgowRespuestaVerbal', valorGlasgowRespuestaVerbal);

            //console.log('setValorGlasgowAperturaOcular', valorGlasgowAperturaOcular );
            this.$emit('setValorGlasgowAperturaOcular', valorGlasgowAperturaOcular);
            
        },
        //#region selects-Dropdowns
            selectDropDownGlasgowMotora(){ 
                this.valorGlasgowMotora = this.selectGlasgowMotora; 
                this.emitiToComponentFather(this.valorGlasgowMotora, 
                                            this.valorGlasgowRespuestaVerbal, 
                                            this.valorGlasgowAperturaOcular);
            },
            selectDropDownGlasgowRespuestaVerbal(){ 
                this.valorGlasgowRespuestaVerbal = this.selectGlasgowRespuestaVerbal; 
                this.emitiToComponentFather(this.valorGlasgowMotora, 
                                            this.valorGlasgowRespuestaVerbal, 
                                            this.valorGlasgowAperturaOcular);
            },
            selectDropDownGlasgowAperturaOcular(){ 
                this.valorGlasgowAperturaOcular = this.selectGlasgowAperturaOcular; 
                this.emitiToComponentFather(this.valorGlasgowMotora, 
                                            this.valorGlasgowRespuestaVerbal, 
                                            this.valorGlasgowAperturaOcular);
            },
        //#endregion

    },

    updated(){
        this.selectGlasgowMotora = this.propGlasgowMotora; 
        //this.valorGlasgowMotora = this.propGlasgowMotora;

        this.selectGlasgowRespuestaVerbal = this.propGlasgowRespuestaVerbal;
        //this.valorGlasgowRespuestaVerbal = this.propGlasgowRespuestaVerbal;

        this.selectGlasgowAperturaOcular = this.propGlasgowAperturaOcular;
        //this.valorGlasgowAperturaOcular = this.propGlasgowAperturaOcular;
    },

    mounted(){
        this.selectGlasgowMotora = this.propGlasgowMotora; 
        //this.valorGlasgowMotora = this.propGlasgowMotora;

        this.selectGlasgowRespuestaVerbal = this.propGlasgowRespuestaVerbal;
        //this.valorGlasgowRespuestaVerbal = this.propGlasgowRespuestaVerbal;

        this.selectGlasgowAperturaOcular = this.propGlasgowAperturaOcular;
        //this.valorGlasgowAperturaOcular = this.propGlasgowAperturaOcular;
    },
    
}
</script>