<template>
    <v-container>
        <HeaderAppComponent :tituloPagina="tituloPagina" />
        <v-row v-if="1==2">
            <v-col>
                <v-card elevation="2">
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" md="10" class="d-flex justify-center">
                                Contratos
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn 
                                    outlined
                                    color="red"
                                    block
                                    @click="$router.go(-1)"
                                > Salir </v-btn>

                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="12" md="10" class="d-flex justify-center">
                                {{ User.Nombre }}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        
        <v-expansion-panels v-model="openPanel" multiple focusable>

            <!--INICIO Expansion Datos Socio-->
            <v-expansion-panel>

                <v-expansion-panel-header>
                    <template v-slot:default="{ open }" >
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Datos del Socio </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                                
                                <v-fade-transition 
                                        v-if="open"
                                        leave-absolute>
                                    {{ Contrato.Numero }}
                                </v-fade-transition>

                                <span v-else>
                                    <div v-if="Contrato.Contrato > 0">
                                        Contrato: {{ Contrato.Contrato }} /Socio: {{ Contrato.NombreS }} /Beneficiarios: {{ Contrato.Beneficiarios }}
                                    </div>
                                </span>

                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <ContratoComponent
                        :Nuevo="Nuevo"
                        @setContrato="Contrato.Contrato = $event"
                        @setNombreS="Contrato.NombreS = $event"
                        @setBeneficiarios="Contrato.Beneficiarios = $event"
                    ></ContratoComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN Expansion Datos Socio-->

            <!--INICIO Expansion Beneficiarios-->
            <v-expansion-panel :disabled="beneficiariosPanelDisabled" >

                <v-expansion-panel-header>
                    <template>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Beneficiarios </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                                
                                <v-fade-transition leave-absolute>
                                    Beneficiarios 1
                                </v-fade-transition>
                                

                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <BeneficiariosComponent
                        :Numero="Number.parseInt(Contrato.Numero)"
                        :Contrato="Number.parseInt(Contrato.Contrato)"
                        :Beneficiarios="Number.parseInt(Contrato.Beneficiarios)"
                    ></BeneficiariosComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN Expansion Beneficiarios-->

        </v-expansion-panels>

    </v-container>
</template>

<script>
import {mapState} from "vuex";
import HeaderAppComponent from '@/components/HeaderAppComponent'
import ContratoComponent from '@/components/Contratos/ContratoComponent';
import BeneficiariosComponent from '@/components/Contratos/BeneficiariosComponent';

export default {
    components:{
        HeaderAppComponent,
        ContratoComponent,
        BeneficiariosComponent,
    },

    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        beneficiariosPanelDisabled(){
            this.beneficiariosPanel = ( this.Contrato.Contrato > 0 ) ? false : true;
            return this.beneficiariosPanel;
        },
        openPanel:{
            get:function(){
                this.panelAbierto = ( this.Contrato.Contrato > 0 ) ? [0,1] : [0];
                return this.panelAbierto;
            },
            set:function(newValue){
                return newValue;
            }
        },
        Nuevo(){
            let operacion = (this.Contrato.Contrato == 0) ? 'Y' : 'N';
            return operacion;
        }
    },

    data(){
        return{
            tituloPagina:'Contratos',
            beneficiariosPanel:true,
            panelAbierto:[0],
            Contrato:{
                Numero:0,
                Contrato:0,
                NombreS:'',
                Beneficiarios:0
            },
        }
    },

    methods:{
        clearContrato(){
            this.Contrato.Numero = 0;
            this.Contrato.Contrato = 0;
            this.Contrato.NombreS = '';
            this.Contrato.Beneficiarios = 0;

            
        }
    }
}
</script>