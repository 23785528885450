<template>
    <v-container class="spacing-playground pa-4" fluid>
        <!--Filtros-->
        <v-row>
            <v-col cols="5" md="2">
                <v-text-field
                    v-model="Contrato.Contrato"
                    label="Contrato"
                    @keyup.enter="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                    @click:clear="clearContratoObject()"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="Contrato.NombreS"
                    label="Socio"
                    append-icon="mdi-magnify"
                    :loading="loadingContrato"
                    @keyup.enter="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                    @click:clear="clearContratoObject()"
                    @click:append="showDialogBusqueda = true "
                    clearable
                ></v-text-field>

            </v-col>

            <v-col cols="8" md="3">
                <v-text-field
                    v-model="Contrato.Tipo"
                    label="Tipo Socio"
                    @click:clear="clearContratoObject()"
                    clearable
                    readonly
                ></v-text-field>
            </v-col>

            <v-col cols="4" md="1" class="d-flex justify-end">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on" v-bind="attrs"
                            color="blue" outlined  icon
                            @click="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                            >
                            <v-icon> mdi-magnify </v-icon>
                        </v-btn>
                    </template>
                    <span>Consultar</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <!-- Datos de contrato y sus beneficiarios -->
        <v-row>
            <!--Datos Contrato-->
            <v-col cols="12" md="7">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8">
                                <v-text-field
                                    v-model="formDatos.Nombre"
                                    label="Nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="formDatos.Col"
                                    label="Col."
                                ></v-text-field>                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="formDatos.Ciudad"
                                    label="Ciudad"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="formDatos.Domicilio"
                                    label="Domicilio"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="formDatos.CP"
                                    label="C.P."
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="formDatos.Mail"
                                    label="Mail"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="formDatos.Sexo"
                                    label="Sexo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="formDatos.Edad"
                                    label="Edad"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6" md="3">
                                <v-text-field
                                    v-model="formDatos.Tipo"
                                    label="Tipo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="formDatos.Beneficiarios"
                                    label="Beneficiarios"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field
                                    v-model="formDatos.Telefonos"
                                    label="Telefonos"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="formDatos.Antecedentes"
                                    label="Antecedentes"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12">
                                <h4>Contacto</h4>
                            </v-col>
                            <v-col cols="9" md="8">
                                <v-text-field
                                    v-model="Contrato.NombreC"
                                    label="Nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" md="4">
                                <v-text-field
                                    v-model="Contrato.TelefonosC"
                                    label="Tels."
                                ></v-text-field>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!--Lista de beneficiarios-->
            <v-col cols="12" md="5">
                <v-card>
                    <v-card-subtitle>
                        Seleccione la sucursal correspondiente al beneficiario
                    </v-card-subtitle>
                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-textarea
                                        :value="Contrato.Observaciones"
                                        label="Observaciones"
                                        readonly
                                        filled
                                        rows="2"
                                    >
                                    </v-textarea>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="(item, index) of listaBeneficiarios" :key="index"
                                        @click="fillFormDatos(null,item)">
                                <v-list-item-content>
                                    <v-col cols="10" md="10">
                                        {{ item.NombreB }}
                                    </v-col>
                                    <v-col cols="2" md="2" class="d-flex justify-end">
                                        <v-icon>
                                            mdi-arrow-top-left
                                        </v-icon>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Ventana de busqueda -->
        <v-dialog v-model="showDialogBusqueda" max-width="600">
            <v-card>
                <v-card-title>
                    Buscar Socio
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="10" md="10">
                            <v-text-field
                                v-model="Contrato.NombreS"
                                label="Nombre Socio"
                                @click:clear="clearContratoObject()"
                                @keyup.enter="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                                clearable
                                >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2" md="2" class="d-flex justify-center">
                            <v-btn icon
                                color="blue"
                                @click="getContratos(Contrato.Numero, Contrato.Contrato, Contrato.NombreS)"
                                >
                                <v-icon> mdi-magnify </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <v-list two-line>
                                <v-list-item v-for="(item, index) of listaContratos" :key="index"
                                            @click="selectSocio(item)" >

                                    <v-list-item-content>
                                        <v-row>
                                            <v-col cols="10" md="10">
                                                {{ item.NombreS }}
                                            </v-col>
                                            <v-col cols="2" md="2" class="d-flex justify-end">
                                                <v-icon>
                                                    mdi-arrow-top-left
                                                </v-icon>
                                            </v-col>

                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Aviso -->
        <v-snackbar v-model="showSnackbarAviso" :timeout="timeSnackbar">
            {{ mensajeSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                v-bind="attrs"
                color="red" text
                @click="showSnackbarAviso = false"
                >
                <v-icon> mdi-close </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script> 
import {mapState} from "vuex"; 

import ApiContratos from '@/services/ApiContratosService';



export default { 
    //------------------------------------------------------------------------------- 
    //#region Computed
    computed:{ ...mapState(['URLSrc', 'URLApi', 'User']), }, 
    //#endregion 

    //#region Props
    props:{
        NumeroCrum:Number,
        ContratoNumero:Number,
        EstatusCrum:String
    },
    //#endregion

    //------------------------------------------------------------------------------- 
    //#region Data 
    data(){ 
        return{ 
            api:new ApiContratos(), 
            loadingContrato:false,
            showDialogBusqueda:false,
            showSnackbarAviso:false,
            mensajeSnackbar:'No hay resultados',
            timeSnackbar:2000,
            Contrato:{
                Numero:0,
                Contrato:null,
                Tipo:'',
                Estatus:null,
                NombreS:'',
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Observaciones:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            listaContratos:[],
            listaBeneficiarios:[],
            formDatos:{
                Nombre:'',
                Domicilio:'',
                Col:'',
                Ciudad:'',
                CP:'',
                Mail:'',
                Sexo:'',
                Edad:'',
                Tipo:'',
                Telefonos:'',
                Beneficiarios:'',
                Antecedentes:''
            },

                
        } 
    }, 
    //#endregion 

    //------------------------------------------------------------------------------- 
    //#region Methods 
    methods:{ 
        //#region Metodos Funciones Locales 
        emitiToComponentFather(Contrato, EsBeneficiario, NumeroBeneficiario, NombreBeneficiario, Edad, Estatus){
            this.$emit('setContrato', Contrato);
            this.$emit('setNumeroBeneficiario', NumeroBeneficiario);
            this.$emit('setEdad', Edad);
            this.$emit('setEstatus', Estatus);
            //Emite esta variable para saber si tiene que buscar en beneficiarios o solo en contrato
            this.$emit('setEsBeneficiario', EsBeneficiario);
        },
        clearContratoObject(){
            this.listaContratos = [];
            this.ContratoNombre = '';
            this.clearFormDatosObject();
            this.emitiToComponentFather(0,0,'','',0,'');
            this.Contrato = {
                Numero:null,
                Contrato:null,
                Tipo:'',
                Estatus:null,
                NombreS:null,                
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Observaciones:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            }
            this.getContratos(this.Contrato.Numero, this.Contrato.Contrato, this.Contrato.NombreS);
            
        },
        clearFormDatosObject(){
            this.formDatos = {
                Nombre:'',
                Domicilio:'',
                Col:'',
                Ciudad:'',
                CP:'',
                Mail:'',
                Sexo:'',
                Edad:'',
                Tipo:'',
                Telefonos:'',
                Beneficiarios:'',
                Antecedentes:'',
            }
            
        },
        fillContrato(item){
            //console.log('fillContrato', item);
            this.Numero                     = item.Numero;

            this.Contrato.Numero            = item.Numero;
            this.Contrato.Contrato          = item.Contrato;
            this.Contrato.Tipo              = item.Tipo;
            this.Contrato.Estatus           = item.Estatus;
            this.Contrato.NombreS           = item.NombreS; 
            this.ContratoNombreInput        = item.NombreS;

            this.Contrato.RFCS              = item.RFCS;
            this.Contrato.DomicilioS        = item.DomicilioS;
            this.Contrato.ColoniaS          = item.ColoniaS; 
            this.selectCiudadS              = item.CiudadS; //this.Contrato.CiudadS
            this.Contrato.CPS               = item.CPS;

            this.Contrato.CorreoS           = item.CorreoS;
            this.Contrato.TelefonosS        = item.TelefonosS;

            this.Contrato.DiasServicioS     = item.DiasServicioS;
            this.Contrato.HorarioS          = item.HorarioS;

            this.Contrato.AntecedentesS     = item.AntecedentesS;
            this.selectSexoS                = item.SexoS; //this.Contrato.SexoS
            this.Contrato.FechaNacimientoS  = item.FechaNacimientoS;
            this.selectTipoS                = item.TipoS; //this.Contrato.TipoS
            this.Contrato.EdadS             = item.EdadS;
            
            this.Contrato.Beneficiarios     = item.Beneficiarios;
            this.Contrato.NombreC           = item.NombreC;
            this.Contrato.TelefonosC        = item.TelefonosC;
            this.Contrato.CorreoC           = item.CorreoC;
            this.Contrato.Usuario           = item.Usuario;
                
            this.Contrato.FechaContrato     = item.FechaContrato;
            this.Contrato.HoraContrato      = item.HoraContrato;

            this.Contrato.Observaciones     = item.Observaciones;

            this.Contrato.Extra1            = item.Extra1;
            this.Contrato.Extra2            = item.Extra2;
            this.Contrato.Extra3            = item.Extra3;

            this.fillFormDatos(this.Contrato,null);
            this.getBeneficiarios();
        },
        fillFormDatos(ContratoObject, BeneficiarioObject){
            this.formDatos.Beneficiarios = this.Contrato.Beneficiarios;
            let NumeroBeneficiario = 0;
            let NombreBeneficiario =  '';
            let EsBeneficiario = 0;
            let Edad = 0;

            if(ContratoObject){
                //console.log('if-ContratoObject', ContratoObject);
                NumeroBeneficiario = ContratoObject.Numero;
                NombreBeneficiario = ContratoObject.NombreC;

                this.formDatos.Nombre           = ContratoObject.NombreS;
                this.formDatos.Domicilio        = ContratoObject.DomicilioS;
                this.formDatos.Col              = ContratoObject.ColoniaS;
                this.formDatos.Ciudad           = ContratoObject.CiudadS;
                this.formDatos.CP               = ContratoObject.CPS;
                this.formDatos.Mail             = ContratoObject.CorreoC;

                this.formDatos.Sexo             = ContratoObject.SexoS;
                this.formDatos.Edad             = ContratoObject.EdadS;
                this.formDatos.Tipo             = ContratoObject.TipoS;
                this.formDatos.Telefonos        = ContratoObject.TelefonosS;
                this.formDatos.Antecedentes     = ContratoObject.AntecedentesS;
                
                EsBeneficiario = 0;
            }
            if(BeneficiarioObject){
                //console.log('if-BeneficiarioObject', BeneficiarioObject);
                NumeroBeneficiario = BeneficiarioObject.Numero;
                NombreBeneficiario = BeneficiarioObject.NombreB;

                this.formDatos.Nombre = BeneficiarioObject.NombreB;
                this.formDatos.Domicilio = BeneficiarioObject.DomicilioB;
                this.formDatos.Col = BeneficiarioObject.ColoniaB;
                this.formDatos.Ciudad = BeneficiarioObject.CiudadB;
                this.formDatos.CP = BeneficiarioObject.CPB;
                this.formDatos.Mail = BeneficiarioObject.CorreoB;

                this.formDatos.Sexo = BeneficiarioObject.SexoB;
                this.formDatos.Edad = BeneficiarioObject.EdadB;
                this.formDatos.Tipo = BeneficiarioObject.TipoB;
                this.formDatos.Telefonos = BeneficiarioObject.TelefonosB;
                this.formDatos.Antecedentes = BeneficiarioObject.AntecedentesB;
                EsBeneficiario = 1;
            }

            this.emitiToComponentFather( this.Contrato.Contrato, EsBeneficiario, 
                NumeroBeneficiario, NombreBeneficiario, this.formDatos.Edad, this.Contrato.Estatus);
        },
        selectSocio(item){
            this.showDialogBusqueda = false;
            this.fillContrato(item);
            this.getContratos(this.Contrato.Numero, this.Contrato.Contrato, this.Contrato.NombreS);
        },
        //#endregion 

        //#region Consumo Api-Contratos
        getContratos(ContratoNumero, ContratoContrato, ContratoNombreS){
            this.loadingContrato = true;
            this.showSnackbarAviso = false;
            this.listaBeneficiarios = [];

            //debugger;

            if(!ContratoContrato && !ContratoNombreS ){
                this.loadingContrato = false;
                this.showSnackbarAviso = false;
                return;
            }

            //console.log('getContratos', this.Contrato.NombreS);

            //Solo puede mostrar uno por seguridad
            this.api.recuperaContratos( this.URLApi, 
                    ContratoNumero, ContratoContrato, ContratoNombreS)
            .then( response => {
                //console.log('getContratos.data', response.data);
                this.loadingContrato = false;

                this.listaContratos = response.data.objeto;

                if( this.listaContratos.length == 1 ){
                    this.fillContrato( this.listaContratos[0] );
                    this.showDialogBusqueda = false;
                }
                else if(this.listaContratos.length > 1){
                    this.showDialogBusqueda = true;
                    //this.clearContratoObject();
                }
                else{
                    //console.log('else', this.listaContratos);
                    this.showSnackbarAviso = true;
                }
            })
            .catch(error=>{console.log(error);});

        },        
        //#endregion 

        //#region Consumo Api-Beneficiarios
        getBeneficiarios(){
            //Aqui debe de recuperar todos los beneficiarios del contrato
            //Por eso va en 0 el primer parametro para que no busque ningun beneficiario
            //En especifico
            this.api.recuperaBeneficiarios(this.URLApi,
                0, this.Contrato.Contrato, '')
            .then( response => {
                //console.log("getBeneficiarios.data", response.data);
                if(response.data.mensaje == ''){
                    this.listaBeneficiarios = response.data.objeto;
                }
            })
            .catch(error=>{console.log(error);});
        }
        //#endregion 

        //#region Consumo Api-CRUM
        //#endregion 
    }, 
    //#endregion 

    //#region update
    updated(){
        
    },
    //#endregion

    //#region mounted
    mounted(){
        //this.Contrato.Numero = null;
        this.Contrato.Contrato = (!this.ContratoNumero)?'':this.ContratoNumero;
        this.Contrato.NombreS = ''; 

        this.getContratos(this.Contrato.Numero, this.Contrato.Contrato, this.Contrato.NombreS);

    },

    //#region beforeMounted
    beforeMount(){        

        
    },
    //#endregion

    //#endregion

    //#region watch
    watch:{
    },
    //#endregion
}
</script>