<template>
    <v-container>
        <!--Filtros-->
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field
                    v-model="articuloBusqueda.NumeroAlmacen"
                    label="Buscar por Número"
                    append-icon="mdi-magnify"
                    clearable
                    @keyup="getArticulos()"
                    @click:append="getArticulos()"
                    @click:clear="clearArticuloBusqueda()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
                <v-text-field
                    v-model="articuloBusqueda.Descripcion"
                    label="Buscar por descripción"
                    append-icon="mdi-magnify"
                    clearable
                    @keyup="getArticulos()"
                    @click:append="getArticulos()"
                    @click:clear="clearArticuloBusqueda()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
                <v-select
                    label="Tipo"
                    v-model="selectTipo"
                    :items="listaTipos"
                    item-text="text"
                    item-value="value"
                    @change="selectDropDownFiltroTipo()"
                ></v-select>
            </v-col>

            <v-col cols="12" md="2">
                <v-btn
                    color="blue"
                    outlined block
                    @click="activarDialogArticulo(titleDialgoInsert)"
                    >
                    Nuevo
                </v-btn>
            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaArticulos"
                    :single-select="singleSelect"
                    :items-per-page="10"
                    :footer-props="{
                        'items-per-page-text':'Artículos por página',
                    }"
                    :loading="loadingDatatable"
                    item-key="NumeroAlmacen"
                    class="elevation-1"
                    loading-text="Cargando..."
                    no-data-text="Sin datos disponibles."
                    @click:row="handleClick"
                > <!--show-select-->
                </v-data-table>
            </v-col>
        </v-row>

        <!--INICIO Dialog Producto-->
        <v-dialog v-model="showDialogArticulo" max-width="1000">
            <v-card>
                
                <v-card-title class="headline">
                    <v-row>
                        <v-col cols="11" md="11">
                            {{ titleDialogArticulo }}
                        </v-col>
                        
                    </v-row>
                    <v-col cols="1"  md="1" class="d-flex justify-end">
                        <v-btn  icon
                                @click="showDialogArticulo = false" 
                                color="red"
                                >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>

                <v-card-text>
                    
                    <v-form ref="form" v-model="valid" lazy-validation >
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model="articulo.NumeroAlmacen"
                                    label="Clave del Artículo"
                                    :disabled="updateFlag"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" offset-md="6" md="2" class="d-flex justify-end">

                                <v-tooltip bottom v-if="updateFlag">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  v-on="on" v-bind="attrs"
                                                color="red" fab small class="white--text"
                                                @click="showDialogEliminar=true"
                                                >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar Artículo</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="9">
                                <v-text-field
                                    v-model="articulo.Descripcion"
                                    label="Descripción"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="articulo.Marca"
                                    label="Marca"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="3">
                                <!--
                                    <v-text-field
                                        v-model="articulo.Ubicacion"
                                        label="Ubicación"
                                    ></v-text-field>
                                -->

                                <v-select
                                    v-model="selectTipos"
                                    :items="listaTipos"
                                    :loading="selectTiposLoading"
                                    label="Tipos"
                                    item-text="Tipo"
                                    item-value="Tipo"
                                    required
                                    @change="selectDropDownTipos()"
                                    ></v-select><!--:rules="[v => !!v || 'Es necesario seleccionar una presentación.']"-->

                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="articulo.CostoNeto"
                                    label="Costo"
                                    prefix="$"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="articulo.PorcentajeUtilidad"
                                    label="Porcentaje Utilidad"
                                    append-icon="%"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="PrecioPublico"
                                    label="Precio Público"
                                    prefix="$"
                                ></v-text-field>
                            </v-col>

                            
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="4">
                                <v-select
                                    v-model="selectPresentacion"
                                    :items="listaPresentaciones"
                                    :loading="selectPresentacionesLoading"
                                    label="Presentación"
                                    item-text="Presentacion"
                                    item-value="Presentacion"
                                    required
                                    @change="selectDropDownPresentacion()"
                                    ></v-select><!--:rules="[v => !!v || 'Es necesario seleccionar una presentación.']"-->
                            </v-col>
                            
                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="articulo.UnidadesPorPtn"
                                    :label="labelUnidadesPorPtn"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="articulo.Existencia"
                                    label="Existencia Unitaria"
                                ></v-text-field>
                            </v-col>

                        </v-row>

                        <v-row>
                            
                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="articulo.CantidadPtn"
                                    :label="selectPresentacion"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="articulo.MinimoStock"
                                    label="Mínimo Stock"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="articulo.ExistenciaTotal"
                                    label="Existencia Total Unit."
                                ></v-text-field>
                            </v-col>

                        </v-row>


                        
                    </v-form>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="red darken-1" text 
                            @click="showDialogArticulo = false" >
                        Cancelar
                    </v-btn>
                    <v-btn  color="green" class="white--text" 
                            @click="verificarUpdate(updateFlag)"
                             >
                        <v-icon v-if="updateFlag" > mdi-content-save-alert</v-icon>
                        <v-icon v-else> mdi-content-save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Dialog Producto-->

        <!--INICIO Dialog Eliminar -->
        <v-dialog v-model="showDialogEliminar" persistent max-width="290" >
            <v-card>
                <v-card-title class="headline">
                    ¿Desea continuar?
                </v-card-title>
                <v-card-text>
                    <p>
                        Esta a punto de eliminar el artículo con descripción:  <strong> {{ articulo.Descripcion }} </strong>.
                    </p>
                    <p>
                        Con la clave: <strong> {{ articulo.NumeroAlmacen }} </strong>
                    </p>

                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="showDialogEliminar = false" >
                        No
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteArticulo()" >
                        Si
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Dialog Eliminar -->



        <!--Mensaje para accion-->
        <v-snackbar v-model="showSnackbar" :timeout="timeoutSnackbar" >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="showSnackbar = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
import {mapState} from "vuex";
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiService from '@/services/ApiInventarioService';

export default {
    //-------------------------------------------------------------------------------
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        labelUnidadesPorPtn(){
            let label = 'Unidades Por';

            if(this.tituloLabelUnidadesPorPtn == ''){
                label = 'Unidades Por';
            }
            else{
                label = 'Unidades Por ' +this.tituloLabelUnidadesPorPtn;
            }

            return label;
        },
        PrecioPublico(){
            const precioVenta = this.articulo.CostoNeto * (1+(this.articulo.PorcentajeUtilidad/100));

            return Number.parseFloat(precioVenta).toFixed(2);
        }
    },
    //#endregion

    //-------------------------------------------------------------------------------
    //#region Data
    data(){
        return{
            apiConfiguracion:new ApiConfiguracionService,
            api:new ApiService(),
            valid: true,
            selected: [],
            singleSelect:true,
            showDialogArticulo:false,
            showDialogEliminar:false,
            showSnackbar:false,
            timeoutSnackbar:2500,
            textSnackbar:'Artículo guardado correctamente. ',
            titleDialgoInsert:'NUEVO',
            titleDialgoUpdate:'MODIFICAR',
            titleDialogArticulo:'NUEVO',
            updateFlag:false,
            headers:[
                //{ text: 'Clave', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Numero de Almacen', value: 'NumeroAlmacen' },
                { text: 'Descripción', value: 'Descripcion' },
                { text: 'Existencia', value: 'ExistenciaTotal' },
                { text: 'Costo($)', value: 'CostoNeto' },
                //{ text: 'Precio Sin IVA', value: 'PrecioSinIva' },
                { text: 'Utilidad(%)', value: 'PorcentajeUtilidad' },
                { text: 'Min ST', value: 'MinimoStock' },
            ],
            selectPresentacion:null,
            selectPresentacionesLoading:false,
            listaPresentaciones:['AMPOLLETA', 'BOTE', 'CAJA', 'CAPSULAS'],
            selectTipos:null,
            selectTiposLoading:false,
            listaTipos:['Todos','Farmacia', 'Miscelanea', 'Servicios'],
            loadingDatatable:true,
            listaArticulos:[],
            articuloBusqueda:{
                NumeroAlmacen:'',
                Descripcion:'',
                Tipo:null
            },
            tituloLabelUnidadesPorPtn:'Unidades Por',
            articulo:{
                CodigoBarras:'',
                NumeroAlmacen:'',
                CostoNeto:null,
                Descripcion:'',
                Existencia:null,
                PorcentajeUtilidad:null,
                PrecioPublicoNeto:null,
                MinimoStock:null,
                Grupo:'',
                Subgrupo:'',
                Marca:'',
                Ubicacion:'',
                Tipo:'',
                Presentacion:'',
                UnidadesPorPtn:null,
                CantidadPtn:null,
                ExistenciaTotal:null,
                //------------------------
                PrecioSinIva:null
            },
            selectTipo:null,
        }
    },
    //#endregion

    //-------------------------------------------------------------------------------
    //#region  Methods
    methods:{

        //#region Metodos Funciones Locales
        mostrarSnackBar(texto, error=false){
            this.textSnackbar = texto;
            this.showSnackbar = true;

            if( error ){
                return;
            }
            this.showDialogArticulo = false;
            this.showDialogEliminar = false;
        },

        activarDialogArticulo(titulo){
            this.titleDialogArticulo = titulo;
            this.showDialogArticulo = true;

            if(titulo == this.titleDialgoUpdate ){
                this.updateFlag = true;
            }
            else{
                console.log('activarDialogArticulo');
                this.updateFlag = false;
            }
            this.clearArticuloObject();
        },

        handleClick(value) {
            //console.log('handleClick', value);
            this.activarDialogArticulo(this.titleDialgoUpdate);
            this.fillArticuloObject(value);
        },

        clearArticuloBusqueda(){
            //console.log('clearArticuloBusqueda');
            this.articuloBusqueda.NumeroAlmacen = '';
            this.articuloBusqueda.Descripcion = '';
            this.selectTipo = 'Todos';
            this.selectTipos = 'Todos';
            this.getArticulos();
        },

        clearArticuloObject(){
            this.articulo.CodigoBarras          = '';
            this.articulo.NumeroAlmacen         = '';
            this.articulo.CostoNeto             = null;
            this.articulo.Descripcion           = '';
            this.articulo.Existencia            = null;
            this.articulo.PorcentajeUtilidad    = null;
            this.articulo.PrecioPublicoNeto     = null;
            this.articulo.MinimoStock           = null;
            this.articulo.Grupo                 = '';
            this.articulo.Subgrupo              = '';
            this.articulo.Marca                 = '';
            this.articulo.Ubicacion             = '';
            this.articulo.Tipo                  = '';
            this.articulo.Presentacion          = '';
            this.articulo.UnidadesPorPtn        = null;
            this.articulo.CantidadPtn           = null;
            this.articulo.ExistenciaTotal       = null;
            // -----------------------------------------------
            this.selectPresentacion             = null;
            this.tituloLabelUnidadesPorPtn      = '';
        },

        fillArticuloObject(item){
            this.articulo.CodigoBarras          = item.CodigoBarras;
            this.articulo.NumeroAlmacen         = item.NumeroAlmacen;
            this.articulo.CostoNeto             = item.CostoNeto;
            this.articulo.Descripcion           = item.Descripcion;
            this.articulo.Existencia            = item.Existencia;
            this.articulo.PorcentajeUtilidad    = item.PorcentajeUtilidad;
            this.articulo.PrecioPublicoNeto     = item.PrecioPublicoNeto;
            this.articulo.MinimoStock           = item.MinimoStock;
            this.articulo.Grupo                 = item.Grupo;
            this.articulo.Subgrupo              = item.Subgrupo;
            this.articulo.Marca                 = item.Marca;
            this.articulo.Ubicacion             = item.Ubicacion;
            
            this.selectTipos                    = item.Tipo;

            this.selectPresentacion             = item.Presentacion; //this.articulo.Presentacion
            this.tituloLabelUnidadesPorPtn      = item.Presentacion; //this.articulo.Presentacion

            this.articulo.UnidadesPorPtn        = item.UnidadesPorPtn;
            this.articulo.CantidadPtn           = item.CantidadPtn;
            this.articulo.ExistenciaTotal       = item.ExistenciaTotal;

        },

        verificarUpdate(banderaUpdate){

            if(banderaUpdate){
                //Update
                this.updateArticulo();
            }
            else{
                //Insert
                this.insertArticulo();                
            }

        },
        //#endregion

        //#region selects-Dropdown     
        selectDropDownPresentacion(){ 
            this.articulo.Presentacion = this.selectPresentacion; 
            this.tituloLabelUnidadesPorPtn = this.selectPresentacion;
        },
        selectDropDownTipos(){ 
            console.log('selectDropDownTipos', this.selectTipos);
            this.articulo.Tipo = this.selectTipos;            
        },

        selectDropDownFiltroTipo(){
            this.articuloBusqueda.Tipo = this.selectTipo;
            this.getArticulos();
        },
        //#endregion


        //#region Consumo Api-Configuración
        getPresentaciones(){
            this.apiConfiguracion.recuperaPresentaciones(this.URLApi,'','')
            .then( response => {
                //console.log('getPresentaciones.data', response.data);
                this.listaPresentaciones = response.data.objeto;

            })
            .catch( error=> console.log('error', error) );
        },
        //#endregion

        //#region Consumo Api-Inventario
        getArticulos(){
            this.loadingDatatable = true;

            this.api.recuperaArticulos(this.URLApi, 
                this.articuloBusqueda.NumeroAlmacen, this.articuloBusqueda.Descripcion, this.articuloBusqueda.Tipo)
            .then( response => {
                //console.log('getArticulos.data', response.data);
                this.listaArticulos = response.data.objeto;

                if(response.data.mensaje!=''){
                    console.log('mensaje', response.data.mensaje);
                }
                else{
                    this.loadingBuscar = false;
                    this.loadingDatatable = false;
                }

            })
            .catch( error=> console.log('error', error) );

        },

        insertArticulo(){
            //console.log('insertArticulo()');

            this.api.guardaArticulo(this.URLApi, 
                this.articulo.CodigoBarras, this.articulo.NumeroAlmacen,
                this.articulo.CostoNeto, this.articulo.Descripcion,
                this.articulo.Existencia, this.articulo.PorcentajeUtilidad,
                this.articulo.PrecioPublicoNeto,
                this.articulo.MinimoStock, this.articulo.Grupo, this.articulo.Subgrupo,
                this.articulo.Marca, this.articulo.Ubicacion, 
                this.articulo.Tipo, this.articulo.Presentacion,
                this.articulo.UnidadesPorPtn, this.articulo.CantidadPtn, this.articulo.ExistenciaTotal )
            .then( response =>{
                //console.log('insertArticulo.data', response.data);
                if(response.data.objeto>0){
                    this.mostrarSnackBar('Artículo guardado correctamente. ');
                    this.clearArticuloBusqueda();
                }
                else{
                    let objetoCatch = response.data.objetoCatch;
                    if(response.data.objetoCatch[1] == 1062 ){
                        this.mostrarSnackBar('Clave del Artículo duplicado, intente otro. ', true);
                    }
                }

            })
            .catch( error=> console.log('error', error) );
        },

        updateArticulo(){
            //console.log('updateArticulo()');

            this.api.modificaArticulo(this.URLApi, 
                this.articulo.CodigoBarras, this.articulo.NumeroAlmacen,
                this.articulo.CostoNeto, this.articulo.Descripcion,
                this.articulo.Existencia, this.articulo.PorcentajeUtilidad,
                this.articulo.PrecioPublicoNeto,
                this.articulo.MinimoStock, this.articulo.Grupo, this.articulo.Subgrupo,
                this.articulo.Marca, this.articulo.Ubicacion, 
                this.articulo.Tipo, this.articulo.Presentacion,
                this.articulo.UnidadesPorPtn, this.articulo.CantidadPtn, this.articulo.ExistenciaTotal )
            .then( response =>{
                //console.log('updateArticulo.data', response.data);
                if(response.data.objeto>0){
                    this.mostrarSnackBar('Artículo guardado correctamente. ');
                    this.clearArticuloBusqueda();
                }
            })
            .catch( error=> console.log('error', error) );

        },

        deleteArticulo(){

            this.api.eliminaArticulo(this.URLApi, this.articulo.NumeroAlmacen)
            .then( response =>{
                //console.log('deleteArticulo.data', response.data);
                if(response.data.objeto){
                    this.mostrarSnackBar('El Artículo fue eliminado. ');
                    this.clearArticuloBusqueda();
                }
            })
            .catch( error=> console.log('error', error) );

        },

        //#endregion

    },
    //#endregion

    mounted(){
        this.getArticulos();
    },

    beforeMount(){
        this.getPresentaciones();
    },

    watch:{
        selectPresentacion(val){
            this.articulo.Presentacion = val;
        },
    }
}
</script>