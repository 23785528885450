<template>
    
</template>

<script>
export default {
    
    //#region Props
    props:{
        pContrato:Number,
    },
    //#endregion

    //#region Data
    data(){
        return{

        }
    },
    //#endregion

    //#region Methods
    methods:{

    },
    //#endregion

}
</script>