<template>
    <v-container>
        <v-form ref="form" v-model="valid" lazy-validation >
            <v-row>
                <v-col  v-if="1==2"
                        cols="12" md="2">
                    <v-select
                        v-model="selectTiposArticulo"
                        :items="listaTiposArticulo"
                        item-text="text"
                        item-value="value"
                        label="Tipo artículo"
                        required
                        @change="selectDropDownTipoArticulo()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        label="Desde:"
                        v-mask="'####-##-##'"
                        v-model="filtro.fechaDesde" 
                        :rules="rules.fechaDesde"
                        placeholder="AAAA-MM-DD"
                        hint="AAAA-MM-DD Ej.: 2019-01-15"
                        persistent-hint
                        />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        label="Hasta:"
                        v-mask="'####-##-##'"
                        v-model="filtro.fechaHasta" 
                        :rules="rules.fechaHasta"
                        placeholder="AAAA-MM-DD"
                        hint="AAAA-MM-DD Ej.: 2019-01-15"
                        persistent-hint
                        />
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn
                        @click="buscarVentas()"
                        color="blue"
                        class="white--text"
                        >
                        <v-icon>
                            mdi-magnify
                        </v-icon>
                        Buscar
                    </v-btn>
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn
                        color="orange"
                        class="white--text"
                        @click="generaCorte()"
                        >
                        <v-icon>mdi-webhook </v-icon>
                        Generar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-alert
                        v-model="alert.show"
                        :color="alert.color"
                        dismissible
                        class="white--text"
                    >
                        {{ alert.text }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-form>

        <v-list two-line>
            <v-subheader v-if="listaVentas.length>0" > Total ventas: {{ listaVentas.length }} </v-subheader>
            <v-list-item v-for="item of listaVentas" :key="item.IdVenta" 
                        @click="()=>{}"> 
                <v-list-item-content>
                    <v-row>
                        <v-col cols="3" md="2">
                            Folio venta: {{ item.IdVenta }}
                        </v-col>
                        <v-col cols="3" md="3">
                            Subtotal: $ {{ Number.parseFloat(item.Subtotal).toFixed(2) }}
                        </v-col>
                        <v-col cols="3" md="3">
                            Iva: $ {{ Number.parseFloat(item.Iva).toFixed(2) }}
                        </v-col>
                        <v-col cols="3" md="4">
                            Total: $ {{ Number.parseFloat(item.Total).toFixed(2) }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" md="2">
                            Articulos: {{ item.Renglones }}
                        </v-col>
                        <v-col cols="3" md="3">
                            Tipo Pago: {{ item.TipoPago }}
                        </v-col>
                        <v-col cols="3" md="3">
                            Estatus: {{ item.Estatus }}
                        </v-col>
                        <v-col cols="12" md="4">
                            Fecha y Hora: {{ item.FechaHora }}
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <ShowPdfComponent
            :mostrarVentana="showDialog"
            :pTituloPDF="tituloPDF"
            :rutaPdf="rutaPdf"
            @setShowDialog="showDialog = $event"
        ></ShowPdfComponent>


    </v-container>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";

import ApiInventario from '@/services/ApiInventarioService';
import ApiPuntoVenta from '@/services/ApiPuntoVentaService';
import ApiReportesService from '@/services/ApiReportesService';

import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    //#endregion

    //#region Components
    components:{
        ShowPdfComponent,
    },
    //#endregion
    
    //#region Data
    data(){
        return{
            apiInventario: new ApiInventario(),
            apiPVenta: new ApiPuntoVenta(),
            apiReporte: new ApiReportesService(),
            //--------------------------------------------------------
            valid: true,
            alert:{
                show:false,
                text:'Seleccione un tipo de artículo.',
                color:'red'
            },
            selectTiposArticulo:'',
            listaTiposArticulo:[
                { value:'TODOS', text:'Todos'},
                { value:'FARMACIA', text:'Farmacia'},
                { value:'MISCELANEA', text:'Miscelanea'},
                { value:'SERVICIOS', text:'Servicios'}                
            ],
            filtro:{
                fechaDesde:'',
                fechaHasta:'',
                CorteHecho: 0,
                NumeroCorte: null
            },
            listaVentas:[],
            listaVentaDetalle:[],
            rules:{
                fechaDesde:[
                    v => /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'La fecha debe de tener el formato indicado.',
                ],
                fechaHasta:[
                    v => /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'La fecha debe de tener el formato indicado.',
                ]
            },
            showDialog:false,
            tituloPDF:'Corte de caja diario',
            rutaPdf:'',
            rutaReportes:'/reportes/Rpt8/',
            oReporte:{
                NumeroCorte:null,
                tipo:'',
                pMes:'',
                pYear:'',
                fechaDesde:null,
                fechaHasta:null,
            }
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la vista
        selectDropDownTipoArticulo(){
            this.filtro.tipoArticulo = this.selectTiposArticulo;
        },
        buscarVentas(){
            //console.log('filtros', this.filtro);
            this.alert.show = false;
            if ( this.filtro.tipoArticulo == '') {
                //Mostrar mensaje de que seleccione que tipo de articulo
                this.alert.show = true;
                return;
            }

            this.recuperaVentas(this.filtro);

        },
        fechaActual(mesAnterior=1){//Si se pasa el parametro 0, da el mes anterior
            const date =  new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+mesAnterior;
            const D = date.getDate();
            return Y.toString() + '-' + M.toString().padStart(2,'00') + '-' + D.toString().padStart(2,'00');
        },
        //#endregion

        //#region Metodos de la api del punto de venta
        recuperaVentas(filtro){
            this.listaVentas = [];
            this.apiPVenta.recuperaVenta(this.URLApi, filtro.fechaDesde, filtro.fechaHasta, filtro.CorteHecho, filtro.NumeroCorte )
            .then( response => {
                console.log('recuperaVenta', response.data);
                if( response.data.objeto.length > 0 ){
                    this.listaVentas = response.data.objeto;
                }
                else{
                    //Nada
                    this.listaVentas = [];
                }
            })
            .catch( error => console.log( error ) );
        },

        recuperaVentasDetalle(filtro){
            this.listaVentaDetalle = [];
            this.apiPVenta.recuperaVentaDetalle( this.URLApi, filtro.tipoArticulo, filtro.fechaDesde, filtro.fechaHasta )
            .then( response =>{
                console.log('recuperaVentaDetalle', response.data);

                if(response.data.objeto.length>0){
                    this.listaVentaDetalle = response.data.objeto;
                }
                else{
                    this.listaVentaDetalle = [];
                }


            })
            .catch( error => console.log( error ) );

        },

        generaCorte(){
            
            this.apiPVenta.guardaCorte(this.URLApi, this.listaVentas)
            .then( response =>{
                console.log('guardaCorte', response.data);

                if(response.data.objeto.NumeroCorte>0){
                    this.generaReporteCorte(response.data.objeto.NumeroCorte);
                }
            })
            .catch( error => console.log( error ) );

        },
        //#endregion

        //#region Metodos de la api de reportes
        generaReporteCorte( NumeroCorte){
            this.apiReporte.DescargaReporteCorte(this.URLApi, 
                        NumeroCorte, this.oReporte.pMes, this.oReporte.pYear,
                        this.oReporte.fechaDesde, this.oReporte.fechaHasta)
            .then( response =>{
                //console.log('generaReporte.data', response.data);
                this.recuperaVentas(this.filtro);
                this.rutaPdf = this.rutaReportes + response.data.objeto;

                //console.log('Ruta PDF: ', this.URLSrc + this.rutaPdf);
                this.showDialog = true;

            })
            .catch( error => console.log( error ) );
        },
        //#endregion

    },
    //#endregion

    //#region mounted
    mounted(){
        this.filtro.fechaDesde = this.fechaActual(0);
        this.filtro.fechaHasta = this.fechaActual();

    },
    //#endregion


}
</script>
