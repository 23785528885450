import axios from 'axios';

export default class ApiInventarioService {

    //#region Articulos
    recuperaArticulos(url, NumeroAlmacen, Descripcion, Tipo){
        //Definicion de ruta
        const controller = 'Articulos';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroAlmacen,
            Descripcion,
            Tipo
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaArticulo( url, 
                    CodigoBarras, NumeroAlmacen,
                    CostoNeto, Descripcion,
                    Existencia, PorcentajeUtilidad, PrecioPublicoNeto,
                    MinimoStock, Grupo, Subgrupo,
                    Marca, Ubicacion, Tipo, Presentacion,
                    UnidadesPorPtn, CantidadPtn, ExistenciaTotal){
        //Definicion de ruta
        const controller = 'Articulos';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            CodigoBarras,
            NumeroAlmacen,
            CostoNeto,
            Descripcion,
            Existencia,
            PorcentajeUtilidad,
            PrecioPublicoNeto,
            MinimoStock,
            Grupo,
            Subgrupo,
            Marca,
            Ubicacion,
            Tipo,
            Presentacion,
            UnidadesPorPtn,
            CantidadPtn,
            ExistenciaTotal
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaArticulo(   url, 
                        CodigoBarras, NumeroAlmacen,
                        CostoNeto, Descripcion,
                        Existencia, PorcentajeUtilidad, PrecioPublicoNeto,
                        MinimoStock, Grupo, Subgrupo,
                        Marca, Ubicacion, Tipo, Presentacion,
                        UnidadesPorPtn, CantidadPtn, ExistenciaTotal){
        //Definicion de ruta
        const controller = 'Articulos';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            CodigoBarras,
            NumeroAlmacen,
            CostoNeto,
            Descripcion,
            Existencia,
            PorcentajeUtilidad,
            PrecioPublicoNeto,
            MinimoStock,
            Grupo,
            Subgrupo,
            Marca,
            Ubicacion,
            Tipo,
            Presentacion,
            UnidadesPorPtn,
            CantidadPtn,
            ExistenciaTotal
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaArticulo(url, NumeroAlmacen){
        //Definicion de ruta
        const controller = 'Articulos';
        const method = 'elimina';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroAlmacen
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    sumaMercanciaURL(url){
        //Definicion de ruta
        const controller = 'Articulos';
        const method = 'sumaMercancia';

        //Construccion de objeto a enviar
        //const objeto = { 
          //  NumeroAlmacen
        //};
        //const objetoString = JSON.stringify(objeto);
        //const json = 'json='+objetoString;
        
        //Consumo de api
        const ruta = `${ url }/${ controller }/${ method }`;
        //return axios.post(ruta , json);
        return ruta;
    }

    //#endregion

    //#region Proveedores
    recuperaProveedores(url, NumeroProveedor, NombreProveedor){
        //Definicion de ruta
        const controller = 'Proveedores';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroProveedor,
            NombreProveedor
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaProveedor(    url, 
                        NumeroProveedor, NombreProveedor, 
                        Telefono1, Telefono2, Fax, 
                        Direccion, Colonia, Cp, Ciudad,
                        Estado, Rfc, FormaPago,
                        Banco, Cuenta, Clabe,
                        Email, Contacto, Comentarios,
                        DiasCredito, Credito, PaginaWeb){
        //Definicion de ruta
        const controller = 'Proveedores';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroProveedor,
            NombreProveedor,
            Telefono1,
            Telefono2,
            Fax,
            Direccion,
            Colonia,
            Cp,
            Ciudad,
            Estado,
            Rfc,
            FormaPago,
            Banco,
            Cuenta,
            Clabe,
            Email,
            Contacto,
            Comentarios,
            DiasCredito,
            Credito,
            PaginaWeb
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaProveedor(  url, 
                        NumeroProveedor, NombreProveedor, 
                        Telefono1, Telefono2, Fax, 
                        Direccion, Colonia, Cp, Ciudad,
                        Estado, Rfc, FormaPago,
                        Banco, Cuenta, Clabe,
                        Email, Contacto, Comentarios,
                        DiasCredito, Credito, PaginaWeb){
        //Definicion de ruta
        const controller = 'Proveedores';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroProveedor,
            NombreProveedor,
            Telefono1,
            Telefono2,
            Fax,
            Direccion,
            Colonia,
            Cp,
            Ciudad,
            Estado,
            Rfc,
            FormaPago,
            Banco,
            Cuenta,
            Clabe,
            Email,
            Contacto,
            Comentarios,
            DiasCredito,
            Credito,
            PaginaWeb
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaProveedor(url, NumeroProveedor){
        //Definicion de ruta
        const controller = 'Proveedores';
        const method = 'elimina';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroProveedor
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

}