<template>
    <div>
        <HeaderAppComponent :tituloPagina="tituloPagina"/>

        <v-main>
            <v-tabs v-model="tab" >
                <v-tab v-for="item of tabs" :key="item.tab" >
                    {{ item.title }}
                </v-tab>

                <v-tabs-items v-model="tab">
                    <v-tab-item> <InventarioComponent :key="numeroRandom"></InventarioComponent>  </v-tab-item>
                    <v-tab-item> <ComprasComponent @setNumeroRandom="numeroRandom = $event" ></ComprasComponent> </v-tab-item>
                    <!--<v-tab-item> <CotizacionesComponent></CotizacionesComponent> </v-tab-item>-->
                </v-tabs-items>

            </v-tabs>
        </v-main>
        
    </div>
</template>

<script>
import HeaderAppComponent from '@/components/HeaderAppComponent';

import ComprasComponent from '@/components/Inventario/ComprasComponent';
import CotizacionesComponent from '@/components/Inventario/CotizacionesComponent';
import InventarioComponent from '@/components/Inventario/InventarioComponent';

export default {
    components:{
        HeaderAppComponent,
        ComprasComponent,
        CotizacionesComponent,
        InventarioComponent
    },
    data(){
        return{
            tab:null,
            tabs:[
                { tab:0, title:'Inventario', content:'' },
                { tab:1, title:'Compras', content:'' },
                //{ tab:2, title:'Cotizaciones', content:'' },
            ],
            tituloPagina:'Inventario',
            numeroRandom:0
        }
    }
}
</script>