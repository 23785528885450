<template>
    <div>
        <v-btn 
            color="orange"
            class="white--text"
            block
            :disabled="crumDisabled"
            @click="vDialog.show = true"
            >
            Enviar Correo
            <v-icon>
                mdi-email
            </v-icon>
        </v-btn>

        <v-dialog v-model="vDialog.show" max-width="700" persistent>

            <v-card>
                <v-card-subtitle>
                    <v-row>
                        <v-col cols="11" md="11">
                            Enviar reporte por correo
                        </v-col>
                        <v-col cols="1"  md="1" class="d-flex justify-end">
                            <v-btn  icon
                                    @click="vDialog.show = false" 
                                    color="red"
                                    >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-subtitle>

                <v-card-text>

                    <v-form ref="form" v-model="valid" lazy-validation >
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Correo"
                                    v-model="datosCorreo.correo"
                                    ref="txtCorreo"
                                    :rules="reglasDatosCorreo.correo"
                                    counter="100"
                                    :readonly="datosCorreoDisabled.correo"
                                    append-icon="mdi-pencil"
                                    @click:append="habilitarDeshabilitarCamposTexto( descripcionDatosCorreo.correo )"
                                />
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Asunto"
                                    v-model="datosCorreo.asunto"
                                    ref="txtAsunto"
                                    :rules="reglasDatosCorreo.asunto"
                                    counter="50"
                                    :readonly="datosCorreoDisabled.asunto"
                                    append-icon="mdi-pencil"
                                    @click:append="habilitarDeshabilitarCamposTexto( descripcionDatosCorreo.asunto )"
                                />
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-textarea
                                    label="Mensaje"
                                    v-model="datosCorreo.cuerpoMensaje"
                                    ref="txtCuerpoMensaje"
                                    :rules="reglasDatosCorreo.cuerpoMensaje"
                                    counter="180"
                                    rows="2"
                                    no-resize
                                    :readonly="datosCorreoDisabled.cuerpoMensaje"
                                    append-icon="mdi-pencil"
                                    @click:append="habilitarDeshabilitarCamposTexto( descripcionDatosCorreo.cuerpoMensaje )"
                                />
                            </v-col>

                        </v-row>
                    </v-form>
                    
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-alert
                                v-model="errorCorreo.show"
                                border="left"
                                :color="errorCorreo.color"
                                type="warning"
                                dense
                                dismissible
                            >
                            {{ errorCorreo.text }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-btn 
                                color="orange"
                                class="white--text"
                                block
                                @click="validar()"
                                >
                                Enviar

                                <v-icon v-if="!vProgressEnviando">
                                    mdi-email-check
                                </v-icon>

                                <v-progress-circular
                                    v-else
                                    :size="20"
                                    color="white"
                                    indeterminate
                                ></v-progress-circular>

                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>

        </v-dialog>



    </div>
</template>

<script>
import ApiReporte from '@/services/ApiReportesService';
import ApiContratos from '@/services/ApiContratosService';
import ApiAmbulanciasService from '@/services/ApiAmbulanciasService';
import {mapState} from "vuex";

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']), 
        crumDisabled(){
            return (this.NumeroCrum)?false:true;
        }
    },
    //#endregion

    //#region Props
    props:{
        NumeroCrum:Number
    },
    //#endregion

    //#region ---- Data ----    
    data(){
        return{
            apiReporte: new ApiReporte(),
            apiContratos: new ApiContratos(),
            apiCrum: new ApiAmbulanciasService(),
            //--------------------------
            valid:false,
            vDialog:{
                show:false,
                descripcion:''
            },
            vProgressEnviando:false,
            errorCorreo:{
                show:false,
                color:'orange',
                text:'Correo no enviado, vuelva a intentar.'
            },
            mensajesCorreo:{
                arregloErrores: {
                    mensaje: 'Correo no enviado, vuelva a intentar.',
                    color:'red'
                },
                exito:{
                    mensaje: 'Correo enviado.',
                    color:'green'
                },
                errorApi:{
                    mensaje: 'Correo no enviado.[1]',
                    color:'red'
                },


            },
            oContrato:{
                ContratoNumero:0, 
                ContratoContrato:0, 
                ContratoNombreS:''
            },
            oCrum:{

            },
            datosCorreo:{
                correo:'',
                asunto:'PROMEDICA - [Reporte de Servicio]',
                cuerpoMensaje:'Reporte de servicio'
            },
            datosCorreoDisabled:{
                correo:true,
                asunto:true,
                cuerpoMensaje:true
            },
            descripcionDatosCorreo:{
                correo:'CORREO',
                asunto:'ASUNTO',
                cuerpoMensaje:'CUERPOMENSAJE'
            },
            reglasDatosCorreo:{
                correo:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 100) || 'El campo debe tener menos 100 caracteres.', 
                ],
                asunto:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 50) || 'El campo debe tener menos 10 caracteres.', 
                ],
                cuerpoMensaje:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 180) || 'El campo debe tener menos 10 caracteres.', 
                ],
            }

        }
    },
    //#endregion

    //#region Methods
    methods:{

        //#region Metodos de la vista
        habilitarDeshabilitarCamposTexto( descripcion ){

            if( descripcion == this.descripcionDatosCorreo.correo ){
                this.datosCorreoDisabled.correo = !this.datosCorreoDisabled.correo;
                this.$refs.txtCorreo.focus();
            }

            if( descripcion == this.descripcionDatosCorreo.asunto ){
                this.datosCorreoDisabled.asunto = !this.datosCorreoDisabled.asunto;
                this.$refs.txtAsunto.focus();
            }

            if( descripcion == this.descripcionDatosCorreo.cuerpoMensaje ){
                this.datosCorreoDisabled.cuerpoMensaje = !this.datosCorreoDisabled.cuerpoMensaje;
                this.$refs.txtCuerpoMensaje.focus();
            }


        },

        validar () {
            const formValid = this.$refs.form.validate();
            console.log('formValid', formValid);

            if(formValid){
                this.enviarCorreoCrum();
            }
        },

        mostrarAlertCorreo( mensaje, color ){
            this.errorCorreo.text = mensaje; // 'Correo no enviado, vuelva a intentar.';
            this.errorCorreo.color = color; //'orange';
            this.errorCorreo.show = true;
        },
        //#endregion

        //#region Metodos con Api
        getContratos(contrato){
            this.apiContratos.recuperaContratos( this.URLApi, 
                    contrato.ContratoNumero, contrato.ContratoContrato, contrato.ContratoNombreS)
            .then( response =>{

                //console.log('getContratos', response.data);

                const listaContratos = response.data.objeto;

                if(listaContratos.length>0){
                    this.datosCorreo.correo = listaContratos[0].CorreoS;
                }


            })
            .catch( error => console.log( error ) );
        },

        getCrum(NumeroCrum){
            this.apiCrum.recuperaCrum( this.URLApi, 
                    NumeroCrum, 0, '' )
            .then( response =>{
                //console.log('getCrum', response.data);

                const listaCrum = response.data.objeto;

                if(listaCrum.length>0){
                    this.oContrato.ContratoNumero = 0;//listaCrum[0].Contrato;
                    this.oContrato.ContratoContrato = listaCrum[0].Contrato;
                    this.oContrato.ContratoNombreS = '';

                    this.datosCorreo.cuerpoMensaje = 'Servicio realizado con fecha: ' + listaCrum[0].Fecha;

                    this.getContratos( this.oContrato );
                }


            })
            .catch( error => console.log( error ) );
        },

        enviarCorreoCrum(){
            const mail = this.datosCorreo.correo;
            //const mail = 'rodrigo.krotzsch@hotmail.com';
            this.vProgressEnviando = true;
            this.apiReporte.enviarCorreoCrum(   this.URLApi,
                                                this.NumeroCrum,
                                                mail,
                                                this.datosCorreo.asunto,
                                                this.datosCorreo.cuerpoMensaje
             )
            .then( response => {
                console.log('enviarCorreoCrum', response.data);

                const listaErrores = response.data.objeto;

                if( listaErrores.length >0 ){
                    this.mostrarAlertCorreo (   this.mensajesCorreo.arregloErrores.mensaje,
                                                this.mensajesCorreo.arregloErrores.color);
                }
                else{
                    this.mostrarAlertCorreo (   this.mensajesCorreo.exito.mensaje,
                                                this.mensajesCorreo.exito.color);
                }


                this.vProgressEnviando = false;
            })
            .catch( error => {
                this.vProgressEnviando = false;
                console.log( error );
                this.mostrarAlertCorreo (   this.mensajesCorreo.errorApi.mensaje,
                                            this.mensajesCorreo.errorApi.color);
            } );
        }
        //#endregion

    },
    //#endregion
    beforeMount(){
        this.getCrum( this.NumeroCrum );
    }
}
</script>