<template>
    <div>
        <h2> {{ titulo }} </h2>
        
        <v-row>
            <v-col cols="12" md="3">
                <v-select
                    :items="listaMedicos"
                    v-model="selectMedicos"
                    item-text="Nombre"
                    item-value="Nombre"
                    label="Medico"
                    dense
                    @change="selectDropDownMedico()"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    label="Sexo"
                    v-model="selectSexo"
                    :items="listaSexos"
                    item-text="text"
                    item-value="value"
                    dense
                    @change="selectDropDownSexo()"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field   
                    label="Edad"
                    v-model="EstudioLab.Edad"
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-select
                    label="Resultado"
                    v-model="selectResultado"
                    :items="listaResultados"
                    item-text="text"
                    item-value="value"
                    dense
                    @change="selectDropDownResultados()"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>            
            <!-- <<INICIO>> PIE DE ELEMENTO. BOTON DE GUARDAR /GENERAR-->
            <v-col cols="12" offset-md="9"  md="3">
                <v-btn  color="blue"
                        class="white--text"
                        block
                        @click="generarEstudio()"
                        ><!--@click="generarEstudio()"-->
                    Generar 
                    <v-icon>mdi-content-save</v-icon> 
                </v-btn>
            </v-col>
            <!-- <<FIN>> PIE DE ELEMENTO. BOTON DE GUARDAR /GENERAR-->
        </v-row>

        <ShowPdfComponent
            :mostrarVentana="imprimir"
            :pTituloPDF="tituloPDF"
            :rutaPdf="rutaPdf"
            @setShowDialog="imprimir = $event"
        ></ShowPdfComponent>

    </div>
</template>

<script>
import {mapState, mapMutations} from "vuex"; 
import ApiEstudiosService from '@/services/ApiEstudiosService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

export default {

    //#region Components
    components:{
        ShowPdfComponent,
    },
    //#endregion

    //#region Computed
    computed:{ 
        ...mapState(['URLSrc', 'URLApi', 'User','Empresa']), 
        showDialog:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.imprimir;
            },
            set(newValue){
                console.log('F1.showDialog.newValue', newValue);
                this.$emit('setShowDialog', newValue);
            }
        }
    }, 
    //#endregion 

    //#region Props
    props:{
        propContrato:Number,
        propSocio:String,
        propSolicitante:String,
        propPaciente:String,
    },
    //#endregion

    //#region  Data
    data(){
        return {
            apiEstudios:new ApiEstudiosService(),
            apiConfiguracion:new ApiConfiguracionService(),
            //---------------------------------------------------------
            titulo:'Prueba de embarazo',
            tipoEstudio:'PRUEBA_EMBARAZO',
            //---------------------------------------------------------
            // Para mostrar el pdf
            tituloPDF:'Prueba de embarazo',
            rutaPdf:'',
            rutaReportes:'/reportes/Estudios/',
            nombrePdf:'',
            imprimir:false,
            //---------------------------------------------------------
            selectMedicos:'',
            listaMedicos:[],
            //---------------------------------------------------------
            selectSexo:'',
            listaSexos:[
                { value:'MASCULINO', text:'Masculino'  },
                { value:'FEMENINO', text:'Femenino'  },
            ],
            selectAntigenoProstaticoResultado:'',
            selectResultado:'',
            listaResultados:[
                { value:'NEGATIVO', text:'Negativo'  },
                { value:'POSITIVO', text:'Positivo'  },
            ],
            selectAnticuerposHIV12:'',
            selectAnticuerposAntitreponemicosInespecificos:'',
            listaResultados:[
                { value:'POSITIVO', text:'Positivo'  },
                { value:'NEGATIVO', text:'Negativo'  },
            ],

            //#region Objeto EstudioLab
            EstudioLab:{
                Numero: null, 
                Contrato: null, 
                Socio: null, 
                TipoEstudio: null, 
                Fecha: null, 
                FechaHora: null, 
                HoraLlamada: null, 
                Usuario: null, 
                Despacho: null, 
                Salida: null, 
                Llegada: null, 
                Doping: null, 
                Metanfetamina: null, 
                Marihuana: null, 
                Cocaina: null, 
                Solicitante: null, 
                Edad: null, 
                Sexo: null, 
                Beneficiario: null, 
                Paciente: null, 
                Telefonos: null, 
                Domicilio: null, 
                Signos: null, 
                TipoServicio: null, 
                Triage: null, 
                Equipo: null, 
                Horario: null, 
                NumeroLab: null, 
                Diagnostico: null, 
                Tratamiento: null, 
                Observaciones: null, 
                PadecimientoActual: null, 
                TratamientoActual: null, 
                InicioSintomas: null, 
                ExploracionFisica: null, 
                AntecedentesPersonalesPato: null, 
                EstadoCivil: null, 
                GlasgowMotora: null, 
                GlasgowRespuestaVerbal: null, 
                GlasgowAperturaOcular: null, 
                Estatus: null, 
                Cancelacion: null, 
                Destino: null, 
                Viaje: null, 
                Extra1: null, 
                NumLatidos: null, 
                Estatura: null, 
                Medico: null, 
                Oxigenacion: null, 
                Peso: null,
                Glucosa: null,
                Bun: null,
                Urea: null,
                Creatinina: null,
                Colesterol: null,
                Trigliceridos: null,
                AcidoUrico: null,
                Hemoglobina : null, 
                Hematocrito : null, 
                GlobulosRojos : null, 
                VCM : null, 
                HCM : null, 
                CHCM : null, 
                GlobulosBlancos : null, 
                Linfocitos : null, 
                Monocitos : null, 
                Eosinofilos : null, 
                Basofilos : null, 
                Segmentados : null, 
                EnBanda : null, 
                Plaquetas : null,
                ColesterolTotal: null,
                ColesterolHDL: null,
                ColesterolLDL: null,
                ColesterolVLDL: null,
                LipidosTotales: null,
                AnticuerposHIV12: null,
                GGT: null,
                TGO: null,
                TGP: null,
                AnticuerposIGG: null,
                AnticuerposIGM: null,
                AnticuerposAntitreponemicosInespecificos: null,
                AntigenoProstaticoResultado:null,
                embarazo_HormonaGonadotropinaCorionica:null,
                //-------------------------------------------
                GpoRh_FactorRH: null,
                //-------------------------------------------
                RFebriles_AntigenoParatificoA: null,
                RFebriles_AntigenoParatificoB: null,
                RFebriles_AntigenoTificoH: null,
                RFebriles_AntigenoTificoO: null,
                RFebriles_AntigenoProteusOX19: null,
                RFebriles_AntigenoBrucellaAbortus: null,
                //-------------------------------------------
                KOH_MuestraUnias: null,
                //-------------------------------------------
                CulFaringeo_Cultivo: null,
                CulFaringeo_Observaciones: null,
                //-------------------------------------------
                Preumaticas_Antiestreptolisina: null,
                Preumaticas_FactorReumatoide: null,
                Preumaticas_ProteinaCReactiva: null,
                //-------------------------------------------
                Coproparasitoscopico_Muestra1: null,
                //-------------------------------------------
                FuncHepatica_GGT: null,
                FuncHepatica_TGO: null,
                FuncHepatica_TGP: null,
                FuncHepatica_FosfatasaAlcalina: null,
                FuncHepatica_BilirrubinaTotal: null,
                FuncHepatica_BilirrubinaDirecta: null,
                FuncHepatica_BilirrubinaIndirecta: null,
                FuncHepatica_ProteinasTotales: null,
                FuncHepatica_Albumina: null,
                FuncHepatica_Globulina: null,
                FuncHepatica_RelacionAG: null,
                //-------------------------------------------
                Hemoglobina_HemoglobinaGlicosilada: null,
                //-------------------------------------------
                DeteccionDengue_IgG: null,
                DeteccionDengue_IgM: null,
                DeteccionDengue_AgNS1: null,
                //-------------------------------------------
                PlomoSangre_PlomoSangre: null,
                //-------------------------------------------
                PruebaDimero_DimeroD: null,
                //-------------------------------------------
                FerritinaSuero_Ferritina: null,
                //-------------------------------------------
                PruebaInfluenza_TipoA: null,
                PruebaInfluenza_TipoB: null,
                PruebaInfluenza_AH1N1: null,
                //-------------------------------------------
                ElectrolitosSericos_Cloro: null,
                ElectrolitosSericos_Sodio: null,
                ElectrolitosSericos_Potasio: null,
                ElectrolitosSericos_Calcio: null,
                ElectrolitosSericos_Fosforo: null,
                ElectrolitosSericos_Magnesio: null,
                //-------------------------------------------
                TiemposCoagulacion_TiempoProtombina: null,
                TiemposCoagulacion_TiempoPromboplatinaParcial: null,
                TiemposCoagulacion_INR: null,
                //-------------------------------------------
                ExGOrina_Macro_Color: null,
                ExGOrina_Macro_Aspecto: null,
                ExGOrina_FisicoQumico_PH: null,
                ExGOrina_FisicoQumico_Densidad: null,
                ExGOrina_FisicoQumico_Glucosa: null,
                ExGOrina_FisicoQumico_Bilirrubinas: null,
                ExGOrina_FisicoQumico_Cetonas: null,
                ExGOrina_FisicoQumico_SangreHB: null,
                ExGOrina_FisicoQumico_Proteinas: null,
                ExGOrina_FisicoQumico_Urobilinogeno: null,
                ExGOrina_FisicoQumico_Nitritos: null,
                ExGOrina_FisicoQumico_Leucocitos: null,
                ExGOrina_Cristaluria_UratosAmorfos: null,
                ExGOrina_Cristaluria_AcidoUrico: null,
                ExGOrina_Cristaluria_OxalatosCalcicos: null,
                ExGOrina_Cristaluria_FosfatosAmorfos: null,
                ExGOrina_Cristaluria_FosfatosTriples: null,
                ExGOrina_MicroSedimiento_Celularidad: null,
                ExGOrina_MicroSedimiento_Uretral: null,
                ExGOrina_MicroSedimiento_Urotelial: null,
                ExGOrina_MicroSedimiento_Renal: null,
                ExGOrina_MicroSedimiento_Leucocitos: null,
                ExGOrina_MicroSedimiento_Eritrocitos: null,
                ExGOrina_MicroSedimiento_PorcNormorficos: null,
                ExGOrina_MicroSedimiento_PorcDismorficos: null,
                ExGOrina_MicroSedimiento_Bacterias: null,
                ExGOrina_CitolisisBacteriana: null,
                ExGOrina_Piocitos: null,
                ExGOrina_Espermas: null,
                ExGOrina_Levaduras: null,
                ExGOrina_Parasitos: null,
                ExGOrina_FMucoides: null,
                ExGOrina_Cilindros: null,
            },
            //#endregion

        }
    },
    //#endregion

    //#region Metodos
    methods:{
        ...mapMutations(['setEstudioLab']),

        //#region Metodos de la pantalla
        generarEstudio(){
            this.EstudioLab.TipoEstudio = this.tipoEstudio;
            //console.log('this.EstudioLab', this.EstudioLab);
            this.guardarEstudioLab(this.EstudioLab.TipoEstudio);

        },
        //#endregion

        //#region dropdowns
        selectDropDownMedico(){ this.EstudioLab.Medico = this.selectMedicos; },
        selectDropDownSexo(){ this.EstudioLab.Sexo = this.selectSexo; },
        selectDropDownAnticuerposHIV12(){ this.EstudioLab.AnticuerposHIV12 = this.selectAnticuerposHIV12; },
        selectDropDownAnticuerposAntitreponemicosInespecificos(){ this.EstudioLab.AnticuerposAntitreponemicosInespecificos = this.selectAnticuerposAntitreponemicosInespecificos },
        selectDropDownResultados(){ this.EstudioLab.embarazo_HormonaGonadotropinaCorionica = this.selectResultado; },
        //#endregion

        //#region Metodos Api Estudios
        guardarEstudioLab(TipoEstudio){
            //console.log("this.EstudioLab", this.EstudioLab);
            this.apiEstudios.guardaEstudioLab(this.URLApi
                ,this.propContrato , this.propSocio, this.propSolicitante ,this.propPaciente
                ,TipoEstudio
                ,this.EstudioLab.Medico
                ,this.EstudioLab.Sexo
                ,this.EstudioLab.Edad
                ,this.EstudioLab.Estatura
                ,this.EstudioLab.Peso
                ,this.EstudioLab.Oxigenacion
                ,this.EstudioLab.NumLatidos
                ,this.EstudioLab.Doping
                ,this.EstudioLab.Metanfetamina
                ,this.EstudioLab.Marihuana
                ,this.EstudioLab.Cocaina
                ,this.EstudioLab.Glucosa
                ,this.EstudioLab.Bun
                ,this.EstudioLab.Urea
                ,this.EstudioLab.Creatinina
                ,this.EstudioLab.Colesterol
                ,this.EstudioLab.Trigliceridos
                ,this.EstudioLab.AcidoUrico
                ,this.EstudioLab.Hemoglobina
                ,this.EstudioLab.Hematocrito
                ,this.EstudioLab.GlobulosRojos
                ,this.EstudioLab.VCM
                ,this.EstudioLab.HCM
                ,this.EstudioLab.CHCM
                ,this.EstudioLab.GlobulosBlancos
                ,this.EstudioLab.Linfocitos
                ,this.EstudioLab.Monocitos
                ,this.EstudioLab.Eosinofilos
                ,this.EstudioLab.Basofilos
                ,this.EstudioLab.Segmentados
                ,this.EstudioLab.EnBanda
                ,this.EstudioLab.Plaquetas
                ,this.EstudioLab.ColesterolTotal
                ,this.EstudioLab.ColesterolHDL
                ,this.EstudioLab.ColesterolLDL
                ,this.EstudioLab.ColesterolVLDL
                ,this.EstudioLab.LipidosTotales
                ,this.EstudioLab.AnticuerposHIV12
                ,this.EstudioLab.GGT
                ,this.EstudioLab.TGO
                ,this.EstudioLab.TGP
                ,this.EstudioLab.AnticuerposIGG
                ,this.EstudioLab.AnticuerposIGM
                ,this.EstudioLab.AnticuerposAntitreponemicosInespecificos
                ,this.EstudioLab.AntigenoProstaticoResultado
                ,this.EstudioLab.embarazo_HormonaGonadotropinaCorionica
            )
            .then( resp => {
                console.log('guardarEstudioLab', resp.data);

                if( resp.data.objeto>0){
                    const Numero = resp.data.objeto;
                    this.generaReporte(Numero);
                }
            })
            .catch( error => console.log( error ) );
        },

        generaReporte(Numero){

            this.apiEstudios.generaReporte(this.URLApi
                , Numero
                , this.tipoEstudio
                , this.propSocio
            )
            .then( response => {
                console.log('generaReporte.data', response.data);
                //this.rutaPdf = this.rutaReportes + this.Empresa +'/' + response.data.objeto;
                this.rutaPdf = this.rutaReportes + response.data.objeto;

                //console.log('Ruta PDF: ', this.URLSrc + this.rutaPdf);
                this.imprimir = true;

            })
            .catch( error => console.log( error ) );
        },

        //#endregion


        //#region Metodos API Configuracion
        getMedicos(){
                this.listaMedicos = [];
                this.apiConfiguracion.recuperaMedicos(this.URLApi,0,0)
                .then( response => {
                    const oMedicoArray = [
                        {
                            Nombre:'SIN ASIGNAR',
                            Numero: 0
                        }
                    ];

                    this.listaMedicos.push(...oMedicoArray);
                    this.listaMedicos.push(...response.data.objeto);
                    //console.log('getMedicos', this.listaMedicos);
                })
                .catch( error => console.log(error));
            },
        //#endregion
    },
    //#endregion
    
    //#region beforeMounted
    beforeMount(){
        this.getMedicos();
    },
    //#endregion

    //#region mounted
    mounted(){

    },
    //#endregion


}
</script>