<template>
    <div>
        <v-row>
            <v-col cols="12" md="8">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field
                            v-model="oCrum.Solicitante"
                            label="Solicitante"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="oCrum.Telefonos"
                            label="Tels"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="5">
                        <v-text-field
                            v-model="oCrum.Paciente"
                            label="Paciente"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            v-model="oCrum.Edad"
                            label="Edad"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field
                            v-model="oCrum.Domicilio"
                            label="Domicilio"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-select
                            :items="listaEstadosCiviles"
                            v-model="selectEstadoCivil"
                            item-text="EstadoCivil"
                            item-value="EstadoCivil"
                            label="Estado Civil"
                            @change="selectDropDownEstadoCivil()"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field
                            v-model="oCrum.AntecedentesPersonalesPato"
                            label="Antecedentes Personales Patologicos"
                        ></v-text-field>
                    </v-col>
                </v-row>


                <!--INICIO PadecimientoActual, InicioSintomas, ExploracionFisica -->
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="oCrum.PadecimientoActual"
                            label="Padecimiento Actual"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="oCrum.TratamientoActual"
                            label="Tratamiento Actual"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="oCrum.InicioSintomas"
                            label="Inicio de los Sintomas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            :items="listaTiposServicio"
                            v-model="selectTiposServicio"
                            item-text="Nombre"
                            item-value="Nombre"
                            label="Tipo de Servicio"
                            @change="selectDropDownTiposServicio()"
                        ></v-select>
                    </v-col>
                </v-row>
                <!--FIN PadecimientoActual, InicioSintomas, ExploracionFisica -->


                <!--INICIO Signos y sintomas hasta Costo de laboratorio-->
                <v-row>
                    <v-col cols="12" md="6">
                        
                        
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="oCrum.NumeroLab"
                                    label="No. de Laboratorio y/o Rayos X"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Costo de Laboratorio y/o Rayos X"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-select
                                    :items="listaTriageInicial"
                                    v-model="selectTriageInicial"
                                    item-text="Nombre"
                                    item-value="Nombre"
                                    label="Triage Inicial"
                                    @change="selectDropDownTriageInicial()"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    :items="listaEquipos"
                                    v-model="selectEquipos"
                                    item-text="Nombre"
                                    item-value="Nombre"
                                    label="Equipo"
                                    @change="selectDropDownEquipo()"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <!--
                                <v-select
                                    :items="listaHorarios"
                                    v-model="selectHorarios"
                                    item-text="Nombre"
                                    item-value="Nombre"
                                    label="Horario"
                                    @change="selectDropDownHorario()"
                                ></v-select>
                                -->
                                <v-text-field
                                        v-model="oCrum.Horario"
                                        label="Horario"
                                    >

                                </v-text-field>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
                <!--FIN Signos y sintomas hasta Costo de laboratorio-->

            </v-col>            
            <!--INICIO Carrusel de pasos de atencion-->
            <v-col cols="12" md="4">
                <v-carousel v-model="step" height="400" 
                            
                            :show-arrows="false">

                    <v-carousel-item
                        v-for="item of listaPasos" :key="item.paso">

                        <v-card color="#5e73f0" > <!--#5e73f0-->
                            <v-card-title class="d-flex justify-center" >
                                {{ item.descripcion }}
                            </v-card-title>

                            
                            <v-card-text >
                                <br><br>
                                <v-row class="d-flex justify-center">
                                    <v-btn 
                                        @click="fillHoraPaso(item)"
                                        x-large
                                        text
                                        :disabled="item.disabled"
                                        >
                                        <div style="width:10em; height: 10em;">
                                            <v-img
                                                style="width:100%; height:auto;"
                                                :src="item.img"/>
                                        </div>
                                    </v-btn>
                                </v-row>
                                <br><br>
                                <v-row class="d-flex justify-center" >
                                    <v-textarea
                                        v-model="oCrum.Cancelacion"
                                        label="Motivo Cancelación"
                                        :rows="2"
                                        no-resize
                                    ></v-textarea>

                                    <v-btn
                                        color="red"
                                        block
                                        >
                                        Cancelar
                                    </v-btn>
                                </v-row>

                            </v-card-text>
                        </v-card>

                    </v-carousel-item>
                </v-carousel>


            </v-col>
            <!--FIN Carrusel de pasos de atencion-->
        </v-row>

        <!--INICIO Datos del medico -->
        <v-row>
            <v-col cols="12" md="8">

                


                <v-select
                    :items="listaMedicos"
                    v-model="selectMedicos"
                    item-text="Nombre"
                    item-value="Nombre"
                    label="Medico"
                    @change="selectDropDownMedico()"
                ></v-select>

                <v-text-field
                    v-model="oCrum.Signos"
                    label="Signos y Sintomas"
                >
                </v-text-field>

                <v-text-field
                    v-model="oCrum.ExploracionFisica"
                    label="Exploración Física"
                ></v-text-field>
<!--
:valorGlasgowMotora="oCrum.GlasgowMotora"
:valorGlasgowRespuestaVerbal="oCrum.GlasgowRespuestaVerbal"
:valorGlasgowAperturaOcular="oCrum.GlasgowAperturaOcular"
-->

                <EscalaGlasgowComponent                  
                    :key="oCrum.Contrato"

                    :propGlasgowMotora="Number.parseInt(oCrum.GlasgowMotora)"
                    :propGlasgowRespuestaVerbal="Number.parseInt(oCrum.GlasgowRespuestaVerbal)"
                    :propGlasgowAperturaOcular="Number.parseInt(oCrum.GlasgowAperturaOcular)"

                    @setValorGlasgowMotora="oCrum.GlasgowMotora = $event"
                    @setValorGlasgowRespuestaVerbal="oCrum.GlasgowRespuestaVerbal = $event"
                    @setValorGlasgowAperturaOcular="oCrum.GlasgowAperturaOcular = $event"

                    
                ></EscalaGlasgowComponent>
                
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Signos Vitales</h4>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="TA (mm/Hg)"
                            v-model="oCrum.SV_TA"
                            >                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="FR (r/min)"
                            v-model="oCrum.SV_FR"
                            >                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="TEMP (ºC)"
                            v-model="oCrum.SV_TEMP"
                            >                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="FC (lpm)"
                            v-model="oCrum.SV_FC"
                            >                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="SAT (%)"
                            v-model="oCrum.SV_SAT"
                            >                        
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="PESO (kg)"
                            v-model="oCrum.SV_PESO"
                            >                        
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-textarea
                    v-model="oCrum.Diagnostico"
                    label="Diagnostico Medico"
                    rows="2"
                    no-resize
                ></v-textarea>

                <v-text-field
                    v-model="oCrum.Tratamiento"
                    label="Tratamiento Ap.">
                </v-text-field>

                <v-textarea
                    v-model="oCrum.Observaciones"
                    label="Observaciones"
                    rows="2"
                    no-resize
                ></v-textarea>

            </v-col>
            <!--INICIO Botones para control de CRUM-->
            <v-col cols="12" md="4">
                <v-row>
                    <!--
                    <v-col cols="12" md="12">
                        <v-btn  color="blue"
                                class="white--text"
                                block
                                >
                            Nuevo 
                            <v-icon>mdi-plus</v-icon> 
                        </v-btn>
                    </v-col>
                    -->

                    <v-col cols="12" md="12">
                        <v-btn  color="blue"
                                class="white--text"
                                block
                                @click="compruebaFormulario(operacionINSERT)"
                                :disabled="btnGuardandoDisabled"
                                >
                            Guardar 
                            <v-icon>mdi-content-save</v-icon> 
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-btn  color="blue"
                                class="white--text"
                                block
                                @click="compruebaFormulario(operacionUPDATE)"
                                >
                            Actualizar 
                            <v-icon>mdi-refresh</v-icon> 
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-btn  color="blue"
                                class="white--text"
                                block
                                @click="compruebaTerminaTrabajo()"
                                >
                            Termina Trabajo
                            <v-icon>mdi-check</v-icon> 
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                        <BotonReporteCrumComponent
                            :key="oCrum.Numero"
                            :NumeroCrum="Number.parseInt(oCrum.Numero)"
                        ></BotonReporteCrumComponent>
                    </v-col>
                    <v-col cols="12" md="12">
                        <EnviarReporteCrumComponent
                            :key="oCrum.Numero"
                            :NumeroCrum="Number.parseInt(oCrum.Numero)"
                        />

                    </v-col>
                </v-row>
            </v-col>
            <!--FIN Botones para control de CRUM-->

        </v-row>            
        <!--FIN Datos del medico -->

        <!--INICIO Alertas con error -->
        <v-dialog v-model="oDialog.show" max-width="400" persistent>
            <v-card :color="oDialog.color">
                <v-card-title>
                    <span class="white--text"> SAM </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="oDialog.show = false"
                        >
                            <!--<v-icon color="black" >mdi-close </v-icon>-->
                        </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <v-alert 
                        v-model="oDialog.show"
                        type="info"
                        :color="oDialog.color"
                        class="white--text"
                    >
                        {{ oDialog.mensaje }}
                    </v-alert>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn 
                        text class="white--text"
                        @click="oDialog.show = false">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Alertas con error -->

        <!--
            EsBeneficiario: {{ EsBeneficiario }}
            Contrato: {{ Contrato }}
            NumeroBeneficiario: {{ NumeroBeneficiario }}
        -->

        <v-dialog v-model="dialogTrabajo.show" max-width="400" class="">
            <v-card :color="dialogTrabajo.color">
                <v-card-title>
                    <span class="white--text"> SAM </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogTrabajo.show = false"
                        >
                            <!--<v-icon color="black" >mdi-close </v-icon>-->
                        </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <v-alert 
                        v-model="dialogTrabajo.show"
                        type="info"
                        :color="dialogTrabajo.color"
                        class="white--text"
                    >
                        {{ dialogTrabajo.mensaje }}
                    </v-alert>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="red darken-1" 
                            class="white--text"
                            @click="dialogTrabajo.show = false" >
                        No
                    </v-btn>
                    <v-btn  color="green darken-1" 
                            class="white--text"
                            @click="terminaCrum(oCrum)" >
                        Si
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script> 
import {mapState} from "vuex"; 
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';
import ApiAmbulanciasService from '@/services/ApiAmbulanciasService';

import BotonReporteCrumComponent from '@/components/Bloques/BotonReporteCrumComponent';
import EnviarReporteCrumComponent from '@/components/Bloques/EnviarReporteCrumComponent';
import EscalaGlasgowComponent from '@/components/Bloques/EscalaGlasgowComponent';

export default { 
    //#region Components
    components:{
        BotonReporteCrumComponent,
        EscalaGlasgowComponent,
        EnviarReporteCrumComponent
    },
    //#endregion
    //------------------------------------------------------------------------------- 
    //#region Computed
    computed:{ ...mapState(['URLSrc', 'URLApi', 'User']), }, 
    //#endregion 

    //#region Props
    props:{
        //Si 'EsBeneficiario' es 0, se hace busqueda en contratos
        //Si es 1, se hace busqueda en beneficiarios
        EsBeneficiario:Number,
        ContratoNumero:Number,
        NumeroBeneficiario:Number,
        NumeroCrum:Number,
        EstatusCrum:String,
    },
    //#endregion

    //------------------------------------------------------------------------------- 
    //#region Data 
    data(){ 
        return{ 
            //Objetos de api
            apiConfiguracion:new ApiConfiguracionService(),
            apiAmbulancias:new ApiAmbulanciasService(),
            apiContratos:new ApiContratosService(), 
            //Variables para componentes visuales
            step:0,
            interval:{},
            oDialog:{
                show:false,
                mensaje:'',
                color:'orange'
            },
            dialogTrabajo:{
                show:false,
                mensaje:'¿ Desea terminar Trabajo o Servicio ?',
                color:'blue'
            },
            //Variables de la pantalla
            btnGuardandoDisabled:false,
            operacionINSERT:'INSERT',
            operacionUPDATE:'UPDATE',
            motivoCancelacion:'',
            listaPasos:[
                { paso:0, disabled:false,
                    descripcion:'Despacho', img: require("@/assets/AmbulanciaDespacho.png")  },
                { paso:1, disabled:false,
                    descripcion:'Salida', img: require("@/assets/AmbulanciaSalida.png") },
                { paso:2, disabled:false,
                    descripcion:'Llegada', img: require("@/assets/AmbulanciaLlegada.png") },
                { paso:3, disabled:false,
                    descripcion:'Traslado', img: require("@/assets/AmbulanciaTraslado.png") },
                { paso:4, disabled:false,
                    descripcion:'Hospital', img: require("@/assets/AmbulanciaHospital.png") },
                { paso:5, disabled:false,
                    descripcion:'Libre', img: require("@/assets/AmbulanciaLibre.png") },
                { paso:6, disabled:false,
                    descripcion:'En Promedica', img: require("@/assets/AmbulanciaEnPromedica.png") },
            ],
            horaPasos:{
                Despacho:'',
                Salida:'',
                Llegada:'',
                Traslado:'',
                Hospital:'',
                Libre:'',
                MS:''
            },
            //Variables con acceso a datos
            Contrato:{
                Numero:0,
                Contrato:null,
                Tipo:'',
                Estatus:null,
                NombreS:'',
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            listaContratos:[],
            listaBeneficiarios:[],
            //Variables del formulario
            oCrum:{
                Numero : null,
                Contrato : null,
                Socio : null,
                Tipo : null,
                Fecha : null,
                FechaHora : null,
                HoraLlamada : null,
                Usuario : null,
                Despacho : null,
                Salida : null,
                Llegada : null,
                Traslado : null,
                Hospital : null,
                Libre : null,
                MS : null,
                Solicitante : null,
                Edad : null,
                Sexo : null,
                Beneficiario : null,
                Paciente : null,
                Telefonos : null,
                Domicilio : null,
                Signos : null,
                TipoServicio : null,
                Triage : null,
                Equipo : null,
                Horario : null,
                NumeroLab : null,
                Diagnostico : null,
                Tratamiento : null,
                Observaciones : null,

                PadecimientoActual : null, 
                TratamientoActual :null,
                InicioSintomas : null,
                ExploracionFisica : null,

                SV_TA : null,
                SV_FR : null,
                SV_TEMP : null,
                SV_FC : null,
                SV_SAT : null,
                SV_PESO : null,

                AntecedentesPersonalesPato : null,
                EstadoCivil : null,
                GlasgowMotora : null,
                GlasgowRespuestaVerbal : null,
                GlasgowAperturaOcular : null,

                Estatus : null,
                Cancelacion : null,
                Destino : null,
                Viaje : null,
                Extra1 : null,
                Extra2 : null,
                Extra3 : null,
                Medico : null,
                Mes : null,
                Ano : null,
            },
            //Listas de configuracion
            selectTiposServicio:'',
            listaTiposServicio:[],
            
            selectTriageInicial:'',
            listaTriageInicial:[],
            
            selectEquipos:'',
            listaEquipos:[],
            
            selectHorarios:'',
            listaHorarios:[],

            selectMedicos:'',
            listaMedicos:[],

            selectEstadoCivil:'',
            listaEstadosCiviles:[
                {EstadoCivil:'SOLTERA/O'},
                {EstadoCivil:'CASADA/O'},
                {EstadoCivil:'DIVIORCIADA/O'},
                {EstadoCivil:'VIUDA/O'},
                {EstadoCivil:'CONCUBINATO'},
            ],

        } 
    }, 
    //#endregion //------------------------------------------------------------------------------- 

    //#region Methods 
    methods:{ 
            //#region Metodos Funciones Locales
            getHoraActual(){
                const horaActual = new Date();
                let meridiano = 'a.m.';
                let hora = horaActual.getHours();            
                const minutos = horaActual.getMinutes();
                const segundos = horaActual.getSeconds();

                meridiano = (hora >= 12) ? 'p.m.' : 'a.m.';
                //hora = (hora > 12) ? (hora-12) : hora;

                this.horaActualFormateada = 
                    hora.toString().padStart(2,"0") 
                    + ':' + minutos.toString().padStart(2,"0") 
                    + ':' + segundos.toString().padStart(2,"0") ;
                    //+ ' ' + meridiano;
                return this.horaActualFormateada;
            },
            crearIntervalo(){
                this.interval = setInterval(() => {
                    this.getHoraActual();
                }, 500);
            },
            
            fillHoraPaso( item ){

                switch(item.paso){
                    case 0://Despacho
                        this.oCrum.Despacho = (!this.oCrum.Despacho) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.Despacho;
                        this.listaPasos[0].disabled = true;
                        break;
                    case 1://Salida
                        this.oCrum.Salida = (!this.oCrum.Salida) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.Salida;
                        this.listaPasos[1].disabled = true;
                        break;
                    case 2://Llegada
                        this.oCrum.Llegada = (!this.oCrum.Llegada) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.Llegada;
                        this.listaPasos[2].disabled = true;
                        break;
                    case 3://Traslado
                        this.oCrum.Traslado = (!this.oCrum.Traslado) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.Traslado;
                        this.listaPasos[3].disabled = true;
                        break;
                    case 4://Hospital
                        this.oCrum.Hospital = (!this.oCrum.Hospital) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.Hospital;
                        this.listaPasos[4].disabled = true;
                        break;
                    case 5://Libre
                        this.oCrum.Libre = (!this.oCrum.Libre) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.Libre;                        
                        this.listaPasos[5].disabled = true;
                        break;
                    case 6://En Promedica ( MS )
                        this.oCrum.MS = (!this.oCrum.MS) 
                                                        ?   this.getHoraActual()
                                                        :   this.oCrum.MS;
                        this.listaPasos[6].disabled = true;
                        break;
                }

                if(this.step<6){
                    this.step++;
                }

            },

            verificaFormulario(){
                //console.log('this.step', this.step);
                let flagPasoValido = true;
                let mensaje = '';

                //No valida campos 2021-Marzo-20
                return flagPasoValido;

                if(!this.ContratoNumero>0){
                    mensaje = 'Falta el número de contrato, Verifique.'
                    flagPasoValido = false;
                    this.presentAlert(mensaje, 'blue');
                    return;
                }
                else{
                }


                if( !this.oCrum.Horario ){
                    mensaje = 'Falta indicar el Horario del Equipo, Verifique.'
                    flagPasoValido = false;
                }
                if( !this.oCrum.Equipo ){
                    mensaje = 'Falta indicar el Equipo, Verifique.'
                    flagPasoValido = false;
                }
                if( !this.oCrum.Triage ){
                    mensaje = 'Falta indicar el Triage Inicial, Verifique.'
                    flagPasoValido = false;
                }
                if( !this.oCrum.TipoServicio ){
                    mensaje = 'Falta indicar el tipo de servicio, Verifique.'
                    flagPasoValido = false;
                }
                if( !this.oCrum.Signos ){
                    mensaje = 'Falta indicar los Signos y Sintomas, Verifique.'
                    flagPasoValido = false;
                }


                switch(this.step){
                    case 0://Despacho
                        console.log('verificaFormulario.Despacho');                        
                        break;
                    case 1://Salida
                        console.log('verificaFormulario.Salida');
                        break;
                    case 2://Llegada
                        console.log('verificaFormulario.Llegada');
                        break;
                    case 3://Traslado
                        console.log('verificaFormulario.Traslado');
                        break;
                    case 4://Hospital
                        console.log('verificaFormulario.Hospital');
                        break;
                    case 5://Libre
                        console.log('verificaFormulario.Libre');
                        break;
                    case 6://En Promedica
                        console.log('verificaFormulario.MS');
                        break;
                    default:
                        mensaje = 'Aún no se ha despachado, Verifique.'
                        flagPasoValido = false;
                        break;
                }

                

                if(!flagPasoValido){
                    //Mandar mensaje de notificacion
                    this.presentAlert(mensaje, 'blue');
                    return flagPasoValido;
                }

                return flagPasoValido;
            },

            verificaPaso(){
                let flagPaso = true;

                if( !this.oCrum.Despacho ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                // ------------------------------
                if( !this.oCrum.Salida ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                // ------------------------------
                if( !this.oCrum.Llegada ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                // ------------------------------
                if( !this.oCrum.Traslado ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                // ------------------------------
                if( !this.oCrum.Hospital ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                // ------------------------------
                if( !this.oCrum.Libre ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                // ------------------------------
                if( !this.oCrum.MS ){
                    //mensaje = 'Aun no se ha despachado, Verifique.'
                    flagPaso = false;
                }
                else{ flagPaso = true; }

                //Verificar las validaciones de los pasos
                flagPaso = true;
                // -------------------------------------------------------------
                if(!flagPaso){
                    //Mandar mensaje de notificacion
                    this.presentAlert(mensaje, 'blue');
                    return flagPaso;
                }

                return flagPaso;
            },
            verificaConsulta(){

                if( this.NumeroCrum > 0 ){
                    this.getCrum();
                }
                else{
                    if(this.EsBeneficiario == 1){
                        //Es Beneficiario y consulta en beneficiarios
                        this.getBeneficiarios(this.ContratoNumero,this.NumeroBeneficiario);
                    }
                    if(this.EsBeneficiario == 0){
                        //Es contrato y consulta en contratos
                        this.getContratos(this.ContratoNumero);
                    }
                }
            },

            compruebaTerminaTrabajo(){
                this.dialogTrabajo.show = true;

            },

            compruebaFormulario( operacion ){
                this.btnGuardandoDisabled = true;
                if( !this.verificaFormulario() ) { return; }
                else{
                    //Si pasa la verificacion de campos,
                    //Verifica los pasos

                    if( !this.verificaPaso() ){ return; } 
                    else{

                        //Si pasa las dos verificaciones, hace la operacion
                        if( operacion == this.operacionINSERT ){
                            //Insert
                            console.log('Insert');
                            
                            if( this.NumeroCrum > 0){
                                this.presentAlert('Este servicio ya ha sido guardado, verifique', 'red');
                            }
                            else{
                                this.insertCrum(this.oCrum);
                            }

                        }

                        if( operacion == this.operacionUPDATE ){
                            //Update
                            console.log('Update');
                            this.updateCrum(this.oCrum);

                        }
                    }
                }

            },
            
            fillContrato(item){
                //console.log('fillContrato', item);
                this.Numero                     = item.Numero;

                this.Contrato.Numero            = item.Numero;
                this.Contrato.Contrato          = item.Contrato;
                this.Contrato.Tipo              = item.Tipo;
                this.Contrato.Estatus           = item.Estatus;
                this.Contrato.NombreS           = item.NombreS; 
                this.ContratoNombreInput        = item.NombreS;

                this.Contrato.RFCS              = item.RFCS;
                this.Contrato.DomicilioS        = item.DomicilioS;
                this.Contrato.ColoniaS          = item.ColoniaS; 
                this.selectCiudadS              = item.CiudadS; //this.Contrato.CiudadS
                this.Contrato.CPS               = item.CPS;

                this.Contrato.CorreoS           = item.CorreoS;
                this.Contrato.TelefonosS        = item.TelefonosS;

                this.Contrato.DiasServicioS     = item.DiasServicioS;
                this.Contrato.HorarioS          = item.HorarioS;

                this.Contrato.AntecedentesS     = item.AntecedentesS;
                this.selectSexoS                = item.SexoS; //this.Contrato.SexoS
                this.Contrato.FechaNacimientoS  = item.FechaNacimientoS;
                this.selectTipoS                = item.TipoS; //this.Contrato.TipoS
                this.Contrato.EdadS             = item.EdadS;
                
                this.Contrato.Beneficiarios     = item.Beneficiarios;
                this.Contrato.NombreC           = item.NombreC;
                this.Contrato.TelefonosC        = item.TelefonosC;
                this.Contrato.CorreoC           = item.CorreoC;
                this.Contrato.Usuario           = item.Usuario;
                    
                this.Contrato.FechaContrato     = item.FechaContrato;
                this.Contrato.HoraContrato      = item.HoraContrato;

                this.Contrato.Extra1            = item.Extra1;
                this.Contrato.Extra2            = item.Extra2;
                this.Contrato.Extra3            = item.Extra3;

                //Llenado de crum
                this.oCrum.Contrato             = this.Contrato.Contrato;
                this.oCrum.Socio                = this.Contrato.NombreS;
                this.oCrum.Tipo                 = this.Contrato.Tipo;
                
            },
            fillOCrum(ContratoObject, BeneficiarioObject){
                //console.log('fillOCrum.ContratoObject', ContratoObject);
                //console.log('fillOCrum.BeneficiarioObject', BeneficiarioObject);               
                

                if(ContratoObject){
                    //console.log('ContratoObject', ContratoObject)
                    this.oCrum.Contrato             = ContratoObject.Contrato;
                    this.oCrum.Solicitante          = ContratoObject.NombreS;
                    this.oCrum.Paciente             = ContratoObject.NombreS;
                    this.oCrum.Domicilio            = ContratoObject.DomicilioS;
                    this.oCrum.Telefonos            = ContratoObject.TelefonosS;
                    this.oCrum.Edad                 = ContratoObject.EdadS;
                    this.oCrum.Sexo                 = ContratoObject.SexoS;

                    
                }
                if(BeneficiarioObject){
                    //console.log('BeneficiarioObject', BeneficiarioObject)
                    this.oCrum.Contrato             = BeneficiarioObject.Contrato;
                    this.oCrum.Solicitante          = BeneficiarioObject.NombreB;
                    this.oCrum.Paciente             = BeneficiarioObject.NombreB;
                    this.oCrum.Domicilio            = BeneficiarioObject.DomicilioB;
                    this.oCrum.Telefonos            = BeneficiarioObject.TelefonosB;
                    this.oCrum.Edad                 = BeneficiarioObject.EdadB;
                    this.oCrum.Sexo                 = BeneficiarioObject.SexoB;
                }

            },

            fillOCrumFromHojaTrabajo( item ){
                //console.log('fillOCrumFromHojaTrabajo.data', data);

                this.oCrum.Numero               = 	item.Numero;
                this.oCrum.Contrato             = 	item.Contrato;
                this.oCrum.Socio                = 	item.Socio;
                this.oCrum.Tipo                 = 	item.Tipo;
                this.oCrum.Fecha                = 	item.Fecha;
                this.oCrum.FechaHora            = 	item.FechaHora;
                this.oCrum.HoraLlamada          = 	item.HoraLlamada;
                this.oCrum.Usuario              = 	item.Usuario;
                this.oCrum.Despacho             = 	item.Despacho;
                this.oCrum.Salida               = 	item.Salida;
                this.oCrum.Llegada              = 	item.Llegada;
                this.oCrum.Traslado             = 	item.Traslado;
                this.oCrum.Hospital             = 	item.Hospital;
                this.oCrum.Libre                = 	item.Libre;
                this.oCrum.MS                   = 	item.MS;
                this.oCrum.Solicitante          = 	item.Solicitante;
                this.oCrum.Edad                 =   item.Edad;
                this.oCrum.Sexo                 =   item.Sexo;

                this.oCrum.Beneficiario         = 	item.Beneficiario;
                this.oCrum.Paciente             = 	item.Paciente;
                this.oCrum.Telefonos            = 	item.Telefonos;
                this.oCrum.Domicilio            = 	item.Domicilio;
                this.oCrum.Signos               =   item.Signos;
                this.oCrum.TipoServicio         =   item.TipoServicio;
                this.oCrum.Triage               = 	item.Triage;
                this.oCrum.Equipo               = 	item.Equipo;
                this.oCrum.Horario              = 	item.Horario;
                this.oCrum.NumeroLab            = 	item.NumeroLab;
                this.oCrum.Diagnostico          = 	item.Diagnostico;
                this.oCrum.Tratamiento          = 	item.Tratamiento;
                this.oCrum.Observaciones        = 	item.Observaciones;

                this.oCrum.PadecimientoActual   = 	item.PadecimientoActual;
                this.oCrum.TratamientoActual   =    item.TratamientoActual;
                this.oCrum.InicioSintomas       = 	item.InicioSintomas;
                this.oCrum.ExploracionFisica    = 	item.ExploracionFisica;

                this.oCrum.SV_TA                = 	item.SV_TA;
                this.oCrum.SV_FR                = 	item.SV_FR;
                this.oCrum.SV_TEMP              = 	item.SV_TEMP;
                this.oCrum.SV_FC                = 	item.SV_FC;
                this.oCrum.SV_SAT               = 	item.SV_SAT;
                this.oCrum.SV_PESO              = 	item.SV_PESO;

                this.oCrum.AntecedentesPersonalesPato = item.AntecedentesPersonalesPato;
                this.oCrum.EstadoCivil          = 	item.EstadoCivil;
                this.oCrum.GlasgowMotora        = 	item.GlasgowMotora;
                this.oCrum.GlasgowRespuestaVerbal   = 	item.GlasgowRespuestaVerbal;
                this.oCrum.GlasgowAperturaOcular    = 	item.GlasgowAperturaOcular;

                this.oCrum.Estatus              = 	item.Estatus;
                this.oCrum.Cancelacion          = 	item.Cancelacion;
                this.oCrum.Destino              = 	item.Destino;
                this.oCrum.Viaje                = 	item.Viaje;
                this.oCrum.Extra1               = 	item.Extra1;
                this.oCrum.Extra2               = 	item.Extra2;
                this.oCrum.Extra3               = 	item.Extra3;
                this.oCrum.Medico               = 	item.Medico;
                this.oCrum.Mes                  = 	item.Mes;
                this.oCrum.Ano                  =	item.Año;

                //Selecciona el dato en los dropdowns, para mostrarlos
                this.selectTiposServicio = item.TipoServicio;
                this.selectTriageInicial = item.Triage;
                this.selectEquipos  = item.Equipo;
                this.selectHorarios = item.Horario;
                this.selectMedicos  = item.Medico;

                this.selectEstadoCivil = item.EstadoCivil;

                this.identificaPaso( item );
            },

            identificaPaso( data){

                if(data.Despacho!=''){
                    this.step = 1;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }
                else{
                    this.step = 0;
                }

                if(data.Salida!=''){
                    this.step = 2;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }   

                if(data.Llegada!=''){
                    this.step = 3;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }   

                if(data.Traslado!=''){
                    this.step = 4;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }

                      
                
                if(data.Hospital!=''){
                    this.step = 5;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }

                if(data.Libre!=''){
                    this.step = 6;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }

                if(data.MS!=''){
                    this.step = 6;
                    //Deshabilita el boton que este seleccionado
                    this.listaPasos[this.step-1].disabled = true;
                }
                
            },
            clearCrumObject(){
                this.oCrum = {
                    Numero : null,
                    Contrato : null,
                    Socio : null,
                    Tipo : null,
                    Fecha : null,
                    FechaHora : null,
                    HoraLlamada : null,
                    Usuario : null,
                    Despacho : null,
                    Salida : null,
                    Llegada : null,
                    Traslado : null,
                    Hospital : null,
                    Libre : null,
                    MS : null,
                    Solicitante : null,
                    Edad : null,
                    Sexo : null,
                    Beneficiario : null,
                    Paciente : null,
                    Telefonos : null,
                    Domicilio : null,
                    Signos : null,
                    TipoServicio : null,
                    Triage : null,
                    Equipo : null,
                    Horario : null,
                    NumeroLab : null,
                    Diagnostico : null,
                    Tratamiento : null,
                    Observaciones : null,

                    PadecimientoActual   : null,
                    TratamientoActual   : null,
                    InicioSintomas       : null,
                    ExploracionFisica    : null,

                    SV_TA : null,
                    SV_FR : null,
                    SV_TEMP : null,
                    SV_FC : null,
                    SV_SAT : null,
                    SV_PESO : null,

                    AntecedentesPersonalesPato : null,
                    EstadoCivil : null,
                    GlasgowMotora : null,
                    GlasgowRespuestaVerbal : null,
                    GlasgowAperturaOcular : null,

                    Estatus : null,
                    Cancelacion : null,
                    Destino : null,
                    Viaje : null,
                    Extra1 : null,
                    Extra2 : null,
                    Extra3 : null,
                    Medico : null,
                    Mes : null,
                    Ano : null,
                }
            },
            presentAlert(mensaje, color){
                //console.log('presentAlert.mensaje', mensaje);
                this.oDialog.mensaje = mensaje;
                this.oDialog.color = color;
                this.oDialog.show = true;
            },
            llenaDropdowns(){
                this.getTiposServicio();
                this.getTriagesInicial();
                this.getEquipos();
                this.getHorarios();
                this.getMedicos();
            },
            //#endregion 

            //#region selects-Dropdowns
            selectDropDownTiposServicio(){ this.oCrum.TipoServicio = this.selectTiposServicio; },
            selectDropDownTriageInicial(){ this.oCrum.Triage = this.selectTriageInicial; },
            selectDropDownEquipo(){ this.oCrum.Equipo = this.selectEquipos; },
            selectDropDownHorario(){ this.oCrum.Horario = this.selectHorarios; },
            selectDropDownMedico(){ this.oCrum.Medico = this.selectMedicos; },
            selectDropDownEstadoCivil(){ this.oCrum.EstadoCivil = this.selectEstadoCivil; },
            //#endregion

            //#region Consumo Api-Configuracion
            getTiposServicio(){
                this.apiConfiguracion.recuperaTiposServicio(this.URLApi,0,0)
                .then( response => {
                    this.listaTiposServicio = response.data.objeto;
                    //console.log('getTiposServicio', this.listaTiposServicio);
                })
                .catch( error => console.log(error));
            },

            getTriagesInicial(){
                this.apiConfiguracion.recuperaTriagesInicial(this.URLApi,0,0)
                .then( response => {
                    this.listaTriageInicial = response.data.objeto;
                    //console.log('getTriagesInicial', this.listaTriageInicial);
                })
                .catch( error => console.log(error));
            },

            getEquipos(){
                this.apiConfiguracion.recuperaEquipos(this.URLApi,0,0)
                .then( response => {
                    this.listaEquipos = response.data.objeto;
                    //console.log('getEquipos', this.listaEquipos);
                })
                .catch( error => console.log(error));
            },

            getHorarios(){
                this.apiConfiguracion.recuperaHorarios(this.URLApi,0,0)
                .then( response => {
                    this.listaHorarios = response.data.objeto;
                    //console.log('getHorarios', this.listaHorarios);
                })
                .catch( error => console.log(error));
            },

            getMedicos(){
                this.listaMedicos = [];
                this.apiConfiguracion.recuperaMedicos(this.URLApi,0,0)
                .then( response => {
                    const oMedicoArray = [
                        {
                            Nombre:'SIN ASIGNAR',
                            Numero: 0
                        }
                    ];

                    this.listaMedicos.push(...oMedicoArray);
                    this.listaMedicos.push(...response.data.objeto);
                    //console.log('getMedicos', this.listaMedicos);
                })
                .catch( error => console.log(error));
            },

            //#endregion

            //#region Consumo Api-Contratos
            getContratos(ContratoNumero){
                //this.loadingContrato = true;
                //this.showSnackbarAviso = false;
                this.listaBeneficiarios = [];

                //this.emitiToComponentFather(0,0,0,'',0);

                if(!ContratoNumero>0){
                    //this.loadingContrato = false;
                    //this.showSnackbarAviso = false;
                    return;
                }

                //console.log('getContrato', this.Contrato.NombreS);

                //Solo puede mostrar uno por seguridad
                this.apiContratos.recuperaContratos( this.URLApi, 
                        this.Contrato.Numero, ContratoNumero, '')
                .then( response => {
                    //console.log('getContratos.data', response.data);
                    this.listaContratos = response.data.objeto;

                    if( this.listaContratos.length == 1 ){
                        this.fillContrato( this.listaContratos[0] );
                        this.fillOCrum( this.listaContratos[0] );
                    }
                    else if(this.listaContratos.length > 1){
                        //this.showDialogBusqueda = true;
                        //this.clearContratoObject();
                    }
                    else{
                        //console.log('else', this.listaContratos);
                        //this.showSnackbarAviso = true;
                    }
                })
                .catch(error=>{console.log(error);});

            },        
            //#endregion 

            //#region Consumo Api-Beneficiarios
            getBeneficiarios(ContratoNumero, NumeroBeneficiario){
                this.apiContratos.recuperaBeneficiarios(this.URLApi,
                    NumeroBeneficiario, ContratoNumero, '')
                .then( response => {
                    console.log("getBeneficiarios.data", response.data);
                    if(response.data.mensaje == ''){
                        this.listaBeneficiarios = response.data.objeto;

                        if(this.listaBeneficiarios.length==1){
                            this.fillOCrum( null, this.listaBeneficiarios[0] );
                        }


                    }
                })
                .catch(error=>{console.log(error);});
            },
            //#endregion

            //#region Consumo Api-CRUM
            getCrum(){
                if(!this.oCrum.Numero){
                    return;

                }
                //Obtiene los servicios
                this.apiAmbulancias.recuperaCrum(  this.URLApi, 
                                        this.oCrum.Numero, this.oCrum.Contrato, this.oCrum.Estatus )
                .then( response =>{
                    //console.log('getCrum.data',response.data);
                    const lista =  response.data.objeto;
                    if( lista.length == 1 ){
                        this.fillOCrumFromHojaTrabajo( lista[0] );
                    }
                    
                    else if( lista.length > 1 ){
                        console.log('Encontro mas de uno');
                    }
                    else{
                        console.log('No encontró nada.');
                    }

                })
                .catch(error=>{console.log(error);});

            },

            insertCrum(crum){

                crum.Estatus = 'ACTIVO';
                this.apiAmbulancias.guardaCrum(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Edad,          crum.Sexo,
                    crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, 
                    crum.PadecimientoActual, crum.TratamientoActual,
                    crum.InicioSintomas, crum.ExploracionFisica,
                    crum.SV_TA , crum.SV_FR , crum.SV_TEMP , 
                    crum.SV_FC , crum.SV_SAT , crum.SV_PESO ,
                    crum.AntecedentesPersonalesPato, crum.EstadoCivil,
                    crum.GlasgowMotora, crum.GlasgowRespuestaVerbal, crum.GlasgowAperturaOcular,
                    crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
                )
                .then( response => {
                    //console.log('insertCrum.data', response.data);

                    if(response.data.objeto>0){
                        this.presentAlert('Servicio registrado correctamente', 'green');
                        //this.btnGuardandoDisabled = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                    //this.btnGuardandoDisabled = false;
                } );
            },

            updateCrum( crum ){

                this.apiAmbulancias.modificaCrum(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Edad,          crum.Sexo,
                    crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, 
                    crum.PadecimientoActual, crum.TratamientoActual,
                    crum.InicioSintomas, crum.ExploracionFisica,
                    crum.SV_TA , crum.SV_FR , crum.SV_TEMP , 
                    crum.SV_FC , crum.SV_SAT , crum.SV_PESO ,
                    crum.AntecedentesPersonalesPato, crum.EstadoCivil,
                    crum.GlasgowMotora, crum.GlasgowRespuestaVerbal, crum.GlasgowAperturaOcular,
                    crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
                )
                .then( response => {
                    //console.log('updateCrum.data', response.data);
                    //this.btnGuardandoDisabled = false;
                    if( response.data.objeto ){
                        this.presentAlert('Trabajo o Servicio actualizado correctamente', 'green');

                    }

                })
                .catch(error => {
                    console.log(error)
                    this.btnGuardandoDisabled = false;
                } );
            },

            terminaCrum( crum ){

                crum.Estatus = 'TERMINADO';
                this.apiAmbulancias.terminaTrabajoCrum(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Edad,          crum.Sexo,
                    crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, 
                    crum.PadecimientoActual, crum.PadecimientoActual,
                    crum.InicioSintomas, crum.ExploracionFisica,
                    crum.SV_TA , crum.SV_FR , crum.SV_TEMP , 
                    crum.SV_FC , crum.SV_SAT , crum.SV_PESO ,
                    crum.AntecedentesPersonalesPato, crum.EstadoCivil,
                    crum.GlasgowMotora, crum.GlasgowRespuestaVerbal, crum.GlasgowAperturaOcular,
                    crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
                )
                .then( response => {
                    //console.log('terminaCrum.data', response.data);
                    if( response.data.objeto ){
                        this.dialogTrabajo.show = false;
                        this.presentAlert('Trabajo o Servicio terminado correctamente', 'green');
                    }

                })
                .catch(error => console.log(error) );
            },

            deleteCrum(){

            },
            //#endregion
        },
        //#endregion 

        //#region update
        updated(){
            //console.log('CrumComponent.Updated');
            
        },
        //#endregion

        //#region mounted
        mounted(){
            this.llenaDropdowns();
            //------------------
            //Consultar el Contrato
            this.getContratos( this.ContratoNumero );
            this.getCrum();
        },
        beforeMount(){
            //this.crearIntervalo();
            this.oCrum.Numero = this.NumeroCrum;
            this.oCrum.Estatus = this.EstatusCrum;
            this.oCrum.Contrato = this.ContratoNumero;

            if(this.oCrum.Numero>0){
                this.btnGuardandoDisabled = true;
            }
            
            //console.log('beforeMount', this.oCrum);
        },
        beforeDestroy () {
            //clearInterval(this.interval);
        },
        //#endregion

        //#region  watch
        watch: {
            EsBeneficiario(val){
                //console.log('EsBeneficiario', val);
            },
            ContratoNumero(val){
                console.log('ContratoNumero', val);
                this.clearCrumObject();
                if( val == 0 ){
                    this.clearCrumObject();
                }
                else{
                    this.clearCrumObject();
                    this.verificaConsulta();
                }
            },
            NumeroBeneficiario(val){
                //console.log('NumeroBeneficiario', val);
                this.verificaConsulta();
            }
        }
        //#endregion
    }
</script>