<template>
    <v-container class="mt-8 mb-1">
        <v-row>
            <v-col offset-md="2" md="8">
                <v-card>
                    <v-card-subtitle class="d-flex justify-center">
                        <v-img max-height="150" max-width="250" src="@/assets/SAM.png"></v-img>
                    </v-card-subtitle>
                    <v-card-title class="d-flex justify-center">
                        ¿Qué deseas hacer?
                    </v-card-title>
                    
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" offset-md="2" md="8">
                <v-tabs v-model="tab">
                    <v-tab v-for="item of tabs" :key="item.tab" >
                        {{ item.title }}
                    </v-tab>

                    <v-tabs-items v-model="tab">
                        <v-tab-item> <PagoPublicoComponent/>  </v-tab-item>
                        <v-tab-item> <DescargaEstudioComponent></DescargaEstudioComponent>  </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-col>
        </v-row>

        
        
    </v-container>
</template>


<script>
import PagoPublicoComponent from '@/components/Publico/PagoPublicoComponent';
import DescargaEstudioComponent from '@/components/Publico/DescargaEstudioComponent';

export default {


    //#region Componentes
    components:{
        PagoPublicoComponent,
        DescargaEstudioComponent
    },
    //#endregion


    //#region Data
    data(){
        return{
            tab:null,
            tabs:[
                { tab:0, title:'Pagar factura', content:'<PagoPublicoComponent></PagoPublicoComponent>' },
                { tab:1, title:'Descargar estudio', content:'<DescargaEstudioComponent></DescargaEstudioComponent>' },
            ]
        }
    },
    //#endregion

    //#region Metodos
    methods:{

    },
    //#endregion

    

}
</script>