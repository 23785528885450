<template>
    <div>
      <v-card-text class="mt-12">

        <h1 class="text-center display-2 teal--text text accent-3"> Create Account  </h1>

        <div class="text-center mt-4" style="display:none;">

          <v-btn class="mx-2" fab color="black" outlined >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="black" outlined >
              <v-icon>mdi-google</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="black" outlined >
              <v-icon>mdi-login</v-icon>
            </v-btn>

        </div>

        <h4 class="text-center mlt-4"> Ensure your email for registration </h4>

        <v-form ref="form" v-model="valid" lazy-validation>
          
            <v-text-field 
              id="txt-name"
              label="Name/User"
              name="Name"
              prepend-icon="mdi-account"
              type="text"
              color="teal accent-3" 
              :counter="80"
              :rules="nameRules"
              v-model="User.name"
              required
              clearable/>

            <v-text-field 
              label="Email"
              name="Email"
              prepend-icon="mdi-email"
              type="email"
              color="teal accent-3" 
              :counter="80"
              :rules="emailRules"
              v-model="User.email"
              required
              clearable/>

            <v-row>
              <v-col >
                <v-text-field 
                  id="txt-password"
                  label="Password"
                  name="Password"
                  prepend-icon="mdi-lock"
                  color="teal accent-3" 
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                  @focus="showAlert = false"
                  :rules="passwordRules"
                  v-model="User.password"
                  required
                  clearable/>
              </v-col>
              <v-col >
                <v-text-field 
                  id="txt-passwordConfirm"
                  label="Confirm Password"
                  name="ConfirmPassword"
                  type="password"
                  color="teal accent-3" 
                  :rules="passwordRules"
                  v-model="User.passwordConfirm"
                  @focus="showAlert = false"
                  @blur="verificarPassword()"
                  required
                  clearable/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="showAlert" color="red" dismissible type="error" 
                          transition="scale-transition"> 
                  {{ msgAlert }} 
                </v-alert>
              </v-col>
            </v-row>

            <div class="text-center mt-n5">
              <br/>
              <v-icon @click="reset" > mdi-broom </v-icon>
              <v-btn rounded color="teal accent-8" dark @click="validate" > SIGN UP </v-btn>
              <v-progress-circular indeterminate color="amber" v-if="progressCircular"/>
            </div>

          </v-form>
      </v-card-text>
    </div>  
</template>

<script>
  import {mapState} from "vuex";

  export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi'])
    },
    data: () => ({
      step:2,
      valid: true,
      progressCircular:false,
      showPass:false,
      showAlert:false,
      msgAlert:'password does not match',
      msgContrasenasDiferentes:'Las contraseñas no coinciden',
      User:{
        name: '',
        email: '',
        password:'',
        passwordConfirm:''
      },
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 5) || 'Min 5 characters',
        //v => /(?=[^AZ]*[AZ])(?=[^az]*[az])(?=[^0-9]*[0-9]).{6,}$/.test(v)  || 'Password must be valid (It must contain upper and lower case letters and at least one digit.)'
      ],
      nameRules: [
        v => !!v || 'Name/User is required',
        v => (v && v.length >= 4) || 'Min 4 characters',
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match')
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4'
      ],
      checkbox: false,
    }),
    props:{
      source : String
    },
    methods: {
      validate () {
        const valid = this.$refs.form.validate();
        if(valid){
        }

      },
      reset () {
        this.$refs.form.reset();
      },
      resetValidation () {
        this.$refs.form.resetValidation();
      },
      //Metodos de la pagina
      
    },
    mounted(){
      
    },
    updated(){
      
    }

  }
</script>