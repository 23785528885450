<template>
    <v-container>
        <!--INICIO Busqueda Articulo -->
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field
                    label="Código del Artículo"
                    filled
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
                <v-autocomplete
                    v-model="oArticulo.NumeroAlmacen"
                    label="Descripción"
                    :items="listaArticulos"
                    item-text="Descripcion"
                    item-value="NumeroAlmacen"
                    dense
                    filled
                    clearable
                    @change="selectAutocompleteArticulo()"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="sumaTotalCompra"
                    label="Total"
                    prefix="$"
                    readonly
                ></v-text-field>

            </v-col>

            <v-col cols="12" md="2">
                <v-btn
                    class="white--text"
                    block
                    color="green"
                    @click="guardaCompra()"
                > Guardar </v-btn>

            </v-col>
            
        </v-row>
        <!--FIN Busqueda Articulo -->

        <!--INICIO Tabla -->
        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaCompra"
                    item-key="NumeroAlmacen"
                    :single-select="singleSelect"
                    :items-per-page="10"
                    :footer-props="{
                        'items-per-page-text':'Artículos por página',
                    }"
                    :loading="loadingDatatable"
                    class="elevation-1"
                    loading-text="Cargando..."
                    no-data-text="Sin datos disponibles."
                    @click:row="handleClick"
                    @dblclick:row="handleDblClick"
                    
                > <!--show-select-->

                <!--
                    <template v-slot:item="{ item }">
                    <tr>
                        <td> {{ item.NumeroAlmacen }} </td>
                        <td> {{ item.cantidadCompra }} </td>
                        <td> {{ item.CostoNeto }} </td>
                        <td> {{ item.totalCompra }} </td>
                    </tr>
                </template>

                <template slot="body.append">
                    <tr class="black--text">
                        <th class="title" > Totales </th>
                        <th class="title" colspan="2"> {{ sumField('cantidadCompra') }} </th>
                        <th class="title"  > {{ sumField('totalCompra') }} </th>
                    </tr>
                </template>
                -->


                </v-data-table>

            </v-col>
        </v-row>
        <!--INICIO Tabla -->

        <!--INICIO Dialog de Proveedores -->
        <v-dialog v-model="showDialogProveedores" max-width="800">
            <v-card>
                <v-card-title>
                    <span > Proveedores </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="showDialogProveedores = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <ProveedoresComponent
                        @setNombreProveedor="oArticulo.NombreProveedor = $event"
                        @setShowDialogProveedores="showDialogProveedores = $event"
                    ></ProveedoresComponent>             
                </v-card-text>
            </v-card>

        </v-dialog>
        <!--FIN Dialog de Proveedores -->

        <!--INICIO Formulario para agregar piezas -->
        <v-dialog v-model="dialogArticulo.show" max-width="800" persistent>
            <v-card>
                <v-card-title>
                    <span > Registro de Compras </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogArticulo.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>

                <v-card-text>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Código del Árticulo"
                                v-model="oArticulo.NumeroAlmacen"
                                disabled
                            ></v-text-field>                            
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-text-field
                                label="Descripción"
                                v-model="oArticulo.Descripcion"
                                disabled
                            ></v-text-field>                            
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Mínimo Stock"
                                v-model="oArticulo.MinimoStock"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Existencia"
                                v-model="oArticulo.ExistenciaTotal"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Unidad Por Presentación"
                                v-model="oArticulo.UnidadesPorPtn"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Costo Unitario"
                                v-model="costoArticulo"
                                readonly
                                prefix="$"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="3">
                            <v-text-field
                                label="Compra de"
                                v-model="oArticulo.cantidadCompra"
                                ref="txtCantidadCompra"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field
                                label="Presentación"
                                v-model="oArticulo.Presentacion"
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Costo Total"
                                v-model="costoTotal"
                                prefix="$"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="9" md="6" >
                            <v-autocomplete
                                label="Proveedor"
                                v-model="oArticulo.NombreProveedor"
                                :items="listaProveedores"
                                :search-input.sync="searchInput"
                                item-text="NombreProveedor"
                                item-value="NombreProveedor"
                                clearable
                                no-data-text="No existe el proveedor"
                                @change="selectAutocompleteProveedor()"
                                dense
                                filled
                                ref="txtNombreProveedor"
                            >                                
                            </v-autocomplete>

                        </v-col>

                        <v-col cols="3" md="2">
                            <v-btn
                                @click="showDialogProveedores=true" 
                                text
                                block
                                >
                                <v-icon
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </v-col>


                        <v-col cols="12" md="4">
                            <v-btn 
                                color="green"
                                class="white--text"
                                block
                                @click="agregarListaCompra( oArticulo) "
                            >
                                Agregar
                            </v-btn>

                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>

        </v-dialog>
        <!--FIN Formulario para agregar piezas -->

        <!--INICIO Loading al guardar la venta -->
        <v-dialog v-model="dialogLoading.show" max-width="300" persistent>
            <v-card>
                <v-card-title>
                    Procesando compra...
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-dialog>
        <!--FIN Loading al guardar la venta -->

        <!--Inicio Mensaje de errores o advertencias -->
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackbar.color"
                    text
                    v-bind="attrs"
                    @click="snackbar.show = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!--Inicio Mensaje de errores o advertencias -->

    </v-container>
</template>

<script>
import axios from 'axios';
import ProveedoresComponent from '@/components/Inventario/ProveedoresComponent';

import {mapState} from "vuex";
import ApiInventario from '@/services/ApiInventarioService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';

export default {
    components:{
        ProveedoresComponent,
    },
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        costoTotal(){
            let tot = this.oArticulo.CostoNeto * this.oArticulo.cantidadCompra;
            tot = (isNaN(tot))?0:tot;
            return Number.parseFloat(tot).toFixed(2);
        },
        costoArticulo(){
            const Costo = this.oArticulo.CostoNeto;
            return Number.parseFloat(Costo).toFixed(2);
        },
        sumaTotalCompra(){
            let sumaTotal = Number.parseFloat(0);
            //console.log('sumaTotalCompra', sumaTotal);
            this.listaCompra.forEach(item => {
                sumaTotal+= Number.parseFloat(item.totalCompra);
                //console.log('sumaTotal', sumaTotal);

            });


            return sumaTotal.toFixed(2);
        }
    },
    //#region Data
    data(){
        return{
            api:new ApiInventario(),
            apiConfiguracion:new ApiConfiguracionService(),
            showDialogProveedores:false,
            selected: [],
            singleSelect:true,
            searchInput:'',
            loadingBuscar:false,
            loadingDatatable:true,
            dialogLoading:{
                show:false,
            },
            snackbar:{
                show:false,
                color:'blue',
                text:'',
                timeout:3000
            },
            headers:[
                { text: 'Clave', value: 'NumeroAlmacen' },
                { text: 'Piezas', value: 'cantidadCompra' },
                { text: 'Unitario', value: 'CostoNeto' },
                { text: 'Total', value: 'totalCompra' },
            ],
            dialogArticulo:{
                show:false,
            },
            listaCompra:[],
            listaArticulos:[],
            oArticulo:{
                CodigoBarras : null, 
                NumeroAlmacen : null, //
                CostoNeto : null, 
                Descripcion : null, 
                Existencia : null, 
                PorcentajeUtilidad : null, 
                PrecioPublicoNeto : null, 
                MinimoStock : null, 
                Grupo : null, 
                Subgrupo : null, 
                Marca : null, 
                Ubicacion : null, 
                Tipo : null, 
                Presentacion : null, 
                UnidadesPorPtn : null, 
                CantidadPtn : null, 
                ExistenciaTotal : null, 
                //Propiedades para comprar
                cantidadCompra:0,
                totalCompra:0,
                NombreProveedor:''

            },
            articuloBusqueda:{
                NumeroAlmacen:'',
                Descripcion:''
            },
            listaProveedores:[]
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la vista

        handleClick(value) {
            console.log('handleClick', value);
        },

        handleDblClick( value ){
            console.log( 'handleDblClick', value );
            this.remueveArticulo( value );
        },

        remueveArticulo( articulo ){
            this.listaCompra.pop( articulo );
        },

        selectAutocompleteArticulo(){
            //console.log('selectAutocompleteArticulo', this.oArticulo.NumeroAlmacen);
            this.localizaArticuloNumeroAlmacen( this.oArticulo.NumeroAlmacen );
        },

        selectAutocompleteProveedor(){
            //console.log('selectAutocompleteProveedor', this.oArticulo);
        },

        fillArticulo( item ){
            this.oArticulo = item;
            this.oArticulo.NombreProveedor = this.oArticulo.NombreProveedor;
            //console.log('fillArticulo', this.oArticulo);
            this.dialogArticulo.show = true;

        },

        agregarListaCompra( articulo ){

            if(! Number.parseFloat(articulo.cantidadCompra) > 0) { 
                this.$refs.txtCantidadCompra.focus();
                return; 
            }

            if(! articulo.NombreProveedor ) { 
                this.$refs.txtNombreProveedor.focus();
                return; 
            }

            articulo.totalCompra = this.costoTotal;
            articulo.CostoNeto = Number.parseFloat(articulo.CostoNeto).toFixed(2);
            this.listaCompra.push(articulo);            
            articulo = {};
            this.searchInput = '';
            this.oArticulo = {};
            this.dialogArticulo.show = false;

        },

        sumField(key){
             return this.listaCompra.reduce((a, b) => a + ( Number.parseFloat(b[key]) || 0), 0);
        },

        mostrarSnackbar(text, color, timeout){

            this.snackbar = {
                text, 
                color, 
                timeout,
                show:true
            }
            
        },

        //#endregion

        //#region Metodos Api-Inventarios
        getArticulos(){
            this.loadingDatatable = true;

            this.api.recuperaArticulos(this.URLApi, 
                this.articuloBusqueda.NumeroAlmacen, this.articuloBusqueda.Descripcion)
            .then( response => {
                //console.log('getArticulos.data', response.data);
                this.listaArticulos = response.data.objeto;

                if(response.data.mensaje!=''){
                    console.log('mensaje', response.data.mensaje);
                }
                else{
                    this.loadingBuscar = false;
                    this.loadingDatatable = false;
                }

            })
            .catch( error=> console.log('error', error) );

        },

        localizaArticuloNumeroAlmacen(NumeroAlmacen){
            if(!NumeroAlmacen)return;

            this.api.recuperaArticulos(this.URLApi, 
                NumeroAlmacen, '')
            .then( response => {

                this.fillArticulo( response.data.objeto[0] );

            })
            .catch( error=> console.log('error', error) );
        },

        guardaCompra(){
            this.dialogLoading.show = true;
            const objeto = {
                arrayArticulos : this.listaCompra,
            }

            //Preparacion de Body
            const objetoString = JSON.stringify(objeto);
            const json = 'json='+objetoString;

            //Url de consumo
            const URL = this.api.sumaMercanciaURL(this.URLApi);

            axios.post(URL, json)
            .then(response =>{
                //console.log("sumaMercancia", response.data);

                if(response.data.objeto > 0){
                    this.mostrarSnackbar('Compra guardada correctamente', 'green', 3000);
                    this.listaCompra = [];
                    this.$emit('setNumeroRandom', Math.random());
                }

                this.dialogLoading.show = false;
            })
            .catch(error=>{
                console.log(error);
                this.dialogLoading.show = false;
            });

        },
        //#endregion
    
        //#region Metodos Api Configuracion
        getProveedores(){

            this.api.recuperaProveedores(this.URLApi, 
                    '', '')
            .then( response =>{
                //console.log("recuperaCiudad.data", response.data);
                this.listaProveedores = response.data.objeto;

            })
            .catch(error=>{console.log(error);});

        },
        //#endregion
    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        this.getArticulos();
        this.getProveedores();
    },
    //#endregion

    //#region watch
    watch:{
        showDialogProveedores(val){
          this.getProveedores();  
        },

    },
    //#endregion

}
</script>