<template>
    <div>
        
        <v-btn  color="blue"
                class="white--text"
                block
                @click="showDialogDescarga()"
                :disabled="descargaDisabled"
                >
            Descarga Reporte
            <v-icon>mdi-printer</v-icon> 
        </v-btn>

        <v-dialog v-model="dialogDescarga" max-width="700">
            <v-card>
                <v-card-subtitle>
                    
                </v-card-subtitle>
                <v-card-text>
                    <v-btn :href="srcPdf" color="blue" class="white--text" block>
                        <v-icon color="white"> mdi-download </v-icon> Descarga PDF
                    </v-btn>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogDescarga = false"
                            color="green darken-1" text>
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog>

    </div>
</template>

<script>
import ApiReporte from '@/services/ApiReportesService';
import {mapState} from "vuex";

export default {
    //#region Computed
    computed:{ 
        ...mapState(['URLSrc', 'URLApi', 'User']), 
        descargaDisabled(){
            return (this.NumeroCrum)?false:true;
        }
    }, 
    //#endregion 

    //#region ---- Props ----
    props:{
        NumeroCrum:Number,
    },
    //#endregion

    //#region ---- Data ----
    data(){
        return{
            api: new ApiReporte(),
            //--------------------------
            dialogDescarga:false,
            srcPdf:'',

        }
    },
    //#endregion

    //#region ---- Methods ----
    methods:{
        //#region Metodos de la vista
        showDialogDescarga(){
            this.srcPdf = this.api.urlDescargaReporteCrum(this.URLApi, this.NumeroCrum);
            this.dialogDescarga = true;
        },
        //#endregion

        //#region Metodos con api
        generaUrlReporte(){
            this.api.urlDescargaReporteCrum(this.URLApi, this.NumeroCrum);
        }
        //#endregion
    },
    //#endregion


}
</script>