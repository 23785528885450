<template>
  <div class="home">
    <v-row>
      <v-col>
        <v-card elevation="1">
            <v-card-title class="d-flex justify-center">
              <v-img max-height="150" max-width="250" src="../assets/SAM.png"></v-img>
            </v-card-title>
            <v-card-subtitle  class="d-flex justify-center">
                {{ fechaActualFormateada }} --- {{ horaActualFormateada }}
            </v-card-subtitle>
            <v-card-text>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <v-row>
      
      <v-col cols="12" md="4"
              v-if="!habilitaModulo">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="344"
            >
              <v-img src="../assets/ambulancia.png"></v-img>

              <v-card-text>
                <h2 class="title blue--text">
                  Ambulancias
                </h2>
                Revisa nuestro personal medico!
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#0d93fb"
                >
                  <v-btn 
                    link @click="openLoginDialog(linksConImagen.Ambulancias)"
                  > 
                  Ambulancias 
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col> 


      <v-col cols="12" md="4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="344"
            >
              <v-img src="../assets/contrato.png"></v-img>

              <v-card-text>
                <h2 class="title blue--text">
                  Contratos
                </h2>
                Manten en orden nuestros contratos!
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#0d93fb"
                >
                  <v-btn 
                    link @click="openLoginDialog(linksConImagen.Contratos)"
                  > 
                    Contratos 
                    </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
            

      <v-col cols="12" md="4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="344"
            >
              <v-img src="../assets/puntodeventa.png"></v-img>

              <v-card-text>
                <h2 class="title blue--text">
                  Punto de Venta
                </h2>
                Revisa nuestro punto de venta!
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#0d93fb"
                >
                  <v-btn 
                    link @click="openLoginDialog(linksConImagen.PuntoVenta)"
                  >
                    Punto de Venta
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col> 

      <v-col cols="12" md="4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="344"
            >
              <v-img src="../assets/inventario.png"></v-img>

              <v-card-text>
                <h2 class="title blue--text">
                  Inventario
                </h2>
                Mantén nuestro inventario en orden!
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#0d93fb"
                >
                  <v-btn link @click="openLoginDialog(linksConImagen.Inventario)" 
                  > 
                  Inventario 
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      
      <v-col cols="12" md="4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="344"
            >
              <v-img src="../assets/pagos.png"></v-img>

              <v-card-text>
                <h2 class="title blue--text">
                  Pagos
                </h2>
                Revisa los pagos de nuestros clientes!
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#0d93fb"
                >
                  <v-btn
                    link @click="openLoginDialog(linksConImagen.Pagos)"
                  >
                    Pagos
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>  

      <v-col cols="12" md="4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="344"
            >
              <v-img src="../assets/estudios.png" max-height="195"></v-img>

              <v-card-text>
                <h2 class="title blue--text">
                  Estudios
                </h2>
                Captura y realiza tus estudios clínicos!
              </v-card-text>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#0d93fb"
                >
                  <v-btn 
                    link @click="openLoginDialog(linksConImagen.Estudios)"
                  > 
                    Estudios
                    </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>

    </v-row>



    <v-row>
      <v-col cols="12" md="12">
        <v-hover>
          <template>
            <v-footer
              color="blue lighten-1"
              padless
            >
              <v-row
                justify="center"
                no-gutters
              >
                <v-btn
                  v-for="link in links"
                  :key="link.clave"
                  color="white"
                  text
                  rounded
                  class="my-2"
                  @click="openLoginDialog(link)"
                >
                  {{ link.texto }}
                </v-btn>
              </v-row>
            </v-footer>
          </template>
        </v-hover>
      </v-col> 
    </v-row>
    <!--<h3>Aquí hay que poner la navegación para todo</h3>-->

    <!--Comente esta linea para ver el espacio en la pantalla principal-->
    <!--<v-btn link to="/configuracion" > Configuración </v-btn>-->
    <!--Comente esta linea para ver el espacio en la pantalla principal-->
    
    
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <!--<h3>Aquí hay que poner la navegación para todo</h3>
    <v-btn link to="/configuracion" > Configuración </v-btn>
    <v-btn link to="/Usuarios" > Usuarios </v-btn>
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <v-dialog v-model="loginDialog" max-width="600" persistent >
      <v-card>
        <v-card-title>
          <span> SAM </span>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="loginDialog = false"
              >
                <v-icon color="red" >mdi-close </v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        
        <v-card-subtitle>
          Ir a {{ loginComponentObject.loginTexto }}
        </v-card-subtitle>
        <v-card-text>
          <LoginFormComponent 
            :textoRuta="loginComponentObject.loginClave" 
            :Ruta="loginComponentObject.loginRuta" 
          ></LoginFormComponent>

        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
  
  
</template>

<script>
// @ is an alias to /src
import {mapState} from "vuex";
import HelloWorld from '@/components/HelloWorld.vue'
import LoginFormComponent from '@/components/Usuarios/LoginFormComponent.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    LoginFormComponent
  },

  computed:{
    ...mapState(['URLSrc', 'URLApi', 'User', 'Empresa']),

    habilitaModulo(){
        let permiso;

        console.log('Empresa', this.Empresa);
        if(this.Empresa == 'EMA'){
          permiso = true;
        }


        return permiso;
    }

  },

  data(){
        return{
            loginDialog:false,
            loginComponentObject:{
              loginTexto:'',
              loginRuta:'',
              loginClave:''
            },
            fechaActualFormateada:'',
            horaActualFormateada:'',
            links: [
                { clave: 'CONFIGURACION', texto:'Configuración', ruta:'/operacion/configuracion' },
                { clave: 'REPORTES', texto:'Reportes', ruta:'/operacion/reportes' },
                { clave: 'USUARIOS', texto:'Usuarios', ruta:'/operacion/usuarios' },
                //{ clave: 'SALIR', texto:'Salir', ruta:'' }
            ],
            linksConImagen:{
              Ambulancias: { clave: 'AMBULANCIAS', texto:'Ambulancias', ruta:'/operacion/ambulancias' },
              Contratos: { clave: 'CONTRATOS', texto:'Contratos', ruta:'/operacion/contratos' },
              PuntoVenta: { clave: 'PUNTOVENTA', texto:'Punto de Venta', ruta:'/operacion/ventas' },
              Inventario: { clave: 'INVENTARIO', texto:'Inventario', ruta:'/operacion/inventario' },
              Pagos: { clave: 'PAGOS', texto:'Pagos', ruta:'/operacion/pagos' },
              Estudios: { clave: 'ESTUDIOS', texto:'Estudios', ruta:'/operacion/estudios' },
            }
        }
    },
    methods:{
      //#region Metodos para la hora
      getFechaActual(){
          let fechaActual = new Date();
          let diaSemana = '';
          let mesLetra = '';

          let year = fechaActual.getFullYear();
          let month = fechaActual.getMonth();
          let day = fechaActual.getDate();
          let numeroDia = fechaActual.getDay();

          switch(numeroDia){
              case 0: diaSemana = 'Domingo'
                  break;
              case 1: diaSemana = 'Lunes'
                  break;
              case 2: diaSemana = 'Martes'
                  break;
              case 3: diaSemana = 'Miércoles'
                  break;
              case 4: diaSemana = 'Jueves'
                  break;
              case 5: diaSemana = 'Viernes'
                  break;
              case 6: diaSemana = 'Sábado'
                  break;
          }

          switch(month){
              case 0: mesLetra = 'Enero'
                  break;
              case 1: mesLetra = 'Febrero'
                  break;
              case 2: mesLetra = 'Marzo'
                  break;
              case 3: mesLetra = 'Abril'
                  break;
              case 4: mesLetra = 'Mayo'
                  break;
              case 5: mesLetra = 'Junio'
                  break;
              case 6: mesLetra = 'Julio'
                  break;
              case 7: mesLetra = 'Agosto'
                  break;
              case 8: mesLetra = 'Septiembre'
                  break;
              case 9: mesLetra = 'Octubre'
                  break;
              case 10: mesLetra = 'Noviembre'
                  break;
              case 11: mesLetra = 'Diciembre'
                  break;
          }
          //this.fechaActualFormateada = diaSemana + " " +  fechaActual.toJSON().slice(0,10).replace(/-/g,'/');
          this.fechaActualFormateada = diaSemana + " " + day + " de "+ mesLetra + " " + year;
          //console.log('fechaActualFormateada',this.fechaActualFormateada);
      },
      getHoraActual(){
          const horaActual = new Date();
          let meridiano = 'a.m.';
          let hora = horaActual.getHours();            
          const minutos = horaActual.getMinutes();
          const segundos = horaActual.getSeconds();

          meridiano = (hora >= 12) ? 'p.m.' : 'a.m.';
          hora = (hora > 12) ? (hora-12) : hora;

          this.horaActualFormateada = 
                hora.toString().padStart(2,"0") 
                + ':' + minutos.toString().padStart(2,"0") 
                + ':' + segundos.toString().padStart(2,"0") 
                + ' ' + meridiano;
      },
      crearIntervalo(){
          this.interval = setInterval(() => {
              this.getHoraActual();
          }, 500);
      },
      //#endregion

      //#region Funciones de la vista
      openLoginDialog(navItem){
        this.loginComponentObject.loginClave = navItem.clave;
        this.loginComponentObject.loginTexto = navItem.texto;
        this.loginComponentObject.loginRuta = navItem.ruta;

        //Si hay coincidencia, son rutas que no necesitan sesion para entrar.
        this.loginDialog = true;
        return;
        if(navItem.clave === this.linksConImagen.Ambulancias.clave) {           
          this.goRuta(navItem);
          return;
        }
        else{
          this.loginDialog = true;
        }

      },

      goRuta(navItem){
        //this.$router.replace('/ambulancias');//Borra pagina actual de la cola de navegacion
        if(navItem.ruta==''){ return; }

        //Apila la pagina siguiente sobre la pagina actual
        this.$router.push(this.loginComponentObject.loginRuta);
      },


      //#endregion

    },
    beforeMount(){
        this.crearIntervalo();
    },
    mounted(){
        this.getFechaActual();
        this.openPanel = [0,1,2];
    },
    beforeDestroy () {
      clearInterval(this.interval);
    },
    
}
</script>

