<template>
    <v-card elevation="0">

        <v-card-text>
            <!--INICIO Formulario-->
            <v-form ref="form" v-model="valid" lazy-validation >
                <v-row>
                    <v-col cols="8" md="8">
                        <v-text-field
                            v-model="Beneficiario.NombreB"
                            :counter="80"
                            :rules="BeneficiarioRules.NombreB"
                            label="Nombre"
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4">
                        <v-btn color="error" class="mr-4" @click="reset" block outlined >
                            <v-icon> mdi-broom </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="selectCiudadB"
                            :items="listaCiudades"
                            :loading="selectCiudadBLoading"
                            item-text="Nombre"
                            item-value="Nombre"
                            :rules="[v => !!v || 'Es necesario seleccionar una ciudad.']"
                            label="Ciudad"
                            required
                            @change="selectDropDownCiudadB()"
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="Beneficiario.DomicilioB"
                            :counter="80"
                            :rules="BeneficiarioRules.DomicilioB"
                            label="Domicilio"
                            required
                            ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="3">
                        <v-text-field
                            v-model="Beneficiario.TelefonosB"
                            :counter="50"
                            :rules="BeneficiarioRules.TelefonosB"
                            label="Telefonos"
                            required
                            ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="2">
                        <v-text-field
                            v-model="Beneficiario.CPB"
                            :counter="10"
                            :rules="BeneficiarioRules.CPB"
                            label="C.P."
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            v-model="Beneficiario.ColoniaB"
                            :counter="40"
                            :rules="BeneficiarioRules.ColoniaB"
                            label="Col."
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            v-model="Beneficiario.CorreoB"
                            :rules="BeneficiarioRules.CorreoB"
                            label="Correo"
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="Beneficiario.AntecedentesB"
                            :counter="100"
                            :rules="BeneficiarioRules.AntecedentesB"
                            label="Antecedentes"
                            required
                            ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="Beneficiario.DiasServicioB"
                            :counter="50"
                            :rules="BeneficiarioRules.DiasServicioB"
                            label="Dias Servicio"
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="Beneficiario.HorarioB"
                            :counter="50"
                            :rules="BeneficiarioRules.HorarioB"
                            label="Horarios"
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            v-model="selectSexoB"
                            :items="listaSexos"
                            :loading="selectSexoBLoading"
                            :rules="[v => !!v || 'Es necesario seleccionar un sexo.']"
                            label="Sexo"
                            required
                            @change="selectDropDownSexoB()"
                            ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <v-menu
                            ref="menu"
                            v-model="menuFechaNacimiento"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="Beneficiario.FechaNacimientoB"
                                        label="Fecha Nacimiento"
                                        hint="AAAA/MM/DD Ej.: 2000/01/15"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        :rules="BeneficiarioRules.FechaNacimientoB"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    ref="picker"
                                    v-model="Beneficiario.FechaNacimientoB"
                                    :max="new Date().toISOString().substr(0, 10)"
                                    min="1900-01-01"
                                    locale="es-419"
                                    @change="saveFechaNacimiento()"
                                    ></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select
                            v-model="tipoEdad"
                            :items="listaTiposEdad"
                            :loading="selectTipoBLoading"
                            item-text="Nombre"
                            item-value="Nombre"
                            :rules="[v => !!v || 'Es necesario seleccionar un tipo de edad.']"
                            label="Tipo"
                            required
                            @change="selectDropDownTipoB()"
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            v-model="Beneficiario.EdadB"
                            :counter="3"
                            :rules="BeneficiarioRules.EdadB"
                            label="Edad"
                            required
                            ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn :disabled="!banderaUpdate" color="success" class="mr-4" 
                                block
                                @click="validate(operacionUpdate)" >
                            Modificar
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn :disabled="!valid||banderaUpdate" color="success" class="mr-4" 
                                block
                                @click="validate(operacionInsert)" >
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <!--FIN Formulario-->

            <!--INICIO Data Table-->
            <v-data-table
                v-model="selected"
                :headers="headersDatatable"
                :items="listaBeneficiarios"
                :single-select="singleSelect"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-text':'Beneficiarios por página',
                }"
                :loading="loadingDatatable"
                loading-text="Cargando..."
                no-data-text="Sin datos disponibles."
                item-key="Numero"
                class="elevation-0"
                @click:row="rowClick"
            > <!--show-select-->
            </v-data-table>
            <!--FIN Data Table-->

        </v-card-text>
        
    </v-card>
</template>

<script>
import {mapState} from "vuex";
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        tipoEdad:{
            get:function(){
                let TipoEdad = '';

                if(this.Beneficiario.EdadB > 59){
                    TipoEdad =  this.listaTiposEdad[2].Nombre;
                }
                else if(this.Beneficiario.EdadB > 16){
                    TipoEdad =  this.listaTiposEdad[1].Nombre;
                }
                else if(this.Beneficiario.EdadB > 0){
                    TipoEdad =  this.listaTiposEdad[0].Nombre;
                }
                else{
                    TipoEdad =  '';
                }
                this.Beneficiario.TipoB = TipoEdad;
                return TipoEdad;
            },
            set:function(){
                return '';
            }
        }
    },
    props:{
        Numero:Number,
        Contrato:Number,
        Beneficiarios:Number
    },
    data(){
        return{
            apiConfiguracion:new ApiConfiguracionService,
            api:new ApiContratosService,
            valid: true,
            operacionInsert:'INSERT',
            operacionUpdate:'UPDATE',
            banderaUpdate:false,
            menuFechaNacimiento:false,
            selected: [],
            singleSelect: true,
            loadingDatatable:true,
            headersDatatable:[
                { text: 'Contrato', value: 'Contrato' },
                { text: 'Nombre', value: 'NombreB' },
                { text: 'Telefonos', value: 'TelefonosB' },
                { text: 'Domicilio', value: 'DomicilioB' },
                { text: 'Colonia', value: 'ColoniaB' },
                { text: 'Ciudad', value: 'CiudadB' },
                { text: 'Sexo', value: 'SexoB' },
                { text: 'Tipo', value: 'TipoB' },
                { text: 'Edad', value: 'EdadB' }
            ],
            estatusBeneficiarioActivo:'ACTIVO',
            //estatusContratoActivo:'ACTIVO',
            estatusBeneficiarioCancelado:'CANCELADO',
            Beneficiario:{
                Numero:null,
                Contrato:null,

                NombreB:null,
                TelefonosB:null,
                DomicilioB:null,
                ColoniaB:null,
                CiudadB:null,
                CPB:null,
                CorreoB:null,
                AntecedentesB:null,
                DiasServicioB:null,
                HorarioB:null,
                SexoB:null,
                FechaNacimientoB:null,
                TipoB:null,
                EdadB:null,

                Usuario:null,
                FechaRegistro:null,
                HoraRegistro:null,
                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            BeneficiarioPrueba:{
                Numero:106,
                Contrato:93,

                NombreB: 'Rodrigo Monarrez',
                TelefonosB: '8712396593',
                DomicilioB: 'Flores 349',
                ColoniaB: 'Hamburgo',
                CiudadB:'GOMEZ PALACIO',
                CPB: '35018',
                CorreoB: 'spam@hotmail.com',
                AntecedentesB: 'No',
                DiasServicioB: 'Lun-Dom',
                HorarioB: '00:00-23:59',
                SexoB:'HOMBRE',
                FechaNacimientoB: '1993-08-22',
                TipoB: 'ADULTO',
                EdadB:27,
                
                Usuario: 'ADMINISTRADOR DEL SISTEMA',
                FechaRegistro:null,
                HoraRegistro:null,
                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            selectCiudadB:null,
            selectCiudadBLoading:true,
            selectTipoB:null,
            selectTipoBLoading:true,
            selectSexoB:null,
            selectSexoBLoading:false,
            BeneficiarioRules:{
                Numero:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Contrato:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 6) || 'El campo debe tener menos 6 caracteres.', 
                ],
                Tipo:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                EstatuB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                NombreB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                RFCB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 15) || 'El campo debe tener menos 15 caracteres.', 
                ],
                DomicilioB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                ColoniaB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 40) || 'El campo debe tener menos 40 caracteres.', 
                ],
                CiudadB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                CPB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                CorreoB:[
                    v => !!v || 'Correo es Requerido.',
                    v => /.+@.+\..+/.test(v) || 'El correo debe ser válido.',
                ],
                TelefonosB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El teléfono debe ser válido.',
                ],
                DiasServicioB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 50) || 'El campo debe tener menos 50 caracteres.', 
                ],
                HorarioB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 50) || 'El campo debe tener menos 50 caracteres.', 
                ],
                AntecedentesB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 100) || 'El campo debe tener menos 100 caracteres.', 
                ],
                SexoB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                FechaNacimientoB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                TipoB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                EdadB:[
                    v=> !!v || '' , 
                    v => /^([0-9])*$/.test(v) || 'La edad debe ser un número ser válido.',
                ],
                BeneficiarioB:[
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'Debe ser un número ser válido.',
                    v => (v && v.length <= 2) || 'El campo debe tener menos 2 caracteres.', 
                ],
                NombreC:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                TelefonosC:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El teléfono debe ser válido.',
                ],
                CorreoC:[ 
                    v => !!v || 'Correo es Requerido.',
                    v => /.+@.+\..+/.test(v) || 'El correo debe ser válido.',
                ],
                Usuario:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                FechaContrato:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                HoraContrato:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Extra1:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Extra2:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
                Extra3:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 10) || 'El campo debe tener menos 10 caracteres.', 
                ],
            },
            listaCiudades: [ 'Tórreon, Coah.', 'Gómez Palacio, Dgo.', 'Lerdo, Dgo.', ],
            listaSexos:[ '-', 'MUJER', 'HOMBRE', ],
            listaTiposEdad:[ 'PEDIATRICO', 'ADULTO MAYOR', 'ADULTO' ],
            listaBeneficiarios:[],
        }
    },

    methods:{
        
        //#region Funciones Locales
        emitiToComponentFather(NumeroContrato){
            this.$emit('setNumeroContrato', NumeroContrato);
        },
        clearBeneficiario(){
            this.Beneficiario = {
                //Numero:null,
                //Contrato:null,
                NombreB:null,
                TelefonosB:null,
                DomicilioB:null,
                ColoniaB:null,
                CiudadB:null,
                CPB:null,
                CorreoB:null,
                AntecedentesB:null,
                DiasServicioB:null,
                HorarioB:null,
                SexoB:null,
                FechaNacimientoB:null,
                TipoB:null,
                EdadB:null,
                //Usuario:null,
                FechaRegistro:null,
                HoraRegistro:null,
                Extra1:null,
                Extra2:null,
                Extra3:null
            }
        },
        calcularEdad(){
            var hoy = new Date();
            var cumpleanos = new Date(this.Beneficiario.FechaNacimientoB);
            var edad = hoy.getFullYear() - cumpleanos.getFullYear();
            var m = hoy.getMonth() - cumpleanos.getMonth();
            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--;
            }
            this.Beneficiario.EdadB = edad;
        },
        rowClick(value) {
            //console.log('rowClick', value);
            this.fillBeneficiario(value);
            this.banderaUpdate = true;
        },
        fillBeneficiario(item){
            //console.log('fillBeneficiario',item);            
            this.Beneficiario.Numero            = item.Numero;
            //this.Beneficiario.Contrato          = item.Contrato;
            this.Beneficiario.NombreB           = item.NombreB;
            this.Beneficiario.TelefonosB        = item.TelefonosB;
            this.Beneficiario.DomicilioB        = item.DomicilioB;
            this.Beneficiario.ColoniaB          = item.ColoniaB;            
            this.selectCiudadB                  = item.CiudadB; //this.Beneficiario.CiudadB
            this.Beneficiario.CPB               = item.CPB;
            this.Beneficiario.CorreoB           = item.CorreoB;
            this.Beneficiario.AntecedentesB     = item.AntecedentesB;
            this.Beneficiario.DiasServicioB     = item.DiasServicioB;
            this.Beneficiario.HorarioB          = item.HorarioB;
            this.selectSexoB                    = item.SexoB; //this.Beneficiario.SexoB
            this.Beneficiario.FechaNacimientoB  = item.FechaNacimientoB;
            this.Beneficiario.TipoB             = item.TipoB;
            this.Beneficiario.EdadB             = item.EdadB;
            this.Beneficiario.Usuario           = item.Usuario;
            this.Beneficiario.FechaRegistro     = item.FechaRegistro;
            this.Beneficiario.HoraRegistro      = item.HoraRegistro;
            this.Beneficiario.Extra1            = item.Extra1;
            this.Beneficiario.Extra2            = item.Extra2;
            this.Beneficiario.Extra3            = item.Extra3;
        },
        //#endregion
        
        //#region Formulario
        validate (operacion) {
            //console.log('Contrato', JSON.stringify(this.Contrato));
            const formValid = this.$refs.form.validate();
            console.log('formValid', formValid);

            if(formValid){
                if(operacion == this.operacionInsert){    
                    console.log('insert');
                    if( this.Beneficiarios > this.listaBeneficiarios.length ){
                        this.insertBeneficiario();                        
                    }
                    else{
                        console.log('Ya tiene el tope de beneficiarios. ');
                    }
                }
                else if(operacion == this.operacionUpdate){
                    console.log('update');
                    this.updateBeneficiario();
                }
            }
        },
        reset () { //Limpia todo
            this.$refs.form.reset();
            this.banderaUpdate = false;
        },
        resetValidation () { //Reestablece la validación sin quitar datos
            this.$refs.form.resetValidation();
        },
        saveFechaNacimiento (date) {
            this.$refs.menu.save(date);
            this.calcularEdad();
        },
        //#endregion
        
        //#region selects-Dropdown        
        selectDropDownCiudadB(){ this.Beneficiario.CiudadB = this.selectCiudadB; },
        selectDropDownTipoB(){ this.Beneficiario.TipoB = this.selectTipoB; },
        selectDropDownSexoB(){ this.Beneficiario.SexoB = this.selectSexoB; },
        //#endregion

        //#region Consumo Api-Configuracion
        getCiudades(){
            this.selectCiudadBLoading = true;
            this.apiConfiguracion.recuperaCiudad(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaCiudad.data", response.data);
                this.listaCiudades = response.data.objeto;

            })
            .catch(error=>{console.log(error);});
            this.selectCiudadBLoading = false;
        },
        getTiposEdad(){
            this.selectTipoBLoading = true;
            this.apiConfiguracion.recuperaTiposEdad(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaTiposEdad.data", response.data);
                this.listaTiposEdad = response.data.objeto;
            })
            .catch(error=>{console.log(error);});
            this.selectTipoBLoading = false;
        },
        //#endregion

        //#region Consumo Api-Contratos
        getBeneficiarios(){
            this.loadingDatatable = true;
            this.api.recuperaBeneficiarios(this.URLApi,
                this.Numero, this.Contrato, this.Beneficiario.NombreB)
            .then( response => {
                //console.log("getBeneficiarios.data", response.data);
                if(response.data.mensaje == ''){
                    this.listaBeneficiarios = response.data.objeto;
                    this.banderaUpdate = false;
                    this.loadingDatatable = false;
                }
            })
            .catch(error=>{console.log(error);});
        },
        insertBeneficiario(){
            //console.log('insertBeneficiario', this.Beneficiario);
            this.api.guardaBeneficiario(this.URLApi, 
                this.Numero, this.Contrato,
                this.Beneficiario.NombreB,  
                this.Beneficiario.TelefonosB,  this.Beneficiario.DomicilioB,  
                this.Beneficiario.ColoniaB,  this.Beneficiario.CiudadB,  
                this.Beneficiario.CPB,  this.Beneficiario.CorreoB,  this.Beneficiario.AntecedentesB,  
                this.Beneficiario.DiasServicioB,  this.Beneficiario.HorarioB,  this.Beneficiario.SexoB,  
                this.Beneficiario.FechaNacimientoB,  this.Beneficiario.TipoB,  this.Beneficiario.EdadB, 
                //this.Beneficiario.Usuario,  
                this.User.Nombre,
                this.Beneficiario.FechaRegistro,  this.Beneficiario.HoraRegistro, 
                this.Beneficiario.Extra1,  this.Beneficiario.Extra2,  this.Beneficiario.Extra3
            )
            .then( response =>{
                console.log("insertBeneficiario.data", response.data);
                if(response.data.objeto > 0){
                    //this.Beneficiario.Numero = response.data.objeto;
                    this.reset();
                    this.clearBeneficiario();
                    this.getBeneficiarios();
                    this.banderaUpdate = false;
                }
            })
            .catch(error=>{console.log(error);});
        },
        updateBeneficiario(){
            console.log('updateBeneficiario');
            this.api.modificaBeneficiario(this.URLApi, 
                this.Beneficiario.Numero, this.Contrato,
                this.Beneficiario.NombreB,  
                this.Beneficiario.TelefonosB,  this.Beneficiario.DomicilioB,  
                this.Beneficiario.ColoniaB,  this.Beneficiario.CiudadB,  
                this.Beneficiario.CPB,  this.Beneficiario.CorreoB,  this.Beneficiario.AntecedentesB,  
                this.Beneficiario.DiasServicioB,  this.Beneficiario.HorarioB,  this.Beneficiario.SexoB,  
                this.Beneficiario.FechaNacimientoB,  this.Beneficiario.TipoB,  this.Beneficiario.EdadB, 
                //this.Beneficiario.Usuario,  
                this.User.Nombre,
                this.Beneficiario.FechaRegistro,  this.Beneficiario.HoraRegistro, 
                this.Beneficiario.Extra1,  this.Beneficiario.Extra2,  this.Beneficiario.Extra3
            )
            .then( response =>{
                //console.log("updateBeneficiario.data", response.data);
                if(response.data.objeto > 0){
                    //this.Beneficiario.Numero = response.data.objeto;
                    this.reset();
                    this.getBeneficiarios();
                }
            })
            .catch(error=>{console.log(error);});

            this.banderaUpdate = false;
        },
        deleteBeneficiario(){

        },
        //#endregion
        
    },

    beforeMount(){
        this.Beneficiario.Usuario = this.User.Nombre;
    },

    mounted(){
        this.getCiudades();
        this.getTiposEdad();
        this.getBeneficiarios();
    },

    watch: {
      menuFechaNacimiento (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
      },
      selectCiudadB(val){
          //console.log('selectCiudadB', val);
          this.Beneficiario.CiudadB = val;
      },
      selectSexoB(val){
          //console.log('selectSexoB', val);
          this.Beneficiario.SexoB = val;
      },
      Contrato(val){
          //console.log('watch.Contrato', val);
          this.getBeneficiarios();
          this.clearBeneficiario();
      }



    },
}
</script>