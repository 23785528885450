var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"max-height":"150","max-width":"250","src":require("../assets/SAM.png")}})],1),_c('v-card-subtitle',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.fechaActualFormateada)+" --- "+_vm._s(_vm.horaActualFormateada)+" ")]),_c('v-card-text')],1)],1)],1),_c('v-row',[(!_vm.habilitaModulo)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":require("../assets/ambulancia.png")}}),_c('v-card-text',[_c('h2',{staticClass:"title blue--text"},[_vm._v(" Ambulancias ")]),_vm._v(" Revisa nuestro personal medico! ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#0d93fb"}},[_c('v-btn',{attrs:{"link":""},on:{"click":function($event){return _vm.openLoginDialog(_vm.linksConImagen.Ambulancias)}}},[_vm._v(" Ambulancias ")])],1):_vm._e()],1)],1)]}}],null,false,842960950)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":require("../assets/contrato.png")}}),_c('v-card-text',[_c('h2',{staticClass:"title blue--text"},[_vm._v(" Contratos ")]),_vm._v(" Manten en orden nuestros contratos! ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#0d93fb"}},[_c('v-btn',{attrs:{"link":""},on:{"click":function($event){return _vm.openLoginDialog(_vm.linksConImagen.Contratos)}}},[_vm._v(" Contratos ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":require("../assets/puntodeventa.png")}}),_c('v-card-text',[_c('h2',{staticClass:"title blue--text"},[_vm._v(" Punto de Venta ")]),_vm._v(" Revisa nuestro punto de venta! ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#0d93fb"}},[_c('v-btn',{attrs:{"link":""},on:{"click":function($event){return _vm.openLoginDialog(_vm.linksConImagen.PuntoVenta)}}},[_vm._v(" Punto de Venta ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":require("../assets/inventario.png")}}),_c('v-card-text',[_c('h2',{staticClass:"title blue--text"},[_vm._v(" Inventario ")]),_vm._v(" Mantén nuestro inventario en orden! ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#0d93fb"}},[_c('v-btn',{attrs:{"link":""},on:{"click":function($event){return _vm.openLoginDialog(_vm.linksConImagen.Inventario)}}},[_vm._v(" Inventario ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":require("../assets/pagos.png")}}),_c('v-card-text',[_c('h2',{staticClass:"title blue--text"},[_vm._v(" Pagos ")]),_vm._v(" Revisa los pagos de nuestros clientes! ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#0d93fb"}},[_c('v-btn',{attrs:{"link":""},on:{"click":function($event){return _vm.openLoginDialog(_vm.linksConImagen.Pagos)}}},[_vm._v(" Pagos ")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"344"}},[_c('v-img',{attrs:{"src":require("../assets/estudios.png"),"max-height":"195"}}),_c('v-card-text',[_c('h2',{staticClass:"title blue--text"},[_vm._v(" Estudios ")]),_vm._v(" Captura y realiza tus estudios clínicos! ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#0d93fb"}},[_c('v-btn',{attrs:{"link":""},on:{"click":function($event){return _vm.openLoginDialog(_vm.linksConImagen.Estudios)}}},[_vm._v(" Estudios ")])],1):_vm._e()],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-hover',[[_c('v-footer',{attrs:{"color":"blue lighten-1","padless":""}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},_vm._l((_vm.links),function(link){return _c('v-btn',{key:link.clave,staticClass:"my-2",attrs:{"color":"white","text":"","rounded":""},on:{"click":function($event){return _vm.openLoginDialog(link)}}},[_vm._v(" "+_vm._s(link.texto)+" ")])}),1)],1)]],2)],1)],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.loginDialog),callback:function ($$v) {_vm.loginDialog=$$v},expression:"loginDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" SAM ")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.loginDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close ")])],1)]}}])})],1),_c('v-card-subtitle',[_vm._v(" Ir a "+_vm._s(_vm.loginComponentObject.loginTexto)+" ")]),_c('v-card-text',[_c('LoginFormComponent',{attrs:{"textoRuta":_vm.loginComponentObject.loginClave,"Ruta":_vm.loginComponentObject.loginRuta}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }