<template>
    <div>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="listaCrum"
            :single-select="singleSelect"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-text':'',
            }"
            loading-text="Cargando..."
            :loading="loadingDatatable"
            item-key="Numero"
            class="elevation-1"
            @click:row="handleClick"
        ></v-data-table>
    </div>
</template>

<script>
import {mapState} from "vuex";
import ApiService from '@/services/ApiAmbulanciasService';

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi']),
    },
    data(){
        return{
            api:new ApiService(),
            totalServicios:0,
            oCrum:{
                numero : 0,
                contrato : 0,
                estatus : 'ACTIVO' //CANCELADO Y TERMINADO
            },
            listaCrum:[],

            selected: [],
            singleSelect: true,
            loadingDatatable:true,
            headers:[
                { text: 'Numero', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Contrato', value: 'Contrato' },
                
                { text: 'HoraLlamada', value: 'HoraLlamada' },
                { text: 'Despacho', value: 'Despacho' },
                { text: 'Salida', value: 'Salida' },
                { text: 'Llegada', value: 'Llegada' },
                { text: 'Traslado', value: 'Traslado' },
                { text: 'Hospital', value: 'Hospital' },
                { text: 'Promedica', value: 'MS' },

                { text: 'Paciente', value: 'Paciente' },
                { text: 'Telefonos', value: 'Telefonos' },
                { text: 'Domicilio', value: 'Domicilio' },
                { text: 'Socio', value: 'Socio' },
            ],
        }
    },
    methods:{
        pasarTotal(){
            //console.log('setTotalServicios', this.totalServicios);
            this.$emit('setTotalServicios', this.totalServicios);
            
        },

        handleClick(value) {
            //console.log('handleClick', value);
            this.goAmbulancias(value);
        },

        getCrum(){
            //Obtiene los servicios
            this.loadingDatatable = true;
            this.api.recuperaCrum(  this.URLApi, 
                                    this.oCrum.numero, this.oCrum.contrato, this.oCrum.estatus )
            .then( response =>{
                //console.log('getCrum.data',response.data);
                if(response.data.objeto){
                    this.listaCrum = response.data.objeto;
                    this.totalServicios = this.listaCrum.length;
                }
                else{
                    this.listaCrum = [];
                    this.totalServicios = 0;
                }
                this.pasarTotal();
                this.loadingDatatable = false;
            })
            .catch(error=>{console.log(error);});
        },

        goAmbulancias(itemCrum){            
            const Beneficiario = ( itemCrum.Beneficiario == '' ) ? '_' : itemCrum.Beneficiario;
            const Estatus = itemCrum.Estatus;
            const ruta = `/operacion/ambulancias/${ itemCrum.Contrato }/${ itemCrum.Numero }/${ Estatus }`;
            //console.log('ruta', ruta);
            this.$router.push(ruta);
        },
    },
    beforeMount(){
       this.getCrum();
    },
    mounted(){
        this.pasarTotal();
    }
    
}
</script>