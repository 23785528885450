<template>
    <v-container class="mt-12">

        <v-row>
            <v-col offset-sm="2" cols="8" offset-md="2" md="8">
                <v-card elevation="5">
                    <v-card-title class="d-flex justify-center">
                        <v-img max-height="150" max-width="250" src="@/assets/SAM.png"></v-img>
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center">
                        
                    </v-card-subtitle>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Nombre"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="12" md="12">
                                <form   id="payment-form" 
                                    @submit.prevent="createToken()" >
                                    <div class="form-row">                        

                                        <div id="card-element">
                                            <!-- A Stripe Element will be inserted here. -->
                                        </div>

                                        <!-- Used to display Element errors. -->
                                        <div id="card-errors" role="alert"></div>
                                    </div>
                                    <v-divider></v-divider>

                                    <v-btn
                                        color="blue"
                                        class="white--text mt-4"
                                        block
                                        @click="createToken()"
                                        >
                                        Pagar $ {{ calculaMontoTotal }}
                                    </v-btn>
                                </form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogPagado.show">
            <v-card>
                <v-card-title>
                    <span > {{ dialogPagado.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogPagado.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            Folio {{ oCredito.IdCredito }} pagado.
                        </v-col>
                        <v-col cols="12" md="12">
                            Cantidad pagada ${{ calculaMontoTotal }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-btn
                                color="orange"
                                class="white--text"
                                block
                                to="/"
                                >
                                Volver
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-dialog>
        
        

        <v-snackbar v-model="snackBar.show" :timeout="snackBar.timeout" :color="snackBar.color">
            {{ snackBar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackBar.colorCerrar"
                    text
                    v-bind="attrs"
                    @click="showSnackbar = false" >
                <v-icon> mdi-close </v-icon>
                </v-btn>

            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import ApiCreditoService from '@/services/ApiCreditoService';

export default {
    //#region ---- Computed ----
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN', 'StripePK']),
        calculaMontoTotal(){
            const total =  Number.parseFloat(this.oCredito.MontoTotal) + Number.parseFloat(this.oCredito.CargoMoratorio);
            return total.toFixed(2);
        }
    },
    //#endregion
    
    //#region ---- Data ----
    data(){
        return{
            apiCredito: new  ApiCreditoService(),
            //------------------------------------------------
            dialogStripe:{
                show:false,
            },
            stripe:{},
            elements:{},
            card:{},
            stripeToken:{},
            snackBar:{
                show:false,
                timeout:5000,
                text:'',
                color:'#000000',
                colorCerrar:'white'
            },
            oCredito:{ },
            dialogPagado:{
                show:false,
                text:''
            }
        }
    },
    //#endregion
    //#region ---- Methods ----
    methods:{
        //#region Metodos de la vista
        sumaCreditoMoratorio(MontoTotal, CargoMoratorio){
            const monto =  Number.parseFloat(MontoTotal);
            const cargo =  Number.parseFloat(CargoMoratorio);
            return monto + cargo;
        },
        //#endregion
        
        //#region metodos de stripe
         async createToken(){
            let stripeResponse = await this.stripe.createToken(this.card).then(function(result) {
                if (result.error) {
                    // Inform the customer that there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    return { token: null, error: errorElement.textContent};
                } else {
                    // Send the token to your server.
                    //console.log('createToken',result.token);
                    return { token: result.token, error:null };
                }
            });
            console.log('stripeResponse', stripeResponse);
            if(stripeResponse.token){
                this.stripeTokenHandler(stripeResponse.token);
            }
        },
        stripeTokenHandler(token){
            
            //let formData = new FormData();
            //formData.append('token', token.id);
            //formData.append('idCotizacion', this.idCotizacion);
            //console.log('token.id',token.id);
            //return;
            this.apiCredito.pagarCredito(this.URLApi, token.id, this.oCredito.IdCredito, this.calculaMontoTotal)
            //await axios.post(urlPago, formData)
            .then( response => { 
                //console.log('pagoStripeCard.data', response.data);
                if(response.data.objeto.paid){
                    console.log('Pago hecho correctamente');

                    this.dialogPagado.show = true
                    //this.snackBar.text = 'Pay sucess';
                    //this.snackBar.color = 'green';
                    //this.snackBar.colorCerrar = 'white';
                    //this.snackBar.show = true;
                    /* setTimeout(() => {
                        this.$router.go(-1);
                    }, 1200); */
                }
                else{
                    /* this.snackBar.text = response.data.mensaje;
                    this.snackBar.color = 'red';
                    this.snackBar.colorCerrar = 'white';
                    this.snackBar.show = true; */
                    console.log('Error en el pago');
                }
            })
            .catch( error => console.log(error));
        },
        //#endregion
        //#region metodos api
        getCredito(IdCredito){
            this.apiCredito.recuperaCredito(this.URLApi, null, IdCredito)
            .then( response => {
                console.log('recuperaCredito.data', response.data);

                this.oCredito = response.data.objeto;
                
            })
            .catch( error => console.log( error ) );
        },
        //#endregion
    },
    //#endregion
    //#region ---- mounted ----
    mounted(){
        // Set your publishable key: remember to change this to your live publishable key in production
        // See your keys here: https://dashboard.stripe.com/account/apikeys
        this.stripe = Stripe(this.StripePK);
        this.elements = this.stripe.elements();
        // Custom styling can be passed to options when creating an Element.
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: '#32325d',
            },
        };
        // Create an instance of the card Element.
        this.card = this.elements.create('card', {style: style});
        // Add an instance of the card Element into the `card-element` <div>.
        this.card.mount('#card-element');
    },
    //#endregion
    
    //#region beforeMount
    beforeMount(){
        this.getCredito( this.$route.params.IdCredito );
    }
    //#endregion
}
</script>