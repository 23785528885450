import axios from 'axios';

const _Controller = "EstudiosLab"; 
export default class ApiEstudiosService {

     

    //#region Estudios
    recuperaCrum(url, Numero, Contrato, Estatus, FechaDesde=undefined, FechaHasta=undefined){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Estatus,
            FechaDesde,
            FechaHasta
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    recuperaEstudioUnico(url, Empresa, ClaveUnica){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'recuperaEstudioUnico';
    
        //Construccion de objeto a enviar
        const objeto = { 
            ClaveUnica
            ,Empresa
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    recuperaEstudios(url, Numero, Contrato, Estatus, FechaDesde, FechaHasta){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
            , Contrato
            , Estatus
            , FechaDesde
            , FechaHasta
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }



    getListaEstudios(Empresa){
        let listaEstudios=[];

        if(Empresa == 'EMA'){
            listaEstudios = [
                { value:'CERTIFICADO_MEDICO'            , text:'Certificado Médico'  },
                { value:'CERTIFICADO_COVID'             , text:'No Covid'  },
                { value:'TOXICOLOGIA'                   , text:'Toxicologia'  },
                { value:'CONSENTIMIENTO_ANTIDOPING'     , text:'Consentimiento Antidoping'  },
                { value:'ANTIDOPING'                    , text:'Antidoping'  },
                { value:'AUDIOMETRIA'                   , text:'Audiometría'  },
                { value:'HOLCIM'                        , text:'Holcim'  },
                { value:'HISTORIAL_CLINICO'             , text:'Historial Clínico'  },
                { value:'APTITUD_FISICA'                , text:'Aptitud Física'  },
                { value:'RIESGO_BIOLOGICO'              , text:'Riesgo Biologico'  },
                { value:'EXAMEN_MEDICO'                 , text:'Examen Médico'  },
                { value:'HOJA_LABORATORIO'              , text:'Hoja de Laboratorio'  },
                { value:'INGRESO_OUTSOURCING'           , text:'Ingreso Global'  },
                { value:'INGRESO_NORMAL'                , text:'Ingreso Normal'  },
                { value:'COVID_SANGRE'                  , text:'Prueba rápida de Covid'  },
                { value:'CRITERIOS_INGRESO_CHOFERES'    , text:'Ingreso Choferes'  },
                { value:'RADIOLOGICO'                   , text:'Reporte Radiológico'  }
            ]
        }

        if(Empresa == 'PROMEDICA'){
            listaEstudios = [
                { value:'QUIMICA_SANGUINEA'         , text:'Química Sanguínea'  },
                { value:'BIOMETRIA_HEMATICA'        , text:'Biometria Hematica'  },
                { value:'PERFIL_LIPIDOS'            , text:'Perfil de lipídos'  },
                { value:'ANTICUERPOS_ANTI_HIV_1Y2'  , text:'Anticuerpos Anti HIV 1 Y 2'  },
                { value:'TRANSAMINASAS_SERICAS'     , text:'Transaminasas Sericas'  },
                { value:'VDRL'                      , text:'VDRL'  },
                { value:'ANTIGENO_PROSTATICO'       , text:'Antígeno Prostático'  },
                { value:'PRUEBA_EMBARAZO'           , text:'Prueba de Embarazo'  },
                { value:'GPO_RH'                    , text:'GPO Y RH'  },
                { value:'REACCIONES_FEBRILES'       , text:'Reacciones Febriles'  },
                { value:'PRUEBA_KOH'                , text:'Prueba de KOH'  },
                { value:'CULTIVO_FARINGEO'          , text:'Cultivo Faríngeo'  },
                { value:'PRUEBAS_REUMATICAS'        , text:'Pruebas Reumaticas'  },
                { value:'COPROPARASITOSCOPICO'      , text:'Coproparasitoscópico'  },
                { value:'PRUEBAS_FUNCION_HEPATICA'  , text:'Pruebas de Función Hepatica'  },
                { value:'HEMOGLOBINA_GLICOSILADA'   , text:'Hemoglobina Glicosilada'  },
                { value:'COVID_19'                  , text:'Covid 19'  },
                { value:'DETECCION_DENGUE'          , text:'Deteccion de Dengue'  },
                { value:'PLOMO_SANGRE'              , text:'Plomo en Sangre'  },
                { value:'PRUEBA_DIMERO_PLASMA'      , text:'Prueba Dímero D en Plasma'  },
                { value:'FERRITINA_SUERO'           , text:'Ferritina en Suero'  },
                { value:'PRUEBA_INFLUENZA'          , text:'Prueba de Influenza'  },
                { value:'ELECTROLITOS_SERICOS'      , text:'Electrolitos Séricos'  },
                { value:'TIEMPOS_COAGULACION'       , text:'Tiempos Coagulación'  },
                { value:'EXAMEN_ORINA'              , text:'Examen General de Orina'  },
            ]
        }

        return listaEstudios;
    }

    guardaEstudioLab( url
                ,Contrato
                ,Socio
                ,Solicitante
                ,Paciente
                ,TipoEstudio
                ,Medico
                ,Sexo
                ,Edad
                ,Estatura
                ,Peso
                ,Oxigenacion = ''
                ,NumLatidos = ''
                ,Doping = ''
                ,Metanfetamina = ''
                ,Marihuana = ''
                ,Cocaina = ''
                ,Glucosa = ''
                ,Bun = ''
                ,Urea = ''
                ,Creatinina = ''
                ,Colesterol = ''
                ,Trigliceridos = ''
                ,AcidoUrico = ''
                ,Hemoglobina  = ''
                ,Hematocrito  = ''
                ,GlobulosRojos  = ''
                ,VCM  = ''
                ,HCM  = ''
                ,CHCM  = ''
                ,GlobulosBlancos  = ''
                ,Linfocitos  = ''
                ,Monocitos  = ''
                ,Eosinofilos  = ''
                ,Basofilos  = ''
                ,Segmentados  = ''
                ,EnBanda  = ''
                ,Plaquetas  = ''
                ,ColesterolTotal = ''
                ,ColesterolHDL = ''
                ,ColesterolLDL = ''
                ,ColesterolVLDL = ''
                ,LipidosTotales = ''
                ,AnticuerposHIV12 = ''
                ,GGT = ''
                ,TGO = ''
                ,TGP = ''
                ,AnticuerposIGG = ''
                ,AnticuerposIGM = ''
                ,AnticuerposAntitreponemicosInespecificos = ''
                ,AntigenoProstaticoResultado = ''
                ,embarazo_HormonaGonadotropinaCorionica = ''
                //-------------------------------------------
                ,GpoRh_FactorRH = ''
                //-------------------------------------------
                ,RFebriles_AntigenoParatificoA = ''
                ,RFebriles_AntigenoParatificoB = ''
                ,RFebriles_AntigenoTificoH = ''
                ,RFebriles_AntigenoTificoO = ''
                ,RFebriles_AntigenoProteusOX19 = ''
                ,RFebriles_AntigenoBrucellaAbortus = ''
                //-------------------------------------------
                ,KOH_MuestraUnias = ''
                //-------------------------------------------
                ,CulFaringeo_Cultivo = ''
                ,CulFaringeo_Observaciones = ''
                //-------------------------------------------
                ,Preumaticas_Antiestreptolisina = ''
                ,Preumaticas_FactorReumatoide = ''
                ,Preumaticas_ProteinaCReactiva = ''
                //-------------------------------------------
                ,Coproparasitoscopico_Muestra1 = ''
                //-------------------------------------------
                ,FuncHepatica_GGT = ''
                ,FuncHepatica_TGO = ''
                ,FuncHepatica_TGP = ''
                ,FuncHepatica_FosfatasaAlcalina = ''
                ,FuncHepatica_BilirrubinaTotal = ''
                ,FuncHepatica_BilirrubinaDirecta = ''
                ,FuncHepatica_BilirrubinaIndirecta = ''
                ,FuncHepatica_ProteinasTotales = ''
                ,FuncHepatica_Albumina = ''
                ,FuncHepatica_Globulina = ''
                ,FuncHepatica_RelacionAG = ''
                //-------------------------------------------
                ,Hemoglobina_HemoglobinaGlicosilada = ''
                //-------------------------------------------
                ,DeteccionDengue_IgG = ''
                ,DeteccionDengue_IgM = ''
                ,DeteccionDengue_AgNS1 = ''
                //-------------------------------------------
                ,PlomoSangre_PlomoSangre = ''
                //-------------------------------------------
                ,PruebaDimero_DimeroD = ''
                //-------------------------------------------
                ,FerritinaSuero_Ferritina = ''
                //-------------------------------------------
                ,PruebaInfluenza_TipoA = ''
                ,PruebaInfluenza_TipoB = ''
                ,PruebaInfluenza_AH1N1 = ''
                //-------------------------------------------
                ,ElectrolitosSericos_Cloro = ''
                ,ElectrolitosSericos_Sodio = ''
                ,ElectrolitosSericos_Potasio = ''
                ,ElectrolitosSericos_Calcio = ''
                ,ElectrolitosSericos_Fosforo = ''
                ,ElectrolitosSericos_Magnesio = ''
                //-------------------------------------------
                ,TiemposCoagulacion_TiempoProtombina = ''
                ,TiemposCoagulacion_TiempoPromboplatinaParcial = ''
                ,TiemposCoagulacion_INR = ''
                //-------------------------------------------
                ,ExGOrina_Macro_Color = ''
                ,ExGOrina_Macro_Aspecto = ''
                ,ExGOrina_FisicoQumico_PH = ''
                ,ExGOrina_FisicoQumico_Densidad = ''
                ,ExGOrina_FisicoQumico_Glucosa = ''
                ,ExGOrina_FisicoQumico_Bilirrubinas = ''
                ,ExGOrina_FisicoQumico_Cetonas = ''
                ,ExGOrina_FisicoQumico_SangreHB = ''
                ,ExGOrina_FisicoQumico_Proteinas = ''
                ,ExGOrina_FisicoQumico_Urobilinogeno = ''
                ,ExGOrina_FisicoQumico_Nitritos = ''
                ,ExGOrina_FisicoQumico_Leucocitos = ''
                ,ExGOrina_Cristaluria_UratosAmorfos = ''
                ,ExGOrina_Cristaluria_AcidoUrico = ''
                ,ExGOrina_Cristaluria_OxalatosCalcicos = ''
                ,ExGOrina_Cristaluria_FosfatosAmorfos = ''
                ,ExGOrina_Cristaluria_FosfatosTriples = ''
                ,ExGOrina_MicroSedimiento_Celularidad = ''
                ,ExGOrina_MicroSedimiento_Uretral = ''
                ,ExGOrina_MicroSedimiento_Urotelial = ''
                ,ExGOrina_MicroSedimiento_Renal = ''
                ,ExGOrina_MicroSedimiento_Leucocitos = ''
                ,ExGOrina_MicroSedimiento_Eritrocitos = ''
                ,ExGOrina_MicroSedimiento_PorcNormorficos = ''
                ,ExGOrina_MicroSedimiento_PorcDismorficos = ''
                ,ExGOrina_MicroSedimiento_Bacterias = ''
                ,ExGOrina_CitolisisBacteriana = ''
                ,ExGOrina_Piocitos = ''
                ,ExGOrina_Espermas = ''
                ,ExGOrina_Levaduras = ''
                ,ExGOrina_Parasitos = ''
                ,ExGOrina_FMucoides = ''
                ,ExGOrina_Cilindros = ''
        ){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Contrato
            ,Socio
            ,Solicitante
            ,Paciente
            ,TipoEstudio
            ,Medico
            ,Sexo
            ,Edad
            ,Estatura
            ,Peso
            ,Oxigenacion
            ,NumLatidos 
            ,Doping
            ,Metanfetamina
            ,Marihuana
            ,Cocaina
            ,Glucosa
            ,Bun
            ,Urea
            ,Creatinina
            ,Colesterol
            ,Trigliceridos
            ,AcidoUrico
            ,Hemoglobina
            ,Hematocrito
            ,GlobulosRojos
            ,VCM
            ,HCM
            ,CHCM
            ,GlobulosBlancos
            ,Linfocitos
            ,Monocitos
            ,Eosinofilos
            ,Basofilos
            ,Segmentados
            ,EnBanda
            ,Plaquetas
            ,ColesterolTotal
            ,ColesterolHDL
            ,ColesterolLDL
            ,ColesterolVLDL
            ,LipidosTotales
            ,AnticuerposHIV12
            ,GGT
            ,TGO
            ,TGP
            ,AnticuerposIGG
            ,AnticuerposIGM
            ,AnticuerposAntitreponemicosInespecificos
            ,AntigenoProstaticoResultado
            ,embarazo_HormonaGonadotropinaCorionica
            //-------------------------------------------
            ,GpoRh_FactorRH
            //-------------------------------------------
            ,RFebriles_AntigenoParatificoA
            ,RFebriles_AntigenoParatificoB
            ,RFebriles_AntigenoTificoH
            ,RFebriles_AntigenoTificoO
            ,RFebriles_AntigenoProteusOX19
            ,RFebriles_AntigenoBrucellaAbortus
            //-------------------------------------------
            ,KOH_MuestraUnias
            //-------------------------------------------
            ,CulFaringeo_Cultivo
            ,CulFaringeo_Observaciones
            //-------------------------------------------
            ,Preumaticas_Antiestreptolisina
            ,Preumaticas_FactorReumatoide
            ,Preumaticas_ProteinaCReactiva
            //-------------------------------------------
            ,Coproparasitoscopico_Muestra1
            //-------------------------------------------
            ,FuncHepatica_GGT
            ,FuncHepatica_TGO
            ,FuncHepatica_TGP
            ,FuncHepatica_FosfatasaAlcalina
            ,FuncHepatica_BilirrubinaTotal
            ,FuncHepatica_BilirrubinaDirecta
            ,FuncHepatica_BilirrubinaIndirecta
            ,FuncHepatica_ProteinasTotales
            ,FuncHepatica_Albumina
            ,FuncHepatica_Globulina
            ,FuncHepatica_RelacionAG
            //-------------------------------------------
            ,Hemoglobina_HemoglobinaGlicosilada
            //-------------------------------------------
            ,DeteccionDengue_IgG
            ,DeteccionDengue_IgM
            ,DeteccionDengue_AgNS1
            //-------------------------------------------
            ,PlomoSangre_PlomoSangre
            //-------------------------------------------
            ,PruebaDimero_DimeroD
            //-------------------------------------------
            ,FerritinaSuero_Ferritina
            //-------------------------------------------
            ,PruebaInfluenza_TipoA
            ,PruebaInfluenza_TipoB
            ,PruebaInfluenza_AH1N1
            //-------------------------------------------
            ,ElectrolitosSericos_Cloro
            ,ElectrolitosSericos_Sodio
            ,ElectrolitosSericos_Potasio
            ,ElectrolitosSericos_Calcio
            ,ElectrolitosSericos_Fosforo
            ,ElectrolitosSericos_Magnesio
            //-------------------------------------------
            ,TiemposCoagulacion_TiempoProtombina
            ,TiemposCoagulacion_TiempoPromboplatinaParcial
            ,TiemposCoagulacion_INR
            //-------------------------------------------
            ,ExGOrina_Macro_Color
            ,ExGOrina_Macro_Aspecto
            ,ExGOrina_FisicoQumico_PH
            ,ExGOrina_FisicoQumico_Densidad
            ,ExGOrina_FisicoQumico_Glucosa
            ,ExGOrina_FisicoQumico_Bilirrubinas
            ,ExGOrina_FisicoQumico_Cetonas
            ,ExGOrina_FisicoQumico_SangreHB
            ,ExGOrina_FisicoQumico_Proteinas
            ,ExGOrina_FisicoQumico_Urobilinogeno
            ,ExGOrina_FisicoQumico_Nitritos
            ,ExGOrina_FisicoQumico_Leucocitos
            ,ExGOrina_Cristaluria_UratosAmorfos
            ,ExGOrina_Cristaluria_AcidoUrico
            ,ExGOrina_Cristaluria_OxalatosCalcicos
            ,ExGOrina_Cristaluria_FosfatosAmorfos
            ,ExGOrina_Cristaluria_FosfatosTriples
            ,ExGOrina_MicroSedimiento_Celularidad
            ,ExGOrina_MicroSedimiento_Uretral
            ,ExGOrina_MicroSedimiento_Urotelial
            ,ExGOrina_MicroSedimiento_Renal
            ,ExGOrina_MicroSedimiento_Leucocitos
            ,ExGOrina_MicroSedimiento_Eritrocitos
            ,ExGOrina_MicroSedimiento_PorcNormorficos
            ,ExGOrina_MicroSedimiento_PorcDismorficos
            ,ExGOrina_MicroSedimiento_Bacterias
            ,ExGOrina_CitolisisBacteriana
            ,ExGOrina_Piocitos
            ,ExGOrina_Espermas
            ,ExGOrina_Levaduras
            ,ExGOrina_Parasitos
            ,ExGOrina_FMucoides
            ,ExGOrina_Cilindros
        };

        //console.log("guarda", objeto);

        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaCrum( url,
                Numero, Contrato, Socio,
                Tipo, Fecha, FechaHora,
                HoraLlamada, Usuario, Despacho,
                Salida, Llegada, Traslado,
                Hospital, Libre, MS,
                Solicitante, Edad, Sexo,
                Beneficiario, Paciente,
                Telefonos, Domicilio, Signos,
                TipoServicio, Triage, Equipo,
                Horario, NumeroLab, Diagnostico,
                Tratamiento, Observaciones, 
                PadecimientoActual, TratamientoActual,
                InicioSintomas, ExploracionFisica,
                SV_TA , SV_FR , SV_TEMP , SV_FC , SV_SAT , SV_PESO ,
                AntecedentesPersonalesPato, EstadoCivil,
                GlasgowMotora, GlasgowRespuestaVerbal, GlasgowAperturaOcular,
                Estatus,
                Cancelacion, Destino, Viaje,
                Extra1, Extra2, Extra3,
                Medico, Mes, Ano 
        ){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Socio,
            Tipo,
            Fecha,
            FechaHora,
            HoraLlamada,
            Usuario,
            Despacho,
            Salida,
            Llegada,
            Traslado,
            Hospital,
            Libre,
            MS,
            Solicitante,
            Edad,
            Sexo,
            Beneficiario,
            Paciente,
            Telefonos,
            Domicilio,
            Signos,
            TipoServicio,
            Triage,
            Equipo,
            Horario,
            NumeroLab,
            Diagnostico,
            Tratamiento,
            Observaciones,
            PadecimientoActual,
            TratamientoActual, 
            InicioSintomas, 
            ExploracionFisica,
            SV_TA , 
            SV_FR , 
            SV_TEMP , 
            SV_FC , 
            SV_SAT , 
            SV_PESO ,
            AntecedentesPersonalesPato , 
            EstadoCivil ,
            GlasgowMotora , 
            GlasgowRespuestaVerbal , 
            GlasgowAperturaOcular ,
            Estatus,
            Cancelacion,
            Destino,
            Viaje,
            Extra1,
            Extra2,
            Extra3,
            Medico,
            Mes,
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    terminaTrabajoCrum( url,
                Numero, Contrato, Socio,
                Tipo, Fecha, FechaHora,
                HoraLlamada, Usuario, Despacho,
                Salida, Llegada, Traslado,
                Hospital, Libre, MS,
                Solicitante, Edad, Sexo,
                Beneficiario, Paciente,
                Telefonos, Domicilio, Signos,
                TipoServicio, Triage, Equipo,
                Horario, NumeroLab, Diagnostico,
                Tratamiento, Observaciones, 
                PadecimientoActual, TratamientoActual,
                InicioSintomas, ExploracionFisica,
                SV_TA , SV_FR , SV_TEMP , SV_FC , SV_SAT , SV_PESO ,
                AntecedentesPersonalesPato ,  EstadoCivil ,
                GlasgowMotora ,  GlasgowRespuestaVerbal ,  GlasgowAperturaOcular ,
                Estatus,
                Cancelacion, Destino, Viaje,
                Extra1, Extra2, Extra3,
                Medico, Mes, Ano 
        ){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'terminaTrabajo';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Socio,
            Tipo,
            Fecha,
            FechaHora,
            HoraLlamada,
            Usuario,
            Despacho,
            Salida,
            Llegada,
            Traslado,
            Hospital,
            Libre,
            MS,
            Solicitante,
            Edad,
            Sexo,
            Beneficiario,
            Paciente,
            Telefonos,
            Domicilio,
            Signos,
            TipoServicio,
            Triage,
            Equipo,
            Horario,
            NumeroLab,
            Diagnostico,
            Tratamiento,
            Observaciones,
            PadecimientoActual,
            TratamientoActual, 
            InicioSintomas, 
            ExploracionFisica,
            SV_TA , 
            SV_FR , 
            SV_TEMP , 
            SV_FC , 
            SV_SAT , 
            SV_PESO ,
            AntecedentesPersonalesPato , 
            EstadoCivil ,
            GlasgowMotora , 
            GlasgowRespuestaVerbal , 
            GlasgowAperturaOcular ,
            Estatus,
            Cancelacion,
            Destino,
            Viaje,
            Extra1,
            Extra2,
            Extra3,
            Medico,
            Mes,
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaCrum(url, Numero){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    generaReporte(url, Numero, TipoEstudio, Empresa){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'generaReporte';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
            ,TipoEstudio
            ,Empresa
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);

    }

    guardaEstudioDinamico(url, 
        Contrato
        ,Socio
        ,Solicitante
        ,Paciente
        ,EstudioId
        ,Medico
        ,Sexo
        ,Edad
        ,Estatura
        ,Peso
        ,listaPropiedades){
        //Definicion de ruta
        const method = 'guardaEstudioDinamico';
     
        //Construccion de objeto a enviar
        const objeto = {
            Contrato
            ,Socio
            ,Solicitante
            ,Paciente
            ,EstudioId
            ,Medico
            ,Sexo
            ,Edad
            ,Estatura
            ,Peso
            ,listaPropiedades
        };

        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        return axios.post(`${ url }/${ _Controller }/${ method }`, json);

    }

    generaEstudioDinamico(url, EstudioPacienteId){
        //Definicion de ruta
        const controller = _Controller;
        const method = 'generaEstudioDinamico';
    
        //Construccion de objeto a enviar
        const objeto = { 
            EstudioPacienteId
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);

    }
    //#endregion


}