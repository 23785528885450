<template>
    <div>
        <HeaderAppComponent :tituloPagina="tituloPagina" />
        <v-main>
            <v-tabs v-model="tab" >
                <v-tab v-for="item of tabs" :key="item.tab" >
                    {{ item.title }}
                </v-tab>

                <v-tabs-items v-model="tab">
                    <v-tab-item> <CiudadesComponent></CiudadesComponent>  </v-tab-item>
                    <v-tab-item> <EquiposComponent></EquiposComponent> </v-tab-item>
                    <v-tab-item> <HorariosComponent></HorariosComponent> </v-tab-item>
                    <v-tab-item> <MedicosComponent></MedicosComponent> </v-tab-item>
                    <v-tab-item> <TiposEdadComponent></TiposEdadComponent> </v-tab-item>
                    <v-tab-item> <TiposServicioComponent></TiposServicioComponent> </v-tab-item>
                    <v-tab-item> <TriageInicialComponent></TriageInicialComponent> </v-tab-item>
                </v-tabs-items>

            </v-tabs>
        </v-main>
    </div>
</template>

<script>
import HeaderAppComponent from '@/components/HeaderAppComponent'

import CiudadesComponent from '@/components/Configuracion/CiudadesComponent';
import EquiposComponent from '@/components/Configuracion/EquiposComponent';
import HorariosComponent from '@/components/Configuracion/HorariosComponent';
import MedicosComponent from '@/components/Configuracion/MedicosComponent';
import TiposEdadComponent from '@/components/Configuracion/TiposEdadComponent';
import TiposServicioComponent from '@/components/Configuracion/TiposServicioComponent';
import TriageInicialComponent from '@/components/Configuracion/TriageInicialComponent';

export default {
    components:{
        HeaderAppComponent,
        CiudadesComponent,
        EquiposComponent,
        HorariosComponent,
        MedicosComponent,
        TiposEdadComponent,
        TiposServicioComponent,
        TriageInicialComponent
    },
    data(){
        return{
            tituloPagina:'Configuración',
            tab:null,
            tabs:[
                { tab:0, title:'Ciudades', content:'<CiudadesComponent></CiudadesComponent>' },
                { tab:1, title:'Equipos', content:'<EquiposComponent></EquiposComponent>' },
                { tab:2, title:'Horarios', content:'<HorariosComponent></HorariosComponent>' },
                { tab:3, title:'Medicos', content:'<MedicosComponent></MedicosComponent>' },
                { tab:4, title:'Tipos de Edad', content:'<TiposEdadComponent></TiposEdadComponent>' },
                { tab:5, title:'Tipos Servicio', content:'<TiposServicioComponent></TiposServicioComponent>' },
                { tab:6, title:'Triage Inicial', content:'<TriageInicialComponent></TriageInicialComponent>' }
            ]
        }
    }
}
</script>