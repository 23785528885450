<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="4" class="caption d-flex justify-start" >
                &copy; Todos los derechos reservados {{ year }}.
            </v-col>
            <v-col cols="12" offset-md="2" md="4" class="caption d-flex justify-start" >
                Fecha de publicación: {{ FechaPublicacion }}                
            </v-col>
            <v-col cols="12" md="2" class="caption d-flex justify-start" >
                versión {{ VersionApp }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from "vuex"; 

export default {
    computed:{ ...mapState(['URLSrc', 'URLApi', 'User', 'VersionApp', 'FechaPublicacion']), }, 

    data(){
        return{
            year: new Date().getFullYear(),

        }
    },


}
</script>