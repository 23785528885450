<template>
    <div>
        
        <v-row>
            <v-text-field
                label="Nombre"
            ></v-text-field>
        </v-row>

        <form   id="payment-form" 
                @submit.prevent="createToken()" >
            <div class="form-row">

                <label for="card-element"> Credit or debit card </label>

                <div id="card-element">
                    <!-- A Stripe Element will be inserted here. -->
                </div>

                <!-- Used to display Element errors. -->
                <div id="card-errors" role="alert"></div>
            </div>
            <v-divider></v-divider>

            <v-btn
                color="blue"
                class="white--text mt-4"
                block
                @click="createToken()"
                >
                Pay $ 000.00
            </v-btn>
        </form>

        <v-snackbar v-model="snackBar.show" :timeout="snackBar.timeout" :color="snackBar.color">
            {{ snackBar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackBar.colorCerrar"
                    text
                    v-bind="attrs"
                    @click="showSnackbar = false" >
                <v-icon> mdi-close </v-icon>
                </v-btn>

            </template>
        </v-snackbar>


    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    //#region ---- Computed ----
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN', 'StripePK']),
    },
    //#endregion
    //#region ---- Props ----
    props:{
        idCotizacion:Number,
        total:Number,
        moneda:String
    },
    //#endregion
    //#region ---- Data ----
    data(){
        return{
            //api: new ApiService(),
            stripe:{},
            elements:{},
            card:{},
            stripeToken:{},
            snackBar:{
                show:false,
                timeout:5000,
                text:'',
                color:'#000000',
                colorCerrar:'white'
            }
        }
    },
    //#endregion
    //#region ---- Methods ----
    methods:{
        //#region metodos de stripe
         async createToken(){
            let stripeResponse = await this.stripe.createToken(this.card).then(function(result) {
                if (result.error) {
                    // Inform the customer that there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    return { token: null, error: errorElement.textContent};
                } else {
                    // Send the token to your server.
                    //console.log('createToken',result.token);
                    return { token: result.token, error:null };
                }
            });
            console.log('stripeResponse', stripeResponse);
            if(stripeResponse.token){
                //this.stripeTokenHandler(stripeResponse.token);
            }
        },
        stripeTokenHandler(token){
            
            //let formData = new FormData();
            //formData.append('token', token.id);
            //formData.append('idCotizacion', this.idCotizacion);
            //console.log('token.id',token.id);
            //return;
            this.api.pagoStripeCard(this.URLApi, token.id, this.idCotizacion)
            //await axios.post(urlPago, formData)
            .then( response => { 
                console.log('pagoStripeCard.data', response.data);
                if(response.data.objeto.paid){
                    console.log('Pago hecho correctamente');
                    this.snackBar.text = 'Pay sucess';
                    this.snackBar.color = 'green';
                    this.snackBar.colorCerrar = 'white';
                    this.snackBar.show = true;
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1200);
                }
                else{
                    this.snackBar.text = response.data.mensaje;
                    this.snackBar.color = 'red';
                    this.snackBar.colorCerrar = 'white';
                    this.snackBar.show = true;
                }
            })
            .catch( error => console.log(error));
        },
        //#endregion
        //#region metodos api
        //#endregion
    },
    //#endregion
    //#region ---- mounted ----
    mounted(){
        // Set your publishable key: remember to change this to your live publishable key in production
        // See your keys here: https://dashboard.stripe.com/account/apikeys
        this.stripe = Stripe(this.StripePK);
        this.elements = this.stripe.elements();
        // Custom styling can be passed to options when creating an Element.
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: '#32325d',
            },
        };
        // Create an instance of the card Element.
        this.card = this.elements.create('card', {style: style});
        // Add an instance of the card Element into the `card-element` <div>.
        this.card.mount('#card-element');
    },
    //#endregion
    
}
</script>