<template>
    <v-dialog v-model="showDialog" persistent max-width="1000">
        <v-card>
            <v-card-title>
                <span > {{ pTituloPDF }} </span>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="showDialog = false"
                        >
                            <v-icon color="red" >mdi-close </v-icon>
                        </v-btn>
                    </template>
                </v-menu>
            </v-card-title>

            <v-card-text>

                <embed  v-if="rutaPdf!=''"
                        :src="URLSrc+rutaPdf" 
                        type="application/pdf" 
                        width="100%" 
                        height="800px" />

                <div    v-else
                        class="text-center">

                    <v-progress-circular
                        :size="80"
                        color="blue"
                        indeterminate
                    ></v-progress-circular>

                </div>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        showDialog:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.mostrarVentana;
            },
            set(newValue){
                //console.log('mostrarVentana.set', newValue);
                //console.log('ShowPdfComponent');
                this.$emit('setShowDialog', newValue);
            }
        }
    },
    //#endregion

    //#region Props
    props:{
        mostrarVentana:Boolean,
        pTituloPDF:String,
        // '/reportes/PVenta/' + nombrePDF
        rutaPdf:String 
    },
    //#endregion

    //#region Data
    data(){
        return{

        }
    },
    //#endregion
    watch:{
        mostrarVentana(val){
            console.log('ShowPdfComponent.mostrarVentana', val);
        }
    },

}
</script>