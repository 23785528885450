<template>
    <!--<v-container>-->
    <div>
        <v-row>
            <v-col>
                <v-card elevation="2">
                    <v-card-text>
                        <v-row>
                            <v-col  cols="12" md="4" >
                                <div class="text-xl">
                                    <h1> <strong> Hoja de Trabajo </strong> </h1>
                                </div>
                            </v-col>
                            <v-col  cols="6" md="4">
                                {{ fechaActualFormateada }}
                            </v-col>
                            <v-col  cols="6" md="4"
                                    class="d-flex justify-end red--text" >
                                <h3> <strong> {{ horaActualFormateada }} </strong> </h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2">  
                                <v-chip 
                                    class="ma-2 white--text"
                                    :color="colorServicio"
                                    @click="openPanel=[0]"
                                >
                                    Servicios: {{ totalServicios }}
                                </v-chip>
                            </v-col>
                            <v-col cols="12" md="2"> 
                                <v-chip 
                                    class="ma-2 white--text"
                                    :color="colorTraslados"
                                    @click="openPanel=[1]"
                                >
                                    Traslados Programados: {{ totalTraslados }}
                                </v-chip>                                
                            </v-col>
                            <v-col cols="12" md="2"> 
                                <v-chip 
                                    class="ma-2 white--text"
                                    :color="colorLaboratorios"
                                    @click="openPanel=[2]"
                                >
                                    Lab. Ray X y Examenes: {{ totalLaboratorios }}
                                </v-chip>                                
                            </v-col>
                            <v-col cols="12" md="2" offset-md="2" > 
                                <v-btn  color="blue" class="white--text" outlined block
                                        to="/operacion/ambulancias">
                                    <v-icon> mdi-plus</v-icon> Nuevo
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn 
                                    outlined
                                    color="red"
                                    block
                                    @click="$router.go(-1)"
                                > Salir </v-btn>

                            </v-col>


                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-expansion-panels v-model="openPanel" multiple focusable >

            <!--INICIO Expansion Servicios-->            
            <v-expansion-panel>

                <v-expansion-panel-header>
                    <template >
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Servicios </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                                
                                <v-fade-transition leave-absolute>
                                    <v-chip 
                                        class="ma-2 white--text"
                                        :color="colorServicio"
                                    >
                                        Total: {{ totalServicios }}
                                    </v-chip>
                                </v-fade-transition>

                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <ServiciosComponent
                        @setTotalServicios="totalServicios = $event"
                    ></ServiciosComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN Expansion Servicios-->

            <!--INICIO Traslados Programados-->            
            <v-expansion-panel>

                <v-expansion-panel-header>
                    <template >
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Traslados Programados </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                                
                                <v-fade-transition leave-absolute>
                                    <v-chip 
                                        class="ma-2 white--text"
                                        :color="colorTraslados"
                                    >
                                        Total: {{ totalTraslados }}
                                    </v-chip>
                                </v-fade-transition>

                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <TrasladosComponent
                        @setTotalTraslados="totalTraslados = $event"
                    ></TrasladosComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN Traslados Programados-->

            <!--INICIO RayosXExamenes-->            
            <v-expansion-panel>

                <v-expansion-panel-header>
                    <template>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Laboratorios, Rayos X y Examenes de Ingreso o Periodicos. </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                                
                                <v-fade-transition leave-absolute>
                                    <v-chip 
                                        class="ma-2 white--text"
                                        :color="colorLaboratorios"
                                    >
                                        Total: {{ totalLaboratorios }}
                                    </v-chip>
                                </v-fade-transition>

                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <LaboratoriosExamenesComponent
                        @setTotalLaboratorios="totalLaboratorios = $event"
                    ></LaboratoriosExamenesComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN RayosXExamenes-->

        </v-expansion-panels>
    </div>
    <!--</v-container>-->
</template>

<script>
import {mapState} from "vuex";
import ServiciosComponent from '@/components/HojaTrabajo/ServiciosComponent';
import TrasladosComponent from '@/components/HojaTrabajo/TrasladosComponent';
import LaboratoriosExamenesComponent from '@/components/HojaTrabajo/LaboratoriosExamenesComponent';

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi']),
        colorServicio(){
            let color = 'red';
            color = ( this.totalServicios >= 10 )? 'red' : 'orange';
            color = ( this.totalServicios >= 4 )? 'orange' : 'green';
            return color;
        },
        colorTraslados(){
            let color = 'red';
            color = ( this.totalTraslados >= 10 )? 'red' : 'orange';
            color = ( this.totalTraslados >= 4 )? 'orange' : 'green';
            return color;
        },
        colorLaboratorios(){
            let color = 'red';
            color = ( this.totalLaboratorios >= 10 )? 'red' : 'orange';
            color = ( this.totalLaboratorios >= 4 )? 'orange' : 'green';
            return color;
        }
    },
    components:{
        ServiciosComponent,
        TrasladosComponent,
        LaboratoriosExamenesComponent
    },
    data(){
        return{
            interval:{},
            totalServicios:0,
            totalTraslados:0,
            totalLaboratorios:0,
            openPanel:[0],
            fechaActualFormateada:'',
            horaActualFormateada:''
        }
    },
    methods:{
        getFechaActual(){
            let fechaActual = new Date();
            let diaSemana = '';
            let mesLetra = '';

            let year = fechaActual.getFullYear();
            let month = fechaActual.getMonth();
            let day = fechaActual.getDate();
            let numeroDia = fechaActual.getDay();

            switch(numeroDia){
                case 0: diaSemana = 'Domingo'
                    break;
                case 1: diaSemana = 'Lunes'
                    break;
                case 2: diaSemana = 'Martes'
                    break;
                case 3: diaSemana = 'Miércoles'
                    break;
                case 4: diaSemana = 'Jueves'
                    break;
                case 5: diaSemana = 'Viernes'
                    break;
                case 6: diaSemana = 'Sábado'
                    break;
            }

            switch(month){
                case 0: mesLetra = 'Enero'
                    break;
                case 1: mesLetra = 'Febrero'
                    break;
                case 2: mesLetra = 'Marzo'
                    break;
                case 3: mesLetra = 'Abril'
                    break;
                case 4: mesLetra = 'Mayo'
                    break;
                case 5: mesLetra = 'Junio'
                    break;
                case 6: mesLetra = 'Julio'
                    break;
                case 7: mesLetra = 'Agosto'
                    break;
                case 8: mesLetra = 'Septiembre'
                    break;
                case 9: mesLetra = 'Octubre'
                    break;
                case 10: mesLetra = 'Noviembre'
                    break;
                case 11: mesLetra = 'Diciembre'
                    break;
            }
            //this.fechaActualFormateada = diaSemana + " " +  fechaActual.toJSON().slice(0,10).replace(/-/g,'/');
            this.fechaActualFormateada = diaSemana + " " + day + " de "+ mesLetra + " " + year;
            //console.log(this.fechaActualFormateada);
        },
        getHoraActual(){
            const horaActual = new Date();
            let meridiano = 'a.m.';
            let hora = horaActual.getHours();            
            const minutos = horaActual.getMinutes();
            const segundos = horaActual.getSeconds();

            meridiano = (hora >= 12) ? 'p.m.' : 'a.m.';
            hora = (hora > 12) ? (hora-12) : hora;

            this.horaActualFormateada = 
                 hora.toString().padStart(2,"0") 
                 + ':' + minutos.toString().padStart(2,"0") 
                 + ':' + segundos.toString().padStart(2,"0") 
                 + ' ' + meridiano;
        },
        crearIntervalo(){
            this.interval = setInterval(() => {
                this.getHoraActual();
            }, 500);
        },
    },
    beforeMount(){
        this.crearIntervalo();
    },
    mounted(){
        this.getFechaActual();
        this.openPanel = [0,1,2];
    },
    beforeDestroy () {
      clearInterval(this.interval);
    },
    
}
</script>