import axios from 'axios';

export default class ApiAmbulanciasService {

    //#region Crum (Hoja de Trabajo)
    recuperaCrum(url, Numero, Contrato, Estatus, FechaDesde=undefined, FechaHasta=undefined){
        //Definicion de ruta
        const controller = 'Crum';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Estatus,
            FechaDesde,
            FechaHasta
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    guardaCrum( url,
                Numero, Contrato, Socio,
                Tipo, Fecha, FechaHora,
                HoraLlamada, Usuario, Despacho,
                Salida, Llegada, Traslado,
                Hospital, Libre, MS,
                Solicitante, Edad, Sexo,
                Beneficiario, Paciente,
                Telefonos, Domicilio, Signos,
                TipoServicio, Triage, Equipo,
                Horario, NumeroLab, Diagnostico,
                Tratamiento, Observaciones, 
                PadecimientoActual, TratamientoActual,
                InicioSintomas, ExploracionFisica,
                SV_TA , SV_FR , SV_TEMP , SV_FC , SV_SAT , SV_PESO ,
                AntecedentesPersonalesPato, EstadoCivil,
                GlasgowMotora, GlasgowRespuestaVerbal, GlasgowAperturaOcular,
                Estatus,
                Cancelacion, Destino, Viaje,
                Extra1, Extra2, Extra3,
                Medico, Mes, Ano 
        ){
        //Definicion de ruta
        const controller = 'Crum';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Socio,
            Tipo,
            Fecha,
            FechaHora,
            HoraLlamada,
            Usuario,
            Despacho,
            Salida,
            Llegada,
            Traslado,
            Hospital,
            Libre,
            MS,
            Solicitante,
            Edad,
            Sexo,
            Beneficiario,
            Paciente,
            Telefonos,
            Domicilio,
            Signos,
            TipoServicio,
            Triage,
            Equipo,
            Horario,
            NumeroLab,
            Diagnostico,
            Tratamiento,
            Observaciones,
            PadecimientoActual, 
            TratamientoActual,
            InicioSintomas, 
            ExploracionFisica,
            SV_TA , 
            SV_FR , 
            SV_TEMP , 
            SV_FC , 
            SV_SAT , 
            SV_PESO ,
            AntecedentesPersonalesPato , 
            EstadoCivil ,
            GlasgowMotora , 
            GlasgowRespuestaVerbal ,
            GlasgowAperturaOcular ,
            Estatus,
            Cancelacion,
            Destino,
            Viaje,
            Extra1,
            Extra2,
            Extra3,
            Medico,
            Mes,
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaCrum( url,
                Numero, Contrato, Socio,
                Tipo, Fecha, FechaHora,
                HoraLlamada, Usuario, Despacho,
                Salida, Llegada, Traslado,
                Hospital, Libre, MS,
                Solicitante, Edad, Sexo,
                Beneficiario, Paciente,
                Telefonos, Domicilio, Signos,
                TipoServicio, Triage, Equipo,
                Horario, NumeroLab, Diagnostico,
                Tratamiento, Observaciones, 
                PadecimientoActual, TratamientoActual,
                InicioSintomas, ExploracionFisica,
                SV_TA , SV_FR , SV_TEMP , SV_FC , SV_SAT , SV_PESO ,
                AntecedentesPersonalesPato, EstadoCivil,
                GlasgowMotora, GlasgowRespuestaVerbal, GlasgowAperturaOcular,
                Estatus,
                Cancelacion, Destino, Viaje,
                Extra1, Extra2, Extra3,
                Medico, Mes, Ano 
        ){
        //Definicion de ruta
        const controller = 'Crum';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Socio,
            Tipo,
            Fecha,
            FechaHora,
            HoraLlamada,
            Usuario,
            Despacho,
            Salida,
            Llegada,
            Traslado,
            Hospital,
            Libre,
            MS,
            Solicitante,
            Edad,
            Sexo,
            Beneficiario,
            Paciente,
            Telefonos,
            Domicilio,
            Signos,
            TipoServicio,
            Triage,
            Equipo,
            Horario,
            NumeroLab,
            Diagnostico,
            Tratamiento,
            Observaciones,
            PadecimientoActual,
            TratamientoActual, 
            InicioSintomas, 
            ExploracionFisica,
            SV_TA , 
            SV_FR , 
            SV_TEMP , 
            SV_FC , 
            SV_SAT , 
            SV_PESO ,
            AntecedentesPersonalesPato , 
            EstadoCivil ,
            GlasgowMotora , 
            GlasgowRespuestaVerbal , 
            GlasgowAperturaOcular ,
            Estatus,
            Cancelacion,
            Destino,
            Viaje,
            Extra1,
            Extra2,
            Extra3,
            Medico,
            Mes,
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    terminaTrabajoCrum( url,
                Numero, Contrato, Socio,
                Tipo, Fecha, FechaHora,
                HoraLlamada, Usuario, Despacho,
                Salida, Llegada, Traslado,
                Hospital, Libre, MS,
                Solicitante, Edad, Sexo,
                Beneficiario, Paciente,
                Telefonos, Domicilio, Signos,
                TipoServicio, Triage, Equipo,
                Horario, NumeroLab, Diagnostico,
                Tratamiento, Observaciones, 
                PadecimientoActual, TratamientoActual,
                InicioSintomas, ExploracionFisica,
                SV_TA , SV_FR , SV_TEMP , SV_FC , SV_SAT , SV_PESO ,
                AntecedentesPersonalesPato ,  EstadoCivil ,
                GlasgowMotora ,  GlasgowRespuestaVerbal ,  GlasgowAperturaOcular ,
                Estatus,
                Cancelacion, Destino, Viaje,
                Extra1, Extra2, Extra3,
                Medico, Mes, Ano 
        ){
        //Definicion de ruta
        const controller = 'Crum';
        const method = 'terminaTrabajo';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Socio,
            Tipo,
            Fecha,
            FechaHora,
            HoraLlamada,
            Usuario,
            Despacho,
            Salida,
            Llegada,
            Traslado,
            Hospital,
            Libre,
            MS,
            Solicitante,
            Edad,
            Sexo,
            Beneficiario,
            Paciente,
            Telefonos,
            Domicilio,
            Signos,
            TipoServicio,
            Triage,
            Equipo,
            Horario,
            NumeroLab,
            Diagnostico,
            Tratamiento,
            Observaciones,
            PadecimientoActual,
            TratamientoActual, 
            InicioSintomas, 
            ExploracionFisica,
            SV_TA , 
            SV_FR , 
            SV_TEMP , 
            SV_FC , 
            SV_SAT , 
            SV_PESO ,
            AntecedentesPersonalesPato , 
            EstadoCivil ,
            GlasgowMotora , 
            GlasgowRespuestaVerbal , 
            GlasgowAperturaOcular ,
            Estatus,
            Cancelacion,
            Destino,
            Viaje,
            Extra1,
            Extra2,
            Extra3,
            Medico,
            Mes,
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaCrum(url, Numero){
        //Definicion de ruta
        const controller = 'Crum';
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region CrumTraslados
    recuperaCrumTraslados(url, Numero, Contrato, Estatus){
        //Definicion de ruta
        const controller = 'CrumTraslados';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Estatus
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);

    }

    guardaCrumTraslados(url, 
                        Numero, Contrato, Socio,
                        Tipo, Fecha, FechaHora,
                        HoraLlamada, Usuario,  FechaViaje, 
                        Despacho,
                        Salida, Llegada, Traslado,
                        Hospital, Libre, MS,
                        Solicitante, Beneficiario, Paciente,
                        Telefonos, Domicilio, Signos,
                        TipoServicio, Triage, Equipo,
                        Horario, NumeroLab, Diagnostico,
                        Tratamiento, Observaciones, Estatus,
                        Cancelacion, Destino, Viaje,
                        Extra1, Extra2, Extra3,
                        Medico, Mes, Ano 
        ){
            //Definicion de ruta
        const controller = 'CrumTraslados';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Socio,
            Tipo,
            Fecha,
            FechaHora,
            HoraLlamada,
            Usuario,
            FechaViaje,
            Despacho,
            Salida,
            Llegada,
            Traslado,
            Hospital,
            Libre,
            MS,
            Solicitante,
            Beneficiario,
            Paciente,
            Telefonos,
            Domicilio,
            Signos,
            TipoServicio,
            Triage,
            Equipo,
            Horario,
            NumeroLab,
            Diagnostico,
            Tratamiento,
            Observaciones,
            Estatus,
            Cancelacion,
            Destino,
            Viaje,
            Extra1,
            Extra2,
            Extra3,
            Medico,
            Mes,
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaCrumTraslados(url, 
            Numero, Contrato, Socio,
            Tipo, Fecha, FechaHora,
            HoraLlamada, Usuario, Despacho,
            Salida, Llegada, Traslado,
            Hospital, Libre, MS,
            Solicitante, Beneficiario, Paciente,
            Telefonos, Domicilio, Signos,
            TipoServicio, Triage, Equipo,
            Horario, NumeroLab, Diagnostico,
            Tratamiento, Observaciones, Estatus,
            Cancelacion, Destino, Viaje,
            Extra1, Extra2, Extra3,
            Medico, Mes, Ano ){

            //Definicion de ruta
            const controller = 'CrumTraslados';
            const method = 'modifica';
        
            //Construccion de objeto a enviar
            const objeto = { 
                Numero,
                Contrato,
                Socio,
                Tipo,
                Fecha,
                FechaHora,
                HoraLlamada,
                Usuario,
                Despacho,
                Salida,
                Llegada,
                Traslado,
                Hospital,
                Libre,
                MS,
                Solicitante,
                Beneficiario,
                Paciente,
                Telefonos,
                Domicilio,
                Signos,
                TipoServicio,
                Triage,
                Equipo,
                Horario,
                NumeroLab,
                Diagnostico,
                Tratamiento,
                Observaciones,
                Estatus,
                Cancelacion,
                Destino,
                Viaje,
                Extra1,
                Extra2,
                Extra3,
                Medico,
                Mes,
                Ano
            };
            const objetoString = JSON.stringify(objeto);
            const json = 'json='+objetoString;
            
            //Consumo de api
            return axios.post(`${ url }/${ controller }/${ method }`, json);

    }

    terminaTrabajoCrumTraslados(url, Numero, Contrato, Estatus){
        //Definicion de ruta
        const controller = 'CrumTraslados';
        const method = 'terminaTrabajo';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Estatus
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaCrumTraslados(url, Numero){

    }
    //#endregion

    //#region CrumLaboratorios
    recuperaCrumLaboratorios(url, Numero, Contrato, Estatus){
         //Definicion de ruta
         const controller = 'CrumLaboratorios';
         const method = 'recupera';
     
         //Construccion de objeto a enviar
         const objeto = { 
             Numero,
             Contrato,
             Estatus
         };
         const objetoString = JSON.stringify(objeto);
         const json = 'json='+objetoString;
         
         //Consumo de api
         return axios.post(`${ url }/${ controller }/${ method }`, json);

    }

    guardaCrumLaboratorios(url, 
            Numero,  Contrato,  Socio, 
            Tipo,  Fecha,  FechaHora, 
            HoraLlamada,  Usuario,  NombreLaboratorio, 
            Solicitante,  Beneficiario,  Paciente, 
            Telefonos,  Domicilio,  Signos,  
            TipoServicio,  Triage,  Equipo, 
            Horario,  NumeroLab,  Diagnostico, 
            Tratamiento,  Observaciones,  Estatus, 
            Cancelacion,  Destino,  Viaje, 
            Extra1,  Extra2,  Extra3, 
            Medico,  Mes,  Ano
        ){
        //Definicion de ruta
        const controller = 'CrumLaboratorios';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero, 
            Contrato, 
            Socio, 
            Tipo, 
            Fecha, 
            FechaHora, 
            HoraLlamada, 
            Usuario, 
            NombreLaboratorio, 
            Solicitante, 
            Beneficiario, 
            Paciente, 
            Telefonos, 
            Domicilio, 
            Signos, 
            TipoServicio, 
            Triage, 
            Equipo, 
            Horario, 
            NumeroLab, 
            Diagnostico, 
            Tratamiento, 
            Observaciones, 
            Estatus, 
            Cancelacion, 
            Destino, 
            Viaje, 
            Extra1, 
            Extra2, 
            Extra3, 
            Medico, 
            Mes, 
            Ano
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);

    }

    modificaCrumLaboratorios(url, Numero){

    }

    terminaTrabajoCrumLaboratorios(url, Numero, Contrato, Estatus){
        //Definicion de ruta
        const controller = 'CrumLaboratorios';
        const method = 'terminaTrabajo';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Estatus
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaCrumLaboratorios(url, Numero){

    }
    //#endregion
    

}