<template>
    <v-container>
        <!--Formulario para objeto-->
        <v-row>
            <v-col
                cols="12"
                md="6"
            >
            <v-text-field
                v-model="Equipo.NombreEquipo"
                label="Equipo"
                @click:clear="clearEquipo()"
                @keyup.enter="checkUpdate()"
                clearable
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="green" class="white--text"
                        @click="checkUpdate()"> 
                    <v-icon v-if="updateEquipoFlag" > mdi-content-save-alert</v-icon>
                    <v-icon v-else> mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="red" class="white--text"
                        :disabled="!updateEquipoFlag"
                        @click="showDialog = true;"> 
                    <v-icon> mdi-delete</v-icon>
                    Eliminar
                </v-btn>
            </v-col>

        </v-row>

        <!--Info data table https://stackoverflow.com/questions/62259868/vuetify-change-text-rows-per-page-text-in-v-data-tables-footer-->
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="listaEquipos"
            :single-select="singleSelect"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-text':'Equipos por página',
            }"
            :loading="loadingDatatable"
            item-key="Numero"
            class="elevation-1"
            @click:row="handleClick"
        > <!--show-select-->
        </v-data-table>

        <!--Formulario para objeto-->
        <v-row>
            <v-col
                cols="12"
                md="6"
            >
            <v-text-field
                v-model="Equipo.NombreEquipo"
                label="Equipo"
                @click:clear="clearEquipo()"
                @keyup.enter="checkUpdate()"
                clearable
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="green" class="white--text"
                        @click="checkUpdate()"> 
                    <v-icon v-if="updateEquipoFlag" > mdi-content-save-alert</v-icon>
                    <v-icon v-else> mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" > 
                <v-btn  block color="red" class="white--text"
                        :disabled="!updateEquipoFlag"
                        @click="showDialog = true;"> 
                    <v-icon> mdi-delete</v-icon>
                    Eliminar
                </v-btn>
            </v-col>

        </v-row>

        <!--Mensaje para accion-->
        <v-snackbar v-model="showSnackbar" :timeout="timeoutSnackbar" >
            {{ textSnackbar }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="showSnackbar = false" >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <!--Alerta de Borrado-->
        <v-dialog
            v-model="showDialog" persistent max-width="290" >
            <v-card>
                <v-card-title class="headline">
                    ¿Desea continuar?
                </v-card-title>
                    <v-card-text>
                        Esta a punto de eliminar el Equipo con el nombre:  <strong> {{ Equipo.NombreEquipo }} </strong>  .
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="showDialog = false" >
                            No
                        </v-btn>
                        <v-btn color="green darken-1" text @click="deleteEquipo()" >
                            Si
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
import ApiService from '@/services/ApiConfiguracionService';
import {mapState} from "vuex";

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    data(){
        return{
            api: new ApiService(),
            showDialog:false,
            showSnackbar:false,
            timeoutSnackbar:2500,
            textSnackbar:'El Equipo fue guardada',
            listaEquipos:[],
            selected: [],
            singleSelect: true,
            loadingDatatable:true,
            headers:[
                //{ text: 'Clave', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Nombre', value: 'Nombre' },
            ],
            valid: false,
            updateEquipoFlag:false,
            Equipo:{
                Numero:0,
                NombreEquipo:''
            }
        }
    },
    methods:{
        accion(){
            console.log('selected', this.selected[0]);
            console.log('Numero', this.selected[0].Numero);
            console.log('Nombre', this.selected[0].Nombre);
        },
        handleClick(value) {
            //console.log('handleClick', value);
            this.Equipo.Numero = value.Numero;
            this.Equipo.NombreEquipo = value.Nombre;
            this.updateEquipoFlag = true;

        },
        clearEquipo(){
            this.updateEquipoFlag=false;
            this.Equipo.Numero = 0;
            this.Equipo.NombreEquipo = '';

        },
        checkUpdate(){
            console.log('checkUpdate', this.updateEquipoFlag);
            if(this.Equipo.NombreEquipo===''){
                console.log('NombreEquipo vacío');
                this.textSnackbar = 'Favor de completar los campos faltantes.';
                this.showSnackbar = true;
                return;
            }
            
            if(this.updateEquipoFlag){
                //Modifica
                this.updateEquipo();
            }
            else{
                //Guarda
                this.insertEquipo();
            }
        },
        getEquipos(){
            this.clearEquipo();
            this.loadingDatatable = true;

            this.api.recuperaEquipos(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaEquipos.data", response.data);
                this.listaEquipos = response.data.objeto;
                this.loadingDatatable = false;

            })
            .catch(error=>{console.log(error);});

        },
        insertEquipo(){
            this.api.guardaEquipo(this.URLApi, this.Equipo.NombreEquipo)
            .then( response =>{
                console.log("guardaEquipo.data", response.data);

                if(response.data.objeto){
                    this.getEquipos();
                    this.updateEquipoFlag = false;
                    this.textSnackbar = 'El Equipo fue guardado';
                    this.showSnackbar = true;
                }
            })
            .catch(error=>{console.log(error);});
        },
        updateEquipo(){
            this.api.modificaEquipo(this.URLApi, this.Equipo.Numero, this.Equipo.NombreEquipo)
            .then( response =>{
                console.log("modificaEquipo.data", response.data);

                if(response.data.objeto){
                    this.getEquipos();
                    this.updateEquipoFlag = false;
                    this.textSnackbar = 'El Equipo fue guardado';
                    this.showSnackbar = true;
                }
            })
            .catch(error=>{console.log(error);});
        },
        deleteEquipo(){
            this.api.eliminaEquipo(this.URLApi, this.Equipo.Numero)
            .then( response =>{
                console.log("eliminaEquipo.data", response.data);

                if(response.data.objeto){
                    this.getEquipos();
                    this.updateEquipoFlag = false;

                    this.textSnackbar = 'El Equipo fue eliminado';
                    this.showSnackbar = true;
                    this.showDialog = false;
                }
            })
            .catch(error=>{console.log(error);});
        }
    },
    beforeMount(){
        //console.log('Evento que se lanza antes de que se imprima la pantalla');
        this.getEquipos();
    }
}
</script>