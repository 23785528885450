<template>
    <v-container>
        <h2>Cotizaciones</h2>
    </v-container>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    data(){
        return{

        }
    }
}
</script>