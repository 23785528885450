<template>
    <div>
      <v-card-text class="mt-12">
        <h1 class="text-center display-2 teal--text text accent-3"> Sign In </h1>
        
        <div class="text-center" mt-4 style="display:none;">

          <v-btn class="mx-2" fab color="black" outlined >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn class="mx-2" fab color="black" outlined >
            <v-icon>mdi-google</v-icon>
          </v-btn>
          <v-btn class="mx-2" fab color="black" outlined >
            <v-icon>mdi-login</v-icon>
          </v-btn>

        </div>

        <h4 class="text-center mlt-4"> Ensure your email for registration </h4>

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-text-field 
            label="Email"
            name="Email"
            prepend-icon="mdi-email"
            type="email"
            color="teal accent-3"
            v-model="Login.user"
            :counter="80"
            :rules="emailRules"
            required
            @keypress.enter="validate"
            clearable/>

          <v-text-field 
            label="Password"
            name="Password"
            prepend-icon="mdi-lock"
            color="teal accent-3" 
            :type="showPass ? 'text' : 'password'"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="Login.password"
            :rules="passwordRules"
            clearable
            required
            @keypress.enter="validate"
            @click:append="showPass = !showPass" />

            <h3 class="text-center mt-3"> Forget your password?</h3>

            <div class="text-center mt-3">
              <v-icon @click="reset" > mdi-broom </v-icon>
              <v-btn rounded color="teal accent-8" dark @click="validate"> SIGN IN </v-btn>
              <v-progress-circular indeterminate color="amber" v-if="progressCircular"/>
            </div>
            
        </v-form>
      </v-card-text>
    </div>  
</template>

<script>
  import {mapState} from "vuex";

  export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi'])
    },
    data: () => ({
      step:1,
      progressCircular:false,
      showPass:false,
      valid: true,
      Login:{
        user: '',
        password: ''
      },      
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 5) || 'Min 5 characters',
      ],
      checkbox: false,
    }),
    props:{
      source : String
    },
    methods: {
      validate () {
        const valid = this.$refs.form.validate();
        if(valid){

        }

      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      //Metodos de Api
      
    },
    mounted(){
      this.$emit('stepSon', this.step);

    }
  }
</script>