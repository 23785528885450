<template>
    <v-container class="spacing-playground pa-4" fluid>
        
        <v-card elevation="1">
            <v-card-title class="d-flex justify-center">
                Ambulancias
            </v-card-title>
            <v-card-subtitle  class="d-flex justify-center">
                {{ fechaActualFormateada }} --- {{ horaActualFormateada }}
            </v-card-subtitle>
            <v-card-text>

                <v-row>
                    <v-col cols="12" md="3">
                        <v-btn  color="blue" 
                                class="white--text" 
                                outlined
                                block
                                @click="clearcrumObject()"
                                >
                            <v-icon> mdi-plus</v-icon> Nuevo
                        </v-btn>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-btn 
                            outlined
                            color="orange"
                            @click="goHojaTrabajo()"
                        > Hoja de Trabajo </v-btn>
                    </v-col>
                    
                    <v-col  cols="12" md="6" 
                            class="d-flex justify-end">
                        <v-btn 
                            outlined
                            color="red"
                            @click="$router.go(-1)"
                        > Salir </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>


        <v-expansion-panels v-model="openPanel" multiple focusable>

            <!--INICIO Panel Busqueda Contrato-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }" >
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Datos del Socio </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                            
                                <v-fade-transition 
                                        v-if="open"
                                        leave-absolute>
                                    <!--{{ Contrato.Numero }}-->
                                </v-fade-transition>

                                <span><!--v-else-->
                                    <div v-if="crumObject.ContratoCrum > 0">
                                        <v-chip
                                            class="ma-2 white--text"
                                            :color="colorEstatusPago"
                                        >
                                        Contrato: {{ crumObject.ContratoCrum }} /Beneficiario: {{ Beneficiario.NombreB }} /Edad: {{ Beneficiario.Edad }}
                                        </v-chip>
                                    </div>
                                    
                                </span>
                        </v-col>
                    </v-row>
                </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <BusquedaContratoComponent 
                        :key="crumObject.EstatusCrum"
                        @setContrato="crumObject.ContratoCrum = $event"
                        @setNumeroBeneficiario="Beneficiario.Numero = $event"
                        @setNombreBeneficiario="Beneficiario.NombreB = $event"
                        @setEdad="Beneficiario.Edad = $event"
                        @setEstatus="EstatusContrato = $event"
                        @setEsBeneficiario="EsBeneficiario = $event"

                        :NumeroCrum="Number.parseInt(crumObject.Numero)"
                        :ContratoNumero="Number.parseInt(crumObject.ContratoCrum)"
                        :EstatusCrum="crumObject.EstatusCrum"
                    ></BusquedaContratoComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN Panel Busqueda Contrato-->

            <!--INICIO Panel CRUM-->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }" >
                        <v-row no-gutters>
                            <v-col cols="4">
                                <strong> Atención </strong>
                            </v-col>
                            <v-col cols="8" class="text--secondary" >
                            
                                <v-fade-transition 
                                        v-if="open"
                                        leave-absolute>
                                    <!--{{ Contrato.Numero }}-->
                                </v-fade-transition>

                                <span v-else>
                                    <!--
                                    <div v-if="Contrato.Contrato > 0">
                                        Contrato: {{ Contrato.Contrato }} /Socio: {{ Contrato.NombreS }} /Beneficiarios: {{ Contrato.Beneficiarios }}
                                    </div>
                                    -->
                                </span>
                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <CrumComponent 
                        :key="crumObject.EstatusCrum"
                        :EsBeneficiario="EsBeneficiario" 
                        :NumeroBeneficiario="Number.parseInt(Beneficiario.Numero)"

                        :NumeroCrum="Number.parseInt(crumObject.Numero)"
                        :ContratoNumero="Number.parseInt(crumObject.ContratoCrum)"
                        :EstatusCrum="crumObject.EstatusCrum"
                    ></CrumComponent>
                </v-expansion-panel-content>

            </v-expansion-panel>
            <!--FIN Panel CRUM-->

        </v-expansion-panels>
        
    </v-container>
</template>

<script>
import BusquedaContratoComponent from '@/components/Ambulancias/BusquedaContratoComponent';
import CrumComponent from '@/components/Ambulancias/CrumComponent';


export default {
    components:{
        BusquedaContratoComponent,
        CrumComponent
    },
    //#region Computed
    computed:{
        colorEstatusPago(){
            let color = 'green';
            if(this.EstatusContrato == 'CANCELADO'){
                color = 'red';
            }
            else if(this.EstatusContrato == 'ACTIVO'){
                color = 'green';
            }
            else{
                color = 'orange';
            }
            return color;
        },  

    },
    //#endregion
    //#region Data
    data(){
        return{
            fechaActualFormateada:'',
            horaActualFormateada:'',
            fab:false,
            openPanel:[0],
            //Emite esta variable para saber si tiene que buscar en beneficiarios o solo en contratos
            EsBeneficiario:0,
            EstatusContrato:'',
            Beneficiario:{
                Numero:0,
                NombreB:'',
                Edad:0
            },
            //Variables para control de Hoja de Trabajo
            crumObject:{
                Numero:0,
                ContratoCrum:null,
                BeneficiarioCrum:'',
                EstatusCrum:''
            }
        }
    },

    //#endregion
    
    methods:{
        getFechaActual(){
            let fechaActual = new Date();
            let diaSemana = '';
            let mesLetra = '';

            let year = fechaActual.getFullYear();
            let month = fechaActual.getMonth();
            let day = fechaActual.getDate();
            let numeroDia = fechaActual.getDay();

            switch(numeroDia){
                case 0: diaSemana = 'Domingo'
                    break;
                case 1: diaSemana = 'Lunes'
                    break;
                case 2: diaSemana = 'Martes'
                    break;
                case 3: diaSemana = 'Miércoles'
                    break;
                case 4: diaSemana = 'Jueves'
                    break;
                case 5: diaSemana = 'Viernes'
                    break;
                case 6: diaSemana = 'Sábado'
                    break;
            }

            switch(month){
                case 0: mesLetra = 'Enero'
                    break;
                case 1: mesLetra = 'Febrero'
                    break;
                case 2: mesLetra = 'Marzo'
                    break;
                case 3: mesLetra = 'Abril'
                    break;
                case 4: mesLetra = 'Mayo'
                    break;
                case 5: mesLetra = 'Junio'
                    break;
                case 6: mesLetra = 'Julio'
                    break;
                case 7: mesLetra = 'Agosto'
                    break;
                case 8: mesLetra = 'Septiembre'
                    break;
                case 9: mesLetra = 'Octubre'
                    break;
                case 10: mesLetra = 'Noviembre'
                    break;
                case 11: mesLetra = 'Diciembre'
                    break;
            }
            //this.fechaActualFormateada = diaSemana + " " +  fechaActual.toJSON().slice(0,10).replace(/-/g,'/');
            this.fechaActualFormateada = diaSemana + " " + day + " de "+ mesLetra + " " + year;
            //console.log('fechaActualFormateada',this.fechaActualFormateada);
        },
        getHoraActual(){
            const horaActual = new Date();
            let meridiano = 'a.m.';
            let hora = horaActual.getHours();            
            const minutos = horaActual.getMinutes();
            const segundos = horaActual.getSeconds();

            meridiano = (hora >= 12) ? 'p.m.' : 'a.m.';
            hora = (hora > 12) ? (hora-12) : hora;

            this.horaActualFormateada = 
                 hora.toString().padStart(2,"0") 
                 + ':' + minutos.toString().padStart(2,"0") 
                 + ':' + segundos.toString().padStart(2,"0") 
                 + ' ' + meridiano;
        },
        crearIntervalo(){
            this.interval = setInterval(() => {
                this.getHoraActual();
            }, 500);
        },
        goHojaTrabajo(){
            const ruta = '/operacion/hojatrabajo';
            this.$router.replace(ruta);
        },
        clearcrumObject(){
            this.crumObject = {
                Numero:0,
                ContratoCrum:null,
                BeneficiarioCrum:'',
                EstatusCrum:''
            }
        }
    },
    beforeMount(){
        this.crearIntervalo();
        
    },
    mounted(){
        this.getFechaActual();
        this.openPanel = [0,1,2];
        
        const NumeroCrum = this.$route.params.NumeroCrum;
        const ContratoCrum = this.$route.params.ContratoCrum;
        const EstatusCrum = this.$route.params.EstatusCrum;

        this.crumObject.Numero = Number.parseInt(NumeroCrum);
        this.crumObject.ContratoCrum = Number.parseInt(ContratoCrum);
        this.crumObject.EstatusCrum = EstatusCrum;

        //console.log('crumObject', this.crumObject);
        
        //this.EsBeneficiario = 1;
        this.Beneficiario.Numero = this.crumObject.Numero;
        this.Contrato = this.crumObject.ContratoCrum;
        this.EstatusContrato = this.crumObject.EstatusCrum;

    },
    beforeDestroy () {
      clearInterval(this.interval);
    },

}
</script>