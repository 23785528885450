<template>
    <div>
        <h2> {{ titulo }} </h2>
        
        <v-row>
            <v-col cols="12" md="3">
                <v-select
                    :items="listaMedicos"
                    v-model="selectMedicos"
                    item-text="Nombre"
                    item-value="Nombre"
                    label="Medico"
                    dense
                    @change="selectDropDownMedico()"
                ></v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                    label="Sexo"
                    v-model="selectSexo"
                    :items="listaSexos"
                    item-text="text"
                    item-value="value"
                    dense
                    @change="selectDropDownSexo()"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field   
                    label="Edad"
                    v-model="EstudioLab.Edad"
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    label="Estatura"
                    v-model="EstudioLab.Estatura"
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field   
                    label="Peso (Kg.)"
                    v-model="EstudioLab.Peso"
                    dense
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="1">
                <v-text-field   
                    label="Glucosa"
                    v-model="EstudioLab.Glucosa"
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field   
                    label="BUN"
                    v-model="EstudioLab.Bun"
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
                <v-text-field   
                    label="UREA"
                    v-model="EstudioLab.Urea"
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
                <v-text-field   
                    label="Creatinina"
                    v-model="EstudioLab.Creatinina"
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
                <v-text-field   
                    label="Colesterol Total"
                    v-model="EstudioLab.Colesterol"
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
                <v-text-field   
                    label="Trigliceridos"
                    v-model="EstudioLab.Trigliceridos"
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
                <v-text-field   
                    label="Ac. Urico"
                    v-model="EstudioLab.AcidoUrico"
                    dense
                ></v-text-field>
            </v-col>

            <!-- <<INICIO>> PIE DE ELEMENTO. BOTON DE GUARDAR /GENERAR-->
            <v-col cols="12" md="3">
                <v-btn  color="blue"
                        class="white--text"
                        block
                        @click="generarEstudio()"
                        ><!--@click="generarEstudio()"-->
                    Generar 
                    <v-icon>mdi-content-save</v-icon> 
                </v-btn>
            </v-col>
            <!-- <<FIN>> PIE DE ELEMENTO. BOTON DE GUARDAR /GENERAR-->
        </v-row>

        <ShowPdfComponent
            :mostrarVentana="imprimir"
            :pTituloPDF="tituloPDF"
            :rutaPdf="rutaPdf"
            @setShowDialog="imprimir = $event"
        ></ShowPdfComponent>

    </div>
</template>

<script>
import {mapState, mapMutations} from "vuex"; 
import ApiEstudiosService from '@/services/ApiEstudiosService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

export default {

    //#region Components
    components:{
        ShowPdfComponent,
    },
    //#endregion

    //#region Computed
    computed:{ 
        ...mapState(['URLSrc', 'URLApi', 'User','Empresa']), 
        showDialog:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.imprimir;
            },
            set(newValue){
                console.log('F1.showDialog.newValue', newValue);
                this.$emit('setShowDialog', newValue);
            }
        }
    }, 
    //#endregion 

    //#region Props
    props:{
        propContrato:Number,
        propSocio:String,
        propSolicitante:String,
        propPaciente:String,
    },
    //#endregion

    //#region  Data
    data(){
        return {
            apiEstudios:new ApiEstudiosService(),
            apiConfiguracion:new ApiConfiguracionService(),
            //---------------------------------------------------------
            titulo:'Química Sanguinea',
            tipoEstudio:'QUIMICA_SANGUINEA',
            //---------------------------------------------------------
            // Para mostrar el pdf
            tituloPDF:'Química Sanguinea',
            rutaPdf:'',
            rutaReportes:'/reportes/Estudios/',
            nombrePdf:'',
            imprimir:false,
            //---------------------------------------------------------
            selectMedicos:'',
            listaMedicos:[],
            //---------------------------------------------------------
            selectSexo:'',
            listaSexos:[
                { value:'MASCULINO', text:'Masculino'  },
                { value:'FEMENINO', text:'Femenino'  },
            ],

            //#region Objeto EstudioLab
            EstudioLab:{
                Numero: null, 
                Contrato: null, 
                Socio: null, 
                TipoEstudio: null, 
                Fecha: null, 
                FechaHora: null, 
                HoraLlamada: null, 
                Usuario: null, 
                Despacho: null, 
                Salida: null, 
                Llegada: null, 
                Doping: null, 
                Metanfetamina: null, 
                Marihuana: null, 
                Cocaina: null, 
                Solicitante: null, 
                Edad: null, 
                Sexo: null, 
                Beneficiario: null, 
                Paciente: null, 
                Telefonos: null, 
                Domicilio: null, 
                Signos: null, 
                TipoServicio: null, 
                Triage: null, 
                Equipo: null, 
                Horario: null, 
                NumeroLab: null, 
                Diagnostico: null, 
                Tratamiento: null, 
                Observaciones: null, 
                PadecimientoActual: null, 
                TratamientoActual: null, 
                InicioSintomas: null, 
                ExploracionFisica: null, 
                AntecedentesPersonalesPato: null, 
                EstadoCivil: null, 
                GlasgowMotora: null, 
                GlasgowRespuestaVerbal: null, 
                GlasgowAperturaOcular: null, 
                Estatus: null, 
                Cancelacion: null, 
                Destino: null, 
                Viaje: null, 
                Extra1: null, 
                NumLatidos: null, 
                Estatura: null, 
                Medico: null, 
                Oxigenacion: null, 
                Peso: null,
                Glucosa: null,
                Bun: null,
                Urea: null,
                Creatinina: null,
                Colesterol: null,
                Trigliceridos: null,
                AcidoUrico: null,
            },
            //#endregion

        }
    },
    //#endregion

    //#region Metodos
    methods:{
        ...mapMutations(['setEstudioLab']),

        //#region Metodos de la pantalla
        generarEstudio(){
            this.EstudioLab.TipoEstudio = this.tipoEstudio;
            //console.log('this.EstudioLab', this.EstudioLab);
            this.guardarEstudioLab(this.EstudioLab.TipoEstudio);

        },
        //#endregion

        //#region dropdowns
        selectDropDownMedico(){ this.EstudioLab.Medico = this.selectMedicos; },
        selectDropDownSexo(){ this.EstudioLab.Sexo = this.selectSexo; },
        //#endregion

        //#region Metodos Api Estudios
        guardarEstudioLab(TipoEstudio){
            this.apiEstudios.guardaEstudioLab(this.URLApi
                ,this.propContrato , this.propSocio, this.propSolicitante ,this.propPaciente
                ,TipoEstudio
                ,this.EstudioLab.Medico
                ,this.EstudioLab.Sexo
                ,this.EstudioLab.Edad
                ,this.EstudioLab.Estatura
                ,this.EstudioLab.Peso
                ,this.EstudioLab.Oxigenacion
                ,this.EstudioLab.NumLatidos
                ,this.EstudioLab.Doping
                ,this.EstudioLab.Metanfetamina
                ,this.EstudioLab.Marihuana
                ,this.EstudioLab.Cocaina
                ,this.EstudioLab.Glucosa
                ,this.EstudioLab.Bun
                ,this.EstudioLab.Urea
                ,this.EstudioLab.Creatinina
                ,this.EstudioLab.Colesterol
                ,this.EstudioLab.Trigliceridos
                ,this.EstudioLab.AcidoUrico
            )
            .then( resp => {
                console.log('guardarEstudioLab', resp.data);

                if( resp.data.objeto>0){
                    const Numero = resp.data.objeto;
                    this.generaReporte(Numero);
                }
            })
            .catch( error => console.log( error ) );
        },

        generaReporte(Numero){

            this.apiEstudios.generaReporte(this.URLApi
                , Numero
                , this.tipoEstudio
                , this.propSocio
            )
            .then( response => {
                console.log('generaReporte.data', response.data);
                //this.rutaPdf = this.rutaReportes + this.Empresa +'/' + response.data.objeto;
                this.rutaPdf = this.rutaReportes + response.data.objeto;

                //console.log('Ruta PDF: ', this.URLSrc + this.rutaPdf);
                this.imprimir = true;

            })
            .catch( error => console.log( error ) );
        },

        //#endregion


        //#region Metodos API Configuracion
        getMedicos(){
                this.listaMedicos = [];
                this.apiConfiguracion.recuperaMedicos(this.URLApi,0,0)
                .then( response => {
                    const oMedicoArray = [
                        {
                            Nombre:'SIN ASIGNAR',
                            Numero: 0
                        }
                    ];

                    this.listaMedicos.push(...oMedicoArray);
                    this.listaMedicos.push(...response.data.objeto);
                    //console.log('getMedicos', this.listaMedicos);
                })
                .catch( error => console.log(error));
            },
        //#endregion
    },
    //#endregion
    
    //#region beforeMounted
    beforeMount(){
        this.getMedicos();
    },
    //#endregion

    //#region mounted
    mounted(){

    },
    //#endregion


}
</script>