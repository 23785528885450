<template>
    <div>

        <v-text-field   
            v-if="tipoDato.toUpperCase() == tiposValor.valorNumero || this.tipoDato == this.tiposValor.valorDecimal"
            :label="etiqueta"
            v-model="valorNumerico"
            type="number"
            :min="limiteInferior"
            :max="limiteSuperior"
            :suffix="unidad"
            dense
        ></v-text-field>
        
        <v-text-field   
            v-if="tipoDato.toUpperCase() == tiposValor.valorTexto"
            :label="etiqueta"
            v-model="valorTexto"
            type="text"
            :suffix="unidad"
            dense
        ></v-text-field>



    </div>    
</template>


<script>

export default{

    props:{
        idEstudio:Number,
        idPropiedad:Number,
        tipoDato:String,
        etiqueta:String,
        unidad:String,
        limiteInferior:Number,
        limiteSuperior:Number,
        etiquetaReferencia:String,
        getProperty:Boolean

    },

    data(){
        return{
            tiposValor:{
                valorTexto:'STRING',
                valorNumero:'INT',
                valorDecimal:'DECIMAL',
                valorBinario:'BOOL'
            },
            valorDropDown:null,
            valorNumerico:null,
            valorTexto:null,
        }
    },

    methods:{


        enviaPropiedad(){
            if(!this.validaValor) return;

            if(this.tipoDato == this.tiposValor.valorNumero || this.tipoDato == this.tiposValor.valorDecimal ){
                this.$emit('setValorPropiedad', this.valorNumerico);
            }

            else if(this.tipoDato == this.tiposValor.valorTexto){
                this.$emit('setValorPropiedad', this.valorTexto);
            }

            else if(this.tipoDato == this.tiposValor.valorDecimal){

            }

            else if(this.tipoDato == this.tiposValor.valorBinario){

            }
            
        },

        validaValor(){
            let ok = true;
            if(this.tipoDato == this.tiposValor.valorNumero || this.tipoDato == this.tiposValor.valorDecimal ){
                if(!this.valorNumerico){ ok = false; }
            }
            else if(this.tipoDato == this.tiposValor.valorTexto){
                if(!this.valorTexto){ ok = false; }
            }
            else if(this.tipoDato == this.tiposValor.valorDecimal){}
            else if(this.tipoDato == this.tiposValor.valorBinario){}
            else { ok = false; }

            return ok;
        }


    },
    mounted() {
        this.$emit('updateProperty');
    },
    beforeMount(){

    },
    watch:{
        getProperty(newValue){
            if(newValue) { this.enviaPropiedad(); }
            else{ console.log('No envia, aún'); }

            
        }
    }

};

</script>