import axios from 'axios';

export default class ApiContratosService {

    //#region Contratos
    recuperaContratos(url, Numero, Contrato, NombreS, Tipo='TODOS', Estatus='TODOS'){
        //Definicion de ruta
        const controller = 'Contratos';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            NombreS,
            Tipo,
            Estatus
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    guardaContrato( url, Numero,
                    Contrato, Tipo, Estatus, NombreS, RFCS,
                    DomicilioS, ColoniaS, CiudadS, CPS,
                    CorreoS, TelefonosS,
                    DiasServicioS, HorarioS,
                    AntecedentesS, SexoS, FechaNacimientoS, TipoS, EdadS,
                    Beneficiarios, NombreC, TelefonosC, CorreoC,
                    Usuario, FechaContrato, HoraContrato,
                    Observaciones,
                    Extra1, Extra2, Extra3){
        //Definicion de ruta
        const controller = 'Contratos';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Tipo,
            Estatus,
            NombreS,                
            RFCS,
            DomicilioS,
            ColoniaS,
            CiudadS,                
            CPS,
            CorreoS,
            TelefonosS,
            DiasServicioS,
            HorarioS,
            AntecedentesS,
            SexoS,
            FechaNacimientoS,
            TipoS,
            EdadS,
            Beneficiarios,                
            NombreC,
            TelefonosC,
            CorreoC,
            Usuario,
            FechaContrato,
            HoraContrato,
            Observaciones,
            Extra1,
            Extra2,
            Extra3
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaContrato(   url, Numero,
                        Contrato, Tipo, Estatus, NombreS, RFCS,
                        DomicilioS, ColoniaS, CiudadS, CPS,
                        CorreoS, TelefonosS,
                        DiasServicioS, HorarioS,
                        AntecedentesS, SexoS, FechaNacimientoS, TipoS, EdadS,
                        Beneficiarios, NombreC, TelefonosC, CorreoC,
                        Usuario, FechaContrato, HoraContrato,
                        Observaciones,
                        Extra1, Extra2, Extra3){
        //Definicion de ruta
        const controller = 'Contratos';
        const method = 'modifica';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            Tipo,
            Estatus,
            NombreS,                
            RFCS,
            DomicilioS,
            ColoniaS,
            CiudadS,                
            CPS,
            CorreoS,
            TelefonosS,
            DiasServicioS,
            HorarioS,
            AntecedentesS,
            SexoS,
            FechaNacimientoS,
            TipoS,
            EdadS,
            Beneficiarios,                
            NombreC,
            TelefonosC,
            CorreoC,
            Usuario,
            FechaContrato,
            HoraContrato,
            Observaciones,
            Extra1,
            Extra2,
            Extra3
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    eliminaContrato(url, Numero){
        //Definicion de ruta
        const controller = 'Contratos';
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region Beneficiarios
    recuperaBeneficiarios(url, Numero, Contrato, NombreB){
        //Definicion de ruta
        const controller = 'Beneficiarios';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Contrato,
            NombreB
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaBeneficiario( url, 
                        Numero,  Contrato, 
                        NombreB,  
                        TelefonosB,  DomicilioB,  
                        ColoniaB,  CiudadB,  
                        CPB,  CorreoB,  AntecedentesB,  
                        DiasServicioB,  HorarioB,  SexoB, 
                        FechaNacimientoB, TipoB,  EdadB, 
                        Usuario,  FechaRegistro,  HoraRegistro, 
                        Extra1,  Extra2,  Extra3 ){
        //Definicion de ruta
        const controller = 'Beneficiarios';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,  
            Contrato, 
            NombreB,  
            TelefonosB,  
            DomicilioB, 
            ColoniaB,  
            CiudadB,  
            CPB,  
            CorreoB,  
            AntecedentesB,  
            DiasServicioB,  
            HorarioB,  
            SexoB, 
            FechaNacimientoB, 
            TipoB,  
            EdadB, 
            Usuario,  
            FechaRegistro,  
            HoraRegistro, 
            Extra1,  
            Extra2,  
            Extra3
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaBeneficiario(   url, 
                            Numero,  Contrato, 
                            NombreB,  
                            TelefonosB,  DomicilioB,  
                            ColoniaB,  CiudadB,  
                            CPB,  CorreoB,  AntecedentesB,  
                            DiasServicioB,  HorarioB,  SexoB, 
                            FechaNacimientoB, TipoB,  EdadB, 
                            Usuario,  FechaRegistro,  HoraRegistro, 
                            Extra1,  Extra2,  Extra3 ){
        //Definicion de ruta
        const controller = 'Beneficiarios';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,  
            Contrato, 
            NombreB,  
            TelefonosB,  
            DomicilioB, 
            ColoniaB,  
            CiudadB,  
            CPB,  
            CorreoB,  
            AntecedentesB,  
            DiasServicioB,  
            HorarioB,  
            SexoB, 
            FechaNacimientoB, 
            TipoB,  
            EdadB, 
            Usuario,  
            FechaRegistro,  
            HoraRegistro, 
            Extra1,  
            Extra2,  
            Extra3
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaBeneficiario(url, Numero){
        //Definicion de ruta
        const controller = 'Beneficiarios';
        const method = 'elimina';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

}