<template>    
    <div>
        <v-select
                            label="Estatus"
                            :items="listaEstatus"
                            item-value="Value"
                            item-text="Estatus"
                            v-model="selectEstatus"
                            @change="selectDropDownEstatus()"
                        ></v-select>

        <ShowPdfComponent
            :mostrarVentana="showDialog"
            :pTituloPDF="tituloPDF"
            :rutaPdf="rutaPdf"
            @setShowDialog="showDialog = $event"
        ></ShowPdfComponent>
    </div>
</template>

<script>
import {mapState} from "vuex";
import ApiReportesService from '@/services/ApiReportesService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';

import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

export default {

    //#region Components
    components:{
        ShowPdfComponent,
    },
    //#endregion

    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        showDialog:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.imprimir;
            },
            set(newValue){
                console.log('Rpt1SociosComponent');
                this.$emit('setShowDialog', newValue);
            }
        }
    },
    //#endregion

    //#region Props
    props:{
        pMes:String,
        pYear:Number,
        imprimir:Boolean
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiReporte: new ApiReportesService(),
            apiConfiguracion: new ApiConfiguracionService(),
            apiContratos:new ApiContratosService(),
            //----------------------------------------------------
            tituloPDF:'REPORTE DE CONTRATOS',
            rutaPdf:'',
            rutaReportes:'/reportes/Rpt5/',
            nombrePdf:'',
            //----------------------------------------------------
            selectEstatus:null,

            oContrato:{
                Numero:0,
                Contrato:0,
                NombreS:'TODOS'
            },
            listaContratos:[],
            listaEstatus:[
                { Estatus: 'Todo', Value: 'TODO' },
                { Estatus: 'Activo', Value: 'ACTIVO' },
                { Estatus: 'Cancelado', Value: 'CANCELADO' },
            ]
        }
    },
    //#endregion

    //#region Methods
    methods:{

        selectDropDownEstatus(){ this.oContrato.NombreS = this.selectEstatus; },

        //#region ApiReporte
        generaReporte(){
            this.apiReporte.DescargaReporteContratos(this.URLApi, this.selectEstatus)
            .then( response =>{
                //console.log('generaReporte.data', response.data);
                this.rutaPdf = this.rutaReportes + response.data.objeto;

                //console.log('Ruta PDF: ', this.URLSrc + this.rutaPdf);
                this.showDialog = true;

            })
            .catch( error => console.log( error ) );
        },
        //#endregion

        //#region Api Contratos
        getContrato(){
            //this.emitiToComponentFather();
            this.listaContratos = [];
            this.listaContratos.push({Numero:0, NombreS:'TODOS'});

            //Solo puede mostrar uno por seguridad
            this.apiContratos.recuperaContratos( this.URLApi, 
                    this.oContrato.Numero, this.oContrato.Contrato, this.oContrato.NombreS)
            .then( response => {
                //console.log('getContrato.data', response.data);
                this.listaContratos.push(...response.data.objeto);
                if(this.listaContratos.length>0){
                    //this.fillContrato( this.listaContratos[0] );
                }
                else{
                    //this.reset();
                }
            })
            .catch(error=>{console.log(error)});

        },
        //#endregion
    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        //this.getContrato();
    },
    //#endregion

    //#region Watch
    watch:{
        imprimir(val){
            console.log('RptqSociosComponent.pImprimir', val);
            if(val){
                this.generaReporte();
            }
        }
    }
    //#endregion

    
}
</script>