import axios from 'axios';

export default class ApiPuntoVentaService {

    //#region Ventas
    recuperaVenta(url, FechaDesde, FechaHasta, CorteHecho, NumeroCorte){
        //Definicion de ruta
        const controller = 'Ventas';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            FechaDesde,
            FechaHasta,
            CorteHecho, 
            NumeroCorte
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaVenta(url, IdVenta,
                Sucursal, Cliente, Consecutivo,
                Fecha, FechaHora, Renglones,
                TipoPago, FolioPagoTarjeta, Subtotal, Iva,
                Autoriza, PorcentajeDescuento, DescuentoNeto,
                Total, Nota, Factura,
                Vendedor, Porcentaje, Abono,
                Cambio, Saldo, Estatus){
        //Definicion de ruta
        const controller = 'Ventas';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            IdVenta,
            Sucursal,
            Cliente,
            Consecutivo,
            Fecha,
            FechaHora,
            Renglones,
            TipoPago,
            FolioPagoTarjeta,
            Subtotal,
            Iva,
            Autoriza,
            PorcentajeDescuento,
            DescuentoNeto,
            Total,
            Nota,
            Factura,
            Vendedor,
            Porcentaje,
            Abono,
            Cambio,
            Saldo,
            Estatus
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaVenta(url){

    }

    generaTicket(url, IdVenta, Usuario){
        //Definicion de ruta
        const controller = 'Ventas';
        const method = 'generaTicket';

        const objeto = {
            IdVenta,
            Usuario
        };

        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        //return axios.post(`${ url }/${ controller }/${ method }`, json);
        return axios.get(`${ url }/${ controller }/${ method }/${ IdVenta }/${ Usuario }`);

    }


    //#endregion

    //#region Ventas Detalle
    recuperaVentaDetalle(url, Tipo, FechaDesde, FechaHasta){
        //Definicion de ruta
        const controller = 'VentasDetalle';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            Tipo,
            FechaDesde,
            FechaHasta
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaVentaDetalle(url){

        const controller = 'VentasDetalle';
        const method = 'guarda';

        return `${ url }/${ controller }/${ method }`;
    }

    modificaVentaDetalle(url){

    }

    eliminaVentaDetalle(url){

    }

    //#endregion

    //#region Cortes
    guardaCorte(url, ArrayVentas){
        //Definicion de ruta
        const controller = 'Corte';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            ArrayVentas
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

}