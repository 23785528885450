import axios from 'axios';

export default class ApiUsuariosService {

    login(url, Nombre, Usuario, Contrasena, getToken){
        //Definicion de ruta
        const controller = 'Usuarios';
        const method = 'login';

        //Construccion de objeto a enviar
        const objeto = { 
            Nombre,
            Usuario,
            Contrasena,
            getToken
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    recuperaUsuarios(url, nombre){ 
        //Definicion de ruta
        const controller = 'Usuarios';
        const method = 'recupera';

        const objeto = {  
            nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaUsuario(  url, 
                    Nombre, Usuario, Contrasena,
                    Sucursal, Empenos, Abonos,
                    Refrendos, Desempenos, Vencimientos,
                    Ventas, Traspasos, Configuraciones,
                    Reportes, Usuarios, Crum, Estudios ){
        
        //Definicion de ruta
        const controller = 'Usuarios';
        const method = 'guarda';

        const objeto = {  
            Nombre,
            Usuario,
            Contrasena,
            Sucursal,
            Empenos,
            Abonos,
            Refrendos,
            Desempenos,
            Vencimientos,
            Ventas,
            Traspasos,
            Configuraciones,
            Reportes,
            Usuarios,
            Crum, 
            Estudios
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaUsuario(  url, 
                    Nombre, Usuario, Contrasena,
                    Sucursal, Empenos, Abonos,
                    Refrendos, Desempenos, Vencimientos,
                    Ventas, Traspasos, Configuraciones,
                    Reportes, Usuarios, Crum, Estudios ){

        //Definicion de ruta
        const controller = 'Usuarios';
        const method = 'modifica';

        const objeto = {  
            Nombre,
            Usuario,
            Contrasena,
            Sucursal,
            Empenos,
            Abonos,
            Refrendos,
            Desempenos,
            Vencimientos,
            Ventas,
            Traspasos,
            Configuraciones,
            Reportes,
            Usuarios,
            Crum,
            Estudios
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaUsuario(url, Usuario){
        //Definicion de ruta
        const controller = 'Usuarios';
        const method = 'elimina';

        const objeto = {  
            Usuario
        }

        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;

        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
}