import axios from 'axios';

export default class ApiCreditoService {


    //#region Credito
    recuperaCredito(url, Contrato, IdCredito=null, Usuario = null){
        //Definicion de ruta
        const controller = 'Credito';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            Contrato, 
            IdCredito,
            Usuario
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaCredito(url, Contrato, NombreS, FechaInicioPago, FechaVencimiento, MontoTotal, Autoriza){
        //Definicion de ruta
        const controller = 'Credito';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            Contrato, 
            NombreS, 
            FechaInicioPago, 
            FechaVencimiento, 
            MontoTotal, 
            Autoriza
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    pagarCredito(url, token, IdCredito, MontoPago){
        //Definicion de ruta
        const controller = 'Credito';
        const method = 'pagar';

        //Construccion de objeto a enviar
        const objeto = { 
            token,
            IdCredito, 
            MontoPago
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    //#endregion

}