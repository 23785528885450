<template> 
    <v-container>
        
        <v-row>
            <!--INICIO Formulario-->
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="8" md="8">
                        <h3 class="text-center">
                            <b>Usuario</b>
                        </h3>
                    </v-col>
                    <v-col cols="4" md="4">
                        <v-btn
                            @click="clearOUsuario()"
                            class="white--text"
                            color="blue"
                            block
                            >
                            Nuevo
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field 
                            ref="txtNombre"
                            v-model="oUsuario.Nombre" 
                            label="Nombre completo o Departamento"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>

                    <v-col cols="12" md="7">
                        <v-text-field 
                            v-model="oUsuario.Usuario" 
                            label="Usuario"
                            :readonly ="banderaUpdate"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4"> 
                        <v-text-field
                            v-model="oUsuario.Contraseña" 
                            label="Contraseña"
                            required
                        ></v-text-field>                
                    </v-col> 

                </v-row> 

                <!--INICIO Select con checks-->
                <v-row style="display:none;">
                    <v-col cols="12" md="12">
                        <v-select
                            v-model="modulosPermitidos"
                            :items="listaModulos"
                            item-text="descripcion"
                            label="Módulos permitidos"
                            multiple
                            chips
                            hint="Seleccione los módulos permitidos para este usuario"
                            persistent-hint
                            ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <p> Módulos Permitidos </p>
                    </v-col>
                    <!-- Modulos permitidos -->
                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Empeños"
                            :value="oUsuario.Empeños" 
                            label="Contratos"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Abonos"
                            :value="oUsuario.Abonos" 
                            label="Pagos"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Refrendos"
                            :value="oUsuario.Refrendos" 
                            label="Consultas"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Desempeños"
                            :value="oUsuario.Desempeños" 
                            label="Estadísticas"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Configuraciones"
                            :value="oUsuario.Configuraciones" 
                            label="Catálogos"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Usuarios"
                            :value="oUsuario.Usuarios" 
                            label="Usuarios"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Crum"
                            :value="oUsuario.Crum" 
                            label="Ambulancias"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Ventas"
                            :value="oUsuario.Ventas" 
                            label="Ventas"
                        ></v-checkbox>
                    </v-col>

                    <v-col cols="6" md="6">
                        <v-checkbox
                            v-model="oUsuario.Estudios"
                            :value="oUsuario.Estudios" 
                            label="Estudios"
                        ></v-checkbox>
                    </v-col>

                </v-row>

                <!--INICIO Botones de Accion-->
                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn 
                            @click="validate(operacionInsert)"
                            color="green"
                            class="white--text"
                            block
                            :disabled="banderaUpdate"
                            >
                            <v-icon > mdi-content-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn
                            @click="validate(operacionUpdate)"
                            color="green"
                            class="white--text"
                            block
                            :disabled="!banderaUpdate"
                            >
                            <v-icon v-if="banderaUpdate" > mdi-content-save-alert</v-icon>
                            <v-icon v-else> mdi-content-save</v-icon>

                            Modificar
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn
                            @click="vDialog.show = true"
                            color="red"
                            class="white--text"
                            block
                            :disabled="!banderaUpdate"
                            >
                            <v-icon> mdi-delete</v-icon>
                            Eliminar
                        </v-btn>
                    </v-col>
                </v-row>
                <!--FIN Botones de Accion-->
            </v-col>
            <!--FIN Formulario-->

            <!--INICIO Tabla-->
            <v-col cols="12" md="6">
                <v-row>
                    <v-col>
                        <h3 class="text-center">
                            <b>Listado de usuarios</b>
                        </h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="listaUsuarios"
                            :single-select="singleSelect"
                            :footer-props="{
                            'items-per-page-text':'Usuarios por página',
                            }"
                            :loading="loadingDatatable"
                            loading-text="Cargando datos..."
                            no-data-text="Sin datos"
                            item-key="Usuario"
                            class="elevation-1"
                            @click:row="handleClick"> 
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            <!--FIN Tabla-->
        </v-row>

        <!--INICIO Dialog Eliminar -->
        <v-dialog v-model="vDialog.show" persistent max-width="500" >
            <v-card>
                <v-card-title class="headline">
                    ¿Desea continuar?
                </v-card-title>
                <v-card-text>
                    <p>
                        Esta a punto de eliminar el usuario:  <strong> {{ oUsuario.Usuario }} </strong>.
                    </p>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="vDialog.show = false" >
                        No
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deleteUsuario()" >
                        Si
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Dialog Eliminar -->

    </v-container>
</template>
<script> 
import ApiUsuarios from '@/services/ApiUsuariosService';
import {mapState} from "vuex";
export default { 
    computed:{
        ...mapState(['URLSrc', 'URLApi']),

        modulosPermitidos:{
            get:function(){                
                //console.log('modulosPermitidos.get');
                return this.procesaPermisos();
            },
            set:function(val){
                //console.log('modulosPermitidos.set', val);
                this.identificaPermisos(val);
                
            },
        },

    }, 

    props:{
        
    },

    data(){
        return{
            api: new ApiUsuarios(),
            listaUsuarios:[],
            loadingDatatable:true,
            singleSelect: true,
            selected: [],
            headers:[
                { text: 'Usuario', value: 'Usuario' },
                { text: 'Nombre', value: 'Nombre' },
                //{ text: 'Contraseña', value: 'Contraseña' },
            ],
            operacionInsert:'INSERT',
            operacionUpdate:'UPDATE',
            banUpdate:false,
            formulario: { },
            vDialog:{
                show:false,
            },
            listaModulos:[
                { value:1, descripcion: 'CONTRATOS' },
                { value:2, descripcion: 'PAGOS' },//
                { value:3, descripcion: 'CONSULTAS' },
                { value:4, descripcion: 'ESTADISTICAS' },
                { value:5, descripcion: 'CATALOGOS' },//
                { value:6, descripcion: 'USUARIOS' },//
                { value:7, descripcion: 'CRUM' },
                { value:8, descripcion: 'ESTUDIOS' },
            ],
            banderaUpdate:false,
            oUsuario:{
                Usuario:null,
                Nombre:null,
                Contraseña:null,
                Sucursal:null,

                Empeños:null, //Contratos
                Abonos:null,//Pagos
                
                Desempeños:null,//Estadisticas
                Refrendos:null,//Consultas
                Vencimientos:null,
                Ventas:null,//Usuarios
                Usuarios:null,//Crum

                Estudios:null,//Estudios

                Configuraciones:1,//Siempre activo
                Reportes:1,//Siempre activo
                Traspasos:1,//Siempre activo
                Crum:null
            }

        }
    },

    methods:{
        //#region Metodos de la vista
        emitToFather(){
            this.$emit('emitNuevo', true);
        },

        handleClick(value) {
            //console.log('handleClick', value);
            this.fillOUsuario( value );
            this.banderaUpdate = true;
            this.$refs.txtNombre.focus();
        },

        fillOUsuario(item){
            this.oUsuario.Usuario           = item.Usuario;
            this.oUsuario.Nombre            = item.Nombre;
            this.oUsuario.Contraseña        = item.Contraseña;
            this.oUsuario.Usuarios          = item.Usuarios;

            this.oUsuario.Abonos            = item.Abonos;
            this.oUsuario.Configuraciones   = item.Configuraciones;
            this.oUsuario.Desempeños        = item.Desempeños;
            this.oUsuario.Empeños           = item.Empeños;
            this.oUsuario.Refrendos         = item.Refrendos;
            this.oUsuario.Reportes          = item.Reportes;
            this.oUsuario.Sucursal          = item.Sucursal;
            this.oUsuario.Traspasos         = item.Traspasos;
            this.oUsuario.Vencimientos      = item.Vencimientos;
            this.oUsuario.Ventas            = item.Ventas;
            this.oUsuario.Crum              = item.Crum;
            this.oUsuario.Estudios          = item.Estudios;



            //this.emitToFather();
            //console.log('this.oUsuario', this.oUsuario);
        },

        validate(operacion){

            //const usuario = this.identificaPermisos(this.modulosPermitidos);

            if(operacion == this.operacionInsert){
                //console.log('this.oUsuario', this.oUsuario);
                this.insertUsuario(this.oUsuario);
            }
            
            if(operacion == this.operacionUpdate){
                this.updateUsuario(this.oUsuario);
            }
        },

        clearOUsuario(){

            this.$refs.txtNombre.focus();
            this.banderaUpdate = false;

            let nuevoUsuario = {
                Usuario:null,
                Nombre:null,
                Contraseña:null,
                Usuarios:null,

                Abonos:null,
                Configuraciones:null,
                Desempeños:null,
                Empeños:null,
                Refrendos:null,
                Reportes:null,
                Sucursal:null,
                Traspasos:null,
                Vencimientos:null,
                Ventas:null,
                Crum:null,
                Estudios:null,
            }

            //this.$emit('emitNuevoUsuario', nuevoUsuario);
            this.oUsuario = nuevoUsuario;
        },

        procesaPermisos(){
            let array = [];
            //console.log('this.oUsuario',this.oUsuario);
            
            //Pagos
            if (this.oUsuario.Abonos > 0) {
                array.push(2);
            }
            else{
                array.pop(2);
            }

            //Catalogos
            if (this.oUsuario.Configuraciones > 0) {
                array.push(5);
            }
            else{
                array.pop(5);
            }

            //Usuarios
            if (this.oUsuario.Usuarios > 0) {
                array.push(6);
            }
            else{
                array.pop(6);
            }

            //console.log('procesaPermisos.array', array);
            return array;
        },

        identificaPermisos(arrayPermisos){
            console.log('identificaPermisos.arrayPermisos', arrayPermisos);
            let usuario = this.oUsuario;
            /**
                { value:1, descripcion: 'CONTRATOS' },
                { value:2, descripcion: 'PAGOS' },//
                { value:3, descripcion: 'CONSULTAS' },
                { value:4, descripcion: 'ESTADISTICAS' },
                { value:5, descripcion: 'CATALOGOS' },//
                { value:6, descripcion: 'USUARIOS' },//
                { value:7, descripcion: 'CRUM' },
             */

            //debugger;
            //usuario.Abonos = 0;
            //usuario.Configuraciones = 0;
            //usuario.Usuarios = 0;
            arrayPermisos.forEach( item => {
                console.log('forEach.item', item);
                if( item == 2 ){
                    usuario.Abonos = 1;
                }
                
            });
            
            this.oUsuario = usuario;
            return usuario;
        },

        //#endregion

        //#region Metodos con API
        recuperaUsuarios(){
            this.loadingDatatable = true;
            this.api.recuperaUsuarios(this.URLApi, '')
            .then( response =>{
                //console.log("listaUsuarios.data", response.data);

                this.listaUsuarios = response.data.objeto;
                this.loadingDatatable = false;
                this.vDialog.show = false;
                this.clearOUsuario();

            })
            .catch( error=>{
                console.log(error);
                this.loadingDatatable = false;
            });
        },

        insertUsuario(usuario){            
            //console.log('insert', usuario);

            this.api.guardaUsuario(this.URLApi,
                    usuario.Nombre, usuario.Usuario, usuario.Contraseña,
                    usuario.Sucursal, usuario.Empeños,
                    usuario.Abonos, usuario.Refrendos, usuario.Desempeños,
                    usuario.Vencimientos, usuario.Ventas, usuario.Traspasos,
                    usuario.Configuraciones, usuario.Reportes, usuario.Usuarios,
                    usuario.Crum, usuario.Estudios
            )
            .then( response => {
                //console.log('insertUsuario', response.data);
                this.recuperaUsuarios();
                this.clearOUsuario();
            })
            .catch( error => console.log(error) );
        },

        updateUsuario(usuario){
            //console.log('update', JSON.stringify(usuario));
            //console.log('update', usuario);

            this.api.modificaUsuario(this.URLApi,
                    usuario.Nombre, usuario.Usuario, usuario.Contraseña,
                    usuario.Sucursal, usuario.Empeños,
                    usuario.Abonos, usuario.Refrendos, usuario.Desempeños,
                    usuario.Vencimientos, usuario.Ventas, usuario.Traspasos,
                    usuario.Configuraciones, usuario.Reportes, usuario.Usuarios,
                    usuario.Crum, usuario.Estudios
            )
            .then( response => {
                //console.log('modificaUsuario', response.data);
                this.recuperaUsuarios();
            })
            .catch( error => console.log(error) );
        },

        deleteUsuario(){
            //console.log('delete');

            this.api.eliminaUsuario( this.URLApi, this.oUsuario.Usuario )
            .then( response => {
                //console.log('eliminaUsuario', response.data);
                this.vDialog.show = false;
                this.recuperaUsuarios();
            })
            .catch( error => console.log(error) );
        },
        //#endregion


    }, 
    beforeMount(){ 
        //this.$emit('formulario',this.formulario);
        this.recuperaUsuarios();
    },

    updated(){
    },

    watch:{
    },

    
    
}
</script>