<template>
    <div>
        <v-row>
            <v-col cols="12" offset-md="9" md="3"
                    class="d-flex justify-end"
                    >
                <v-btn
                    outlined
                    color="blue"
                    block
                    @click="dialogForm.show = true "
                >
                    <v-icon> mdi-plus</v-icon>  Nuevo
                </v-btn>

            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaLaboratorios"
                    :single-select="singleSelect"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-text':'',
                    }"
                    loading-text="Cargando..."
                    no-data-text="Sin datos disponibles"
                    :loading="loadingDatatable"
                    item-key="Numero"
                    class="elevation-1"
                    @click:row="handleClick"
                ></v-data-table>
            </v-col>
        </v-row>

       <!-- INICIO Formulario -->
       <v-dialog v-model="dialogForm.show" max-width="1000">
           <v-card>
               <v-card-title>
                   <span> Agregar Examen, Rayos X o Laboratorio </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogForm.show = false"
                        >
                            <v-icon color="red" >mdi-close </v-icon>
                        </v-btn>
                        </template>
                    </v-menu>
               </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <FrmBusquedaContratoComponent
                                @setContrato="oCrumLaboratorio.Contrato = $event"
                                @setNombreS="oCrumLaboratorio.Socio = $event"
                                @setTelefonosS="oCrumLaboratorio.Telefonos = $event"
                                @setDomicilioS="oCrumLaboratorio.Domicilio = $event"
                            ></FrmBusquedaContratoComponent>
                        </v-col>
                    </v-row>

                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent >
                        <v-row>

                            <v-col cols="12" md="9">
                                <v-row>
                                    <v-col cols="12" md="7">
                                        <v-text-field
                                            v-model="oCrumLaboratorio.Paciente"
                                            label="Paciente"
                                            required
                                            :counter="80"
                                            :rules="oCrumLaboratorioRules.Paciente"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <v-text-field
                                            v-model="Telefonos"
                                            label="Telefonos"
                                            required
                                            :rules="oCrumLaboratorioRules.Telefonos"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="7">
                                        <v-text-field
                                            v-model="oCrumLaboratorio.Domicilio"
                                            label="Domicilio"
                                            required
                                            :counter="80"
                                            :rules="oCrumLaboratorioRules.Domicilio"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="5">
                                        <v-text-field
                                            v-model="oCrumLaboratorio.NombreLaboratorio"
                                            label="Nombre Laboratorio"
                                            required
                                            :counter="80"
                                            :rules="oCrumLaboratorioRules.NombreLaboratorio"
                                        ></v-text-field>
                                    </v-col>

                                </v-row>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-btn
                                    class="white--text"
                                    color="blue"
                                    block
                                    @click="validate()"
                                >
                                    Guardar
                                </v-btn>
                            </v-col>


                            
                            
                        </v-row>
                        

                    </v-form>

                </v-card-text>

           </v-card>
       </v-dialog>
       <!-- FIN Formulario -->

       <!--INICIO Dialog Eliminar -->
        <v-dialog v-model="dialogEliminar.show" max-width="500" >
            <v-card>
                <v-card-title class="headline">
                    ¿Desea continuar?
                </v-card-title>
                <v-card-text>
                    <h2>
                        Modificando Laboratorio, Rayos-X o Examen Programado
                    </h2>
                    <br>
                    <p>
                        Esta a punto de terminar el registro programado con Numero: {{ dialogEliminar.numeroCrumLaboratorio }}
                    </p>
                    <p>
                        Para el socio <strong> {{ dialogEliminar.socioCrumLaboratorio }} </strong> y contrato: {{ dialogEliminar.contratoCrumLaboratorio }}
                    </p>

                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialogEliminar.show = false" >
                        No
                    </v-btn>
                    <v-btn color="green darken-1" text @click="terminaTrabajoCrumLaboratorio()">
                        Si
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Dialog Eliminar -->

    </div>
</template>

<script>
import {mapState} from "vuex";
import ApiService from '@/services/ApiAmbulanciasService';

import FrmBusquedaContratoComponent from '@/components/Formularios/Contratos/FrmBusquedaContratoComponent';

export default {
    
    //#region Computed
    computed:{ 
        ...mapState(['URLSrc', 'URLApi', 'User']), 
        Telefonos:{
            get:function(){
                //console.log('Computed.Telefonos');
                let tel = this.oCrumLaboratorio.Telefonos.replaceAll('-','');
                this.oCrumLaboratorio.Telefonos = tel;
                return tel;
            },
            set:function(newValue){
                this.oCrumLaboratorio.Telefonos = newValue;
                return newValue;
            }
        }
    }, 
    //#endregion 

    components:{
        FrmBusquedaContratoComponent,
    },

    //#region ---- DATA ----
    data(){
        return{
            api:new ApiService(),
            totalLaboratorios:0,
            listaLaboratorios:[],
            
            selected: [],
            singleSelect: true,
            loadingDatatable:true,
            
            dialogEliminar:{
                show:false,
                numeroCrumLaboratorio:0,
                contratoCrumLaboratorio:0,
                socioCrumLaboratorio:''
            },

            valid:false,
            oCrumLaboratorioRules:{
                Paciente:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                Telefonos:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El teléfono debe ser válido.',
                ],
                Domicilio:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                NombreLaboratorio:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
            },

            dialogForm: {
                show:false,
                texto:'',
            },

            openPanel:[0],

            estatusActivo:'ACTIVO',
            estatusTerminado:'TERMINADO',

            headers:[
                { text: 'Numero', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Contrato', value: 'Contrato' },                
                { text: 'HoraLlamada', value: 'HoraLlamada' },

                { text: 'Nombre Lab', value: 'NombreLaboratorio' },

                { text: 'Paciente', value: 'Paciente' },
                { text: 'Telefonos', value: 'Telefonos' },
                { text: 'Domicilio', value: 'Domicilio' },
                { text: 'Socio', value: 'Socio' },
            ],

            oCrumLaboratorio:{
                Numero : 0,
                Contrato : 0,
                Socio : '',
                Tipo : '',
                Fecha : '',
                FechaHora : '',
                HoraLlamada : '',
                Usuario : '',
                NombreLaboratorio : '',
                Solicitante : '',
                Beneficiario : '',
                Paciente : '',
                Telefonos : '',
                Domicilio : '',
                Signos : '',
                TipoServicio : '',
                Triage : '',
                Equipo : '',
                Horario : '',
                NumeroLab : 0,
                Diagnostico : '',
                Tratamiento : '',
                Observaciones : '',
                Estatus : 'ACTIVO',
                Cancelacion : '',
                Destino : '',
                Viaje : '',
                Extra1 : '',
                Extra2 : '',
                Extra3 : 0,
                Medico : '',
                Mes : '',
                Ano : '',
            },
        }
    },
    //#endregion


    methods:{
        //#region Metodos de la vista
        pasarTotal(totLaboratorios){
            //console.log('totalLaboratorios');
            this.$emit('setTotalLaboratorios', totLaboratorios);
        },

        handleClick(value) {
            //console.log('handleClick', value);
            this.dialogEliminar.numeroCrumLaboratorio = value.Numero;
            this.dialogEliminar.contratoCrumLaboratorio = value.Contrato;
            this.dialogEliminar.socioCrumLaboratorio = value.Socio;
            this.dialogEliminar.show = true;


        },

        validate(){
            const formValid = this.$refs.form.validate();
            if(formValid){
                this.insert(this.oCrumLaboratorio);
            }
            else{
                console.log('Faltan datos en el formulario');
            }

        },
        //#endregion

        //#region Metodos de Api Ambulancias
        get(){
            this.listaLaboratorios = [];
            this.api.recuperaCrumLaboratorios(this.URLApi,
                this.oCrumLaboratorio.Numero, this.oCrumLaboratorio.Contrato, this.oCrumLaboratorio.Estatus
            )
            .then( response =>{ 
                //console.log('recuperaCrumLaboratorios.data', response.data);
                this.listaLaboratorios = response.data.objeto;

                if(this.listaLaboratorios.length>0){
                    
                }
                else{
                    
                }

                this.pasarTotal(this.listaLaboratorios.length);
                this.loadingDatatable = false;

            })
            .catch( error => console.log( error ) );
        },

        insert(crum){
            this.api.guardaCrumLaboratorios(this.URLApi,
                    crum.Numero,  crum.Contrato,  crum.Socio, 
                    crum.Tipo,  crum.Fecha,  crum.FechaHora, 
                    crum.HoraLlamada,  crum.Usuario,  crum.NombreLaboratorio, 
                    crum.Solicitante,  crum.Beneficiario,  crum.Paciente, 
                    crum.Telefonos,  crum.Domicilio,  crum.Signos, 
                    crum.TipoServicio,  crum.Triage,  crum.Equipo, 
                    crum.Horario,  crum.NumeroLab,  crum.Diagnostico, 
                    crum.Tratamiento,  crum.Observaciones,  crum.Estatus, 
                    crum.Cancelacion,  crum.Destino,  crum.Viaje, 
                    crum.Extra1,  crum.Extra2,  crum.Extra3, 
                    crum.Medico,  crum.Mes,  crum.Ano
            )
            .then( response => {
                console.log('guardaCrumLaboratorios.data', response.data);
                if(response.data.objeto>0){
                    this.oCrumLaboratorio.Contrato = 0;
                    this.oCrumLaboratorio.Numero = 0;
                    this.oCrumLaboratorio.Estatus = this.estatusActivo;
                    this.get();
                }

                this.dialogForm.show = false;

            })
            .catch( error => console.log(error) );

        },

        update(){

        },

        terminaTrabajoCrumLaboratorio(){
            
            this.api.terminaTrabajoCrumLaboratorios( this.URLApi, 
                this.dialogEliminar.numeroCrumLaboratorio, 
                this.dialogEliminar.contratoCrumLaboratorio, this.estatusTerminado)
            .then( response =>{

                if ( response.data.objeto ){
                    this.dialogEliminar.show = false;
                    this.get();

                }

            })
            .catch( error => console.log(error) );


        },

        delete(){

        },

        //#endregion
    },
    mounted(){
        this.pasarTotal(this.totalLaboratorios);
        this.get();

    }
    
}
</script>