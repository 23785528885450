import axios from 'axios';

export default class ApiConfiguracionService {

    //#region Ciudades
    recuperaCiudad(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'Ciudades';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaCiudad(url, Nombre){
        //Definicion de ruta
        const controller = 'Ciudades';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaCiudad(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'Ciudades';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaCiudad(url, Numero){
        //Definicion de ruta
        const controller = 'Ciudades';
        const method = 'elimina';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region  Equipos
    recuperaEquipos(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'Equipos';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaEquipo(url, Nombre){
        //Definicion de ruta
        const controller = 'Equipos';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaEquipo(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'Equipos';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaEquipo(url, Numero){
        //Definicion de ruta
        const controller = 'Equipos';
        const method = 'elimina';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region Horarios
    recuperaHorarios(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'Horarios';
        const method = 'recupera';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    guardaHorario(url, Nombre, EquipoNombre){
        //Definicion de ruta
        const controller = 'Horarios';
        const method = 'guarda';

        //Construccion de objeto a enviar
        const objeto = { 
            Nombre,
            Equipo:EquipoNombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    modificaHorario(url, Numero, Nombre, EquipoNombre){
        //Definicion de ruta
        const controller = 'Horarios';
        const method = 'modifica';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre,
            Equipo:EquipoNombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    eliminaHorario(url, Numero){
        //Definicion de ruta
        const controller = 'Horarios';
        const method = 'elimina';

        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region Medicos
    recuperaMedicos(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'Medicos';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    guardaMedico(url, Nombre, Cedula){
        //Definicion de ruta
        const controller = 'Medicos';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Nombre, 
            Cedula
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaMedico(url, Numero, Nombre, Cedula){
        //Definicion de ruta
        const controller = 'Medicos';
        const method = 'modifica';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre, 
            Cedula
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    eliminaMedico(url, Numero){
        //Definicion de ruta
        const controller = 'Medicos';
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region TiposEdad
    recuperaTiposEdad(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'TiposEdad';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    guardaTipoEdad(url, Nombre){
        //Definicion de ruta
        const controller = 'TiposEdad';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaTipoEdad(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'TiposEdad';
        const method = 'modifica';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    eliminaTipoEdad(url, Numero){
        //Definicion de ruta
        const controller = 'TiposEdad';
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region TiposServicio
    recuperaTiposServicio(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'TiposServicio';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    guardaTipoServicio(url, Nombre){
        //Definicion de ruta
        const controller = 'TiposServicio';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaTipoServicio(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'TiposServicio';
        const method = 'modifica';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    eliminaTipoServicio(url, Numero){
        //Definicion de ruta
        const controller = 'TiposServicio';
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region TriageInicial
    recuperaTriagesInicial(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'TriageInicial';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    guardaTriageInicial(url, Nombre){
        //Definicion de ruta
        const controller = 'TriageInicial';
        const method = 'guarda';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    modificaTriageInicial(url, Numero, Nombre){
        //Definicion de ruta
        const controller = 'TriageInicial';
        const method = 'modifica';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Nombre
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    
    eliminaTriageInicial(url, Numero){
        //Definicion de ruta
        const controller = 'TriageInicial';
        const method = 'elimina';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion

    //#region Presentaciones
    recuperaPresentaciones(url, Numero, Presentacion){
        //Definicion de ruta
        const controller = 'Presentaciones';
        const method = 'recupera';
    
        //Construccion de objeto a enviar
        const objeto = { 
            Numero,
            Presentacion
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }
    //#endregion
}