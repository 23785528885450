<template>
    <div>
        <HeaderAppComponent :tituloPagina="tituloPagina" />
        <ControlPagosComponent></ControlPagosComponent>

    </div>
</template>

<script>
import HeaderAppComponent from '@/components/HeaderAppComponent'
import ControlPagosComponent from '@/components/Pagos/ControlPagosComponent'

export default {
    //#region Components
    components:{
        HeaderAppComponent,
        ControlPagosComponent
    },
    //#endregion

    //#region Data
    data(){
        return{
            tituloPagina:'Pagos'
        }
    },
    //#endregion

    //#region Methods
    methods:{

    },
    //#endregion

    //#region mounted
    mounted(){

    },
    //#endregion

    //#region beforeMount
    beforeMount(){

    },
    //#endregion

}
</script>