<template>
    <div>
        <HeaderAppComponent :tituloPagina="tituloPagina" />
        
        <!--Cuerpo de la pagina-->
        <v-container>
            <!--INICIO Renglon para seleccionar mes y año-->
            <v-row>
                <v-col cols="12" md="5">
                    <v-select
                        label="Reporte"
                        v-model="selectReporte"
                        :items="listaReportes"
                        item-text="text"
                        item-value="value"
                        @change="selectDropDownReporte()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select
                        label="Mes"
                        v-model="selectMes"
                        :items="listaMeses"
                        item-text="nombreMes"
                        item-value="numeroMes"
                        @change="selectDropDownMes()"
                    ></v-select>
                </v-col>
                <v-col cols="12"  md="2">
                    <v-select
                        label="Año"
                        v-model="selectYear"
                        :items="listaYears"
                        @change="selectDropDownYear()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-btn
                        color="red"
                        class="white--text"
                        block
                        @click="cambiarEstatusImpresion()"
                        >
                        <v-icon>mdi-printer</v-icon>
                        Imprimir
                    </v-btn>
                </v-col>
            </v-row>
            <!--FIN Renglon para seleccionar mes y año-->

            <!-- INICIO filas para cada reporte -->

            <!--Reporte #1-->
            <v-row>
                <v-col>
                    <v-list two-line dense>
                        <!--Reporte 1-->
                        <v-list-item    v-if="selectReporte=='ESTADISTICAS_CORPORATIVAS'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="1. Estadísticas Corporativos."
                                                    v-model="check_Rpt1_EstadisticasCorporativas"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt1SociosComponent
                                                    :pMes="filtroFecha.Mes"
                                                    :pYear="filtroFecha.Year"
                                                    :imprimir="imprimir.rpt1"
                                                    @setShowDialog="imprimir.rpt1 = $event"
                                                ></Rpt1SociosComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>                            
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 2-->
                        <v-list-item    v-if="selectReporte=='ESTADISTICAS_FAMILIARES'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <!--Reporte #2-->
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="2. Estadísticas Familiares."
                                                    v-model="check_Rpt2_EstadisticasFamiliares"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="6" md="8">
                                                <Rpt2FamiliaresComponent
                                                    :pMes="filtroFecha.Mes"
                                                    :pYear="filtroFecha.Year"
                                                    :imprimir="imprimir.rpt2"
                                                    @setShowDialog="imprimir.rpt2 = $event"
                                                ></Rpt2FamiliaresComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 3-->
                        <v-list-item    v-if="selectReporte=='BITACORA_VISITAS_AMBULANCIAS'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #3-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="3. Bitacora de visitas de ambulancias."
                                                    v-model="check_Rpt3_CrumVisitas"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <Rpt3CrumVisitasComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt3"
                                                            @setShowDialog="imprimir.rpt3 = $event"
                                                        ></Rpt3CrumVisitasComponent>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <RptCrumComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                        ></RptCrumComponent>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 4-->
                        <v-list-item    v-if="selectReporte=='SERVICIOS_POR_EQUIPO'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #4-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="4. Servicios por equipo."
                                                    v-model="check_Rpt4_ServiciosEquipo"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt4ServiciosEquipoComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt4"
                                                            @setShowDialog="imprimir.rpt4 = $event"
                                                ></Rpt4ServiciosEquipoComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 5-->
                        <v-list-item    v-if="selectReporte=='REPORTE_CONTRATOS'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #5-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="5. Reporte de contratos."
                                                    v-model="check_Rpt5_Contratos"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt5ContratosComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt5"
                                                            @setShowDialog="imprimir.rpt5 = $event"
                                                ></Rpt5ContratosComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 6-->
                        <v-list-item    v-if="selectReporte=='SERVICIO_SOCIO'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #6-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="6. Servicios de un socio."
                                                    v-model="check_Rpt6_ServicioSocios"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt6ServiciosSocioComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt6"
                                                            @setShowDialog="imprimir.rpt6 = $event"
                                                ></Rpt6ServiciosSocioComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 7-->
                        <v-list-item    v-if="selectReporte=='VISITAS_MEDICOS'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #7-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="7. Visitas de médicos."
                                                    v-model="check_Rpt7_VisitaMedicos"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt7VisitaMedicosComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt7"
                                                            @setShowDialog="imprimir.rpt7 = $event"
                                                ></Rpt7VisitaMedicosComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 8-->
                        <v-list-item    v-if="selectReporte=='CORTE_MENSUAL'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #8-->
                                        <v-row>
                                            <v-col cols="6" md="4">
                                                <v-checkbox
                                                    label="8. Corte mensual."
                                                    v-model="check_Rpt8_CorteMes"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="6" md="8">
                                                <Rpt8CorteMesComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt8"
                                                            @setShowDialog="imprimir.rpt8 = $event"
                                                ></Rpt8CorteMesComponent>                                                
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 9-->
                        <v-list-item    v-if="selectReporte=='INVENTARIO'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #9-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="9. Inventario."
                                                    v-model="check_Rpt9_Inventario"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt9InventarioComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt9"
                                                            @setShowDialog="imprimir.rpt9 = $event"
                                                ></Rpt9InventarioComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                        <!--Reporte 10 - Estudios-->
                        <v-list-item    v-if="selectReporte=='ESTUDIOS'"
                                        @click="()=>{}">
                            <v-list-item-content>
                                <v-card>
                                    <v-card-text>
                                        <!--Reporte #10-->
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    label="10. Estudios."
                                                    v-model="check_Rpt10_Estudios"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <Rpt10EstudiosComponent
                                                            :pMes="filtroFecha.Mes"
                                                            :pYear="filtroFecha.Year"
                                                            :imprimir="imprimir.rpt10"
                                                            @setShowDialog="imprimir.rpt10 = $event"
                                                ></Rpt10EstudiosComponent>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
            <!-- FIN filas para cada reporte -->

        </v-container>


        <v-dialog v-model="dialogAlert.show" max-width="400" class="">
            <v-card :color="dialogAlert.color" >
                <v-card-title>
                    <span class="white--text"> SAM </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogAlert.show = false"
                        >
                            <!--<v-icon color="black" >mdi-close </v-icon>-->
                        </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <v-alert 
                        v-model="dialogAlert.show"
                        type="info"
                        :color="dialogAlert.color"
                        class="white--text"
                    >
                        {{ dialogAlert.mensaje }}
                    </v-alert>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="red darken-1" 
                            class="white--text"
                            @click="dialogAlert.show = false" >
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </div>    
</template>

<script>
import HeaderAppComponent from '@/components/HeaderAppComponent'
import RptCrumComponent from '@/components/Reportes/RptCrumComponent'

import Rpt1SociosComponent from '@/components/Reportes/Rpt1SociosComponent'
import Rpt2FamiliaresComponent from '@/components/Reportes/Rpt2FamiliaresComponent'
import Rpt3CrumVisitasComponent from '@/components/Reportes/Rpt3CrumVisitasComponent'
import Rpt4ServiciosEquipoComponent from '@/components/Reportes/Rpt4ServiciosEquipoComponent'
import Rpt5ContratosComponent from '@/components/Reportes/Rpt5ContratosComponent'
import Rpt6ServiciosSocioComponent from '@/components/Reportes/Rpt6ServiciosSocioComponent'
import Rpt7VisitaMedicosComponent from '@/components/Reportes/Rpt7VisitaMedicosComponent'
import Rpt8CorteMesComponent from '@/components/Reportes/Rpt8CorteMesComponent'
import Rpt9InventarioComponent from '@/components/Reportes/Rpt9InventarioComponent'
import Rpt10EstudiosComponent from '@/components/Reportes/Rpt10EstudiosComponent'

import ApiReportesService from '@/services/ApiReportesService';


export default {
    //#region Components
    components:{
        HeaderAppComponent,
        RptCrumComponent,
        Rpt1SociosComponent,
        Rpt2FamiliaresComponent,
        Rpt3CrumVisitasComponent,
        Rpt4ServiciosEquipoComponent,
        Rpt5ContratosComponent,
        Rpt6ServiciosSocioComponent,
        Rpt7VisitaMedicosComponent,
        Rpt8CorteMesComponent,
        Rpt9InventarioComponent,
        Rpt10EstudiosComponent
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiReporte: new ApiReportesService(),
            tituloPagina:'Reportes',
            listaMeses:[],
            listaYears:[],
            selectMes:null,
            selectYear:null,
            filtroFecha:{
                Mes:null,
                Year:null
            },
            listaReportes:[],
            selectReporte:'',
            reporteSeleccionado:'',
            check_Rpt1_EstadisticasCorporativas:false,
            check_Rpt2_EstadisticasFamiliares:false,
            check_Rpt3_CrumVisitas:false,
            check_Rpt4_ServiciosEquipo:false,
            check_Rpt5_Contratos:false,
            check_Rpt6_ServicioSocios:false,
            check_Rpt7_VisitaMedicos:false,
            check_Rpt8_CorteMes:false,
            check_Rpt9_Inventario:false,
            check_Rpt10_Estudios:false,
            //---------------------------------------
            rpt1SociosImprimir:false,
            rpt2FamiliaresImprimir:false,
            rpt3CrumVisitasImprimir:false,
            rpt4ServiciosEquipoImprimir:false,
            rpt5ContratosImprimir:false,
            rpt6ServiciosSocioImprimir:false,
            rpt7VisitaMedicosImprimir:false,
            rpt8CorteMesImprimir:false,
            rpt9InventarioImprimir:false,
            rpt10EstudiosImprimir:false,
            //---------------------------------------
            imprimir:{
                rpt1:false,
                rpt2:false,
                rpt3:false,
                rpt4:false,
                rpt5:false,
                rpt6:false,
                rpt7:false,
                rpt8:false,
                rpt9:false,
                rpt10:false,
            },
            dialogAlert:{
                mensaje:'',
                show:false,
                color:'#2A3B4D'
            },
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la vista

        selectDropDownMes(){ this.filtroFecha.Mes = this.selectMes; },
        selectDropDownYear(){ this.filtroFecha.Year = this.selectYear; },
        selectDropDownReporte(){ 
            this.reporteSeleccionado = this.selectReporte; 
            this.doReporteCheck(this.reporteSeleccionado);
        },

        doReporteCheck( valueReporte ){
            console.log('doReporteCheck.valueReporte', valueReporte);

            //ESTADISTICAS_CORPORATIVAS
            if (valueReporte == this.listaReportes[0].value){
                this.check_Rpt1_EstadisticasCorporativas = true;
            }
            else{
                this.check_Rpt1_EstadisticasCorporativas = false;
            }

            //ESTADISTICAS_FAMILIARES
            if (valueReporte == this.listaReportes[1].value){
                this.check_Rpt2_EstadisticasFamiliares = true;
            }
            else{
                this.check_Rpt2_EstadisticasFamiliares = false;
            }

            //BITACORA_VISITAS_AMBULANCIAS
            if (valueReporte == this.listaReportes[2].value){
                this.check_Rpt3_CrumVisitas = true;
            }
            else{
                this.check_Rpt3_CrumVisitas = false;
            }

            //SERVICIOS_POR_EQUIPO
            if (valueReporte == this.listaReportes[3].value){
                this.check_Rpt4_ServiciosEquipo = true;
            }
            else{
                this.check_Rpt4_ServiciosEquipo = false;
            }

            //REPORTE_CONTRATOS
            if (valueReporte == this.listaReportes[4].value){
                this.check_Rpt5_Contratos = true;
            }
            else{
                this.check_Rpt5_Contratos = false;
            }

            //SERVICIO_SOCIO
            if (valueReporte == this.listaReportes[5].value){
                this.check_Rpt6_ServicioSocios = true;
            }
            else{
                this.check_Rpt6_ServicioSocios = false;
            }

            //VISITAS_MEDICOS
            if (valueReporte == this.listaReportes[6].value){
                this.check_Rpt7_VisitaMedicos = true;
            }
            else{
                this.check_Rpt7_VisitaMedicos = false;
            }

            //CORTE_MENSUAL
            if (valueReporte == this.listaReportes[7].value){
                this.check_Rpt8_CorteMes = true;
            }
            else{
                this.check_Rpt8_CorteMes = false;
            }

            //INVENTARIO
            if (valueReporte == this.listaReportes[8].value){
                this.check_Rpt9_Inventario = true;
            }
            else{
                this.check_Rpt9_Inventario = false;
            }

            //INVENTARIO
            if (valueReporte == this.listaReportes[8].value){
                this.check_Rpt9_Inventario = true;
            }
            else{
                this.check_Rpt9_Inventario = false;
            }

            //ESTUDIOS
            if (valueReporte == this.listaReportes[9].value){
                this.check_Rpt10_Estudios = true;
            }
            else{
                this.check_Rpt10_Estudios = false;
            }



        },


        getListaYears(){
            const date = new Date();
            const yActual = date.getFullYear();

            for (let y = yActual; y >= 2010; y--) {
                this.listaYears.push(y);
            }

        },
        getListaMeses(){
            this.listaMeses = [
                {nombreMes:'Enero', numeroMes:'01'},
                {nombreMes:'Febrero', numeroMes:'02'},
                {nombreMes:'Marzo', numeroMes:'03'},
                {nombreMes:'Abril', numeroMes:'04'},
                {nombreMes:'Mayo', numeroMes:'05'},
                {nombreMes:'Junio', numeroMes:'06'},
                {nombreMes:'Julio', numeroMes:'07'},
                {nombreMes:'Agosto', numeroMes:'08'},
                {nombreMes:'Septiembre', numeroMes:'09'},
                {nombreMes:'Octubre', numeroMes:'10'},
                {nombreMes:'Noviembre', numeroMes:'11'},
                {nombreMes:'Diciembre', numeroMes:'12'},
            ]
        },

        getListaReportes(){
            this.listaReportes = this.apiReporte.getListaReportes();
            this.selectReporte = this.listaReportes[0].value;
            this.doReporteCheck(this.selectReporte);
        },

        cambiarEstatusImpresion(){

            if(!this.validateMonthYear()) return;

            //console.log('cambiarEstatusImpresion', this.check_Rpt1_EstadisticasCorporativas);
            this.imprimir.rpt1 = this.check_Rpt1_EstadisticasCorporativas?true:false;
            this.imprimir.rpt2 = this.check_Rpt2_EstadisticasFamiliares?true:false;
            this.imprimir.rpt3 = this.check_Rpt3_CrumVisitas?true:false;
            this.imprimir.rpt4 = this.check_Rpt4_ServiciosEquipo?true:false;
            this.imprimir.rpt5 = this.check_Rpt5_Contratos?true:false;
            this.imprimir.rpt6 = this.check_Rpt6_ServicioSocios?true:false;
            this.imprimir.rpt7 = this.check_Rpt7_VisitaMedicos?true:false;
            this.imprimir.rpt8 = this.check_Rpt8_CorteMes?true:false;
            this.imprimir.rpt9 = this.check_Rpt9_Inventario?true:false;
        },

        validateMonthYear(){
            let isValid = true;

            if(!this.filtroFecha.Year){
                //console.log('this.filtroFecha.Year', this.filtroFecha.Year);
                this.dialogAlert.mensaje = 'Falta seleccionar el año.';
                isValid = false;
            }

            if(!this.filtroFecha.Mes){
                //console.log('this.filtroFecha.Mes', this.filtroFecha.Mes);
                this.dialogAlert.mensaje = 'Falta seleccionar el mes.';
                isValid = false;
            }
            
            if(!isValid){ this.dialogAlert.show = true; }


            return isValid;
        }

        //#endregion

    },
    //#endregion

    //#region mounted
    mounted(){

    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        this.getListaYears();
        this.getListaMeses();
        this.getListaReportes();
    }
    //#endregion


}
</script>