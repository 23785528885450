<template>
    <div>
        <HeaderAppComponent :tituloPagina="tituloPagina" />
            <v-expansion-panels v-model="openPanel" multiple focusable>

                <!--INICIO FormaPagoComponent-->
                <v-expansion-panel v-if="1==1" >
                    <v-expansion-panel-header>
                        <template v-slot:default="{ open }" >
                            <v-col cols="4">
                                <strong> Forma de Pago </strong>
                            </v-col>

                            <v-fade-transition 
                                    v-if="open"
                                    leave-absolute>
                            </v-fade-transition>

                            <span><!--v-else-->
                            </span>

                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <FormaPagoComponent
                            :DatosPago="DatosPago"
                        />
                    </v-expansion-panel-content>

                </v-expansion-panel>
                <!--FIN FormaPagoComponent-->

                <!--INICIO ListaCompraComponent-->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <template v-slot:default="{ open }" >
                            <v-col cols="4">
                                <strong> Servicios / Medicamento </strong>
                            </v-col>

                            <v-fade-transition 
                                    v-if="open"
                                    leave-absolute>
                            </v-fade-transition>

                            <span><!--v-else-->       
                            </span>
                            
                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <ListaCompraComponent 
                            :TipoPago="TipoPago"
                            :DatosPago="DatosPago"
                            @setDescuento =" DatosPago.Descuento = $event "
                            @setFormaPago =" DatosPago.FormaPago = $event "
                            @setFactura =" DatosPago.Factura = $event "
                            @setComisionTarjeta =" DatosPago.ComisionTarjeta = $event "
                        />
                    </v-expansion-panel-content>

                </v-expansion-panel>
                <!--FIN ListaCompraComponent-->

            </v-expansion-panels>
    
    </div>
</template>

<script>

import HeaderAppComponent from '@/components/HeaderAppComponent'

import FormaPagoComponent from '@/components/PuntoVenta/FormaPagoComponent'
import ListaCompraComponent from '@/components/PuntoVenta/ListaCompraComponent'

export default {
    //#region Components
    components:{
        HeaderAppComponent,
        FormaPagoComponent,
        ListaCompraComponent
    },
    //#endregion

    //#region Data
    data(){
        return{
            tituloPagina:'Ventas',
            openPanel:[0,1],
            TipoPago:'EFECTIVO',
            DatosPago:{
                Factura:'',
                Empresa:'',
                Usuario:'',
                Autoriza:'',
                Descuento:'',
                FormaPago:'EFECTIVO',
                ComisionTarjeta:0
            }

        }
    },
    //#endregion

    //#region Methods
    methods:{

    },
    //#endregion

    //#region mounted
    mounted(){

    },
    //#endregion

    //#region beforeMount
    beforeMount(){

    },
    //#endregion

}
</script>