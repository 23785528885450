<template>
    <div>
        
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";

import ApiInventario from '@/services/ApiInventarioService';
import ApiPuntoVenta from '@/services/ApiPuntoVentaService';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    //#endregion
    
    //#region Data
    data(){
        return{
            apiInventario: new ApiInventario(),
            apiPVenta: new ApiPuntoVenta(),
            //--------------------------------------------------------

        }
    },
    //#endregion

    //#region Methods
    methods:{

    },
    //#endregion


}
</script>