<template>    
    <div>
        <v-row>
            <v-col cols="12" md="4">
                <v-select
                        label="Equipo"
                        :items="listaEquipos"
                        value="Nombre"
                        item-text="Nombre"
                        v-model="selectEquipo"
                        @change="selectDropDownEquipo()"
                    ></v-select>

            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    label="Desde:"
                    v-mask="'####-##-##'"
                    v-model="filtros.FechaDesde" 
                    :rules="rules.fecha"
                    placeholder="AAAA-MM-DD"
                    hint="AAAA-MM-DD Ej.: 2019-01-15"
                    persistent-hint
                    />
            </v-col>
            <v-col cols="12"  md="4">
                <v-text-field
                    label="Hasta:"
                    v-mask="'####-##-##'"
                    v-model="filtros.FechaHasta" 
                    :rules="rules.fecha"
                    placeholder="AAAA-MM-DD"
                    hint="AAAA-MM-DD Ej.: 2019-01-15"
                    persistent-hint
                    />
            </v-col>
        </v-row>


        <ShowPdfComponent
            :mostrarVentana="showDialog"
            :pTituloPDF="tituloPDF"
            :rutaPdf="rutaPdf"
            @setShowDialog="showDialog = $event"
        ></ShowPdfComponent>
    </div>
</template>

<script>
import {mapState} from "vuex";
import ApiReportesService from '@/services/ApiReportesService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';

import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

export default {

    //#region Components
    components:{
        ShowPdfComponent,
    },
    //#endregion

    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        showDialog:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.imprimir;
            },
            set(newValue){
                //console.log('Rpt1SociosComponent');
                this.$emit('setShowDialog', newValue);
            }
        }
    },
    //#endregion

    //#region Props
    props:{
        pMes:String,
        pYear:Number,
        imprimir:Boolean
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiReporte: new ApiReportesService(),
            apiConfiguracion: new ApiConfiguracionService(),
            apiContratos:new ApiContratosService(),
            //----------------------------------------------------
            tituloPDF:'REPORTE DE SERVICIOS POR EQUIPO',
            rutaPdf:'',
            rutaReportes:'/reportes/Rpt4/',
            nombrePdf:'',
            //----------------------------------------------------
            selectEquipo:null,            
            listaEquipos:[],
            filtros:{
                Equipo:null,
                FechaDesde:null,
                FechaHasta:null
            },
            //----------------------------------------------------
            rules:{
                fecha:[
                    v => /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'La fecha debe de tener el formato indicado.',
                ],
            },
        }
    },
    //#endregion

    //#region Methods
    methods:{

        //#region Metodos de la vista
        fechaActual(mesAnterior=1){//Si se pasa el parametro 0, da el mes anterior
            const date =  new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+mesAnterior;
            const D = date.getDate();
            return Y.toString() + '-' + M.toString().padStart(2,'00') + '-' + D.toString().padStart(2,'00');
        },
        //#endregion

        selectDropDownEquipo(){ this.filtros.Equipo = this.selectEquipo; },

        //#region ApiReporte
        generaReporte(){
            this.apiReporte.DescargaReporteServiciosEquipo(this.URLApi, this.filtros.Equipo, 
                this.filtros.FechaDesde, this.filtros.FechaHasta )
            .then( response =>{
                //console.log('generaReporte.data', response.data);
                this.rutaPdf = this.rutaReportes + response.data.objeto;

                //console.log('Ruta PDF: ', this.URLSrc + this.rutaPdf);
                this.showDialog = true;

            })
            .catch( error => console.log( error ) );
        },
        //#endregion

        //#region Api Contratos
        getEquipos(){
            this.apiConfiguracion.recuperaEquipos(this.URLApi, 0, 0)
            .then( response =>{
                //console.log("recuperaEquipos.data", response.data);
                this.listaEquipos = response.data.objeto;

            })
            .catch(error=>{console.log(error);});
        }
        //#endregion
    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        this.filtros.FechaDesde = this.fechaActual(0);
        this.filtros.FechaHasta = this.fechaActual();

        this.getEquipos();
    },
    //#endregion

    //#region Watch
    watch:{
        imprimir(val){
            //console.log('RptqSociosComponent.pImprimir', val);
            if(val){
                this.generaReporte();
            }
        }
    }
    //#endregion

    
}
</script>