<template>    
    <div>
        <v-row>
            <v-col cols="12" md="8">
                <v-btn 
                        block
                        color="blue"
                        class="white--text"
                        @click="iniciaEstudios()">
                    Ver estudios
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="estudiosDialog.show" scrollable >
            <v-card>
                <v-card-title>Estudios realizados</v-card-title>
                <v-divider></v-divider>

                <v-card-text style="height:400px;">
                    <div v-for="(item, index) in listaEstudios" :key="index">
                        <v-row>
                            <v-col md="2">
                                Fecha: {{ item.Fecha}}
                            </v-col>
                            <v-col md="3">
                                Clave: {{ item.ClaveUnica}}
                            </v-col>
                            <v-col md="4">
                                Paciente: {{ item.Paciente}}
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="estudiosDialog.show = false"
                        >
                        Cerrar
                    </v-btn>
                    <!--
                        <v-btn
                        color="blue darken-1"
                        text
                        @click="estudiosDialog.show = false"
                        >
                        Enviar
                    </v-btn>
                    -->
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>
import {mapState} from "vuex";
import ApiReportesService from '@/services/ApiReportesService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';
import ApiEstudiosService from '@/services/ApiEstudiosService';

import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

export default {

    //#region Components
    components:{
        ShowPdfComponent,
    },
    //#endregion

    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        showDialog:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.imprimir;
            },
            set(newValue){
                //console.log('Rpt1SociosComponent');
                this.$emit('setShowDialog', newValue);
            }
        }
    },
    //#endregion

    //#region Props
    props:{
        pMes:String,
        pYear:Number,
        imprimir:Boolean
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiReporte: new ApiReportesService(),
            apiConfiguracion: new ApiConfiguracionService(),
            apiContratos:new ApiContratosService(),
            apiEstudios:new ApiEstudiosService(),
            //----------------------------------------------------
            tituloPDF:'REPORTE DE INVENTARIO',
            rutaPdf:'',
            rutaReportes:'/reportes/Rpt9/',
            nombrePdf:'',
            //----------------------------------------------------
            selectEstatus:null,

            estudiosDialog:{
                show:false,
            },

            oContrato:{
                Numero:0,
                Contrato:0,
                NombreS:'TODOS'
            },
            listaContratos:[],
            listaEstatus:[
                { Estatus: 'Todo', Value: 'TODO' },
                { Estatus: 'Activo', Value: 'ACTIVO' },
                { Estatus: 'Cancelado', Value: 'CANCELADO' },
            ],
            listaTipos:[ 'Todos', 'Farmacia', 'Miscelanea', 'Servicios'],
            selectTipo:null,
            filtro:{
                Tipo:null
            },
            listaEstudios:[],
            filterModel:{
                Numero:0,
                Contrato:0,
                Estatus:0,
                FechaDesde:'',
                FechaHasta:''
            },

        }
    },
    //#endregion

    //#region Methods
    methods:{

        selectDropDownTipo(){ this.filtro.Tipo = this.selectTipo; },

        //#region ApiReporte
        generaReporte(){
            this.apiReporte.DescargaReporteInventario(this.URLApi, this.filtro.Tipo)
            .then( response =>{
                //console.log('generaReporte.data', response.data);
                this.rutaPdf = this.rutaReportes + response.data.objeto;

                //console.log('Ruta PDF: ', this.URLSrc + this.rutaPdf);
                this.showDialog = true;

            })
            .catch( error => console.log( error ) );
        },
        //#endregion

        //#region Api Contratos
        getContrato(){
            //this.emitiToComponentFather();
            this.listaContratos = [];
            this.listaContratos.push({Numero:0, NombreS:'TODOS'});

            //Solo puede mostrar uno por seguridad
            this.apiContratos.recuperaContratos( this.URLApi, 
                    this.oContrato.Numero, this.oContrato.Contrato, this.oContrato.NombreS)
            .then( response => {
                //console.log('getContrato.data', response.data);
                this.listaContratos.push(...response.data.objeto);
                if(this.listaContratos.length>0){
                    //this.fillContrato( this.listaContratos[0] );
                }
                else{
                    //this.reset();
                }
            })
            .catch(error=>{console.log(error)});

        },
        //#endregion
    
        //#region Api Estudios
        async getEstudios(){
            this.apiEstudios.recuperaEstudios( this.URLApi, 
                    this.filterModel.Numero, this.filterModel.Contrato, this.filterModel.Estatus,
                    this.filterModel.FechaDesde, this.filterModel.FechaHasta )
            .then( response => {
                console.log("getEstudios.data", response.data);
                this.listaEstudios.push( ... response.data.objeto );

            })
            .catch(error=>{console.log(error)});
        },
        //#endregion

        //#region Eventos de la pantalla
        async iniciaEstudios(){
            await this.getEstudios();
            this.estudiosDialog.show = true;

        }
        //#endregion

    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        
    },
    //#endregion

    //#region Watch
    watch:{
        imprimir(val){
            //console.log('RptqSociosComponent.pImprimir', val);
            if(val){
                this.generaReporte();
            }
        }
    }
    //#endregion

    
}
</script>