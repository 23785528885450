<template>

    <v-dialog v-model="mostrarVentana" persistent max-width="1000">
        <v-card>
            <v-card-title>
                <span > Ticket </span>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="mostrarVentana = false"
                        >
                            <v-icon color="red" >mdi-close </v-icon>
                        </v-btn>
                    </template>
                </v-menu>
            </v-card-title>

            <v-card-text>

                <!--
                    <embed src="http://www.vinolas.cat/webs/bibliotecadigital/materialperlestudi/llibres_per_estudiar/DaleCargengieComoGanarAmigosEinfluirSobreLasPersonas.pdf" 
                        type="application/pdf" 
                        width="100%" 
                        height="800px" />
                -->

                <embed  :src="URLSrc + rutaStorageReportesPVenta + nombrePDF " 
                        type="application/pdf" 
                        width="100%" 
                        height="800px" />

            </v-card-text>
        </v-card>
    </v-dialog>
    
</template>

<script>
import {mapState} from "vuex";

import ApiPuntoVenta from '@/services/ApiPuntoVentaService';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        mostrarVentana:{
            get(){
                //console.log('mostrarVentana.get', this.showDialog);
                return this.showDialog;
            },
            set(newValue){
                //console.log('mostrarVentana.set', newValue);
                this.$emit('setShowDialog', newValue);
            }

        }

    },
    //#endregion

    //#region Props
    props:{
        showDialog:Boolean,
        IdVenta:Number

    },
    //#endregion

    //#region Data
    data(){
        return{
            apiPVenta: new ApiPuntoVenta(),
            pdfBruto:null,
            pdfRequerido:null, //require()
            rutaStorageReportesPVenta:'/reportes/PVenta/',
            rutaPdf:'/reportes/PVenta/30.pdf',
            nombrePDF:'',
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos del api del punto de venta
        generaTicket(){
            this.apiPVenta.generaTicket(this.URLApi, this.IdVenta, this.User.Usuario)
            .then( response =>{
                console.log('generaTicket', response.data);
                
                //this.pdfBruto = response.data;
                this.nombrePDF = response.data;
                //this.pdfRequerido = require(response.data);

                //console.log('Ruta pdf ', this.URLSrc + this.rutaPdf);
            })
            .catch( error => console.log( error ) );
        },
        //#endregion
    },
    //#endregion
    
    //#region watch
    watch:{
        showDialog(val){
            if(val){
                this.generaTicket();
            }
        }
    }
    //#endregion
    
}
</script>