<template>
    <div>
        <v-row>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="oEstudio.Solicitante"
                            label="Solicitante"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="oEstudio.Paciente"
                            label="Paciente"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Tipo de Estudio"
                            :items="listaEstudios"
                            v-model="selectEstudio"
                            item-value="id"
                            item-text="nombre"
                            dense
                            @change="selectDropDownTipoEstudio()"
                        >
                        </v-select>
                    </v-col>
                </v-row>

            </v-col>   

        </v-row>

        <div v-if="Empresa=='GENERAL'">
            
            <v-row v-if="selectEstudio=='3'">
                <v-col>
                    <F4AnticuerposAntiHIV1y2Component
                        :propContrato="Number.parseInt(oEstudio.Contrato)"
                        :propSocio="oEstudio.Socio"
                        :propSolicitante="oEstudio.Solicitante"
                        :propPaciente="oEstudio.Paciente"/>
                </v-col>
            </v-row>

            <v-row v-else-if="selectEstudio=='12'">
                <v-col>
                    <F8PruebaEmbarazo
                        :propContrato="Number.parseInt(oEstudio.Contrato)"
                        :propSocio="oEstudio.Socio"
                        :propSolicitante="oEstudio.Solicitante"
                        :propPaciente="oEstudio.Paciente"/>
                </v-col>
            </v-row>

            <v-row v-else-if="selectEstudio=='14'">
                <v-col>
                    <F1QuimicaSanguineaComponent
                        :propContrato="Number.parseInt(oEstudio.Contrato)"
                        :propSocio="oEstudio.Socio"
                        :propSolicitante="oEstudio.Solicitante"
                        :propPaciente="oEstudio.Paciente"/>
                </v-col>
            </v-row>

            <v-row v-else>
                <!-- REFACTORIZACION DE ESTUDIOS -->
                <v-col cols="12">
                    <div v-if="listaPropiedadesPorEstudioMapeada.length>0">
                    
                        <v-row>
                            <v-col cols="3" md="3">
                                <v-select
                                    :items="listaMedicos"
                                    v-model="selectMedicos"
                                    item-text="Nombre"
                                    item-value="Nombre"
                                    label="Medico"
                                    dense
                                    @change="selectDropDownMedico()"
                                ></v-select>
                            </v-col>
                            <v-col cols="3" v-for="item of listaPropiedadesPorEstudioMapeada" :key="item.idPropiedad">
                            
                                <ControlPropiedadEstudio
                                    :idEstudio="item.idEstudio"
                                    :idPropiedad="item.idPropiedad"
                                    :tipoDato="item.tipoDato"
                                    :etiqueta="item.etiqueta"
                                    :unidad="item.unidad"
                                    :limiteInferior="item.valorInferior"
                                    :limiteSuperior="item.valorSuperior"
                                    :etiquetaReferencia="item.etiquetaReferencia"
                                    :getProperty="obtienePropiedadesFromControl"
                                    @setValorPropiedad="item.valor = $event"

                                />
                                
                            </v-col>
                            <v-col  offset-lg="8" offset-md="8" md="4" cols="4"  
                                    class="d-flex justify-end">
                                <v-btn  color="blue"
                                        class="white--text"
                                        block
                                        @click="onPressGenerarReporte()"
                                        :loading="isGenerating"
                                        ><!--@click="generarEstudio()"-->
                                    Generar 
                                    <v-icon>mdi-content-save</v-icon> 
                                </v-btn>
                            </v-col>
                        </v-row>
                        
                    </div>
                    <div v-else>
                        <h3>::En construcción</h3>
                    </div>
                </v-col>
            </v-row>

        </div>
        
        <ShowPdfComponent
            :mostrarVentana="pdfConfig.imprimir"
            :pTituloPDF="pdfConfig.tituloPDF"
            :rutaPdf="pdfConfig.rutaPdf"
            @setShowDialog="pdfConfig.imprimir = $event"
        ></ShowPdfComponent>


        <!--INICIO Alertas con error -->
        <v-dialog v-model="oDialog.show" max-width="400" persistent>
            <v-card :color="oDialog.color">
                <v-card-title>
                    <span class="white--text"> SAM </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="oDialog.show = false"
                        >
                            <!--<v-icon color="black" >mdi-close </v-icon>-->
                        </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <v-alert 
                        v-model="oDialog.show"
                        type="info"
                        :color="oDialog.color"
                        class="white--text"
                    >
                        {{ oDialog.mensaje }}
                    </v-alert>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn 
                        text class="white--text"
                        @click="oDialog.show = false">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Alertas con error -->

    </div>
</template>

<script> 
import {mapState} from "vuex"; 
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
import ApiContratosService from '@/services/ApiContratosService';
import ApiAmbulanciasService from '@/services/ApiAmbulanciasService';
import ApiEstudiosService from '@/services/ApiEstudiosService';
import ApiCatEstudiosService from '@/services/ApiCatEstudiosService';


import ControlPropiedadEstudio from "../shared/ControlPropiedadEstudio.vue";
import ShowPdfComponent from '@/components/Bloques/ShowPdfComponent';

//#region Formularios para reportes/estudios de PROMEDICA
import F1QuimicaSanguineaComponent from '@/components/Estudios/Promedica/F1_QuimicaSanguineaComponent';
import F4AnticuerposAntiHIV1y2Component from '@/components/Estudios/Promedica/F4_Anticuerpos_AntiHIV1y2Component';
import F8PruebaEmbarazo from '@/components/Estudios/Promedica/F8_PruebaEmbarazoComponent';
//#endregion


export default { 
    //#region Components
    components:{
        ControlPropiedadEstudio,
        ShowPdfComponent,
        F1QuimicaSanguineaComponent,
        F4AnticuerposAntiHIV1y2Component,
        F8PruebaEmbarazo

    },
    //#endregion
    //------------------------------------------------------------------------------- 
    //#region Computed
    computed:{ ...mapState(['URLSrc', 'URLApi', 'User', 'Empresa']), }, 
    //#endregion 

    //#region Props
    props:{
        //Si 'EsBeneficiario' es 0, se hace busqueda en contratos
        //Si es 1, se hace busqueda en beneficiarios
        EsBeneficiario:Number,
        ContratoNumero:Number,
        NumeroBeneficiario:Number,
        NumeroEstudio:Number,
        EstatusCrum:String,
    },
    //#endregion

    //------------------------------------------------------------------------------- 
    //#region Data 
    data(){ 
        return{ 
            //Objetos de api
            apiConfiguracion:new ApiConfiguracionService(),
            apiAmbulancias:new ApiAmbulanciasService(),
            apiEstudios:new ApiEstudiosService(),
            apiContratos:new ApiContratosService(), 
            apiCatEstudios: new ApiCatEstudiosService(),
            //Variables para componentes visuales
            step:0,
            interval:{},
            oDialog:{
                show:false,
                mensaje:'',
                color:'orange'
            },
            dialogTrabajo:{
                show:false,
                mensaje:'¿ Desea terminar Trabajo o Servicio ?',
                color:'blue'
            },
            //Variables de la pantalla
            listaSexos:[
                { value:'MASCULINO', text:'Masculino'  },
                { value:'FEMENINO', text:'Femenino'  },
            ],
            horaPasos:{
                Despacho:'',
                Salida:'',
                Llegada:'',
                Traslado:'',
                Hospital:'',
                Libre:'',
                MS:''
            },
            selectEstudio:null,
            listaEstudios:[],
            listaPropiedadesPorEstudio:[],
            listaPropiedadesPorEstudioMapeada:[],
            isGenerating:false,

            estudioPaciente:{
                Id:null,
                Pdf:null,
            },

            pdfConfig:{
                tituloPDF:'',
                rutaPdf:'',
                rutaReportes:'/reportes/Estudios/',
                nombrePdf:'',
                imprimir:false,
            },

            obtienePropiedadesFromControl:false,
            //Variables con acceso a datos
            Contrato:{
                Numero:0,
                Contrato:null,
                Tipo:'',
                Estatus:null,
                NombreS:'',
                RFCS:null,
                DomicilioS:null,
                ColoniaS:null,
                CiudadS:null,                
                CPS:null,

                CorreoS:null,
                TelefonosS:null,

                DiasServicioS:null,
                HorarioS:null,

                AntecedentesS:null,
                SexoS:null,
                FechaNacimientoS:null,
                TipoS:null,
                EdadS:null,

                Beneficiarios:null,                
                NombreC:null,
                TelefonosC:null,
                CorreoC:null,
                Usuario:null,
                
                FechaContrato:null,
                HoraContrato:null,

                Extra1:null,
                Extra2:null,
                Extra3:null
            },
            listaContratos:[],
            listaBeneficiarios:[],
            //Variables del formulario
            oEstudio:{
                Numero : null,
                Contrato : null,
                Socio : null,
                Estatura:null,
                Tipo : null,
                Usuario : null,
                EstudioId:null,
                Medico:null,
                Despacho : null,
                Salida : null,
                Llegada : null,
                Traslado : null,
                Hospital : null,
                Libre : null,
                MS : null,
                Solicitante : null,
                Edad : null,
                Sexo : null,
                Beneficiario : null,
                Paciente : null,
                Telefonos : null,
                Domicilio : null,
                Signos : null,
                TipoServicio : null,
                Triage : null,
                Equipo : null,
                Horario : null,
                NumeroLab : null,
                Diagnostico : null,
                Tratamiento : null,
                Observaciones : null,

                PadecimientoActual : null, 
                TratamientoActual :null,
                InicioSintomas : null,
                ExploracionFisica : null,

                SV_TA : null,
                SV_FR : null,
                SV_TEMP : null,
                SV_FC : null,
                SV_SAT : null,
                SV_PESO : null,

                AntecedentesPersonalesPato : null,
                EstadoCivil : null,
                GlasgowMotora : null,
                GlasgowRespuestaVerbal : null,
                GlasgowAperturaOcular : null,

                Estatus : null,
                Cancelacion : null,
                Destino : null,
                Viaje : null,
                Extra1 : null,
                Extra2 : null,
                Extra3 : null,
                Medico : null,
                Mes : null,
                Ano : null,

                TipoEstudio: null
            },
            //Listas de configuracion
            selectTiposServicio:'',
            listaTiposServicio:[],
            
            selectTriageInicial:'',
            listaTriageInicial:[],
            
            selectEquipos:'',
            listaEquipos:[],
            
            selectHorarios:'',
            listaHorarios:[],

            selectMedicos:'',
            listaMedicos:[],

            selectEstadoCivil:'',
            listaEstadosCiviles:[
                {EstadoCivil:'SOLTERA/O'},
                {EstadoCivil:'CASADA/O'},
                {EstadoCivil:'DIVIORCIADA/O'},
                {EstadoCivil:'VIUDA/O'},
                {EstadoCivil:'CONCUBINATO'},
            ],

            


        } 
    }, 
    //#endregion //------------------------------------------------------------------------------- 

    //#region Methods 
    methods:{ 
            //#region Metodos Funciones Locales
            getHoraActual(){
                const horaActual = new Date();
                let meridiano = 'a.m.';
                let hora = horaActual.getHours();            
                const minutos = horaActual.getMinutes();
                const segundos = horaActual.getSeconds();

                meridiano = (hora >= 12) ? 'p.m.' : 'a.m.';
                //hora = (hora > 12) ? (hora-12) : hora;

                this.horaActualFormateada = 
                    hora.toString().padStart(2,"0") 
                    + ':' + minutos.toString().padStart(2,"0") 
                    + ':' + segundos.toString().padStart(2,"0") ;
                    //+ ' ' + meridiano;
                return this.horaActualFormateada;
            },

            verificaConsulta(){

                if( this.NumeroEstudio > 0 ){
                    this.getCrum();
                }
                else{
                    if(this.EsBeneficiario == 1){
                        //Es Beneficiario y consulta en beneficiarios
                        this.getBeneficiarios(this.ContratoNumero,this.NumeroBeneficiario);
                    }
                    if(this.EsBeneficiario == 0){
                        //Es contrato y consulta en contratos
                        this.getContratos(this.ContratoNumero);
                    }
                }
            },

            compruebaTerminaTrabajo(){
                this.dialogTrabajo.show = true;

            },

            compruebaFormulario( operacion ){

                if( !this.verificaFormulario() ) { return; }
                else{
                    //Si pasa la verificacion de campos,
                    //Verifica los pasos

                    if( !this.verificaPaso() ){ return; } 
                    else{

                        //Si pasa las dos verificaciones, hace la operacion
                        if( operacion == this.operacionINSERT ){
                            //Insert
                            console.log('Insert');
                            
                            if( this.NumeroEstudio > 0){
                                this.presentAlert('Este servicio ya ha sido guardado, verifique', 'red');
                            }
                            else{
                                this.insertCrum(this.oEstudio);
                            }

                        }

                        if( operacion == this.operacionUPDATE ){
                            //Update
                            console.log('Update');
                            this.updateCrum(this.oEstudio);

                        }
                    }
                }

            },
            
            fillContrato(item){
                //console.log('fillContrato', item);
                this.Numero                     = item.Numero;

                this.Contrato.Numero            = item.Numero;
                this.Contrato.Contrato          = item.Contrato;
                this.Contrato.Tipo              = item.Tipo;
                this.Contrato.Estatus           = item.Estatus;
                this.Contrato.NombreS           = item.NombreS; 
                this.ContratoNombreInput        = item.NombreS;

                this.Contrato.RFCS              = item.RFCS;
                this.Contrato.DomicilioS        = item.DomicilioS;
                this.Contrato.ColoniaS          = item.ColoniaS; 
                this.selectCiudadS              = item.CiudadS; //this.Contrato.CiudadS
                this.Contrato.CPS               = item.CPS;

                this.Contrato.CorreoS           = item.CorreoS;
                this.Contrato.TelefonosS        = item.TelefonosS;

                this.Contrato.DiasServicioS     = item.DiasServicioS;
                this.Contrato.HorarioS          = item.HorarioS;

                this.Contrato.AntecedentesS     = item.AntecedentesS;
                this.selectSexoS                = item.SexoS; //this.Contrato.SexoS
                this.Contrato.FechaNacimientoS  = item.FechaNacimientoS;
                this.selectTipoS                = item.TipoS; //this.Contrato.TipoS
                this.Contrato.EdadS             = item.EdadS;
                
                this.Contrato.Beneficiarios     = item.Beneficiarios;
                this.Contrato.NombreC           = item.NombreC;
                this.Contrato.TelefonosC        = item.TelefonosC;
                this.Contrato.CorreoC           = item.CorreoC;
                this.Contrato.Usuario           = item.Usuario;
                    
                this.Contrato.FechaContrato     = item.FechaContrato;
                this.Contrato.HoraContrato      = item.HoraContrato;

                this.Contrato.Extra1            = item.Extra1;
                this.Contrato.Extra2            = item.Extra2;
                this.Contrato.Extra3            = item.Extra3;

                //Llenado de crum
                this.oEstudio.Contrato             = this.Contrato.Contrato;
                this.oEstudio.Socio                = this.Contrato.NombreS;
                this.oEstudio.Tipo                 = this.Contrato.Tipo;
                
            },
            filloEstudio(ContratoObject, BeneficiarioObject){
                //console.log('filloEstudio.ContratoObject', ContratoObject);
                //console.log('filloEstudio.BeneficiarioObject', BeneficiarioObject);               
                

                if(ContratoObject){
                    //console.log('ContratoObject', ContratoObject)
                    this.oEstudio.Contrato             = ContratoObject.Contrato;
                    this.oEstudio.Solicitante          = ContratoObject.NombreS;
                    this.oEstudio.Paciente             = ContratoObject.NombreS;
                    this.oEstudio.Domicilio            = ContratoObject.DomicilioS;
                    this.oEstudio.Telefonos            = ContratoObject.TelefonosS;
                    this.oEstudio.Edad                 = ContratoObject.EdadS;
                    this.oEstudio.Sexo                 = ContratoObject.SexoS;

                    
                }
                if(BeneficiarioObject){
                    //console.log('BeneficiarioObject', BeneficiarioObject)
                    this.oEstudio.Contrato             = BeneficiarioObject.Contrato;
                    this.oEstudio.Solicitante          = BeneficiarioObject.NombreB;
                    this.oEstudio.Paciente             = BeneficiarioObject.NombreB;
                    this.oEstudio.Domicilio            = BeneficiarioObject.DomicilioB;
                    this.oEstudio.Telefonos            = BeneficiarioObject.TelefonosB;
                    this.oEstudio.Edad                 = BeneficiarioObject.EdadB;
                    this.oEstudio.Sexo                 = BeneficiarioObject.SexoB;
                }

            },

            clearCrumObject(){
                this.oEstudio = {
                    Numero : null,
                    Contrato : null,
                    Socio : null,
                    Tipo : null,
                    Fecha : null,
                    FechaHora : null,
                    HoraLlamada : null,
                    Usuario : null,
                    Despacho : null,
                    Salida : null,
                    Llegada : null,
                    Traslado : null,
                    Hospital : null,
                    Libre : null,
                    MS : null,
                    Solicitante : null,
                    Edad : null,
                    Sexo : null,
                    Beneficiario : null,
                    Paciente : null,
                    Telefonos : null,
                    Domicilio : null,
                    Signos : null,
                    TipoServicio : null,
                    Triage : null,
                    Equipo : null,
                    Horario : null,
                    NumeroLab : null,
                    Diagnostico : null,
                    Tratamiento : null,
                    Observaciones : null,

                    PadecimientoActual   : null,
                    TratamientoActual   : null,
                    InicioSintomas       : null,
                    ExploracionFisica    : null,

                    SV_TA : null,
                    SV_FR : null,
                    SV_TEMP : null,
                    SV_FC : null,
                    SV_SAT : null,
                    SV_PESO : null,

                    AntecedentesPersonalesPato : null,
                    EstadoCivil : null,
                    GlasgowMotora : null,
                    GlasgowRespuestaVerbal : null,
                    GlasgowAperturaOcular : null,

                    Estatus : null,
                    Cancelacion : null,
                    Destino : null,
                    Viaje : null,
                    Extra1 : null,
                    Extra2 : null,
                    Extra3 : null,
                    Medico : null,
                    Mes : null,
                    Ano : null,
                    TipoEstudio: null,
                }
            },
            presentAlert(mensaje, color){
                //console.log('presentAlert.mensaje', mensaje);
                this.oDialog.mensaje = mensaje;
                this.oDialog.color = color;
                this.oDialog.show = true;
            },
            llenaDropdowns(){
                this.getTiposServicio();
                this.getTriagesInicial();
                this.getEquipos();
                this.getHorarios();
                this.getMedicos();
            },

            validaDatos(){
                let ok = true;

                if(!this.selectMedicos){ ok = false; }

                //if(!this.selectTiposServicio){ ok = false; }

                if(!ok){
                    console.log('presentAlert');
                    this.presentAlert('Revise los datos', 'red');
                }
                return ok;
            },

            async onPressGenerarReporte(){
                this.isGenerating = true;
                this.obtienePropiedadesFromControl = !this.obtienePropiedadesFromControl;

                if(!this.validaDatos()) {
                    this.isGenerating = false;
                    return;
                }

                await this.apiEstudios.guardaEstudioDinamico( this.URLApi, this.oEstudio.Contrato, this.oEstudio.Socio, this.oEstudio.Solicitante
                                                        ,this.oEstudio.Paciente
                                                        ,this.oEstudio.EstudioId
                                                        ,this.oEstudio.Medico
                                                        ,this.oEstudio.Sexo
                                                        ,this.oEstudio.Edad
                                                        ,this.oEstudio.Estatura
                                                        ,this.oEstudio.Peso
                                                        ,this.listaPropiedadesPorEstudioMapeada)
                .then( async response => {
                    console.log('response', response.data);
                    this.estudioPaciente.Id = response.data.objeto;
                    await this.generaReporte();
                })
                .catch( error => {
                    console.log(error);
                    this.isGenerating = false;
                });



            },

            async generaReporte(){
                await this.apiEstudios.generaEstudioDinamico(this.URLApi, this.estudioPaciente.Id)
                .then( async response => {
                    console.log('generaEstudioDinamico.response', response.data);
                    this.estudioPaciente.Pdf = response.data.objeto;

                    console.log('this.estudioPaciente', this.estudioPaciente);

                    this.pdfConfig.nombrePdf = this.estudioPaciente.Pdf;

                    this.pdfConfig.rutaPdf = this.pdfConfig.rutaReportes + this.pdfConfig.nombrePdf;

                    this.pdfConfig.imprimir = true;

                    this.isGenerating = false;
                })
                .catch( error => {
                    console.log(error);
                    this.isGenerating = false;
                });
            },
            //#endregion 

            //#region selects-Dropdowns
            selectDropDownTiposServicio(){ this.oEstudio.TipoServicio = this.selectTiposServicio; },
            selectDropDownTriageInicial(){ this.oEstudio.Triage = this.selectTriageInicial; },
            selectDropDownEquipo(){ this.oEstudio.Equipo = this.selectEquipos; },
            selectDropDownHorario(){ this.oEstudio.Horario = this.selectHorarios; },
            selectDropDownMedico(){ this.oEstudio.Medico = this.selectMedicos; },
            selectDropDownEstadoCivil(){ this.oEstudio.EstadoCivil = this.selectEstadoCivil; },
            selectDropDownTipoEstudio(){
                this.pdfConfig.imprimir = false; 
                this.oEstudio.Medico = '';
                //console.log('selectDropDownTipoEstudio.selectEstudio',this.selectEstudio);
                this.listaPropiedadesPorEstudio = [];
                this.obtienePropiedadesEstudio(this.selectEstudio);
                this.oEstudio.TipoEstudio = this.selectEstudio; 
                this.oEstudio.EstudioId = this.selectEstudio; 
            },
            //#endregion

            //#region Consumo Api-Configuracion
            getTiposServicio(){
                this.apiConfiguracion.recuperaTiposServicio(this.URLApi,0,0)
                .then( response => {
                    this.listaTiposServicio = response.data.objeto;
                    //console.log('getTiposServicio', this.listaTiposServicio);
                })
                .catch( error => console.log(error));
            },

            getTriagesInicial(){
                this.apiConfiguracion.recuperaTriagesInicial(this.URLApi,0,0)
                .then( response => {
                    this.listaTriageInicial = response.data.objeto;
                    //console.log('getTriagesInicial', this.listaTriageInicial);
                })
                .catch( error => console.log(error));
            },

            getEquipos(){
                this.apiConfiguracion.recuperaEquipos(this.URLApi,0,0)
                .then( response => {
                    this.listaEquipos = response.data.objeto;
                    //console.log('getEquipos', this.listaEquipos);
                })
                .catch( error => console.log(error));
            },

            getHorarios(){
                this.apiConfiguracion.recuperaHorarios(this.URLApi,0,0)
                .then( response => {
                    this.listaHorarios = response.data.objeto;
                    //console.log('getHorarios', this.listaHorarios);
                })
                .catch( error => console.log(error));
            },

            getMedicos(){
                this.listaMedicos = [];
                this.apiConfiguracion.recuperaMedicos(this.URLApi,0,0)
                .then( response => {
                    const oMedicoArray = [
                        {
                            Nombre:'SIN ASIGNAR',
                            Numero: 0
                        }
                    ];

                    this.listaMedicos.push(...oMedicoArray);
                    this.listaMedicos.push(...response.data.objeto);
                    //console.log('getMedicos', this.listaMedicos);
                })
                .catch( error => console.log(error));
            },

            //#endregion

            //#region Consumo Api-Contratos
            getContratos(ContratoNumero){
                //this.loadingContrato = true;
                //this.showSnackbarAviso = false;
                this.listaBeneficiarios = [];

                //this.emitiToComponentFather(0,0,0,'',0);

                if(!ContratoNumero>0){
                    //this.loadingContrato = false;
                    //this.showSnackbarAviso = false;
                    return;
                }

                //console.log('getContrato', this.Contrato.NombreS);

                //Solo puede mostrar uno por seguridad
                this.apiContratos.recuperaContratos( this.URLApi, 
                        this.Contrato.Numero, ContratoNumero, '')
                .then( response => {
                    //console.log('getContratos.data', response.data);
                    this.listaContratos = response.data.objeto;

                    if( this.listaContratos.length == 1 ){
                        this.fillContrato( this.listaContratos[0] );
                        this.filloEstudio( this.listaContratos[0] );
                    }
                    else if(this.listaContratos.length > 1){
                        //this.showDialogBusqueda = true;
                        //this.clearContratoObject();
                    }
                    else{
                        //console.log('else', this.listaContratos);
                        //this.showSnackbarAviso = true;
                    }
                })
                .catch(error=>{console.log(error);});

            },        
            //#endregion 

            //#region Consumo Api-Beneficiarios
            getBeneficiarios(ContratoNumero, NumeroBeneficiario){
                this.apiContratos.recuperaBeneficiarios(this.URLApi,
                    NumeroBeneficiario, ContratoNumero, '')
                .then( response => {
                    console.log("getBeneficiarios.data", response.data);
                    if(response.data.mensaje == ''){
                        this.listaBeneficiarios = response.data.objeto;

                        if(this.listaBeneficiarios.length==1){
                            this.filloEstudio( null, this.listaBeneficiarios[0] );
                        }


                    }
                })
                .catch(error=>{console.log(error);});
            },
            //#endregion

            //#region Consumo Api-CRUM
            getCrum(){
                if(!this.oEstudio.Numero){
                    return;

                }
                //Obtiene los servicios
                this.apiEstudios.recuperaCrum(  this.URLApi, 
                                        this.oEstudio.Numero, this.oEstudio.Contrato, this.oEstudio.Estatus )
                .then( response =>{
                    //console.log('getCrum.data',response.data);
                    const lista =  response.data.objeto;
                    if( lista.length == 1 ){
                        this.filloEstudioFromHojaTrabajo( lista[0] );
                    }
                    
                    else if( lista.length > 1 ){
                        console.log('Encontro mas de uno');
                    }
                    else{
                        console.log('No encontró nada.');
                    }

                })
                .catch(error=>{console.log(error);});

            },

            insertCrum(crum){              

                crum.Estatus = 'ACTIVO';
                this.apiEstudios.guardaCrum(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Edad,          crum.Sexo,
                    crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, 
                    crum.PadecimientoActual, crum.TratamientoActual,
                    crum.InicioSintomas, crum.ExploracionFisica,
                    crum.SV_TA , crum.SV_FR , crum.SV_TEMP , 
                    crum.SV_FC , crum.SV_SAT , crum.SV_PESO ,
                    crum.AntecedentesPersonalesPato, crum.EstadoCivil,
                    crum.GlasgowMotora, crum.GlasgowRespuestaVerbal, crum.GlasgowAperturaOcular,
                    crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
                )
                .then( response => {
                    //console.log('insertCrum.data', response.data);

                    if(response.data.objeto>0){
                        this.presentAlert('Servicio registrado correctamente', 'green');
                    }
                })
                .catch(error => console.log(error) );
            },

            updateCrum( crum ){

                this.apiEstudios.modificaCrum(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Edad,          crum.Sexo,
                    crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, 
                    crum.PadecimientoActual, crum.TratamientoActual,
                    crum.InicioSintomas, crum.ExploracionFisica,
                    crum.SV_TA , crum.SV_FR , crum.SV_TEMP , 
                    crum.SV_FC , crum.SV_SAT , crum.SV_PESO ,
                    crum.AntecedentesPersonalesPato, crum.EstadoCivil,
                    crum.GlasgowMotora, crum.GlasgowRespuestaVerbal, crum.GlasgowAperturaOcular,
                    crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
                )
                .then( response => {
                    //console.log('updateCrum.data', response.data);
                    if( response.data.objeto ){
                        this.presentAlert('Trabajo o Servicio actualizado correctamente', 'green');

                    }

                })
                .catch(error => console.log(error) );
            },

            terminaCrum( crum ){

                crum.Estatus = 'TERMINADO';
                this.apiEstudios.terminaTrabajoEstudio(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Edad,          crum.Sexo,
                    crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, 
                    crum.PadecimientoActual, crum.PadecimientoActual,
                    crum.InicioSintomas, crum.ExploracionFisica,
                    crum.SV_TA , crum.SV_FR , crum.SV_TEMP , 
                    crum.SV_FC , crum.SV_SAT , crum.SV_PESO ,
                    crum.AntecedentesPersonalesPato, crum.EstadoCivil,
                    crum.GlasgowMotora, crum.GlasgowRespuestaVerbal, crum.GlasgowAperturaOcular,
                    crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
                )
                .then( response => {
                    //console.log('terminaCrum.data', response.data);
                    if( response.data.objeto ){
                        this.dialogTrabajo.show = false;
                        this.presentAlert('Trabajo o Servicio terminado correctamente', 'green');
                    }

                })
                .catch(error => console.log(error) );
            },

            deleteCrum(){

            },
            //#endregion
        
            //#region Consumo Api-CatalogoEstudios
            obtieneListaEstudios(){
                this.apiCatEstudios.recuperaCatEstudios( this.URLApi)
                .then( response => {
                    //console.log('obtieneListaEstudios.data', response.data);
                    
                    if(Array.isArray(response.data)){
                        if(response.data.length>0){
                            this.listaEstudios.push(...response.data);
                        }
                    }

                })
                .catch( error => {
                    console.log('obtieneListaEstudios.error', error);
                });
            },

            async obtienePropiedadesEstudio(idEstudio){
                await this.apiCatEstudios.obtienePropiedadesEstudio(this.URLApi, idEstudio)
                .then( async response =>{ 
                    
                    if(Array.isArray(response.data)){
                        this.listaPropiedadesPorEstudio = response.data;
                        this.mapeaListaPropiedades(this.listaPropiedadesPorEstudio);

                    }
                })
                .catch( error => { console.log('obtienePropiedadesEstudio.error', error); });
            },

            mapeaListaPropiedades( propiedadesEstudio ){

                if(Array.isArray(propiedadesEstudio)){
                    this.listaPropiedadesPorEstudioMapeada = propiedadesEstudio.map( pe =>{
                        return{
                            idEstudio: pe.idestudiolab , 
                            idPropiedad: pe.id , 
                            tipoDato: pe.tipoDato , 
                            etiqueta: pe.etiqueta , 
                            unidad: pe.unidad , 
                            limiteInferior: pe.limiteInferior ,
                            limiteSuperior: pe.limiteSuperior ,
                            etiquetaReferencia: pe.etiquetaReferencia ,
                            valor: null
                        };
                    });
                }
            }

            //#endregion
        },
        //#endregion 

        //#region update
        updated(){
            //console.log('CrumComponent.Updated');
            
        },
        //#endregion

        //#region mounted
        mounted(){
            this.llenaDropdowns();
            //------------------
            //Consultar el Contrato
            this.getContratos( this.ContratoNumero );
            this.getCrum();
        },
        beforeMount(){
            this.oEstudio.Numero = this.NumeroEstudio;
            this.oEstudio.Estatus = this.EstatusCrum;
            this.oEstudio.Contrato = this.ContratoNumero;
            
            this.obtieneListaEstudios();
        },
        beforeDestroy () {
            //clearInterval(this.interval);
        },
        //#endregion

        //#region  watch
        watch: {
            ContratoNumero(val){
                console.log('ContratoNumero', val);
                this.clearCrumObject();
                if( val == 0 ){
                    this.clearCrumObject();
                }
                else{
                    this.clearCrumObject();
                    this.verificaConsulta();
                }
            },
            NumeroBeneficiario(val){
                //console.log('NumeroBeneficiario', val);
                this.verificaConsulta();
            }
        }
        //#endregion
    }
</script>