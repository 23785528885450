<template>
    <div>
        <v-row>
            <v-col cols="12" offset-md="9" md="3"
                    class="d-flex justify-end"
                    >
                <v-btn
                    outlined
                    color="blue"
                    block
                    @click="dialogForm.show = true "
                >
                    <v-icon> mdi-plus</v-icon>  Nuevo
                </v-btn>

            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaTraslados"
                    :single-select="singleSelect"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-text':'',
                    }"
                    loading-text="Cargando..."
                    no-data-text="Sin datos disponibles"
                    :loading="loadingDatatable"
                    item-key="Numero"
                    class="elevation-1"
                    @click:row="handleClick"
                ></v-data-table>
            </v-col>
        </v-row>

       <!-- INICIO Formulario -->
       <v-dialog v-model="dialogForm.show" max-width="1000">
           <v-card>
               <v-card-title>
                   <span> Agregar Traslado </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogForm.show = false"
                        >
                            <v-icon color="red" >mdi-close </v-icon>
                        </v-btn>
                        </template>
                    </v-menu>
               </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <FrmBusquedaContratoComponent
                                @setContrato="oCrumTraslados.Contrato = $event"
                                @setNombreS="oCrumTraslados.Socio = $event"
                                @setTelefonosS="oCrumTraslados.Telefonos = $event"
                                @setDomicilioS="oCrumTraslados.Domicilio = $event"
                            ></FrmBusquedaContratoComponent>
                        </v-col>
                    </v-row>

                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent >
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="oCrumTraslados.Paciente"
                                    label="Paciente"
                                    required
                                    :counter="80"
                                    :rules="oCrumTrasladosRules.Paciente"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="oCrumTraslados.Telefonos"
                                    label="Telefonos"
                                    required
                                    :rules="oCrumTrasladosRules.Telefonos"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="oCrumTraslados.Destino"
                                    label="Destino"
                                    required
                                    :counter="80"
                                    :rules="oCrumTrasladosRules.Destino"
                                >
                                </v-text-field>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" md="3">
                                <v-select
                                    :items="listaEquipos"
                                    v-model="selectEquipos"
                                    item-text="Nombre"
                                    item-value="Nombre"
                                    label="Equipo"
                                    @change="selectDropDownEquipo()"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="oCrumTraslados.FechaViaje"
                                    label="Fecha Viaje"
                                    required
                                    :counter="20"
                                    :rules="oCrumTrasladosRules.FechaViaje"
                                ></v-text-field>

                            </v-col>
                            
                            <v-col cols="12" md="4">
                                <v-text-field
                                    v-model="oCrumTraslados.Domicilio"
                                    label="Domicilio"
                                    required
                                    :counter="80"
                                    :rules="oCrumTrasladosRules.Domicilio"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-btn
                                    class="white--text"
                                    color="blue"
                                    block
                                    @click="validate()"
                                >
                                    Guardar
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-form>

                </v-card-text>

           </v-card>
       </v-dialog>
       <!-- FIN Formulario -->

       <!--INICIO Dialog Eliminar -->
        <v-dialog v-model="dialogAcciones.show" max-width="600" >
            <v-card>
                <v-card-title class="headline">
                    Modificar Traslado.
                </v-card-title>
                <v-card-text>
                    
                    <v-row>
                        <v-col cols="12" md="12">

                            <v-sheet class="mx-auto"
                                elevation="0"
                                max-width="800">

                                <v-slide-group v-model="modelSlide" class="pa-4" show-arrows>

                                    <v-slide-item   v-for="(item, index) of listaAcciones" 
                                                    :key="index"
                                                    v-slot="{ active, toggle }" 
                                                    >

                                        <v-card class="ma-4" height="110" width="110"
                                                :color="active ? '#5e73f0' : '#000000'"
                                                elevation="0" @click="toggle">

                                                <v-card-text @click="registrarPaso(item)">
                                                    <v-row>
                                                        <v-col cols="12" md="12">
                                                            <v-img
                                                                style="width:100%; height:auto;"
                                                                :src="item.imagen"/>

                                                            {{ item.descripcion }}
                                                            
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>

                                                <v-row class="fill-height"
                                                        align="center" justify="center">

                                                        <v-scale-transition>
                                                            <v-icon
                                                                v-if="active"
                                                                color="white"
                                                                size="48"
                                                                v-text="'mdi-close-circle-outline'"
                                                            ></v-icon>

                                                        </v-scale-transition>
                                                </v-row>

                                        </v-card>
                                    </v-slide-item>

                                </v-slide-group>

                            </v-sheet>

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">
                            <p>
                                ¿Desea terminar el traslado programado con Numero  <strong> {{ dialogAcciones.numeroCrumTraslado }} </strong> ?
                            </p>
                            <p>
                                Para el socio <strong> {{ dialogAcciones.socioCrumTraslado }} </strong> y contrato: <strong> {{ dialogAcciones.contratoCrumTraslado }} </strong>
                            </p>
                        </v-col>
                    </v-row>

                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogAcciones.show = false" >
                        Cancelar
                    </v-btn>
                    <v-btn color="green darken-1" text @click="terminaTrabajoCrumTraslado()">
                        Terminar Trabajo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--FIN Dialog Eliminar -->

    </div>
</template>

<script>
import {mapState} from "vuex";
import ApiService from '@/services/ApiAmbulanciasService';
import ApiConfiguracionService from '@/services/ApiConfiguracionService';

import FrmBusquedaContratoComponent from '@/components/Formularios/Contratos/FrmBusquedaContratoComponent';

export default {
    
    //#region Computed
    computed:{ 
        ...mapState(['URLSrc', 'URLApi', 'User']), 
        Telefonos(){
            //console.log('Computed.Telefonos');
            let tel = this.oCrumTraslados.Telefonos.replaceAll('-','');
            this.oCrumTraslados.Telefonos = tel;
            return tel;
        }
    }, 
    //#endregion 

    components:{
        FrmBusquedaContratoComponent,
    },

    //#region ---- DATA ----
    data(){
        return{
            api:new ApiService(),
            apiConfiguracion:new ApiConfiguracionService(),
            totalTraslados:0,
            listaTraslados:[],
            
            modelSlide: null,

            selected: [],
            singleSelect: true,
            loadingDatatable:true,

            selectEquipos:'',
            listaEquipos:[],

            listaAcciones:[
                { Id: 0, descripcion: 'Despacho', hora: '', 
                    imagen: require("@/assets/AmbulanciaDespacho.png")  },
                { Id: 1, descripcion: 'Salida', hora: '', 
                    imagen: require("@/assets/AmbulanciaSalida.png") },
                { Id: 2, descripcion: 'Llegada', hora: '', 
                    imagen: require("@/assets/AmbulanciaLlegada.png") },
                { Id: 3, descripcion: 'Traslado', hora: '', 
                    imagen: require("@/assets/AmbulanciaTraslado.png") },
                { Id: 4, descripcion: 'Hospital', hora: '', 
                    imagen: require("@/assets/AmbulanciaHospital.png") },
                { Id: 5, descripcion: 'Libre', hora: '', 
                    imagen: require("@/assets/AmbulanciaLibre.png") },
                { Id: 6, descripcion: 'Promedica', hora: '', 
                    imagen: require("@/assets/AmbulanciaEnPromedica.png") },
            ],

            dialogAcciones:{
                show:false,
                numeroCrumTraslado:0,
                contratoCrumTraslado:0,
                socioCrumTraslado:''
            },

            valid:false,
            oCrumTrasladosRules:{
                Paciente:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                Telefonos:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => /^([0-9])*$/.test(v) || 'El teléfono debe ser válido.',
                ],
                Domicilio:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
                FechaViaje:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 20) || 'El campo debe tener menos 20 caracteres.', 
                ],
                Destino:[ 
                    v=> !!v || 'Campo requerido.' , 
                    v => (v && v.length <= 80) || 'El campo debe tener menos 80 caracteres.', 
                ],
            },

            dialogForm: {
                show:false,
                texto:'',
            },

            openPanel:[0],

            estatusActivo:'ACTIVO',
            estatusTerminado:'TERMINADO',

            headers:[
                { text: 'Numero', align: 'start', sortable: false, value: 'Numero' },
                { text: 'Contrato', value: 'Contrato' },
                
                { text: 'Destino', value: 'Destino' },
                { text: 'Fecha Viaje', value: 'FechaViaje' },
                { text: 'Equipo', value: 'Equipo' },

                { text: 'HoraLlamada', value: 'HoraLlamada' },
                { text: 'Despacho', value: 'Despacho' },
                { text: 'Salida', value: 'Salida' },
                { text: 'Llegada', value: 'Llegada' },
                { text: 'Traslado', value: 'Traslado' },
                { text: 'Hospital', value: 'Hospital' },
                { text: 'Libre', value: 'Libre' },
                { text: 'Promedica', value: 'MS' },

                { text: 'Paciente', value: 'Paciente' },
                { text: 'Telefonos', value: 'Telefonos' },
                { text: 'Domicilio', value: 'Domicilio' },
                { text: 'Socio', value: 'Socio' },
            ],

            oCrumTraslados:{
                Numero : 0,
                Contrato : 0,
                Socio : '',
                Tipo : '',
                Fecha : '',
                FechaHora : '',
                HoraLlamada : '',
                Usuario : '',
                
                Destino : '',
                FechaViaje : '',

                Despacho : '',
                Salida : '',
                Llegada : '',
                Traslado : '',
                Hospital : '',
                Libre : '',
                MS : '',

                Solicitante : '',
                Beneficiario : '',
                Paciente : '',
                Telefonos : '',
                Domicilio : '',
                Signos : '',
                TipoServicio : '',
                Triage : '',

                Equipo : '',

                Horario : '',
                NumeroLab : 0,
                Diagnostico : '',
                Tratamiento : '',
                Observaciones : '',
                Estatus : 'ACTIVO',
                Cancelacion : '',
                
                Viaje : '',
                Extra1 : '',
                Extra2 : '',
                Extra3 : 0,
                Medico : '',
                Mes : '',
                Ano : '',
            },
        }
    },
    //#endregion


    methods:{
        //#region Metodos de la vista
        getHoraActual(){
            const horaActual = new Date();
            let meridiano = 'a.m.';
            let hora = horaActual.getHours();            
            const minutos = horaActual.getMinutes();
            const segundos = horaActual.getSeconds();

            meridiano = (hora >= 12) ? 'p.m.' : 'a.m.';
            //hora = (hora > 12) ? (hora-12) : hora;

            this.horaActualFormateada = 
                hora.toString().padStart(2,"0") 
                + ':' + minutos.toString().padStart(2,"0") 
                + ':' + segundos.toString().padStart(2,"0") ;
                //+ ' ' + meridiano;
            return this.horaActualFormateada;
        },
        pasarTotal(totTraslados){
            //console.log('setTotalTraslados');
            this.$emit('setTotalTraslados', totTraslados);
        },

        handleClick(value) {
            //console.log('handleClick', value);
            this.dialogAcciones.numeroCrumTraslado = value.Numero;
            this.dialogAcciones.contratoCrumTraslado = value.Contrato;
            this.dialogAcciones.socioCrumTraslado = value.Socio;
            this.dialogAcciones.show = true;

            this.listaAcciones[0].hora = value.Despacho;
            this.listaAcciones[1].hora = value.Salida;
            this.listaAcciones[2].hora = value.Llegada;
            this.listaAcciones[3].hora = value.Traslado;
            this.listaAcciones[4].hora = value.Hospital;
            this.listaAcciones[5].hora = value.Libre;
            this.listaAcciones[6].hora = value.MS;

            this.verificaPaso(this.listaAcciones);
        },

        validate(){
            const formValid = this.$refs.form.validate();
            if(formValid){
                this.insert(this.oCrumTraslados);
            }
            else{
                console.log('Faltan datos en el formulario');
            }

        },

        verificaPaso(acciones){

            if( acciones[0].hora !== ''){
                this.modelSlide = 1;
            }

            if( acciones[1].hora !== ''){
                this.modelSlide = 2;
            }

            if( acciones[2].hora !== ''){
                this.modelSlide = 3;
            }

            if( acciones[3].hora !== ''){
                this.modelSlide = 4;
            }

            if( acciones[4].hora !== ''){
                this.modelSlide = 5;
            }
            
            if( acciones[5].hora !== ''){
                this.modelSlide = 6;
            }

            if( acciones[6].hora !== ''){
                this.modelSlide = 6;
            }

        },

        registrarPaso(item){
            //console.log( 'registrarPaso', item);

            if( item.descripcion === this.listaAcciones[item.Id].descripcion ){
                this.listaAcciones[item.Id].hora 
                    = ( this.listaAcciones[item.Id].hora === '' ) 
                    ? this.getHoraActual()
                    : this.listaAcciones[0].hora;
            }

            //console.log( 'this.listaAcciones', this.listaAcciones);

            this.preparaOCrumTraslados(this.listaAcciones);

        },

        clearAcciones(){
            this.listaAcciones.forEach( item =>{
                item.hora = '';
            });

        },

        preparaOCrumTraslados(acciones){
            this.oCrumTraslados.Despacho = acciones[0].hora;
            this.oCrumTraslados.Salida = acciones[1].hora;
            this.oCrumTraslados.Llegada = acciones[2].hora;
            this.oCrumTraslados.Traslado = acciones[3].hora;
            this.oCrumTraslados.Hospital = acciones[4].hora;
            this.oCrumTraslados.Libre = acciones[5].hora;
            this.oCrumTraslados.MS = acciones[6].hora;

            this.oCrumTraslados.Numero = this.dialogAcciones.numeroCrumTraslado;
            this.oCrumTraslados.Contrato = this.dialogAcciones.contratoCrumTraslado;
            
            this.update(this.oCrumTraslados);

        },
        //#endregion

        //#region selects-Dropdowns
        selectDropDownEquipo(){ this.oCrumTraslados.Equipo = this.selectEquipos; },
        //#endregion

        //#region Metodos de Api Ambulancias
        get(){
            this.listaTraslados = [];
            this.api.recuperaCrumTraslados(this.URLApi,
                this.oCrumTraslados.Numero, this.oCrumTraslados.Contrato, this.oCrumTraslados.Estatus
            )
            .then( response =>{ 
                //console.log('recuperaCrumTraslados.data', response.data);
                this.listaTraslados = response.data.objeto;

                if(this.listaTraslados.length>0){
                    
                }
                else{

                }

                this.pasarTotal(this.listaTraslados.length);
                this.clearAcciones();
                this.loadingDatatable = false;

            })
            .catch( error => console.log( error ) );
        },

        insert(crum){
            this.api.guardaCrumTraslados(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.FechaViaje,
                    crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
            )
            .then( response => {
                console.log('guardaCrumTraslados.data', response.data);
                if(response.data.objeto>0){
                    this.oCrumTraslados.Contrato = 0;
                    this.oCrumTraslados.Numero = 0;
                    this.oCrumTraslados.Estatus = this.estatusActivo;
                    this.get();
                }

                this.dialogForm.show = false;

            })
            .catch( error => console.log(error) );

        },

        update(crum){

            this.api.modificaCrumTraslados(this.URLApi,
                    crum.Numero,        crum.Contrato,      crum.Socio,
                    crum.Tipo,          crum.Fecha,         crum.FechaHora,
                    crum.HoraLlamada,   crum.Usuario,       crum.Despacho,
                    crum.Salida,        crum.Llegada,       crum.Traslado,
                    crum.Hospital,      crum.Libre,         crum.MS,
                    crum.Solicitante,   crum.Beneficiario,  crum.Paciente,
                    crum.Telefonos,     crum.Domicilio,     crum.Signos,
                    crum.TipoServicio,  crum.Triage,        crum.Equipo,
                    crum.Horario,       crum.NumeroLab,     crum.Diagnostico,
                    crum.Tratamiento,   crum.Observaciones, crum.Estatus,
                    crum.Cancelacion,   crum.Destino,       crum.Viaje,
                    crum.Extra1,        crum.Extra2,        crum.Extra3,
                    crum.Medico,        crum.Mes,           crum.Ano
            )
            .then( response => {
                console.log('modificaCrumTraslados.data', response.data);

                if(response.data.objeto){
                    
                }

                this.dialogAcciones.show = false;

                this.oCrumTraslados.Contrato = 0;
                this.oCrumTraslados.Numero = 0;
                this.oCrumTraslados.Estatus = this.estatusActivo;
                this.get();

            })
            .catch( error => console.log(error) );

        },

        terminaTrabajoCrumTraslado(){

            this.api.terminaTrabajoCrumTraslados( this.URLApi, 
                this.dialogAcciones.numeroCrumTraslado, 
                this.dialogAcciones.contratoCrumTraslado, this.estatusTerminado)
            .then( response =>{

                if ( response.data.objeto ){
                    this.dialogAcciones.show = false;
                    this.get();
                }

            })
            .catch( error => console.log(error) );

        },

        delete(){

        },

        //#endregion
    
        //#region Consumo Api-Configuracion
        getEquipos(){
            this.apiConfiguracion.recuperaEquipos(this.URLApi,0,0)
            .then( response => {
                this.listaEquipos = response.data.objeto;
                //console.log('getEquipos', this.listaEquipos);
            })
            .catch( error => console.log(error));
        },
        //#endregion
    },
    mounted(){
        this.pasarTotal(this.totalTraslados);
        this.get();
        this.getEquipos();

    }
    
}
</script>