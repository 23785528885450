import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Inicio from '@/views/Inicio.vue'
import ConfiguracionPage from '@/views/ConfiguracionPage.vue'
import AmbulanciasPage from '@/views/AmbulanciasPage.vue'
import HojaTrabajoPage from '@/views/HojaTrabajoPage.vue'
import ContratosPage from '@/views/ContratosPage.vue'
import InventarioPage from '@/views/InventarioPage.vue'
import UsuariosPage from '@/views/UsuariosPage.vue'
import PuntoVentaPage from '@/views/PuntoVentaPage.vue'
import PagosPage from '@/views/PagosPage.vue'
import ReportesPage from '@/views/ReportesPage.vue'
import PagoStripePublicoComponent from '@/components/Pagos/PagoStripePublicoComponent';
import EstudiosPage from '@/views/EstudiosPage.vue'
import PublicPage from '@/views/PublicPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PublicPage',
    component: PublicPage
  },
  {
    path: '/c/:IdCredito',
    name: 'PagoPublico',
    component: PagoStripePublicoComponent
  },
  {
    path: '/operacion',
    name: 'Operacion',
    component: Home,
  },
  {
    path: '/operacion/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    
  },
  {
    path: '/operacion/inicio',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/operacion/configuracion',
    name: 'Configuracion',
    component: ConfiguracionPage
  },
  {
    path: '/operacion/ambulancias',
    name: 'Ambulancias',
    component: AmbulanciasPage
  },
  {
    path: '/operacion/ambulancias/:ContratoCrum/:NumeroCrum/:EstatusCrum',
    name: 'AmbulanciasParametro',
    component: AmbulanciasPage
  },
  {
    path: '/operacion/hojatrabajo',
    name: 'HojaTrabajo',
    component: HojaTrabajoPage
  },
  {
    path: '/operacion/contratos',
    name: 'Contratos',
    component: ContratosPage
  },
  {
    path: '/operacion/inventario',
    name: 'Inventario',
    component: InventarioPage
  },
  {
    path: '/operacion/usuarios',
    name: 'usuarios',
    component: UsuariosPage
  },
  {
    path: '/operacion/ventas',
    name: 'Ventas',
    component: PuntoVentaPage
  },
  {
    path: '/operacion/pagos',
    name: 'Pagos',
    component: PagosPage
  },
  {
    path: '/operacion/reportes',
    name: 'Reportes',
    component: ReportesPage
  },
  {
    path: '/operacion/estudios',
    name: 'Estudios',
    component: EstudiosPage
  },

]

const router = new VueRouter({
  mode: 'hash',//history
  base: process.env.BASE_URL,
  routes
})

export default router
