<template>
    <v-container>
        <v-row>
            <v-col cols="8" md="2">
                <v-text-field
                    label="Contrato"
                    v-model="filtro.Contrato"
                    clearable
                    @click:clear="clearFiltro()"
                    dense
                >
                </v-text-field>
            </v-col>
            <v-col cols="4" md="2">
                <v-btn 
                    block
                    class="white--text"
                    color="blue"
                    small
                >
                    Consultar
                </v-btn>
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    label="Estatus"
                    :items="listaEstatus"
                    item-value="value"
                    item-text="text"
                    v-model="selectEstatus"
                    dense
                    @change="selectDropDownEstatus()"
                ></v-select>

            </v-col>

            <v-col cols="4" md="2" >
                <v-text-field
                    readonly
                    v-model="listaContratos.length"
                    class="text-caption"
                    prefix="contratos:"
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="8" md="2">
                <v-btn
                    color="blue"
                    class="white--text"
                    small
                    block
                >
                    Pagar
                </v-btn>
            </v-col>

            <v-col cols="4"  md="2">
                <v-btn
                    color="orange"
                    class="white--text"
                    small
                    block
                    @click="mostrarDialog()"
                >
                    Registrar Pago
                </v-btn>

            </v-col>

        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="listaContratos"
                    :single-select="singleSelect"
                    :items-per-page="20"
                    :footer-props="{
                        'items-per-page-text':'Contratos por página',
                    }"
                    :loading="loadingDatatable"
                    item-key="Numero"
                    class="elevation-1"
                    @click:row="handleClick"
                    loading-text="Buscando datos"
                > <!--show-select-->
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogPago.show" max-width="700" persistent>
            <v-card>
                <v-card-title>
                    <span > {{ dialogPago.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogPago.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-subtitle>
                    R.F.C. {{ oContrato.RFCS }} 
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="4" md="3">
                            <v-text-field
                                label="Contrato"
                                v-model="oContrato.Contrato"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8" md="9">
                            <v-text-field
                                label="Socio"
                                v-model="oContrato.NombreS"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                prefix="Estatus:"
                                v-model="oContrato.Estatus"
                                class="text-caption"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Fecha Inicio de Pago"
                                v-mask="'####-##-##'"
                                v-model="oPago.FechaInicioPago" 
                                :rules="rules.fecha"
                                placeholder="AAAA-MM-DD"
                                hint="AAAA-MM-DD Ej.: 2019-01-15"
                                persistent-hint
                                dense
                                />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Fecha de Vencimiento"
                                v-mask="'####-##-##'"
                                v-model="oPago.FechaVencimiento" 
                                :rules="rules.fecha"
                                placeholder="AAAA-MM-DD"
                                hint="AAAA-MM-DD Ej.: 2019-01-15"
                                persistent-hint
                                dense
                                />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Pago"
                                v-model="oPago.MontoTotal"
                                prefix="$"
                                type="number"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="9">
                            <v-alert
                                    border="left"
                                    v-model="vAlert.show"
                                    type="info"
                                    outlined
                                    dense
                                >
                                <v-row>
                                    <v-col cols="8" md="8">
                                        {{ vAlert.text }}
                                    </v-col>
                                    <v-col cols="2" md="2" class="d-flex justify-end">
                                        <v-btn
                                            color="green"
                                            class="white--text"
                                            small
                                            @click="confirmaRegistroCredito()"
                                        >
                                        SI
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" md="2" class="d-flex justify-end">
                                        <v-btn
                                            text
                                            class="white--text"
                                            small
                                            @click="vAlert.show = false"
                                        >
                                            <v-icon color="red" >mdi-close </v-icon>
                                        </v-btn>
                                    </v-col>  


                                </v-row>
                            </v-alert>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn
                                color="blue"
                                small
                                class="white--text"
                                :disabled="vAlert.show"
                                @click="comprobarRegistro()"
                            >
                                Registrar Pago
                            </v-btn>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-dialog>

    </v-container>
</template>

<script>
import {mapState} from "vuex";
import ApiCreditoService from '@/services/ApiCreditoService';
import ApiContratosService from '@/services/ApiContratosService';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User', 'TOKEN', 'StripePK']),
    },
    //#endregion

    //#region Data
    data(){
        return{
            apiContrato:new ApiContratosService(),
            apiCredito:new ApiCreditoService(),
            //--------------------------------------------------------
            loadingDatatable:true,
            selected: [],
            singleSelect: true,
            //--------------------------------------------------------
            dialogPago:{
                show:false,
                text:'Registrar Pago',
                color:''
            },
            //--------------------------------------------------------
            vAlert:{
                show:false,
                text:'¿La orden de pago es correcta?'
            },
            //--------------------------------------------------------
            headers:[
                { text: 'Contrato', value: 'Contrato' },
                { text: 'Tipo', value: 'Tipo' },
                { text: 'Nombre Socio', value: 'NombreS' },
                { text: 'Domicilio', value: 'DomicilioS' },
                { text: 'Telefono', value: 'TelefonosS' },
                { text: 'Beneficiarios', value: 'Beneficiarios' },
            ],
            listaContratos:[],
            listaEstatus:[
                { text: 'Todos', value:'TODOS'},
                { text: 'Activo', value:'ACTIVO'},
                { text: 'Cancelado', value:'CANCELADO'},
            ],
            selectEstatus:null,
            filtro:{
                Contrato:null,
                Estatus:'TODOS',
            },
            //----------------------------------------------------
            oContrato:{},
            //----------------------------------------------------
            oPago:{
                Contrato:null,
                NombreS:null,
                MontoTotal:null,
                FechaInicioPago:null,
                FechaVencimiento:null,

            },
            //----------------------------------------------------
            rules:{
                fecha:[
                    v => /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'La fecha debe de tener el formato indicado.',
                ],
            },

        }
    },
    //#endregion

    //#region  Methods
    methods:{
        //#region Metodos de la vista
        handleClick(value) {
            //console.log('handleClick', value);
            this.filtro.Contrato = value.Contrato;
            this.selectEstatus = value.Estatus;

            this.oContrato = value;
            console.log('this.oContrato', this.oContrato);
        },

        selectDropDownEstatus(){ 
            this.filtro.Estatus = this.selectEstatus; 
            this.getContrato();
        },
        clearFiltro(){
            this.filtro.Estatus = this.listaEstatus[0].value;
            //this.selectEstatus = this.listaEstatus[0].value;
            this.selectEstatus = null;
            this.oContrato = {};
        },
        mostrarDialog(){
            if( this.filtro.Contrato > 0 ){
                this.dialogPago.show = true;
            }

        },
        fechaActual(mesAnterior=1){//Si se pasa el parametro 0, da el mes anterior
            const date =  new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+mesAnterior;
            //const D = date.getDate();
            const D = 5;
            return Y.toString() + '-' + M.toString().padStart(2,'00') + '-' + D.toString().padStart(2,'00');
        },
        fechaInicio(mesAnterior=1){//Si se pasa el parametro 0, da el mes anterior
            const date =  new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+mesAnterior;
            //const D = date.getDate();
            const D = 1;
            return Y.toString() + '-' + M.toString().padStart(2,'00') + '-' + D.toString().padStart(2,'00');
        },
        comprobarRegistro(){
            this.vAlert.show = true;
        },
        confirmaRegistroCredito(){
            this.guardaCredito();
        },

        //#endregion

        //#region Metods de la api de Contratos
        getContrato(){
            
            //Solo puede mostrar uno por seguridad
            this.apiContrato.recuperaContratos( this.URLApi, 
                    0, 0, 'TODOS', 'TODOS', this.filtro.Estatus)
            .then( response => {
                //console.log('getContrato.data', response.data)
                this.listaContratos = response.data.objeto;

                if(this.listaContratos.length>0){
                    this.loadingDatatable = false;

                }
                
            })
            .catch(error=>{console.log(error);});

        },
        //#endregion

        //#region Metodos de la api de Credito
        guardaCredito(){
            this.apiCredito.guardaCredito(this.URLApi, this.oContrato.Contrato, this.oContrato.NombreS,
                this.oPago.FechaInicioPago, this.oPago.FechaVencimiento, this.oPago.MontoTotal,
                this.User.Nombre
             )
            .then( response => {
                console.log('guardaCredito.data', response.data);
                if( response.data.objeto>0){
                    this.vAlert.show = false;
                    this.dialogPago.show = false;
                }
            })
            .catch( error => console.log( erro ) );
        }
        //#endregion

    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        this.getContrato();
        this.oPago.FechaInicioPago = this.fechaInicio(2);
        this.oPago.FechaVencimiento = this.fechaActual(2);

    },
    //#endregion

}
</script>
