<template>
    <div>
        <!--
                    <v-text-field
                    label="Fecha:"
                    v-mask="'####/##/##'"
                    v-model="filtro.fecha" 
                    :rules="rules.fecha"
                    placeholder="AAAA/MM/DD"
                    hint="AAAA/MM/DD Ej.: 2019/01/15"
                    persistent-hint
                    />
                -->

        <v-btn
            color="blue"
            class="white--text"
            block
            @click="dialogCrum.show=true"
            >
            Historial de Servicios
        </v-btn>

        <v-dialog v-model="dialogCrum.show" max-width="1000" persistent>
            <v-card>
                <v-card-title>
                    <span > {{ dialogCrum.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogCrum.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>
                <v-card-text>
                    <HistorialCrumComponent></HistorialCrumComponent>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import HistorialCrumComponent from '@/components/Ambulancias/HistorialCrumComponent';

export default {
    //#region props
    props:{
        pMes:String,
        pYear:Number
    },
    //#endregion

    //#region Components
    components:{
        HistorialCrumComponent,
    },
    //#endregion

    //#region Data
    data(){
        return{
            filtro:{
                fecha:'',
            },
            rules:{
                fecha:[
                    v => /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'La fecha debe de tener el formato indicado.',
                ],
            },
            dialogCrum:{
                show:false,
                text:'Historial de servicios',
                color:''
            }

        }
    },
    //#endregion

    //#region Methods
    methods:{
        fechaActual(mesAnterior=1){//Si se pasa el parametro 0, da el mes anterior
            const date =  new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+mesAnterior;
            const D = date.getDate();
            return Y.toString() + '/' + M.toString().padStart(2,'00') + '/' + D.toString().padStart(2,'00');
        },
    },
    //#endregion

    //#region beforeMount
    beforeMount(){
        this.filtro.fecha = this.fechaActual();
    }
    //#endregion
    
}
</script>