<template>
    <div>
        <v-container>
            <v-app-bar  elevation="3"
                        :absolute="true"
                        :fixed="true"
                        dense>

                    <v-app-bar-nav-icon to="/"></v-app-bar-nav-icon>
                    

                <v-toolbar-title> 
                    {{ tituloPagina }}                     
                </v-toolbar-title>

                <v-spacer></v-spacer>
                {{ User.Nombre }}

                

                <v-btn
                    color="red"
                    text
                    @click="$router.go(-1)"
                    >
                    Atras
                </v-btn>

            </v-app-bar>
        </v-container>
        <br>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },

    props:{
        tituloPagina:String
    },

    data(){
        return{
            menu:false,
            group: null,
        }
    }


    
}
</script>