import axios from 'axios';

export default class ApiReportesService {

    urlDescargaReporteCrum(url, NumeroCrum){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteCrum';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroCrum,
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        const ruta = `${ url }/${ controller }/${ method }/${ NumeroCrum }`;
        return ruta;
    }

    enviarCorreoCrum(url, NumeroCrum, DireccionCorreo, Asunto, CuerpoMensaje){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'enviarEmailCrum';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroCrum, 
            DireccionCorreo, 
            Asunto, 
            CuerpoMensaje
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    getListaReportes(){
        let reportesList = [
            { value:'ESTADISTICAS_CORPORATIVAS'         , text:'1. Estádisticas Corporativas'  },
            { value:'ESTADISTICAS_FAMILIARES'           , text:'2. Estádisticas Familiares'  },
            { value:'BITACORA_VISITAS_AMBULANCIAS'      , text:'3. Bitacora de visitas de Ambulancias'  },
            { value:'SERVICIOS_POR_EQUIPO'              , text:'4. Servicios por equipo'  },
            { value:'REPORTE_CONTRATOS'                 , text:'5. Reporte de Contratos'  },
            { value:'SERVICIO_SOCIO'                    , text:'6. Servicios de un Socio'  },
            { value:'VISITAS_MEDICOS'                   , text:'7. Visitas de Médicos'  },
            { value:'CORTE_MENSUAL'                     , text:'8. Corte Mensual'  },
            { value:'INVENTARIO'                        , text:'9. Inventario'  },
            { value:'ESTUDIOS'                          , text:'10. Estudios'  },
        ];

        return reportesList;
        
    }


    //Reporte #1
    DescargaReporteSocios(url, Mes, Year, Socio = 'TODOS', Tipo = 'CORPORATIVO'){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteSocios';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Tipo }/${ Socio }/${ Mes }/${ Year }`, json);
    }

    //Reporte #2
    DescargaReporteFamiliares(url, Mes, Year, Socio = 'TODOS', Tipo = 'FAMILIAR'){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteFamiliares';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Tipo }/${ Socio }/${ Mes }/${ Year }`, json);
    }

    //Reporte #3
    DescargaReporteCrumVisitas(url, Fecha){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteCrumVisitas';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Fecha }`, json);
    }

    //Reporte #4
    DescargaReporteServiciosEquipo(url, Equipo, FechaDesde, FechaHasta){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteServiciosEquipo';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Equipo }/${ FechaDesde }/${ FechaHasta }`, json);
    }

    //Reporte #5
    DescargaReporteContratos(url, Estatus){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteContratos';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Estatus }`, json);
    }

    //Reporte #6
    DescargaReporteServicioSocios(url, Socio, FechaDesde, FechaHasta){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteServicioSocios';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Socio }/${ FechaDesde }/${ FechaHasta }`, json);
    }

    //Reporte #7
    DescargaReporteVisitaMedicos(url, Medico, FechaDesde, FechaHasta){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteVisitaMedicos';

        //Construccion de objeto a enviar
        const objeto = { 
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }/${ Medico }/${ FechaDesde }/${ FechaHasta }`, json);
    }

    //Reporte #8
    DescargaReporteCorte(url, NumeroCorte, Tipo, Mes, Year, FechaDesde, FechaHasta){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteCorteMes';

        //Construccion de objeto a enviar
        const objeto = { 
            NumeroCorte,
            Tipo, 
            Mes, 
            Year, 
            FechaDesde, 
            FechaHasta
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }

    //Reporte #9
    DescargaReporteInventario(url, Tipo){
        //Definicion de ruta
        const controller = 'Reportes';
        const method = 'descargaReporteInventario';

        //Construccion de objeto a enviar
        const objeto = { 
            Tipo
        };
        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.post(`${ url }/${ controller }/${ method }`, json);
    }


}