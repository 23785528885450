<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field
                    label="Desde:"
                    v-mask="'####-##-##'"
                    v-model="oCrum.FechaDesde" 
                    :rules="rules.fecha"
                    placeholder="AAAA-MM-DD"
                    hint="AAAA-MM-DD Ej.: 2019-01-15"
                    persistent-hint
                    />
            </v-col>
            <v-col cols="12"  md="3">
                <v-text-field
                    label="Hasta:"
                    v-mask="'####-##-##'"
                    v-model="oCrum.FechaHasta" 
                    :rules="rules.fecha"
                    placeholder="AAAA-MM-DD"
                    hint="AAAA-MM-DD Ej.: 2019-01-15"
                    persistent-hint
                    />
            </v-col>
            <v-col cols="12" md="4">
                <v-btn
                    color="blue"
                    class="white--text"
                    @click="getCrum()"
                    block
                    >
                    <v-icon> mdi-magnify</v-icon>
                    Buscar
                </v-btn>
            </v-col>
            <v-col cols="12" md="2" class="d-flex justify-end">

                <div v-if="listaCrum.length>0">
                    Encontrados: {{ listaCrum.length }}
                </div>

            </v-col>
        </v-row>

        <v-row>
            <v-list two-line>
                <v-list-item v-for="(item, index) of listaCrum" :key="index"
                            @click="() =>{}" >

                    <v-list-item-content>
                        <v-row>
                            <v-col md="5">
                                Socio: {{ item.Socio }}
                            </v-col>
                            <v-col md="4">
                                Paciente: {{ item.Paciente }}
                            </v-col>
                            <v-col md="3" class="d-flex justify-end">
                                <BotonReporteCrumComponent
                                    :key="item.Numero"
                                    :NumeroCrum="Number.parseInt(item.Numero)"
                                ></BotonReporteCrumComponent>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="3">
                                Fecha: {{ item.Fecha }}
                            </v-col>
                            
                            <v-col md="2">
                                Tipo: {{ item.Tipo }}
                            </v-col>
                            <v-col md="3">
                                Estatus: {{ item.Estatus }}
                            </v-col>
                            <v-col md="4" class="d-flex justify-end">
                                <EnviarReporteCrumComponent
                                    :key="item.Numero"
                                    :NumeroCrum="Number.parseInt(item.Numero)"
                                />
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-row>

    </v-container>
</template>

<script>
import {mapState} from "vuex"; 
import ApiConfiguracionService from '@/services/ApiConfiguracionService';
//import ApiContratosService from '@/services/ApiContratosService';
import ApiAmbulanciasService from '@/services/ApiAmbulanciasService';

import BotonReporteCrumComponent from '@/components/Bloques/BotonReporteCrumComponent';
import EnviarReporteCrumComponent from '@/components/Bloques/EnviarReporteCrumComponent';

export default {
    //#region Computed
    computed:{ ...mapState(['URLSrc', 'URLApi', 'User']), }, 
    //#endregion 

    //#region Components
    components:{
        BotonReporteCrumComponent,
        EnviarReporteCrumComponent
    },
    //#endregion

    //#region Data
    data(){
        return{
            //Objetos de api
            apiConfiguracion:new ApiConfiguracionService(),
            apiAmbulancias:new ApiAmbulanciasService(),
            //apiContratos:new ApiContratosService(), 
            //------------------------------------------------------
            rules:{
                fecha:[
                    v => /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'La fecha debe de tener el formato indicado.',
                ],
            },
            oCrum:{
                Numero:0,
                Contrato:0,
                Estatus:'',
                FechaDesde:'',
                FechaHasta:''
            },
            listaCrum:[]
            
        }
    },
    //#endregion

    //#region Methods
    methods:{
        //#region Metodos de la vista
        fechaActual(mesAnterior=1){//Si se pasa el parametro 0, da el mes anterior
            const date =  new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+mesAnterior;
            const D = date.getDate();
            return Y.toString() + '/' + M.toString().padStart(2,'00') + '/' + D.toString().padStart(2,'00');
        },
        //#endregion

        //#region Metodos de api
        getCrum(){
            this.listaCrum = [];
            this.apiAmbulancias.recuperaCrum(  this.URLApi, 
                                        this.oCrum.Numero, this.oCrum.Contrato, this.oCrum.Estatus,
                                        this.oCrum.FechaDesde, this.oCrum.FechaHasta )
            .then( response =>{
                console.log('getCrum.data',response.data);
                const lista =  response.data.objeto;
                if( lista.length > 0 ){
                    //console.log('Encontro mas de uno');
                    this.listaCrum = lista;
                }
                else{
                    console.log('No encontró nada.');
                }

            })
            .catch(error=>{console.log(error);});
        }
        //#endregion

    },
    //#endregion

    //#region beforMount
    beforeMount(){
        this.oCrum.FechaDesde = this.fechaActual(0);
        this.oCrum.FechaHasta = this.fechaActual();
        this.getCrum();
    }
    //#endregion


}
</script>


<!--
<EnviarReporteCrumComponent
    :key="oCrum.Numero"
    :NumeroCrum="Number.parseInt(oCrum.Numero)"
/>
-->

<!--
<BotonReporteCrumComponent
    :key="oCrum.Numero"
    :NumeroCrum="Number.parseInt(oCrum.Numero)"
></BotonReporteCrumComponent>
-->