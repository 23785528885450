import axios from 'axios';


const _Controller = "CatEstudiosLab"; 
export default class ApiCatEstudiosService {

     

    //#region Estudios

    recuperaCatEstudios(url){
        //Definicion de ruta
        const method = 'recuperaCatalogoEstudios';
    
        //Construccion de objeto a enviar
        const objeto = { 
        };

        const objetoString = JSON.stringify(objeto);
        const json = 'json='+objetoString;
        
        //Consumo de api
        return axios.get(`${ url }/${ _Controller }/${ method }`);
    }

    obtienePropiedadesEstudio(url, idEstudio){

         //Definicion de ruta
         const method = 'recuperaPropiedadesEstudio';
     
         //Construccion de objeto a enviar
         const objeto = {
            idEstudio: idEstudio
         };
 
         const objetoString = JSON.stringify(objeto);
         const json = 'json='+objetoString;

         
         //Consumo de api
         return axios.get(`${ url }/${ _Controller }/${ method }/${ idEstudio }`);
    }

    //#endregion


}