<template>
    <div>

        <v-form>
            
            <v-row>
                <v-col cols="12" md="3">
                    <v-checkbox
                        label="¿Factura?"
                        v-model="DatosPago.Factura"
                        dense
                    >
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="9">
                    <v-text-field
                        label="Empresa / Cliente"
                        v-model="DatosPago.Empresa"
                        dense
                    />
                </v-col>

                <v-col cols="12" md="2">
                    <v-select
                        label="Forma pago"
                        v-model="selectFormaPago"
                        :items="listaFormaPago"
                        item-value="value"
                        item-text="text"
                        @change="selectDropDownFormaPago()"
                        dense
                    ></v-select>
                </v-col>


                <v-col cols="12" md="3">
                    <v-text-field
                        label="Usuario"
                        v-model="DatosPago.Usuario"
                        dense
                    />
                </v-col>

                <v-col cols="12" md="3">
                    <v-text-field
                        label="Autoriza"
                        v-model="DatosPago.Autoriza"
                        dense
                    />
                </v-col>

                <v-col cols="12" md="2">
                    <v-select
                        label="Descuento"
                        :items="listaDescuentos"
                        v-model="selectDescuento"
                        @change="selectDropDownDescuento()"
                        dense
                    ></v-select>
                </v-col>

                <v-col cols="12" md="2" v-if="selectFormaPago != 'EFECTIVO'">
                    <v-text-field
                        label="Comision Tarjeta"
                        v-model="DatosPago.ComisionTarjeta"
                        dense
                    />
                </v-col>


            </v-row>

        </v-form>


    </div>
</template>

<script>
import {mapState} from "vuex";

export default {

    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
        selectDescuento:{
            get(){
                return this.DatosPago.Descuento;
            },
            set(newValue){
                this.DatosPago.Descuento = newValue;
            }
        },
        selectFormaPago:{
            get(){
                return this.DatosPago.FormaPago;
            },
            set(newValue){
                this.DatosPago.FormaPago = newValue;
            }
        },

    },
    //#endregion


    //#region Props
    props:{
        DatosPago:{
                Factura:'',
                Empresa:'',
                Usuario:'',
                Autoriza:'',
                Descuento:'',
                FormaPago:'',
                ComisionTarjeta:0
        }
    },
    //#endregion

    //#region Components
    components:{
        
    },
    //#endregion

    //#region Data
    data(){
        return{
            listaDescuentos:[
                0,5,8,10,15,20,25,30,35
            ],
            listaFormaPago:[
                {text:'Efectivo', value:'EFECTIVO'},
                {text:'Tarjeta de crédito', value:'TARJETA CREDITO'},
                {text:'Tarjeta de debito', value:'TARJETA DEBITO'},
            ],

        }
    },
    //#endregion

    //#region Methods
    methods:{
        selectDropDownDescuento(){ this.DatosPago.Descuento = this.selectDescuento; },
        selectDropDownFormaPago(){ this.DatosPago.FormaPago = this.selectFormaPago; },
    },
    //#endregion

    //#region mounted
    mounted(){
        //this.DatosPago.Autoriza = this.User.Nombre;
    },
    //#endregion

    //#region beforeMount
    beforeMount(){

    },
    //#endregion

    //#region watch
    watch:{
        DatosPago(val){
            console.log('DatosPago', val);
            this.selectFormaPago = val.FormaPago;
        }
    },
    //#endregion

}
</script>