<template>
    <div>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="white--text"
                    color="blue"
                    @click="dialogInventario.show = true"
                    >
                    <v-icon>
                        mdi-cash-register
                    </v-icon>
                </v-btn>
            </template>
            <span>Corte ó Reporte</span>
        </v-tooltip>

        <!--INICIO Dialog Inventario o Corte -->
        <v-dialog v-model="dialogInventario.show" max-width="1000" >
            <v-card>
                <v-card-title>
                    <span > {{ dialogInventario.text }} </span>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogInventario.show = false"
                            >
                                <v-icon color="red" >mdi-close </v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                </v-card-title>

                <v-card-text>
                    <v-tabs v-model="tab" >
                        <v-tab v-for="item of tabs" :key="item.tab" >
                            {{ item.title }}
                        </v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item> <CorteComponent></CorteComponent> </v-tab-item>
                            <!--<v-tab-item> <RptInventarioComponent></RptInventarioComponent> </v-tab-item>-->
                        </v-tabs-items>
                    </v-tabs>
                </v-card-text>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";

import ApiInventario from '@/services/ApiInventarioService';
import ApiPuntoVenta from '@/services/ApiPuntoVentaService';

//Components
import CorteComponent from '@/components/PuntoVenta/CorteComponent';
import RptInventarioComponent from '@/components/Reportes/RptInventarioComponent';

export default {
    //#region Computed
    computed:{
        ...mapState(['URLSrc', 'URLApi', 'User']),
    },
    //#endregion

    //#region components
    components:{
        CorteComponent,
        RptInventarioComponent,
    },
    //#endregion


    //#region Data
    data(){
        return{
            apiInventario: new ApiInventario(),
            apiPVenta: new ApiPuntoVenta(),
            //--------------------------------------------------------
            dialogInventario:{
                show:false,
                text:'Administración del Punto de Venta',
                color:''
            },
            tab:0,
            tabs:[
                //{ tab:0, title:'Ciudades', content:'<CiudadesComponent></CiudadesComponent>' },
                { tab:0, title:'Corte', content:'<CorteComponent></CorteComponent>' },
                //{ tab:1, title:'Reporte', content:'<RptInventarioComponent></RptInventarioComponent>' },
            ]

        }
    },
    //#endregion
}
</script>